import type { GridApi, GridOptionsWrapper, ICellRendererParams, RowNode } from 'ag-grid-community';
import { useState, type MouseEvent } from 'react';
import { useDynamicCallback } from '../../../hooks';
import { IconButton } from '../../Button';
import { FormControlSizes } from '../../Form';
import { IconName } from '../../Icons';
import { baseColumn } from './baseColumn';
import type { ColDefFactory, Column } from './types';

export interface ExpandRowColumnParams {
  /**
   * If you want the security column to be able to expand the height of their row, then define this function.
   * The function tells the cell what to set the height to if the user was to expand the row.
   *
   * If the expanded height == default height, no button will be shown.
   *
   * If you return undefined, the row will not change heights.
   */
  getExpandedHeight?: (node: RowNode<any>, api: GridApi<any>) => number | undefined;
}

export const expandRow: ColDefFactory<Column<ExpandRowColumnParams>> = column => ({
  ...baseColumn(column),
  width: 40,
  pinned: 'right',
  headerName: '',
  suppressMenu: true,
  suppressColumnsToolPanel: true,
  lockVisible: true,
  lockPinned: true,
  type: 'rightAligned',
  exportable: false,
  cellRenderer: (params: ICellRendererParams<unknown, unknown>) => {
    if (!params.node.data) {
      return <></>;
    }

    return <ExpandRowButton {...column.params} {...params} />;
  },
});

type ExpandRowButtonProps = ICellRendererParams & ExpandRowColumnParams;

function ExpandRowButton({ getExpandedHeight, api, node }: ExpandRowButtonProps) {
  // When we use the term "expanded" in this component, we are referring to height expansion of leaf nodes, not row group expansion.

  const [defaultRowHeight] = useState(() => {
    const gridOptionsWrapper: GridOptionsWrapper = api['gridOptionsWrapper'];
    return gridOptionsWrapper.getRowHeightAsNumber();
  });

  const isRowExpanded = node.rowHeight !== defaultRowHeight;
  const handleExpandCollapseClick = useDynamicCallback((e: MouseEvent) => {
    e.stopPropagation();
    if (isRowExpanded) {
      // collapse clicked
      node.setRowHeight(defaultRowHeight);
      api.onRowHeightChanged();
      api.refreshCells({ rowNodes: [node], force: true });
    } else {
      // expand clicked
      const heightToExpandTo = getExpandedHeight?.(node, api);
      if (heightToExpandTo == null) {
        return;
      }
      node.setRowHeight(heightToExpandTo);
      // We need to call onRowHeightChanged to let aggrid know that some row height has changed
      api.onRowHeightChanged();
      api.refreshCells({ rowNodes: [node], force: true });
    }
  });

  const showExpandCollapseButton = getExpandedHeight != null && getExpandedHeight(node, api) != null;

  if (!showExpandCollapseButton) {
    return <></>;
  }

  return (
    <IconButton
      ghost
      icon={isRowExpanded ? IconName.ListCollapse : IconName.ListExpand}
      size={FormControlSizes.Small}
      onClickCapture={handleExpandCollapseClick}
    />
  );
}
