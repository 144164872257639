import { noop } from 'lodash';
import { createContext, useContext, useRef, type ReactNode } from 'react';
import type { DialogProps } from '../components/Dialog/types';
import { useDisclosure, type UseDisclosureReturn } from '../hooks/useDisclosure';
import { useDynamicCallback } from '../hooks/useDynamicCallback';

type OpenDialogProps = Partial<DialogProps> & { content: JSX.Element };

const Dialog = createContext<
  | ({
      content?: JSX.Element;
      open: (params?: OpenDialogProps) => void;
    } & DialogProps &
      Omit<UseDisclosureReturn, 'open'>)
  | undefined
>(undefined);

export const useGlobalDialog = () => {
  const context = useContext(Dialog);
  if (context === undefined) {
    throw new Error('Missing Dialog.Provider further up in the tree. Did you forget to add it?');
  }
  return context;
};

export const DialogProvider = function DialogProvider({ children }) {
  const onConfirmRef = useRef<() => void>();
  const onCancelRef = useRef<() => void>();
  const contentRef = useRef<JSX.Element>();
  const titleRef = useRef<ReactNode>();
  const confirmLabelRef = useRef<ReactNode>('Yes');
  const cancelLabelRef = useRef<ReactNode>('No');
  const widthRef = useRef<number | string>();
  const showCloseRef = useRef(false);
  const showCancelRef = useRef(true);
  const showConfirmRef = useRef(true);
  const closeOnClickOutside = useRef(true);

  // TODO: parameterize more props as needed, starting with most common for now
  // NOTE: usePortal is not supported. This Dialog is on top of the DOM chain which means that we can't actually place it inline the local component
  const dialog = useDisclosure();

  const openDialog = useDynamicCallback((params?: OpenDialogProps) => {
    onConfirmRef.current = params?.onConfirm ?? noop;
    onCancelRef.current = params?.onCancel ?? noop;
    contentRef.current = params?.content ?? undefined;
    titleRef.current = params?.title;
    confirmLabelRef.current = params?.confirmLabel ?? 'Yes';
    cancelLabelRef.current = params?.cancelLabel ?? 'No';
    widthRef.current = params?.width;
    showCloseRef.current = params?.showClose ?? false;
    showCancelRef.current = params?.showCancel ?? true;
    showConfirmRef.current = params?.showConfirm ?? true;
    closeOnClickOutside.current = params?.closeOnClickOutside ?? true;
    dialog.open();
  });

  return (
    <Dialog.Provider
      value={{
        ...dialog,
        open: openDialog,
        onConfirm: onConfirmRef.current,
        onCancel: onCancelRef.current,
        title: titleRef.current,
        confirmLabel: confirmLabelRef.current,
        cancelLabel: cancelLabelRef.current,
        width: widthRef.current,
        showClose: showCloseRef.current,
        content: contentRef?.current,
        showCancel: showCancelRef.current,
        showConfirm: showConfirmRef.current,
        closeOnClickOutside: closeOnClickOutside.current,
      }}
    >
      {children}
    </Dialog.Provider>
  );
};
