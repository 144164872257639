import {
  Divider,
  ExpandableBottomPanel,
  HStack,
  isTalosUser,
  Tab,
  TabAppearance,
  TabList,
  Tabs,
  TabSize,
  useDynamicCallback,
  useExpandablePanel,
  useTabs,
  VStack,
} from '@talos/kyoko';
import { capitalize } from 'lodash';
import { useState } from 'react';
import { ErrorBoundary } from '../../../../components/ErrorBoundary';
import { useUser } from '../../../../hooks';
import { Module } from '../../components/Module';
import { BlotterWrapper, XScrollableContainer } from '../../styles';
import { HistoricalPerformanceChart } from '../Chart';
import { PerformanceBlotterContainer } from '../PerformanceBlotter';
import { usePerformanceContext } from '../providers/PerformanceStateAndTabsProvider';
import { BenchmarkControl } from './BenchmarkControl';
import { PnLUnitControl } from './PnLUnitControl';
import { ResetChartControl } from './ResetChartControl';
import { ShowDeltaControl } from './ShowDeltaControl';

const TABS = [
  {
    label: 'Positions',
    render: () => <PerformanceBlotterContainer />,
  },
];

const DEFAULT_BLOTTER_HEIGHT = 450;
const PERFORMANCE_BLOTTER_HEIGHT = 'PerformanceBlotterHeight';

export function PerformanceBottomContent() {
  const [isBlotterMaximized, setIsBlotterMaximized] = useState(false);
  const [isBlotterMinimized, setIsBlotterMinimized] = useState(false);

  const handleMaximizeBlotter = useDynamicCallback((shouldExpand: boolean) => {
    setIsBlotterMinimized(false);
    setIsBlotterMaximized(curr => shouldExpand ?? !curr);
  });

  const handleMinimizeBlotter = useDynamicCallback((shouldMinimize: boolean) => {
    setIsBlotterMaximized(false);
    setIsBlotterMinimized(curr => shouldMinimize ?? !curr);
  });

  const tabs = useTabs({
    initialSelectedIndex: 0,
    initialItems: TABS,
  });

  const { containerRef, ...panelProps } = useExpandablePanel<HTMLDivElement>({
    initialHeight: parseInt(localStorage.getItem(PERFORMANCE_BLOTTER_HEIGHT) ?? '0') || DEFAULT_BLOTTER_HEIGHT,
    isExpanded: isBlotterMaximized,
    isMinimized: isBlotterMinimized,
    onToggleExpanded: handleMaximizeBlotter,
    onToggleMinimize: handleMinimizeBlotter,
    onAdjustedHeight(newHeight) {
      localStorage.setItem(PERFORMANCE_BLOTTER_HEIGHT, newHeight.toString());
    },
  });

  const { state } = usePerformanceContext();

  // "all" -> "", "spot" -> "Spot"
  const titleProductPrefix = state.product === 'all' ? '' : capitalize(state.product);

  const user = useUser();
  const showPnLUnitControl = isTalosUser(user);

  return (
    <VStack w="100%" h="100%" overflow="hidden" position="relative" ref={containerRef}>
      <XScrollableContainer w="100%" flex="1 1 0%">
        <HStack minHeight="400px" w="100%" h="100%" minWidth="1000px" gap="spacingTiny">
          <Module
            w="100%"
            h="100%"
            title={`${titleProductPrefix} Performance (PnL)`}
            suffix={
              <HStack gap="spacingDefault">
                <ResetChartControl />
                <Divider orientation="vertical" />
                <BenchmarkControl />
                <ShowDeltaControl />
                {showPnLUnitControl && (
                  <>
                    <Divider orientation="vertical" />
                    <PnLUnitControl />
                  </>
                )}
              </HStack>
            }
          >
            <HistoricalPerformanceChart />
          </Module>
        </HStack>
      </XScrollableContainer>
      <BlotterWrapper>
        <ExpandableBottomPanel
          {...panelProps}
          showControls
          header={
            <Tabs {...tabs} appearance={TabAppearance.Filled} size={TabSize.Small}>
              <TabList>
                {TABS.map((tab, i) => (
                  <Tab key={i} {...tab} />
                ))}
              </TabList>
            </Tabs>
          }
        >
          {TABS.map(
            (tab, i) => tabs.selectedIndex === i && <ErrorBoundary key={`component-${i}`}>{tab.render()}</ErrorBoundary>
          )}
        </ExpandableBottomPanel>
      </BlotterWrapper>
    </VStack>
  );
}
