import {
  AGGRID_AUTOCOLUMN_ID,
  BlotterTable,
  BlotterTableFilters,
  ButtonVariants,
  FormControlSizes,
  IconButton,
  IconName,
  ToggleHorizontal,
  useBehaviorSubject,
  useBlotterTable,
  useObservable,
  usePersistedBlotterTable,
  type BlotterTableFilter,
  type MinimalSubscriptionResponse,
  type UsePersistedBlotterTable,
} from '@talos/kyoko';
import { useEffect, useState } from 'react';
import { shareReplay } from 'rxjs';
import { usePortfolioManagementProvider } from '../../providers/PortfolioManagementProvider';
import type { PortfolioRiskGridData } from '../../types/PortfolioRiskGridData';
import type { ContextSelectionBlotterParams, RiskAggMode, RiskPivotType } from '../../types/types';
import { useGroupedDataExportContext } from '../RiskBlotterExportMenuItems';
import { RiskBlotterSuffixMenu } from '../RiskBlotterSuffixMenu';
import { useRollupTreeMenu } from '../useRollupTreeMenu';
import { usePivotColumnShowHide, usePortfolioRiskColumns } from './usePortfolioRiskColumns';
import { useRollupTreeGridBuilders } from './useRollupTreeGridBuilders';

const riskPivotButtonBarOptions: Array<{ value: RiskPivotType; displayName: string }> = [
  {
    value: 'byTenor',
    displayName: 'By Tenor',
  },
  {
    value: 'byMoneyness',
    displayName: 'By Moneyness',
  },
];

const riskAggModeButtonBarOptions: Array<{ value: RiskAggMode; displayName: string }> = [
  {
    value: 'Net',
    displayName: 'Net',
  },
  {
    value: 'Gross',
    displayName: 'Gross',
  },
];

/**
 * PortfolioRiskBlotter display the underliers and assets of the selected subAccount (Rollup or Book) in a hierarchical grid.
 */
export function PortfolioRiskBlotter({ blotterID, wrapperContext, subAccountId }: ContextSelectionBlotterParams) {
  const { batchedRiskData } = usePortfolioManagementProvider();
  const { observable: riskSubAccountObs } = useBehaviorSubject(() => {
    return batchedRiskData ?? ({} as MinimalSubscriptionResponse<PortfolioRiskGridData>);
  }, [batchedRiskData]);
  const sharedObs = useObservable(() => riskSubAccountObs.pipe(shareReplay(1)), [riskSubAccountObs]);

  const defaultFilter: BlotterTableFilter = {};
  const [riskPivotType, setRiskPivotType] = useState<RiskPivotType>('byTenor');
  const [riskAggMode, setRiskAggMode] = useState<RiskAggMode>('Net');

  const { defaultColumns, defaultColumnsFlat } = usePortfolioRiskColumns();
  const persistedBlotterTable: UsePersistedBlotterTable<PortfolioRiskGridData> = usePersistedBlotterTable(blotterID, {
    persistColumns: false,
    columns: defaultColumnsFlat,
    filter: defaultFilter,
    sort: `+${AGGRID_AUTOCOLUMN_ID}`,
  });

  const { blotterParams } = useRollupTreeGridBuilders();

  const { getContextMenuItems, dialogs } = useRollupTreeMenu();

  const customColumnUpdate = usePivotColumnShowHide({
    riskPivotType,
    riskAggMode,
    initialSort: persistedBlotterTable.initialSort,
  });

  const groupedDataContext = useGroupedDataExportContext();
  const blotterTable = useBlotterTable<PortfolioRiskGridData>({
    dataObservable: sharedObs,
    rowID: 'rowID',
    rowSelection: 'single',
    sort: persistedBlotterTable.initialSort,
    onSortChanged: persistedBlotterTable.onSortChanged,
    columns: defaultColumnsFlat,
    groupableColumns: defaultColumns,
    context: groupedDataContext,
    getContextMenuItems,
    quickSearchParams: {
      entitySearchKeys: ['Asset', 'SubAccount'],
      filterOnAutoGroupLevels: true,
    },
    groupDefaultExpanded: -1,
    supportColumnColDefGroups: true,
    customColumnUpdate,
    ...blotterParams,
  });

  useEffect(() => {
    if (blotterTable.gridApi) {
      if (subAccountId) {
        blotterTable.gridApi.expandAll();
      } else {
        blotterTable.gridApi.collapseAll();
      }
    }
  }, [subAccountId, blotterTable.gridApi]);

  const { expandAllGroups, collapseAllGroups } = blotterTable;

  return (
    <>
      <BlotterTableFilters
        {...blotterTable.blotterTableFiltersProps}
        showFilterBuilder={false}
        showQuickFilterOnly={false}
        quickFilterPrefix={
          <>
            <ToggleHorizontal<RiskAggMode>
              value={riskAggMode}
              options={riskAggModeButtonBarOptions}
              onChange={setRiskAggMode}
            />
            <ToggleHorizontal<RiskPivotType>
              value={riskPivotType}
              options={riskPivotButtonBarOptions}
              onChange={setRiskPivotType}
            />
          </>
        }
        prefix={
          <>
            <IconButton
              icon={IconName.ListExpand}
              size={FormControlSizes.Small}
              variant={ButtonVariants.Default}
              onClick={expandAllGroups}
              data-testid="expand-all-button"
            />
            <IconButton
              icon={IconName.ListCollapse}
              size={FormControlSizes.Small}
              variant={ButtonVariants.Default}
              onClick={collapseAllGroups}
            />
          </>
        }
        suffix={
          <RiskBlotterSuffixMenu
            riskObs={sharedObs}
            exportDataAsCSV={blotterTable.exportDataAsCSV}
            exportDataAsExcel={blotterTable.exportDataAsExcel}
            label={wrapperContext.label}
          />
        }
      />
      <BlotterTable {...blotterTable} />
      {dialogs}
    </>
  );
}
