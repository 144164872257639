import { Box, HStack, Text, Toggle, usePortal } from '@talos/kyoko';
import { DOCK_VIEW_PORTAL_ID } from 'components/FlexibleLayout/constants';
import { useAppStateDispatch } from 'providers/AppStateProvider';
import { useMemo } from 'react';
import { Route } from 'react-router';
import styled, { useTheme, type DefaultTheme } from 'styled-components';
import { MarketAccountSelect } from './contextSelection/MarketAccountsSelect';
import { RollupSubAccountSelect } from './contextSelection/RollupSubAccountSelect';
import { getPortfolioLayoutOptions } from './layoutConfiguration/getPortfolioLayoutOptions';
import {
  getPortfolioViewActions,
  usePortfolioViewStateSelector,
} from './stateManagement/portfolioViewLayoutSlice.hooks';

const PortfolioWorkspaceLabel = () => {
  const layoutRoutes = useMemo(() => {
    const layoutOptions = getPortfolioLayoutOptions();
    return layoutOptions.map(layout => (
      <Route key={layout.route} path={`/portfolio${layout.route}`}>
        <HStack gap="spacingTiny" data-testid="portfolio-dashboard-workspace-label">
          <Text color="colorTextImportant" lineHeight="20px" fontSize="fontSizeLg">
            {layout.label}
          </Text>
        </HStack>
      </Route>
    ));
  }, []);

  return <HStack gap="spacingTiny">{layoutRoutes}</HStack>;
};

const { updateShowRollupHierarchy } = getPortfolioViewActions();
export const PortfolioWorkspaceHeader = () => {
  const theme = useTheme();
  const { view, showRollupHierarchy } = usePortfolioViewStateSelector();
  const dispatch = useAppStateDispatch();
  const { setPortalRef: dockViewRef } = usePortal(DOCK_VIEW_PORTAL_ID);

  return (
    <Box w="100%" background="backgroundContent">
      <HeaderHStack theme={theme} justifyContent="space-between">
        <HStack gap="spacingDefault">
          <PortfolioWorkspaceLabel />
          {view === 'operations' ? <MarketAccountSelect /> : <RollupSubAccountSelect />}
        </HStack>
        <HStack gap="spacingDefault" data-testid="portfolio-dashboard-controls">
          <Toggle
            label="Show Rollup Hierarchy"
            checked={showRollupHierarchy}
            onChange={value => {
              dispatch(updateShowRollupHierarchy(value));
            }}
          />
          <Box ref={dockViewRef} />
        </HStack>
      </HeaderHStack>
    </Box>
  );
};

const HeaderHStack = styled(HStack)<{ theme: DefaultTheme }>`
  border-bottom: solid 1px ${({ theme }) => theme.colors.gray['010']};
  padding: ${({ theme }) => theme.spacingDefault + 'px'};
  gap: ${({ theme }) => theme.spacingDefault + 'px'};
  justify-content: space-between;
  width: 100%;
`;
