import { invariant } from '@epic-web/invariant';
import {
  applySortsToColumns,
  type Column,
  type ColumnDef,
  type ISubaccount,
  type SizeColumnParams,
  type Tree,
  type UseBlotterTableProps,
} from '@talos/kyoko';
import {
  AGGRID_AUTOCOLUMN_ID,
  COLUMN_GROUP_NATIVE_TYPE,
  extractColumns,
  type BlotterTableSort,
  type ColumnGroup,
  type ColumnOrColumnGroup,
} from '@talos/kyoko/src/components/BlotterTable/types';
import { useDefaultColumnsWithGroupings } from '@talos/kyoko/src/components/BlotterTable/useDefaultColumnsWithGroupings';
import type { GridApi, GridOptions } from 'ag-grid-community';
import { useRollupTreeRef } from 'hooks';
import { isEqual, pick } from 'lodash';
import { useDisplaySettings } from 'providers/DisplaySettingsProvider';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import type { PortfolioRiskGridData } from '../../types/PortfolioRiskGridData';
import {
  GREEK_ORDER,
  GreeksDisplayNameMap,
  MONEYNESS_COLUMN_MAP,
  TENOR_COLUMN_MAP,
  UNDERLIER_GROUP_PREFIX,
  type Greeks,
  type MoneynessServerTypes,
  type PortfolioRiskBlotterGridData,
  type RiskAggMode,
  type RiskPivotType,
  type TenorServerTypes,
} from '../../types/types';

/** Business logic - for pivots, aggregate DeltaExposure and Theta all the way up, everything else up to underlier only */
const fullAggregationGreeks: Greeks[] = ['DeltaExposure', 'Theta'];
// TODO: Add Generic typing to SizeColumnParams so this properly can tie to the grid data
/** Custom Aggregate checker to ensure that pivot aggregations only flow up to the underlier level */
const aggregatePivotIf: SizeColumnParams['aggregateIf'] = params => {
  // only aggregate if we are in a proper group node
  if (!params.rowNode?.group || params.rowNode.level < 0) {
    return false;
  }
  const aggField: string = params.rowNode?.groupData?.[AGGRID_AUTOCOLUMN_ID];
  if (typeof aggField !== 'string') {
    throw new Error('AggregateIf (pivot) column must have a field');
  }

  const colDef = params.colDef;
  invariant(colDef.field, 'AggregateIf (pivot) column must have a field');
  const fieldSplit = colDef.field.split('.');
  invariant(fieldSplit.length >= 5, 'AggregateIf (pivot) column must have a field with at least 5 parts');
  const [_, greek, _pivotType, _bucket, _netGross] = fieldSplit;
  if (aggField.startsWith(UNDERLIER_GROUP_PREFIX)) {
    return true;
  }
  if (fullAggregationGreeks.includes(greek as Greeks)) {
    return true;
  }
  return false;
};

function buildPivotColumns(
  type: 'Tenor' | 'Moneyness',
  recordMap: typeof TENOR_COLUMN_MAP | typeof MONEYNESS_COLUMN_MAP
): ColumnGroup[] {
  return Object.entries(GREEK_ORDER).map(([greek, greekInfo]) => {
    return {
      groupId: `${type}_${greek}`,
      type: COLUMN_GROUP_NATIVE_TYPE,
      headerName: GreeksDisplayNameMap[greek],
      children: ['Net', 'Gross'].flatMap(netGross =>
        Object.entries(recordMap).map(([serverType, displayName]) => {
          const col: Column = {
            field: `Pivot.${greek}.${type}.${serverType}.${netGross}`,
            description: `${netGross} ${greekInfo.tooltipBase} (${displayName} ${type})`,
            type: 'size',
            hide: true,
            aggregate: true,
            params: {
              currencyField: 'Equivalent.Currency',
              increment: '0.01',
              round: true,
              highlightNegative: true,
              aggregateIf: aggregatePivotIf,
              aggregateEmptyValues: true,
            } satisfies SizeColumnParams,
            title: displayName,
          };
          return col;
        })
      ),
    };
  });
}

const TENOR_COLUMNS: ColumnGroup[] = buildPivotColumns('Tenor', TENOR_COLUMN_MAP);
const MONEYNESS_COLUMNS: ColumnGroup[] = buildPivotColumns('Moneyness', MONEYNESS_COLUMN_MAP);

const visibleColumns: Array<keyof ReturnType<typeof usePortfolioRiskColumnDefs>['mapping'] | ColumnGroup> = [
  'pmsWarnings',
  'weight',
  'position',
  'positionHome',
  'price',
  ...TENOR_COLUMNS,
  ...MONEYNESS_COLUMNS,
  'riskValuationTime',
];

export const usePortfolioRiskColumns = (): {
  defaultColumns: ColumnOrColumnGroup[];
  defaultColumnsFlat: Column[];
} => {
  const { homeCurrency } = useDisplaySettings();
  const rollupTreeRef = useRollupTreeRef();

  const { colDefsMap } = usePortfolioRiskColumnDefs(rollupTreeRef, homeCurrency);
  const defaultColumns = useDefaultColumnsWithGroupings(visibleColumns, colDefsMap);
  const flatDefultColumns = useMemo(() => {
    return extractColumns(defaultColumns);
  }, [defaultColumns]);
  return {
    defaultColumns,
    defaultColumnsFlat: flatDefultColumns,
  };
};

function usePortfolioRiskColumnDefs(rollupTreeRef: React.MutableRefObject<Tree<ISubaccount>>, homeCurrency: string) {
  return useMemo(() => {
    const mapping = {
      pmsWarnings: {
        id: 'warnings',
        field: 'PMSWarningColumnValue',
        type: 'pmsWarnings',
        titleIntlKey: 'warning',
        aggregate: true,
        pinned: 'left',
      },
      subAccount: {
        id: 'SubAccount',
        field: 'SubAccount',
        type: 'subAccountName',
        sortable: true,
        hide: true,
        params: {
          rollupTree: rollupTreeRef,
        },
      },
      assetField: {
        id: 'Asset',
        field: 'Asset',
        type: 'asset',
        pinned: 'left',
        sortable: true,
        width: 150,
        hide: true,
        title: 'Asset',
        params: {
          assetTypeField: 'AssetType',
          colorful: true,
        },
      },
      weight: {
        id: 'Weight',
        field: 'Equivalent.Weight',
        title: 'Weight (%)',
        width: 80,
        type: 'percent',
        sortable: true,
        description: 'Weight of the position in the portfolio.',
      },
      price: {
        field: 'MarkPrice',
        type: 'price',
        width: 150,
        params: {
          quoteCurrencyField: 'MarkPriceCurrency',
        },
        description: 'Most recent mark price for the instrument provided by the venue.',
      },

      position: {
        id: 'Position',
        field: 'Position',
        title: 'Position Qty',
        type: 'size',
        params: {
          showInTermsOfContracts: true,
          highlightNegative: true,
          currencyField: 'Asset',
          trimTrailingZeroes: true,
        },
        sortable: true,
        description: 'Position normalized in asset currency.',
      },
      positionHome: {
        type: 'size',
        field: 'Equivalent.Position',
        title: `Position (${homeCurrency})`,
        params: { currencyField: 'Equivalent.Currency', highlightNegative: true },
        sortable: true,
        aggregate: true,
        description: 'Position normalized in home currency, by using mark and underlying prices.',
      },
      riskValuationTime: {
        field: 'RiskValuationTime',
        title: 'Risk Valuation Time',
        type: 'date',
      },
    } as const satisfies Record<string, ColumnDef<PortfolioRiskBlotterGridData>>;

    const colDefsMap = Object.entries(mapping).reduce((result, [key, value]) => {
      result.set(key, value);
      return result;
    }, new Map<string, Column>());

    return {
      mapping,
      colDefsMap,
    };
  }, [homeCurrency, rollupTreeRef]);
}

/** Using the customColumnUpdate mechanism of {@link useBlotterTable}, handle the updates of the tenor
 * columns and moneyness columns based on the riskPivotType.
 */
export function usePivotColumnShowHide<R>({
  riskPivotType,
  riskAggMode,
  initialSort,
}: {
  riskPivotType: RiskPivotType;
  riskAggMode: RiskAggMode;
  initialSort?: BlotterTableSort<R>;
}): NonNullable<UseBlotterTableProps<PortfolioRiskGridData>['customColumnUpdate']> {
  type Callback = NonNullable<UseBlotterTableProps<PortfolioRiskGridData>['customColumnUpdate']>;
  const tenorColumnsRef = useRef(new Set<TenorServerTypes>());
  const moneynessColumnsRef = useRef(new Set<MoneynessServerTypes>());
  const [columnDefSetting, setColumnDefSetting] = useState<{
    visiblePivotFields: string[];
    autoGroupColumnDef: GridOptions['autoGroupColumnDef'];
    colDefs: NonNullable<GridOptions['columnDefs']>;
  }>({
    colDefs: [],
    autoGroupColumnDef: undefined,
    visiblePivotFields: [],
  });
  const [api, setApi] = useState<GridApi | null>(null);

  // AgGrid flickers the grouping column and the grouping headers
  // on every call to setColumnDefs so this reduces it (this will hopefully be fixed in the v29+ update)
  type AutoGroupSortInfo = Pick<NonNullable<GridOptions['autoGroupColumnDef']>, 'sort' | 'sortIndex'>;
  const lastAutoGroupSortInfoRef = useRef<AutoGroupSortInfo>();
  const lastVisiblePivotFieldsRef = useRef<(typeof columnDefSetting)['visiblePivotFields']>([]);
  useEffect(() => {
    if (api) {
      const autoGroupSortInfo: AutoGroupSortInfo = pick(columnDefSetting.autoGroupColumnDef, ['sort', 'sortIndex']);
      if (columnDefSetting.autoGroupColumnDef && !isEqual(autoGroupSortInfo, lastAutoGroupSortInfoRef.current)) {
        lastAutoGroupSortInfoRef.current = autoGroupSortInfo;
        api.setAutoGroupColumnDef(columnDefSetting.autoGroupColumnDef);
      }
      if (!isEqual(columnDefSetting.visiblePivotFields, lastVisiblePivotFieldsRef.current)) {
        lastVisiblePivotFieldsRef.current = columnDefSetting.visiblePivotFields;
        api.setColumnDefs(columnDefSetting.colDefs);
      }
    }
  }, [api, columnDefSetting]);

  // On every risk batch update, analyze the latest tenors and moneyness to ensure we have the columns to show
  // and update the columnDefs.
  const customColumnUpdate = useCallback<Callback>(
    (arg): (() => void) => {
      const { api, columnApi, dataObservable, columnDefs, autoGroupColumnDef } = arg;
      const sub = dataObservable.subscribe(subaccountRiskBatch => {
        const data = subaccountRiskBatch.data ?? [];
        if (api && columnApi && data.length > 0) {
          setApi(api);

          if (subaccountRiskBatch.initial) {
            tenorColumnsRef.current.clear();
            moneynessColumnsRef.current.clear();
          }
          data.forEach(item => {
            if (item) {
              item.TenorBucket && tenorColumnsRef.current.add(item.TenorBucket);
              item.MoneynessBucket && moneynessColumnsRef.current.add(item.MoneynessBucket);
            }
          });

          const visibleFields: (typeof columnDefSetting)['visiblePivotFields'] = [];
          columnDefs.forEach(colDef => {
            if ('children' in colDef) {
              colDef.children.forEach(child => {
                if (!('children' in child) && child.field) {
                  const showTenor = riskPivotType === 'byTenor';
                  const showMoneyness = riskPivotType === 'byMoneyness';
                  let show = false;
                  const [_, _greek, pivotType, bucket, netGross] = child.field.split('.');
                  if (showTenor && pivotType === 'Tenor' && netGross === riskAggMode) {
                    show = tenorColumnsRef.current.has(bucket as TenorServerTypes);
                  } else if (showMoneyness && pivotType === 'Moneyness' && netGross === riskAggMode) {
                    show = moneynessColumnsRef.current.has(bucket as MoneynessServerTypes);
                  }
                  child.hide = !show;
                  if (show) {
                    visibleFields.push(child.field);
                  }
                }
              });
            }
          });
          const { workingAutoGroupColumnDef, workingColumnDefs } = applySortsToColumns({
            columnDefs,
            columnApi,
            autoGroupColumnDef,
            initialSorts: initialSort,
          });
          setColumnDefSetting({
            colDefs: workingColumnDefs,
            autoGroupColumnDef: workingAutoGroupColumnDef,
            visiblePivotFields: visibleFields.sort(),
          });
        }
      });
      return () => {
        sub.unsubscribe();
        // cleanup
      };
    },
    [initialSort, riskAggMode, riskPivotType]
  );
  return customColumnUpdate;
}
