import { useState, type PropsWithChildren, type ReactNode } from 'react';
import styled, { useTheme, type DefaultTheme } from 'styled-components';

import { HStack } from '../../Core';
import { SectionHeader } from '../../Forms/styles';
import { Icon, IconName } from '../../Icons';
import { FormSection } from '../styles';

const CollapsibleFormSectionWrapper = styled(FormSection)<{ isExpanded: boolean; theme: DefaultTheme }>`
  max-height: ${({ isExpanded }) => (isExpanded ? 400 : 50)}px;
  overflow: hidden;
  transition: max-height 0.15s ease-in-out;

  ${SectionHeader} {
    margin-bottom: ${
      ({ isExpanded, theme }) => (isExpanded ? theme.spacingDefault : -1) /* Prevents a visual stutter on closing */
    }px;
  }
`;

type ManagedCollapsibleFormSectionProps = PropsWithChildren<{
  header: ReactNode;
  isExpanded: boolean;
  onToggle: (isExpanded: boolean) => void;
}>;

export function ManagedCollapsibleFormSection({
  children,
  header,
  isExpanded,
  onToggle,
}: ManagedCollapsibleFormSectionProps) {
  const theme = useTheme();

  return (
    <CollapsibleFormSectionWrapper isExpanded={isExpanded}>
      <SectionHeader onClick={() => onToggle(!isExpanded)}>
        <HStack gap="spacingDefault" justifyContent="flex-start">
          {header}
          <Icon icon={IconName.ChevronDown} rotate={isExpanded ? 0 : 180} size={theme.baseSize} />
        </HStack>
      </SectionHeader>
      {isExpanded && children}
    </CollapsibleFormSectionWrapper>
  );
}

type UnmanagedCollapsibleFormSectionProps = PropsWithChildren<{
  header: ManagedCollapsibleFormSectionProps['header'];
  initiallyExpanded?: boolean;
}>;

export function UnmanagedCollapsibleFormSection({
  children,
  header,
  initiallyExpanded = false,
}: UnmanagedCollapsibleFormSectionProps) {
  const [isExpanded, setIsExpanded] = useState(initiallyExpanded);

  return (
    <ManagedCollapsibleFormSection header={header} isExpanded={isExpanded} onToggle={setIsExpanded}>
      {children}
    </ManagedCollapsibleFormSection>
  );
}
