import type { CareOrder } from '@talos/kyoko';
import {
  HamburgerMenu,
  IconName,
  useDynamicCallback,
  useGetDefaultContextMenuItems,
  useJsonModal,
  type Column,
  type ColumnDef,
} from '@talos/kyoko';
import type { GetContextMenuItemsParams, MenuItemDef } from 'ag-grid-community';
import { compact } from 'lodash';
import { useMemo, type ReactNode } from 'react';
import { primeNewRFQForm } from '../../../components/OMS/NewRFQ/RFQSlice';
import { openView } from '../../../components/OMS/OMSSlice';
import { OMSView } from '../../../components/OMS/OMSView';
import { useAppStateDispatch } from '../../../providers/AppStateProvider';
import { CareOrderRow } from './types';

export function useCareOrderMenu(): {
  column: Column;
  getContextMenuItems: (params: GetContextMenuItemsParams) => (MenuItemDef | string)[];
  dialogs: ReactNode;
} {
  const { handleClickJson, jsonModal } = useJsonModal();
  const getDefaultContextMenuItems = useGetDefaultContextMenuItems();
  const dispatch = useAppStateDispatch();

  const handlePrimeRFQ = useDynamicCallback((entity: CareOrder) => {
    dispatch(
      primeNewRFQForm({
        group: entity.Group,
        symbol: entity.Symbol,
        side: entity.Side,
        currency: entity.Currency,
        orderQty: entity.LeavesQty,
        parentID: entity.OrderID,
      })
    );
    dispatch(openView(OMSView.RFQForm));
  });

  const getContextMenuItems = useDynamicCallback((params: GetContextMenuItemsParams) => {
    const entity = params.node?.data;
    const isCareOrderRow = entity instanceof CareOrderRow;

    const items = [
      isCareOrderRow
        ? {
            icon: `<i class="ag-icon ${IconName.BookOpen}"/>`,
            name: 'Send RFQ',
            action: () => handlePrimeRFQ(entity.data),
          }
        : undefined,
      entity
        ? {
            name: `Show JSON`,
            action: () => handleClickJson(entity.data),
            icon: `<i class="ag-icon ${IconName.Braces}"/>`,
          }
        : undefined,
    ];

    return compact([...items, ...getDefaultContextMenuItems(params)]);
  });

  const dialogs = useMemo(() => <>{jsonModal}</>, [jsonModal]);

  const column = useMemo(
    () =>
      ({
        type: 'hamburgerMenu',
        id: 'rowMenu',
        params: {
          renderItems: params => <HamburgerMenu {...params} onShowJson={handleClickJson} />,
        },
      } satisfies ColumnDef<CareOrder>),
    [handleClickJson]
  );

  return {
    column,
    getContextMenuItems,
    dialogs,
  };
}
