import type { ColumnApi, GridApi, RowNode } from 'ag-grid-community';
import { compact, endsWith, startsWith, toString, trim } from 'lodash';
import { useCallback, useMemo } from 'react';
import { getCellDisplayValue } from '../../AgGrid';
import { AGGRID_AUTOCOLUMN_ID } from '../types';
import type { UseBlotterQuickFilterParams } from './useBlotterQuickFilter.types';

const regex = /(?:"([^"\n]*?)")|(?:([^\s]+))/;

/**
 * Utility for creating filter functions from user input to use in conjunction with ag-grid
 *
 * Currently supports:
 *  - partial case insensitive matching by default
 *  - AND clauses with spaces between terms
 *  - exact matching when text is in quotes
 *
 * Only really works on string properties
 *
 * @returns Filter function which can be plugged in to ag-grid
 */
export const useBlotterQuickFilter = <T, OtherSearchKey extends string = string>({
  entitySearchKeys,
  otherSearchKeys,
  filterStr,
  filterOnAutoGroupLevels,
}: UseBlotterQuickFilterParams<T, OtherSearchKey>) => {
  const filterFunctions: ((gridValue: string, isGroup?: boolean) => boolean)[] = useMemo(() => {
    const filterParts = compact(
      filterStr
        .toLocaleLowerCase()
        .split(regex)
        .map(filterPart => filterPart?.trim())
    );

    const processedFilterParts = filterParts.map((filterPart: string) => {
      const isInQuotes = startsWith(filterPart, '"') && endsWith(filterPart, '"');
      const inputFilter = isInQuotes ? trim(filterPart, '"') : filterPart;
      return isInQuotes
        ? (gridValue: string) => gridValue === inputFilter
        : (value: string) => value.includes(inputFilter);
    });
    return processedFilterParts;
  }, [filterStr]);

  const searchKeys = useMemo(
    () => [...(entitySearchKeys ?? []), ...(otherSearchKeys ?? [])],
    [entitySearchKeys, otherSearchKeys]
  );

  const blotterQuickFilterChecker = useCallback(
    (row: RowNode<T>, api: GridApi | undefined, columnApi: ColumnApi | undefined) => {
      return filterFunctions.every((filterFunction: (value: string) => boolean) => {
        // TODO: Not sure we even need this flag, but we'll
        // need an inventory of all blotters to confirm the behavior
        if (filterOnAutoGroupLevels && row.group) {
          const value = (row.groupData?.[AGGRID_AUTOCOLUMN_ID] ?? '').toLowerCase();
          return filterFunction(value);
        }
        return searchKeys.some(key => {
          const column = typeof key === 'string' ? columnApi?.getColumn(key) : undefined;
          if (!column || !column.isVisible()) {
            // only search on visible columns
            return false;
          }
          let lowerCaseValue = '';
          let lowerCaseFormattedValue = '';

          if (api && columnApi) {
            // This call to api.getValue is especially heavy
            const cellValue = api.getValue(column, row);
            const cellDisplayValue = getCellDisplayValue({
              column,
              api,
              columnApi,
              node: row,
              // We need to make sure the value we pass into getCellDisplayValue is the output of the columns valueGetter:
              value: cellValue,
            });

            lowerCaseValue = toString(cellValue);

            // This is just for typing purposes, the return type of AgGrid's valueFormatter is always string.
            if (typeof cellDisplayValue === 'string') {
              lowerCaseFormattedValue = cellDisplayValue.toLowerCase();
            }
          }

          return filterFunction(lowerCaseFormattedValue) || filterFunction(lowerCaseValue);
        });
      });
    },
    [filterOnAutoGroupLevels, filterFunctions, searchKeys]
  );

  return blotterQuickFilterChecker;
};
