import {
  AmountInput,
  Box,
  Dialog,
  Flex,
  Icon,
  IconName,
  NotificationVariants,
  Post,
  Text,
  Tooltip,
  abbreviateId,
  useGlobalToasts,
  useUserContext,
  type UseDisclosureReturn,
} from '@talos/kyoko';
import { useCallback, useEffect, useState } from 'react';

import { useCustomerOrder } from 'hooks';

interface ForceFillOrderDialogWrapperProps extends UseDisclosureReturn {
  customerOrderID?: string;
}

interface ForceFillOrderDialogProps extends UseDisclosureReturn {
  customerOrderID: string;
}

const TooltipText = () => (
  <>
    <Icon icon={IconName.InformationCircleSolid} color="colors.gray[090]" />{' '}
    <Text>
      This is the price the remaining order qty will be filled at. If not specified, the order limit price will be used
    </Text>
  </>
);

export const ForceFillOrderDialogComponent = ({ customerOrderID, ...props }: ForceFillOrderDialogProps) => {
  const { orgApiEndpoint } = useUserContext();
  const { add: addToast } = useGlobalToasts();
  const order = useCustomerOrder({ orderID: customerOrderID, tag: 'ForceFillOrderDialogComponent' });
  const [fillPrice, setFillPrice] = useState<string | undefined>();

  const handleUpdateFillPrice = useCallback(
    (value?: string) => {
      setFillPrice(value);
    },
    [setFillPrice]
  );

  const forceFillOrder = useCallback(() => {
    const data: { OrderID: string; FillPrice?: string } = { OrderID: customerOrderID };
    if (fillPrice) {
      data.FillPrice = fillPrice;
    }

    Post(orgApiEndpoint as string, `/customer/orders/${customerOrderID}/force-fill`, null, {
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data),
    })
      .then(() => {
        addToast({
          text: `Order filled.`,
          variant: NotificationVariants.Positive,
        });
      })
      .catch((e: ErrorEvent) =>
        addToast({
          text: `Could not fill order: ${e.message}`,
          variant: NotificationVariants.Negative,
        })
      );
  }, [addToast, customerOrderID, fillPrice, orgApiEndpoint]);

  useEffect(() => {
    // this component stays mounted when closed, but when the customerOrderID changes, we want to reset this fill price
    setFillPrice(undefined);
  }, [customerOrderID]);

  if (order == null) {
    return null;
  }

  return (
    <Dialog {...props} confirmLabel="Confirm Fill Order" cancelLabel="Cancel" onConfirm={forceFillOrder}>
      <Box>
        <Flex justifyContent="space-between">
          <h3>
            Order #<b>{abbreviateId(order.OrderID)}</b>
          </h3>
        </Flex>
        <Text>Are you sure you want to force fill this customer&apos;s order?</Text>
        <Box mt="spacingDefault">
          <Tooltip tooltip={<TooltipText />}>
            <AmountInput placeholder="Fill price (optional)" onChange={handleUpdateFillPrice} value={fillPrice ?? ''} />
          </Tooltip>
        </Box>
      </Box>
    </Dialog>
  );
};

export const ForceFillOrderDialog = ({ customerOrderID, ...props }: ForceFillOrderDialogWrapperProps) => {
  if (!customerOrderID) {
    return null;
  }

  return <ForceFillOrderDialogComponent {...props} customerOrderID={customerOrderID} />;
};
