import type { Column, Paths, SizeColumnParams } from '@talos/kyoko';

/** Build common column definition for PnL columns */
export function getPnLColumn<T>({
  title,
  field,
  currencyType,
  homeCurrency,
  description,
}: {
  title: string;
  field: Paths<T>;
  currencyType: 'quote' | 'home';
  homeCurrency: string;
  description: string;
}) {
  return {
    field: field,
    type: 'size',
    title: `${title}${currencyType === 'home' ? ` (${homeCurrency})` : ''}`,
    aggregate: true,
    params: {
      currencyField: currencyType === 'home' ? 'Equivalent.Currency' : 'PositionPnl.PnLCurrency',
      round: currencyType === 'home',
      highlightNegative: true,
      increment: currencyType === 'quote' ? '0.01' : '1',
    } satisfies SizeColumnParams,
    hide: true,
    description: `${description} ${
      currencyType === 'home'
        ? `(in ${homeCurrency}).`
        : `(in settlement currency for derivatives, ${homeCurrency} otherwise).`
    }`,
  } satisfies Column<SizeColumnParams>;
}
