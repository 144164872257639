import {
  AccordionGroup,
  FilterClauseType,
  Panel,
  Tab,
  TabAppearance,
  TabList,
  TabPanels,
  TabSize,
  Tabs,
  type FilterClause,
} from '@talos/kyoko';
import { useMemo } from 'react';
import type { BalancesFilter } from '../../../types';
import { BottomTabContent } from './components/BottomTabContent';
import { TopTabContent } from './components/TopTabContent';
import { TreasuryManagementBalancesProvider } from './providers/TreasuryManagementBalancesProvider';
import { TreasuryManagementFiltersProvider } from './providers/TreasuryManagementFiltersProvider';
import { TreasuryManagementInteractionsProvider } from './providers/TreasuryManagementInteractionsProvider';
import { useTreasuryManagementTabs } from './providers/TreasuryManagementStateAndTabsProvider';
import { getDefaultTreasuryManagementState } from './providers/getDefaultTreasuryManagementState';

const TAB_PANELS_STYLE: React.CSSProperties = {
  flex: '1',
  display: 'flex',
  flexDirection: 'column',
  overflow: 'hidden',
};

export const TreasuryManagement = () => {
  const { tabs, filtersInitialOpen } = useTreasuryManagementTabs();

  // This memo computes the current initialFilterClauses based on the current tab.
  // When the current tab changes, it will re-compute and re-pass to the child FilterBuilder
  const initialFilterClauses: FilterClause[] = useMemo(() => {
    const filterToUse = tabs.items[tabs.selectedIndex].filter ?? getDefaultTreasuryManagementState().filter;
    const filterKeys = Object.keys(filterToUse) as (keyof BalancesFilter)[];
    return filterKeys
      .map(key => {
        const selections = filterToUse[key];

        if (selections == null) {
          // safeguard
          return undefined;
        }

        return {
          key,
          type: FilterClauseType.INCLUSIVE,
          selections,
        };
      })
      .compact();
  }, [tabs.selectedIndex, tabs.items]);

  return (
    <Panel w="100%" position="relative" justifyContent="start" flex="1" alignItems="left" h="100%" overflow="hidden">
      <Tabs {...tabs} flex="1" appearance={TabAppearance.Filled} size={TabSize.Small}>
        <TabList isBordered>
          {tabs.items.map((tab, idx) => (
            <Tab key={idx} {...tab} />
          ))}
        </TabList>
        <TabPanels style={TAB_PANELS_STYLE}>
          {tabs.items.map(tab => (
            // Within each tab we remount some providers and the components to get a clean slate each time
            <TreasuryManagementBalancesProvider key={tab.id}>
              <TreasuryManagementFiltersProvider>
                <TreasuryManagementInteractionsProvider>
                  <AccordionGroup>
                    <TopTabContent
                      initialFilterClauses={initialFilterClauses}
                      initialFiltersOpen={filtersInitialOpen}
                    />
                  </AccordionGroup>
                  <BottomTabContent />
                </TreasuryManagementInteractionsProvider>
              </TreasuryManagementFiltersProvider>
            </TreasuryManagementBalancesProvider>
          ))}
        </TabPanels>
      </Tabs>
    </Panel>
  );
};
