import { forwardRef, useCallback, type MouseEventHandler } from 'react';
import { defineMessages } from 'react-intl';
import { Button, ButtonVariants, IconButton } from '../Button';
import { FormControlSizes } from '../Form/types';
import { IconName } from '../Icons';
import { FormattedMessage } from '../Intl';
import { Modal } from '../Modal';
import { Actions, Content, Header, Title } from './styles';
import type { DialogProps } from './types';

const messages = defineMessages({
  cancel: {
    defaultMessage: 'Cancel',
    id: 'Dialog.cancel',
  },
  confirm: {
    defaultMessage: 'OK',
    id: 'Dialog.confirm',
  },
});

export { Actions, Content, Header } from './styles';
export type { DialogProps } from './types';

export const Dialog = forwardRef<HTMLDivElement | null, DialogProps>(
  (
    {
      onConfirm,
      onCancel,
      title,
      dataTestId,
      children,
      cancelLabel,
      confirmLabel,
      actions,
      showCancel = true,
      showConfirm = true,
      close,
      variant = ButtonVariants.Primary,
      confirmDisabled = false,
      confirmLoading = false,
      showClose,
      width,
      alignContent,
      contentOverflow = 'auto',
      onConfirmMouseOver,
      headerOverflow,
      preventClosing = false,
      closeOnConfirm = true,
      closeOnCancel = true,
      preview,
      ...props
    },
    ref
  ) => {
    const handleCancel: MouseEventHandler<HTMLButtonElement> = useCallback(
      e => {
        onCancel && onCancel(e);
        if (closeOnCancel) {
          close();
        }
      },
      [close, onCancel, closeOnCancel]
    );

    const handleConfirm: MouseEventHandler<HTMLButtonElement> = useCallback(
      async e => {
        try {
          onConfirm && (await onConfirm(e));
          if (closeOnConfirm) {
            close();
          }
        } catch {
          // Do nothing
        }
      },
      [close, onConfirm, closeOnConfirm]
    );

    const hasPreview = !!preview;
    const previewStyles: React.CSSProperties = hasPreview
      ? {
          overflow: 'hidden',
          position: 'relative',
        }
      : {};

    return (
      <Modal
        data-testid={dataTestId ?? 'dialog'}
        close={close}
        width={width}
        preventClosing={preventClosing}
        {...props}
        style={{ maxHeight: props.style?.maxHeight ?? '90vh', ...previewStyles }}
        ref={ref}
      >
        {preview}
        <Content align={alignContent} overflow={contentOverflow}>
          {children}
        </Content>
        {(showCancel || showConfirm || actions) && (
          <Actions>
            {actions}
            {showCancel && (
              <Button data-testid="dialog-cancel" disabled={preventClosing} onClick={handleCancel}>
                {cancelLabel ?? <FormattedMessage {...messages.cancel} />}
              </Button>
            )}
            {showConfirm && (
              <Button
                variant={variant}
                data-testid="dialog-confirm"
                onClick={handleConfirm}
                disabled={confirmLoading || confirmDisabled || preventClosing}
                loading={confirmLoading}
                onMouseOver={onConfirmMouseOver}
              >
                {confirmLabel ?? <FormattedMessage {...messages.confirm} />}
              </Button>
            )}
          </Actions>
        )}
        {(title || showClose) && (
          <Header headerOverflow={headerOverflow}>
            {title && <Title>{title}</Title>}
            {showClose && (
              <IconButton
                ghost
                disabled={preventClosing}
                size={FormControlSizes.Small}
                tabIndex={0}
                icon={IconName.Close}
                onClick={() => close()}
                data-testid="close-dialog-button"
              />
            )}
          </Header>
        )}
      </Modal>
    );
  }
);
