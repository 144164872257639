import {
  isCareOrder,
  isOrder,
  isQuote,
  type CareOrder,
  type Column,
  type Order,
  type Quote,
  type RowGroupsOpenedState,
  type TabProps,
} from '@talos/kyoko';

export type OrderWithParentOrderID = RequireKeys<Order, 'ParentOrderID'>;
export type QuoteWithParentRFQID = RequireKeys<Quote, 'ParentRFQID'>;

export abstract class CareOrderBlotterEntity<T = CareOrder | OrderWithParentOrderID | QuoteWithParentRFQID> {
  static from(data: CareOrder): CareOrderRow;
  static from(data: OrderWithParentOrderID): OrderRow;
  static from(data: QuoteWithParentRFQID): QuoteRow;
  static from<T>(data: T): CareOrderRow | OrderRow | QuoteRow {
    if (isOrder(data) && data.ParentOrderID) {
      return new OrderRow(data as OrderWithParentOrderID);
    }
    if (isQuote(data) && data.ParentRFQID) {
      return new QuoteRow(data as QuoteWithParentRFQID);
    }
    if (isCareOrder(data)) {
      return new CareOrderRow(data);
    }
    throw new Error('unknown data type');
  }

  abstract get dataPath(): string[];
  abstract get rowID(): string;

  // TODO fhqvst Add more abstract getters here for whatever values we want
  // to use in the blotter

  constructor(data: T) {
    this.data = data;
  }
  data: T;
}

export class QuoteRow extends CareOrderBlotterEntity<QuoteWithParentRFQID> {
  order?: OrderWithParentOrderID;

  get rowID() {
    return this.data.RFQID;
  }

  get dataPath(): string[] {
    return [this.data.ParentRFQID, this.data.RFQID];
  }

  enrichWith(order: OrderWithParentOrderID) {
    const next = new QuoteRow(this.data);
    // TODO handle merge properly
    next.order = order;
    return next;
  }
}

export class OrderRow extends CareOrderBlotterEntity<OrderWithParentOrderID> {
  get rowID() {
    return this.data.OrderID;
  }
  get dataPath(): string[] {
    return [this.data.ParentOrderID, this.data.OrderID];
  }
}

export class CareOrderRow extends CareOrderBlotterEntity<CareOrder> {
  get rowID() {
    return this.data.OrderID;
  }
  get dataPath(): string[] {
    return [this.data.OrderID];
  }
}

export interface CareOrderBlotterTabProps extends TabProps {
  defaultColumns?: Column[];
  defaultRowGroupsOpened?: RowGroupsOpenedState;
}

export const CARE_ORDER_BLOTTER_PREFIX = 'care-order-blotter';
export const CARE_ORDER_BLOTTER_PORTAL_ID = 'care-order-blotter-portal';
