import {
  Box,
  ControlPrefix,
  DateRangePicker,
  FormControlSizes,
  LookbackOption,
  TimePickerPrecision,
  formattedDateForSubscription,
  useDynamicCallback,
  type DateRange,
  type LookbackWindow,
  type ShortcutOption,
} from '@talos/kyoko';
import { values } from 'lodash';
import { useMemo } from 'react';
import { PerformanceActionType } from '../PerformanceReducer';
import { usePerformanceContext } from '../providers/PerformanceStateAndTabsProvider';
import { PERIODS, isPeriodCustomPeriod, periodToChartDateRange, periodToLookbackEquivalent } from '../types';

const SHORTCUT_OPTIONS: ShortcutOption[] = [
  { value: LookbackOption.Today },
  { value: LookbackOption.WeekToDate },
  { value: LookbackOption.MonthToDate },
  { value: LookbackOption.YearToDate },
  { value: LookbackOption.LifeToDate },
  { value: LookbackOption.Past24Hours },
  { value: LookbackOption.PastWeek },
  { value: LookbackOption.PastMonth },
  { value: LookbackOption.PastYear },
];

function getGroup(option: ShortcutOption): string | undefined {
  switch (option.value) {
    case LookbackOption.Today:
    case LookbackOption.WeekToDate:
    case LookbackOption.MonthToDate:
    case LookbackOption.YearToDate:
    case LookbackOption.LifeToDate:
      return 'To Date';
    case LookbackOption.Past24Hours:
    case LookbackOption.PastWeek:
    case LookbackOption.PastMonth:
    case LookbackOption.PastYear:
      return 'Rolling';
    default:
      return undefined;
  }
}

export const PeriodControl = () => {
  const {
    state: { period },
    dispatch,
  } = usePerformanceContext();

  // Convert the selected period to what DateRangePicker wants
  const dateRangePickerValue: DateRange | LookbackWindow = useMemo(() => {
    // If we have a period selected, we grab the equivalent lookback (which is what the DateRangePicker understands),
    // and use forward that.
    const maybeLookback = periodToLookbackEquivalent(period);
    if (maybeLookback) {
      return { lookback: maybeLookback } satisfies LookbackWindow;
    }

    // Otherwise we have a custom period, and we grab the chart date ranges (from and to dates)
    const { StartDate, EndDate } = periodToChartDateRange(period);
    if (!StartDate) {
      return { from: null, to: null };
    }

    return {
      from: new Date(StartDate),
      to: EndDate ? new Date(EndDate) : null,
    };
  }, [period]);

  const getLabel = useDynamicCallback((value: DateRange | LookbackWindow) => {
    // This is just me using the performance state instead to populate the label, and if its a CustomPeriod
    // we just return undefined, which allows the DateRangePicker to take over
    if (isPeriodCustomPeriod(period)) {
      return undefined;
    }

    return PERIODS[period].label;
  });

  const handleDateRangeChange = useDynamicCallback((value: DateRange | LookbackWindow) => {
    if (isLookbackWindow(value)) {
      const equivalentPeriod = values(PERIODS).find(period => period.lookbackEquivalent === value.lookback);
      if (!equivalentPeriod) {
        return;
      }

      dispatch({
        type: PerformanceActionType.PeriodChange,
        payload: {
          period: equivalentPeriod.period,
        },
      });
    } else {
      if (value.from == null) {
        return;
      }
      // we receive a from and to (CustomPeriod)
      dispatch({
        type: PerformanceActionType.PeriodChange,
        payload: {
          period: {
            StartDate: formattedDateForSubscription(value.from),
            EndDate: value.to ? formattedDateForSubscription(value.to) : undefined,
          },
        },
      });
    }
  });

  return (
    <Box>
      <DateRangePicker
        value={dateRangePickerValue}
        onChange={handleDateRangeChange}
        shortcuts={SHORTCUT_OPTIONS}
        maxTimePickerPrecision={TimePickerPrecision.MINUTE}
        size={FormControlSizes.Small}
        prefix={<ControlPrefix>Period</ControlPrefix>}
        getLabel={getLabel}
        getGroup={getGroup}
        clearable={false}
        timePickerVariant="selector"
        timeSelectorIntervalMinutes={15}
      />
    </Box>
  );
};

function isLookbackWindow(value: DateRange | LookbackWindow): value is LookbackWindow {
  return 'lookback' in value;
}
