import type { ReactNode } from 'react';
import { IconButton } from '../Button';
import { HStack } from '../Core';
import { FormControlSizes } from '../Form';
import { Icon, IconName } from '../Icons';
import { Text } from '../Text';

interface ModalHeaderProps {
  icon?: IconName;
  title?: string;
  subtitle?: string;
  prefix?: ReactNode;
  suffix?: ReactNode;
  onCloseClicked?: () => void;
}

export function ModalHeader({ icon, title, subtitle, prefix, suffix, onCloseClicked }: ModalHeaderProps) {
  return (
    <HStack
      gap="spacingComfortable"
      px="spacingDefault"
      py="spacingSmall"
      justifyContent="space-between"
      fontSize="fontSizeSm"
      background="backgroundModalHeader"
    >
      {/* Icon, Title, SubTitle, Prefix */}
      <HStack gap="spacingSmall">
        {icon && <Icon icon={icon} />}
        {title && <Text>{title}</Text>}
        {subtitle && (
          <>
            {title && <Icon icon={IconName.ChevronRight} size="fontSizeSmall" />}
            <Text color="colorTextImportant">{subtitle}</Text>
          </>
        )}
        {prefix}
      </HStack>

      <HStack gap="spacingSmall">
        {suffix}
        {onCloseClicked && (
          <IconButton
            ghost
            color="colorTextSubtle"
            size={FormControlSizes.Small}
            icon={IconName.Close}
            onClick={onCloseClicked}
          />
        )}
      </HStack>
    </HStack>
  );
}
