import { SyntheticProductTypeEnum } from '@talos/kyoko';
import { MultilegComboType } from '../../MultilegCombo/enums';

export const mapSyntheticProductTypeToLabel = (type: SyntheticProductTypeEnum): string => {
  switch (type) {
    case SyntheticProductTypeEnum.CallSpread:
      return 'Option Call Spread';
    case SyntheticProductTypeEnum.PutSpread:
      return 'Option Put Spread';
    case SyntheticProductTypeEnum.CallCalendarSpread:
      return 'Option Call Calendar Spread';
    case SyntheticProductTypeEnum.PutCalendarSpread:
      return 'Option Put Calendar Spread';
    case SyntheticProductTypeEnum.Delta1Spread:
      return 'Delta 1 Spread';
    case SyntheticProductTypeEnum.Cross:
      return 'Synthetic Cross';
    default:
      return 'Custom';
  }
};

export const getMultilegInstrumentType = (
  syntheticProductType: SyntheticProductTypeEnum | undefined
): MultilegComboType | undefined => {
  if (!syntheticProductType) {
    return undefined;
  }

  return syntheticProductType === SyntheticProductTypeEnum.Delta1Spread
    ? MultilegComboType.Delta1Spread
    : syntheticProductType === SyntheticProductTypeEnum.Cross
    ? MultilegComboType.SyntheticCross
    : [SyntheticProductTypeEnum.CallSpread, SyntheticProductTypeEnum.PutSpread].includes(syntheticProductType)
    ? MultilegComboType.OptionsVerticalSpread
    : [SyntheticProductTypeEnum.CallCalendarSpread, SyntheticProductTypeEnum.PutCalendarSpread].includes(
        syntheticProductType
      )
    ? MultilegComboType.OptionsCalendarSpread
    : undefined;
};
