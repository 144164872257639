import {
  ButtonGroup,
  Card,
  EmptyView,
  FormControlSizes,
  HStack,
  HelpIcon,
  LoaderTalos,
  SymbolDisplay,
  SymbolDisplaySize,
  Table,
  Tbody,
  Td,
  Th,
  ToggleButton,
  Tr,
  UnifiedLiquidityEnum,
  getSymbolsAcrossMarkets,
  useSecuritiesContext,
  type CardProps,
  type Order,
  type OrderAnalytic,
} from '@talos/kyoko';
import Big from 'big.js';
import type { useLatestOrderAnalytics } from 'hooks/useLatestOrderAnalytics';
import React from 'react';
import { SlippageText } from './SlippageText';
import { Tables } from './styles';

export function KeyMetricsCard({
  order,
  isMultileg,
  analytics,
  ...props
}: {
  order: Order;
  isMultileg?: boolean;
  analytics: ReturnType<typeof useLatestOrderAnalytics>;
} & CardProps) {
  const [activeLeg, setActiveLeg] = React.useState<number>(isMultileg ? 0 : 1);
  const underlyingSymbols =
    order.unifiedLiquidity === UnifiedLiquidityEnum.Enabled && activeLeg === 0 ? getSymbolsAcrossMarkets(order) : [];

  const { latestAnalytics, receivedAnalytics } = analytics;
  if (latestAnalytics == null) {
    return (
      <Card title="Key Metrics" {...props}>
        {receivedAnalytics ? <EmptyView>No key metrics analytics available</EmptyView> : <LoaderTalos />}
      </Card>
    );
  }

  return (
    <Card
      title="Key Metrics"
      actions={
        isMultileg && (
          <ButtonGroup size={FormControlSizes.Small}>
            <ToggleButton data-testid="button-leg-0" onClick={() => setActiveLeg(0)} selected={activeLeg === 0}>
              Order
            </ToggleButton>
            <ToggleButton data-testid="button-leg-1" onClick={() => setActiveLeg(1)} selected={activeLeg === 1}>
              Leg 1
            </ToggleButton>
            <ToggleButton data-testid="button-leg-2" onClick={() => setActiveLeg(2)} selected={activeLeg === 2}>
              Leg 2
            </ToggleButton>
          </ButtonGroup>
        )
      }
      {...props}
    >
      {isMultileg ? (
        <>
          <HStack justifyContent="space-between">
            <SymbolDisplay
              symbol={analytics?.latestAnalytics?.[activeLeg]?.Symbol}
              showDescription={false}
              underlyingSymbols={underlyingSymbols}
              p="0"
              size={SymbolDisplaySize.Small}
            />
          </HStack>
          <KeyMetricsCardContent order={order} leg={activeLeg} latestAnalytics={latestAnalytics} />
        </>
      ) : (
        <KeyMetricsCardContent order={order} leg={1} latestAnalytics={latestAnalytics} />
      )}
    </Card>
  );
}

export function KeyMetricsCardContent({
  latestAnalytics,
  order,
  leg,
}: {
  order: Order;
  latestAnalytics: Record<number, OrderAnalytic>;
  leg: number;
} & CardProps) {
  const { securitiesBySymbol } = useSecuritiesContext();
  const security = securitiesBySymbol.get(order.Symbol);
  const orderAnalytics = latestAnalytics[leg];
  const parentAnalytic = latestAnalytics[0];
  if (!orderAnalytics) {
    return null;
  }
  return (
    <Tables>
      {leg !== 0 && (
        <Table>
          <Tbody>
            <Tr>
              <Th>Participation</Th>
              <Th align="right">%</Th>
            </Tr>
            <Tr>
              <Td>Global</Td>
              <Td align="right">
                {`${Big(orderAnalytics.GlobalParticipationRate || 0)
                  .times(100)
                  .toFixed(2)}`}
              </Td>
            </Tr>
            <Tr>
              <Td>Order markets</Td>
              <Td align="right">
                {`${Big(orderAnalytics.ParticipationRate || 0)
                  .times(100)
                  .toFixed(2)}`}
              </Td>
            </Tr>
          </Tbody>
        </Table>
      )}
      <Table>
        <Tbody>
          <Tr>
            <Th>
              Slippage vs.{' '}
              <HelpIcon tooltip="Slippage includes analytics side, meaning a positive slippage indicates worse performance compared to the benchmark, and negative slippage indicates better performance." />
            </Th>
            <Th align="right">BPS</Th>
          </Tr>
          <Tr data-testid="arrival-px-row">
            <Td>Arrival Px</Td>
            <Td align="right">
              <SlippageText
                security={security}
                side={order.Side}
                versusPxField="ArrivalPx"
                analyticOrLegAnalytic={orderAnalytics}
                parentAnalytic={parentAnalytic}
              />
            </Td>
          </Tr>
          <Tr data-testid="market-mid-row">
            <Td>Market Mid Px</Td>
            <Td align="right">
              <SlippageText
                security={security}
                side={order.Side}
                versusPxField="MarketMidPx"
                analyticOrLegAnalytic={orderAnalytics}
                parentAnalytic={parentAnalytic}
              />
            </Td>
          </Tr>
          <Tr data-testid="reference-px-row">
            <Td>Reference Px</Td>
            <Td align="right">
              <SlippageText
                security={security}
                side={order.Side}
                versusPxField="ReferencePx"
                analyticOrLegAnalytic={orderAnalytics}
                parentAnalytic={parentAnalytic}
              />
            </Td>
          </Tr>
          <Tr data-testid="market-vwap-row">
            <Td>Market VWAP</Td>
            <Td align="right">
              <SlippageText
                security={security}
                side={order.Side}
                versusPxField="CumulativeVWAP"
                analyticOrLegAnalytic={orderAnalytics}
                parentAnalytic={parentAnalytic}
              />
            </Td>
          </Tr>
        </Tbody>
      </Table>
    </Tables>
  );
}
