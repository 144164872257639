import type { EntityAdminPageProps, FilterableProperty, Security } from '@talos/kyoko';
import {
  ACTION,
  EntityAdminPage,
  HStack,
  Panel,
  useSecuritiesContext,
  useSymbolsFilter,
  type Column,
  type InputsAndDropdownsDrawerOption,
  type SymbolGroup,
} from '@talos/kyoko';
import type { ValueFormatterParams } from 'ag-grid-community';
import { useRoleAuth } from 'hooks';
import { filter } from 'lodash';
import { useCustomerPricingTierContext, useCustomerSecuritiesContext } from 'providers';
import { useCallback, useEffect, useMemo, useState } from 'react';

const SYMBOL_GROUPS_QUICK_SEARCH_KEYS = ['Tier', 'Symbol'] satisfies (keyof SymbolGroup)[];

const SYMBOL_GROUP_COLUMNS: Column<unknown, SymbolGroup>[] = [
  { field: 'Tier', type: 'text', hide: true, sort: '+' },
  { field: 'Symbol', type: 'security', hide: true, sort: '+' },
];

const GET_SYMBOL_GROUP_POST_PATH = (entity: SymbolGroup) => {
  return entity.Symbol == null ? `/symbol-groups` : `/symbol-groups/${entity.Tier}/symbols`;
};

const GET_SYMBOL_GROUP_PATCH_DELETE_PATH = (entity: SymbolGroup) => {
  return entity.Symbol == null
    ? `/symbol-groups/${entity.Tier}`
    : `/symbol-groups/${entity.Tier}/symbols/${entity.Symbol}`;
};

const GET_SYMBOL_GROUP_DRAWER_OPTIONS = (): InputsAndDropdownsDrawerOption<SymbolGroup>[] => [
  {
    field: 'Tier',
    type: 'input',
    required: true,
    disabledWhenEditing: true,
    title: 'Name',
  },
];

const GET_SECURITY_OPTIONS = (securities: Security[]) => {
  return securities.map(security => ({
    value: security.Symbol,
    label: security.DisplaySymbol ?? security.Symbol,
    description: security.Description,
  }));
};
const GET_TIER_OPTIONS = (tiers: SymbolGroup[]) => {
  return tiers.map(tier => ({ value: tier.Tier, label: tier.Tier }));
};

const GET_SYMBOL_GROUP_OVERRIDES_DRAWER_OPTIONS = (
  symbolGroups: SymbolGroup[],
  filteredSecurities: Security[]
): InputsAndDropdownsDrawerOption<SymbolGroup>[] => [
  {
    field: 'Tier',
    type: 'dropdown',
    required: true,
    disabledWhenEditing: true,
    title: 'Group Name',
    options: GET_TIER_OPTIONS(symbolGroups),
    placeholder: 'Select Group...',
  },
  {
    field: 'Symbol',
    type: 'dropdown',
    disabledWhenEditing: true,
    required: true,
    placeholder: 'Select Symbol...',
    options: GET_SECURITY_OPTIONS(filteredSecurities),
  },
];

const SYMBOL_GROUP_COLUMN_DEF = {
  headerName: 'Name',
  width: 300,
  valueFormatter: ({ context, value }: ValueFormatterParams<SymbolGroup>) => {
    const security = context.current.securitiesBySymbol?.get(value);
    return security?.DisplaySymbol ?? value;
  },
};

const SYMBOL_GROUP_ADD_CHILD_ENTITY_BUTTON_PROPS: EntityAdminPageProps<SymbolGroup>['addChildEntityButtonProps'] = {
  text: 'Symbol Override',
  width: 137,
};

export const CustomerSymbolGroups = () => {
  const { isAuthorized } = useRoleAuth();

  const [symbolGroups, setSymbolGroups] = useState<SymbolGroup[]>([]);
  const { getSymbolGroups } = useCustomerPricingTierContext();
  const { securitiesBySymbol } = useSecuritiesContext();
  const { customerSecuritiesBySymbol } = useCustomerSecuritiesContext();

  const filteredSecurities = useMemo((): Security[] => {
    if (!customerSecuritiesBySymbol) {
      return [];
    }

    // Return an array of securities that exist in customerSecuritiesBySymbol
    return filter(Array.from(securitiesBySymbol.values()), security => customerSecuritiesBySymbol.has(security.Symbol));
  }, [customerSecuritiesBySymbol, securitiesBySymbol]);

  useEffect(() => {
    getSymbolGroups().then(({ data }) => {
      setSymbolGroups(data);
    });
  }, [getSymbolGroups]);

  const symbolGroupDrawerOptions = useMemo(() => GET_SYMBOL_GROUP_DRAWER_OPTIONS(), []);
  const symbolGroupOverridesDrawerOptions = useMemo(() => {
    if (filteredSecurities != null) {
      return GET_SYMBOL_GROUP_OVERRIDES_DRAWER_OPTIONS(symbolGroups, filteredSecurities);
    }
  }, [symbolGroups, filteredSecurities]);

  const getDrawerOptions = useCallback(
    (entity?: SymbolGroup, addingChildEntity?: boolean) => {
      if (addingChildEntity) {
        return symbolGroupOverridesDrawerOptions;
      }
      const isGroupRow = entity?.Symbol == null;
      return isGroupRow ? symbolGroupDrawerOptions : symbolGroupOverridesDrawerOptions;
    },
    [symbolGroupDrawerOptions, symbolGroupOverridesDrawerOptions]
  );

  const symbolFilter = useSymbolsFilter(filteredSecurities);
  const symbolGroupFilterableProperties: FilterableProperty[] = useMemo(() => [symbolFilter], [symbolFilter]);

  return (
    <HStack h="100%" w="100%" gap="spacingTiny" overflow="hidden">
      <Panel>
        <EntityAdminPage<SymbolGroup>
          title="Symbol Groups"
          subtitle="Set up your Symbol Groups here."
          path="/symbol-groups?expanded=true"
          getPostPath={GET_SYMBOL_GROUP_POST_PATH}
          getPatchDeletePath={GET_SYMBOL_GROUP_PATCH_DELETE_PATH}
          columns={SYMBOL_GROUP_COLUMNS}
          entityIDField="Tier"
          childIDField="Symbol"
          addChildEntityButtonProps={SYMBOL_GROUP_ADD_CHILD_ENTITY_BUTTON_PROPS}
          getDrawerOptions={getDrawerOptions}
          entitySearchKeys={SYMBOL_GROUPS_QUICK_SEARCH_KEYS}
          entityName="Symbol Group"
          allowAddEntity={isAuthorized(ACTION.EDIT_CUSTOMER_SYMBOL_GROUPS)}
          allowEditEntity={false}
          allowDeleteEntity={isAuthorized(ACTION.EDIT_CUSTOMER_SYMBOL_GROUPS)}
          allowModeSwitch={true}
          groupColumnDef={SYMBOL_GROUP_COLUMN_DEF}
          filterableProperties={symbolGroupFilterableProperties}
        />
      </Panel>
    </HStack>
  );
};
