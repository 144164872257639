import type { TabComponentSpec } from 'components/FlexibleLayout/types';
import { Trades } from 'containers/Blotters/Trades';
import { PORTFOLIO_DOCKVIEW_PANEL_GROUPS } from '../../../portfolioDashboardLayouts';

export type PortfolioComponentTypes = (typeof portfolioComponents)[number]['type'];
export const portfolioComponents = [
  {
    group: PORTFOLIO_DOCKVIEW_PANEL_GROUPS['blotters'],
    type: 'tradesBlotter',
    label: 'Trades',
    renderer: () => {
      return <Trades />;
    },
  },
] as const satisfies TabComponentSpec[];
