import {
  CUSTOMER_SECURITY,
  DELETE,
  GET,
  PATCH,
  POST,
  request,
  useObservable,
  useObservableValue,
  useRecentSymbols,
  useSocketClient,
  useStaticSubscription,
  useUserContext,
  wsScanToMap,
  wsWaitForAllPages,
  type CustomerSecurity,
  type CustomerSpecificSecurity,
  type IWebSocketClient,
  type Response,
  type SingleDataResponse,
  type SubscriptionResponse,
} from '@talos/kyoko';
import { isUndefined, sortBy } from 'lodash';
import {
  createContext,
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
  type PropsWithChildren,
} from 'react';
import { BehaviorSubject, NEVER, Observable, combineLatestWith } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { v4 as uuid } from 'uuid';

type CustomerSecuritiesContextProps = {
  customerSecurities: Observable<CustomerSecurity[]>;
  customerSecuritiesBySymbol: Map<string, CustomerSecurity> | undefined;
  recentCustomerSecurities: Observable<CustomerSecurity[]>;
  listCustomerSecurities(): Promise<Response<CustomerSecurity>>;
  createCustomerSecurity(security: Partial<CustomerSecurity>): Promise<Response<CustomerSecurity>>;
  updateCustomerSecurity(security: Partial<CustomerSecurity>): Promise<SingleDataResponse<CustomerSecurity>>;
  deleteCustomerSecurity(symbol: string): Promise<undefined>;
  customerSpecificSecuritiesCache: CustomerSecuritiesCache;
};

const CustomerSecuritiesContext = createContext<CustomerSecuritiesContextProps | undefined>(undefined);
CustomerSecuritiesContext.displayName = 'CustomerSecuritiesContext';

export const CUSTOMER_SECURITY_UPDATE_ID = 'CUSTOMER_SECURITY_UPDATE_ID';

export const useCustomerSecuritiesContext = () => {
  const context = useContext(CustomerSecuritiesContext);
  if (context === undefined) {
    throw new Error('Missing CustomerSecuritiesContext.Provider further up in the tree. Did you forget to add it?');
  }
  return context;
};

const recentSymbolListSubject = new BehaviorSubject<string[]>([]);

export interface ICustomerSecuritiesCache {
  getSecuritiesForCustomer: (customerName?: string) => Observable<CustomerSpecificSecurity[]>;
}

export class CustomerSecuritiesCache implements ICustomerSecuritiesCache {
  private static instance: CustomerSecuritiesCache;
  private websocketClient: IWebSocketClient<unknown>;

  private cache: Map<string, Observable<CustomerSpecificSecurity[]>> = new Map();

  private constructor(webSocketClient: IWebSocketClient<unknown>) {
    this.websocketClient = webSocketClient;
  }

  public static getInstance(webSocketClient: IWebSocketClient<unknown>): CustomerSecuritiesCache {
    if (this.instance) {
      return this.instance;
    }

    this.instance = new CustomerSecuritiesCache(webSocketClient);
    return this.instance;
  }

  public getSecuritiesForCustomer(customerName?: string): Observable<CustomerSpecificSecurity[]> {
    if (!customerName) {
      return NEVER;
    }
    if (customerName && this.cache.has(customerName)) {
      return this.cache.get(customerName)!;
    }
    const newObservable = new Observable<SubscriptionResponse<CustomerSpecificSecurity>>(observer => {
      const request = {
        name: CUSTOMER_SECURITY,
        tag: 'CustomerSecuritiesProvider',
        Counterparty: customerName,
      };

      const address = uuid();
      this.websocketClient.registerSubscription(
        address,
        [request],
        (err, json) => {
          if (err) {
            observer.error(err);
          } else {
            if (json && json.initial) {
              this.websocketClient.pageSubscription(address, { loadAll: true });
            }
            observer.next(json as unknown as SubscriptionResponse<CustomerSpecificSecurity>);
          }
        },
        { loadAll: true }
      );

      return () => {
        this.websocketClient.unregisterSubscription(address);
        this.cache.delete(customerName);
      };
    }).pipe(
      wsScanToMap({ getUniqueKey: d => d.Symbol, newMapEachUpdate: true }),
      map(map => [...map.values()]),
      shareReplay({
        bufferSize: 1,
        refCount: true,
      })
    );

    this.cache.set(customerName, newObservable);
    return newObservable;
  }
}

export const CustomerSecuritiesProvider = memo(function CustomerSecuritiesProvider(props: PropsWithChildren<unknown>) {
  const { data: customerSecuritySub } = useStaticSubscription<SubscriptionResponse<CustomerSecurity>>(
    { name: CUSTOMER_SECURITY, tag: 'CustomerSecuritiesProvider' },
    { loadAll: true }
  );

  const { recentSymbolsList } = useRecentSymbols();
  const webSocketClient = useSocketClient();

  const customerSpecificSecuritiesCache = useMemo(
    () => CustomerSecuritiesCache.getInstance(webSocketClient),
    [webSocketClient]
  );

  useEffect(() => {
    recentSymbolListSubject.next(recentSymbolsList.slice());
  }, [recentSymbolsList]);

  const customerSecurities = useObservable(
    () =>
      customerSecuritySub.pipe(
        wsWaitForAllPages({ getUniqueKey: d => d.Symbol }), // [DEAL-3561] Dealer Security Master blotter must include paginated records
        wsScanToMap({ getUniqueKey: d => d.Symbol, newMapEachUpdate: false }),
        map(pricingRules => sortBy([...pricingRules.values()], 'Symbol')),
        shareReplay({
          bufferSize: 1,
          refCount: true,
        })
      ),
    [customerSecuritySub]
  );

  const { orgApiEndpoint } = useUserContext();
  const endpoint = `${orgApiEndpoint}/customer-securities`;

  const listCustomerSecurities = useCallback(() => request<Response<CustomerSecurity>>(GET, endpoint), [endpoint]);
  const createCustomerSecurity = useCallback(
    (customerSecurity: Partial<CustomerSecurity>) =>
      request<Response<CustomerSecurity>>(POST, endpoint, customerSecurity),
    [endpoint]
  );
  const updateCustomerSecurity = useCallback(
    (customerSecurity: Partial<CustomerSecurity>) =>
      request<SingleDataResponse<CustomerSecurity>>(PATCH, endpoint, customerSecurity),
    [endpoint]
  );
  const deleteCustomerSecurity = useCallback(
    (symbol: string) => request<undefined>(DELETE, `${endpoint}/counterparty?symbol=${symbol}`),
    [endpoint]
  );

  const recentCustomerSecurities = useObservable(
    () =>
      customerSecurities.pipe(
        combineLatestWith(recentSymbolListSubject),
        map(([securities, recentSymbols]) => {
          // We only care about the top 5 most recent symbols
          const recentSymbolsIndexMap = new Map<string, number>(
            recentSymbols.slice(0, 5).map((symbol, i) => [symbol, i])
          );

          return securities.sort((a, b) => {
            const aRecencyWeighting = recentSymbolsIndexMap.get(a.Symbol);
            const bRecencyWeighting = recentSymbolsIndexMap.get(b.Symbol);

            if (isUndefined(aRecencyWeighting) && isUndefined(bRecencyWeighting)) {
              return a.Symbol.localeCompare(b.Symbol);
            } else if (isUndefined(aRecencyWeighting)) {
              // bRecencyWeighting must be defined else the first if case would have ran - b goes first since b exists in recentList
              return 1;
            } else if (isUndefined(bRecencyWeighting)) {
              return -1;
            } else {
              return aRecencyWeighting - bRecencyWeighting;
            }
          });
        })
      ),
    [customerSecurities]
  );

  const [customerSecuritiesBySymbol, setCustomerSecuritiesBySymbol] = useState<Map<string, CustomerSecurity>>();
  useEffect(() => {
    if (customerSecuritiesBySymbol == null) {
      listCustomerSecurities().then(({ data }) => {
        setCustomerSecuritiesBySymbol(new Map(data.map(security => [security.Symbol, security])));
      });
    }
  }, [customerSecuritiesBySymbol, listCustomerSecurities]);

  const value = useMemo(
    () => ({
      customerSecurities,
      customerSecuritiesBySymbol,
      recentCustomerSecurities,
      listCustomerSecurities,
      createCustomerSecurity,
      updateCustomerSecurity,
      deleteCustomerSecurity,
      customerSpecificSecuritiesCache,
    }),
    [
      customerSecurities,
      customerSecuritiesBySymbol,
      recentCustomerSecurities,
      listCustomerSecurities,
      createCustomerSecurity,
      updateCustomerSecurity,
      deleteCustomerSecurity,
      customerSpecificSecuritiesCache,
    ]
  );

  return <CustomerSecuritiesContext.Provider value={value}>{props.children}</CustomerSecuritiesContext.Provider>;
});

export function useCustomerSecurities() {
  const { customerSecurities } = useCustomerSecuritiesContext();
  return useObservableValue(() => customerSecurities, [customerSecurities]);
}

export function useRecentCustomerSecurities() {
  const { recentCustomerSecurities } = useCustomerSecuritiesContext();
  return useObservableValue(() => recentCustomerSecurities, [recentCustomerSecurities]);
}
