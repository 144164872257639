import { useConstant } from 'hooks/useConstant';
import { useObservable } from 'hooks/useObservable';
import { useSubscription } from 'hooks/useSubscription';
import { isArray, isEqual } from 'lodash';
import { useWsPaginationLimiter, type LimitReachedChangeEvent } from 'pipes';
import { useCallback, useEffect, useMemo, useRef, useState, type Dispatch, type SetStateAction } from 'react';
import { BehaviorSubject, asyncScheduler, type Observable } from 'rxjs';
import { map, throttleTime } from 'rxjs/operators';
import type { MinimalSubscriptionResponse } from 'types';
import type { RequestStream } from 'types/RequestStream';
import type {
  BlotterTableFilter,
  BlotterTableSort,
  CompositePipeFunction,
  UseBlotterTable,
  UseBlotterTableProps,
} from './types';
import { useBlotterTable } from './useBlotterTable';

const WS_REQUEST_THROTTLE_MS = 50;

export interface WebsocketRequest extends RequestStream {
  Throttle?: string;
  HideApiCalls?: boolean;

  // Drives a server-driven sort (recognized by Ava repo internals)
  // Managed by the `sort` property of useWsBlotterTable
  sort_by?: string;
}

/**
 * Props for the useWsBlotterTable hook
 * @template W Type for the Websocket Request
 * @template TRowOutputType Type for the rows after the pipe
 * @template TRowInputType Optional Type for the input stream from the request, TRowOutputType by default
 *
 * (Note the Type order is backwards (TOutputType/TInputType) on purpose, so that TS auto-detects the type for the pipe)
 */
export type UseWsBlotterTableProps<
  W extends Omit<WebsocketRequest, 'sort_by'>,
  TRowOutputType,
  TRowInputType = TRowOutputType
> = Pick<
  UseBlotterTableProps<TRowOutputType>,
  | 'rowID'
  | 'columns'
  | 'groupableColumns'
  | 'rowHeight'
  | 'handleClickJson'
  | 'flashRows'
  | 'animateRows'
  | 'density'
  | 'rowSelection'
  | 'fitColumns'
  | 'onColumnsChanged'
  | 'onSortChanged'
  | 'onDoubleClickRow'
  | 'onFilterChanged'
  | 'onClickRow'
  | 'onCellClicked'
  | 'onRowSelectionChanged'
  | 'renderEmpty'
  | 'clientLocalFilter'
  | 'getContextMenuItems'
  | 'getExtraMainMenuItems'
  | 'groupDisplayType'
  | 'groupRowsSticky'
  | 'suppressAggFuncInHeader'
  | 'showPinnedRows'
  | 'pinnedRowDataPipe'
  | 'rowGroupPanelShow'
  | 'groupRemoveSingleChildren'
  | 'onRowGroupOpened'
  | 'onExpandOrCollapseAll'
  | 'isGroupOpenByDefault'
  | 'onFirstDataRendered'
  | 'suppressRowClickSelection'
  | 'isRowSelectable'
  | 'quickSearchParams'
  | 'pauseParams'
  | 'autoGroupColumnDef'
  | 'groupDefaultExpanded'
  | 'supportColumnColDefGroups'
> & {
  initialRequest: W;
  initialSort?: UseBlotterTableProps<TRowOutputType>['sort'];
  initialFilter?: UseBlotterTableProps<TRowOutputType>['filter'];
  /**
   * Optional function to transform the subscription response before applying the results to the grid.
   * Use it for applying additional filtering, adding properties, etc.
   */
  pipe?: CompositePipeFunction<TRowOutputType, TRowInputType>;

  /**
   * Whenever the request change, call this function to allow propagating the request up out of the hook.
   * Useful if there is a request outside of the blotter that should be kept in sync
   */
  onInnerRequestChanged?: (newRequest: W | null) => void;

  startingRowLimit?: number;
};

export type UseWsBlotterTable<
  TWebsocketRequestType extends WebsocketRequest,
  TRowOutputType,
  TRowInputType = TRowOutputType
> = Omit<UseBlotterTable<TRowOutputType>, 'onFilterChanged' | 'onSortChanged'> & {
  onFilterChanged: Dispatch<SetStateAction<BlotterTableFilter | undefined>>;
  onSortChanged: (sort?: BlotterTableSort<TRowOutputType>) => void;
  /**
   * Call this function to update the request used to source data. The change will only apply if there is a difference between
   * the new request and the current one (lodash.isEqual)
   * @param updatedFilter The new request
   */
  onRequestChanged: (updatedFilter: TWebsocketRequestType) => void;

  /** Pagination limiter related outputs. These provide an API for interacting with the blotter's pagination functionality and give insight into its current state. */
  paginationLimit: {
    /** Whether or not the pagination limit has been reached by the ws blotter table */
    limitReached?: boolean;
    /** If the limit has been reached, the last record we received will be returned here */
    lastRecord?: TRowInputType;
    /** If the limit has been reached, this number signifies how many records we have received to arrive at that conclusion */
    recordsReceived?: number;
    /** A function to call if you want to raise the pagination limit */
    raiseLimit: (raiseBy?: number) => void;
  };
};

/**
 * Remove `undefined` or empty arrays when patching the current set of applied filters.
 * @param filters
 */
export function removeEmptyFilters<TFilterType extends BlotterTableFilter = BlotterTableFilter>(filters?: TFilterType) {
  const cleaned = { ...filters };
  for (const key in cleaned) {
    if (cleaned[key] == null || cleaned[key]?.length === 0) {
      delete cleaned[key];
    }
  }
  return cleaned as TFilterType;
}

/**
 * Construct a blotter table that is backed by a websocket-basd rxjs subscription.
 * @param props Component props
 * @template TWebsocketRequestType Type for the Websocket Request
 * @template TRowOutputType Type for the rows after the pipe
 * @template TRowInputType Optional Type for the input stream from the request, TRowOutputType by default
 * @returns Blotter table object with the same API as useBlotterTable, but with additional methods for interacting with the websocket request
 */
export function useWsBlotterTable<
  TWebsocketRequestType extends WebsocketRequest,
  TRowOutputType,
  TRowInputType = TRowOutputType
>({
  initialRequest: request,
  initialSort: sort,
  initialFilter,
  rowID,
  pipe,
  columns,
  groupableColumns,
  startingRowLimit,
  onSortChanged,
  onFilterChanged,
  onInnerRequestChanged,
  supportColumnColDefGroups,
  ...props
}: UseWsBlotterTableProps<TWebsocketRequestType, TRowOutputType, TRowInputType>): UseWsBlotterTable<
  TWebsocketRequestType,
  TRowOutputType,
  TRowInputType
> {
  const [activeRowID, setActiveRowID] = useState<string>();
  const [activeRequest, setActiveRequest] = useState<TWebsocketRequestType | null>(null);

  const initialRequestRef = useRef(request);
  const initialFilterRef = useRef(initialFilter);

  const [filter, setFilter] = useState<BlotterTableFilter | undefined>(initialFilter);

  const requestSubject = useConstant<BehaviorSubject<TWebsocketRequestType | null>>(
    new BehaviorSubject<TWebsocketRequestType | null>(initialRequestRef.current)
  );

  // Throttles updates to the activeRequest by WS_REQUEST_THROTTLE_MS
  const requestObservable = useConstant(
    requestSubject.pipe(
      throttleTime(WS_REQUEST_THROTTLE_MS, asyncScheduler, {
        leading: false,
        trailing: true,
      })
    )
  );

  useEffect(() => {
    const subscription = requestObservable.subscribe(request => {
      setActiveRequest(request);
      // propagate the change out of the hook
      onInnerRequestChanged?.(request);
    });
    return () => subscription.unsubscribe();
  }, [requestObservable, requestSubject, onInnerRequestChanged]);

  const { data: subscription, nextPage } = useSubscription(activeRequest, {
    loadAll: false,
    replay: false,
  });

  const [limitReachedState, setLimitReachedState] = useState<LimitReachedChangeEvent<TRowInputType> | undefined>(
    undefined
  );
  const wsPaginationLimiter = useWsPaginationLimiter<TRowInputType>({
    startingLimit: startingRowLimit ?? Infinity,
    nextPage,
    onLimitReachedChange: setLimitReachedState,
  });

  const paginationLimitedSubscription = useMemo(
    () => subscription.pipe(wsPaginationLimiter.wsPaginationLimiterInstance),
    [subscription, wsPaginationLimiter.wsPaginationLimiterInstance]
  );

  // Allow parent to apply any kind of pipe to the data stream
  const pipedSubscription = useObservable(() => {
    return paginationLimitedSubscription.pipe(
      // `useSubscription` doesn't return a new observable when filters change etc, meaning
      // that the blotter table never knows when to show the loading overlay.
      // `map` (+ activeRequest in the deps array) creates a new observable when the request changes
      // so that the blotter is cleared out, and the loading overlay is shown.
      map(x => x),
      // type override needed since we want TRowInputType to be 'converted' to TRowOutputType regardless in the fallback
      pipe ?? (source => source as unknown as Observable<MinimalSubscriptionResponse<TRowOutputType>>)
    );
  }, [paginationLimitedSubscription, pipe]);

  // Order of useEffect is important here
  useEffect(() => {
    const prev = requestSubject.value;
    if (prev != null) {
      requestSubject.next({
        ...initialRequestRef.current,
        ...removeEmptyFilters(filter),
        sort_by: prev.sort_by,
      });
    }
    onFilterChanged?.(filter);
    setActiveRowID(rowID);
  }, [filter, onFilterChanged, requestSubject, rowID]);

  // Perform initial subscription
  const didInitialSubscription = useRef(false);
  const actualColumns = groupableColumns ?? columns;
  useEffect(() => {
    if (actualColumns != null && !didInitialSubscription.current) {
      const sortBy = initialRequestRef.current.sort_by;
      requestSubject.next({
        ...initialRequestRef.current,
        ...removeEmptyFilters(initialFilterRef.current),
        // Only change the sort parameters sent to the backend if we are doing infinite scrolling (which requires the backend
        // to know the sort). Otherwise always sort by what was specified in the initialRequest
        sort_by: isArray(sortBy) ? sortBy[0] : sortBy,
      });
      didInitialSubscription.current = true;
    }
  }, [actualColumns, requestSubject]);

  const handleSortChanged = useCallback(
    sort => {
      onSortChanged?.(sort);
    },
    [onSortChanged]
  );

  const handleRequestChanged = useCallback(
    (updatedRequest: TWebsocketRequestType) => {
      const currentRequest = requestSubject.value;
      const maybeNewRequest: TWebsocketRequestType = {
        ...currentRequest, // important to also spread any filters etc
        ...updatedRequest,
      };

      if (!isEqual(currentRequest, maybeNewRequest)) {
        requestSubject.next(maybeNewRequest);
      }
    },
    [requestSubject]
  );

  const blotterTable = useBlotterTable({
    dataObservable: pipedSubscription,
    rowID: activeRowID,
    columns,
    groupableColumns,
    sort,
    filter,
    supportColumnColDefGroups,
    onSortChanged: handleSortChanged,
    onFilterChanged: setFilter,
    ...props,
  });

  return useMemo(
    () => ({
      ...blotterTable,
      onFilterChanged: setFilter,
      onSortChanged: handleSortChanged,
      onRequestChanged: handleRequestChanged,
      paginationLimit: {
        ...limitReachedState,
        raiseLimit: wsPaginationLimiter.raiseLimit,
      },
    }),
    [
      blotterTable,
      setFilter,
      handleSortChanged,
      handleRequestChanged,
      limitReachedState,
      wsPaginationLimiter.raiseLimit,
    ]
  );
}
