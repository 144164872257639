import { ACTION, IconName, type SidebarSubNavItem } from '@talos/kyoko';
import { PEFrame } from './PEFrame';

const currentDomain = window.location.hostname;
export const PORTFOLIO_ENGINEERING_SUB_NAV_ITEMS: SidebarSubNavItem[] = [
  {
    label: 'Rebalance',
    path: '/portfolio-engineering/rebalance',
    component: <PEFrame src={`//tpe.${currentDomain}/rebalance?trim=true`} title="Rebalance" />,
    icon: IconName.Scale,
    requiredPermission: ACTION.EDIT_PORTFOLIO_ENGINEERING,
  },
  {
    label: 'Back-tester',
    path: '/portfolio-engineering/back-tester',
    component: <PEFrame src={`//tpe.${currentDomain}/backtest?trim=true`} title="Back-tester" />,
    icon: IconName.FastRewind,
    requiredPermission: ACTION.EDIT_PORTFOLIO_ENGINEERING,
  },
  {
    label: 'Reporting',
    path: '/portfolio-engineering/reporting',
    component: <PEFrame src={`//tpe.${currentDomain}/reports?trim=true`} title="Reporting" />,
    icon: IconName.DocumentDownload,
    requiredPermission: ACTION.EDIT_PORTFOLIO_ENGINEERING,
  },
  {
    label: 'Benchmarks',
    path: '/portfolio-engineering/benchmarks',
    component: <PEFrame src={`//tpe.${currentDomain}/benchmarks?trim=true`} title="Benchmarks" />,
    icon: IconName.ChartBarBox,
    requiredPermission: ACTION.EDIT_PORTFOLIO_ENGINEERING,
  },
  {
    label: 'Universes',
    path: '/portfolio-engineering/universes',
    component: <PEFrame src={`//tpe.${currentDomain}/universes?trim=true`} title="Universes" />,
    icon: IconName.Star,
    requiredPermission: ACTION.EDIT_PORTFOLIO_ENGINEERING,
  },
  {
    label: 'Timeseries',
    path: '/portfolio-engineering/ts',
    component: <PEFrame src={`//tpe.${currentDomain}/ts?trim=true`} title="Timeseries" />,
    icon: IconName.PresentationChartLine,
    requiredPermission: ACTION.EDIT_PORTFOLIO_ENGINEERING,
  },
];
