import { FieldValidationLevel, type FieldValidationRule, type NumericField } from '@talos/kyoko';
import Big from 'big.js';
import type { WritableDraft } from 'immer/dist/types/types-external';
import type { OrderPresetFormState } from './types';

export const allocationTotalValidation: FieldValidationRule<NumericField, WritableDraft<OrderPresetFormState>> = (
  field,
  context
) => {
  const allocations = context?.allocations.map(pair => pair.valueField.bigValue) || [];

  const sum = allocations.reduce((acc: Big, value: Big | undefined) => {
    if (value) {
      return acc.add(value);
    }
    return acc;
  }, new Big(0));

  if (!field.hasValue || field.value === '0') {
    return {
      message: `${field.name} cannot be 0`,
      level: FieldValidationLevel.Error,
    };
  }

  if (sum.gt(1)) {
    return {
      message: 'Total cannot exceed 100%',
      level: FieldValidationLevel.Error,
    };
  }
  if (sum.lt(1)) {
    return {
      message: 'Total must add up to 100%',
      level: FieldValidationLevel.Error,
    };
  }

  return null;
};
