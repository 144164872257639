import {
  EXPANDABLE_HEADER_HEIGHT,
  EXPANDABLE_MIN_HEIGHT,
  PRINCIPAL_MAIN_HEADER_HEIGHT,
  TabIndicator,
  TabListWrapper,
  TabWrapper,
  Z_INDEX,
  parseColor,
} from '@talos/kyoko';
import styled from 'styled-components';
import { ADMIN_HEADER_HEIGHT } from '../../styles/dimensions';

export const DragHandle = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  background: transparent;
  transition: background 200ms ease, height 200ms ease;
  height: calc(var(--spacingTiny) * 1px);
  user-select: none;
  cursor: row-resize;
  z-index: 2; // need this to display above kyoko/tabs

  &.active,
  &:hover {
    height: 4px;
    background: ${({ theme }) => theme.colors.gray['070']};
  }

  &.hidden {
    display: none;
  }
`;

export const Wrapper = styled.div<{ height: number }>`
  position: relative;
  z-index: ${Z_INDEX.blotters};
  ${props => `height: ${props.height}px;`}
  width: 100%;
  flex: 0 0 auto;
  transition: height 300ms ease;

  h2 {
    margin: 0;
  }
`;

export const ContentHeader = styled.div<{ isPanelMinimized: boolean }>`
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  padding: ${({ theme }) => `0 ${theme.spacingDefault}px 0 0`};
  background: ${({ theme }) => theme.backgroundFilledTabList};

  transition: height 200ms ease;
  height: ${props => (props.isPanelMinimized ? `${EXPANDABLE_MIN_HEIGHT}px` : `${EXPANDABLE_HEADER_HEIGHT}px`)};
  border-bottom: ${({ theme }) => `1px solid ${theme.backgroundBody}`};

  ${TabListWrapper},
  ${TabIndicator},
  ${TabWrapper} {
    height: ${props => (props.isPanelMinimized ? `${EXPANDABLE_MIN_HEIGHT}px` : `${EXPANDABLE_HEADER_HEIGHT}px`)};
  }
`;

export const Panel = styled.div<{ height: number; isContentExpanded?: boolean; showAdminHeader?: boolean }>`
  transition: transform 300ms linear, height 300ms linear;
  background: ${({ theme }) => parseColor(theme, 'gray.main')};

  // Can't use % here, need to use height by vh to get animation to work correctly.
  // theme.spacingSmall refers to the gap between Header and Cards
  --transform-height: ${({ height, showAdminHeader, theme }) =>
    `calc(-100vh + ${PRINCIPAL_MAIN_HEADER_HEIGHT}px + ${height}px + ${theme.spacingTiny}px${
      showAdminHeader ? ` + ${ADMIN_HEADER_HEIGHT}px` : ''
    })`};
  --available-height: ${({ showAdminHeader }) =>
    `calc(100vh - ${PRINCIPAL_MAIN_HEADER_HEIGHT}px${showAdminHeader ? ` - ${ADMIN_HEADER_HEIGHT}px` : ''})`};

  ${({ isContentExpanded }) => `
		transform: ${isContentExpanded ? `translateY(var(--transform-height))` : 'none'};
		height: ${isContentExpanded ? `var(--available-height)` : '100%'};
	`}
`;
