import { useFeatureFlag } from 'hooks/useFeatureFlag';
import { createContext, useContext, type Dispatch, type SetStateAction } from 'react';

export interface PortfolioSettingsContextValue {
  enablePMS: boolean;
  setEnablePMS: Dispatch<SetStateAction<boolean>>;
  treatStablecoinsAsCash: boolean;
  setTreatStablecoinsAsCash: Dispatch<SetStateAction<boolean>>;
}

export const DefaultPortfolioSettingsConfig: PortfolioSettingsContextValue = {
  enablePMS: false,
  setEnablePMS: () => {},
  treatStablecoinsAsCash: true,
  setTreatStablecoinsAsCash: () => {},
};

export const PortfolioSettingsContext = createContext<PortfolioSettingsContextValue | undefined>(undefined);
PortfolioSettingsContext.displayName = 'PortfolioSettingsContext';

export function usePortfolioSettings() {
  const context = useContext(PortfolioSettingsContext);
  if (context === undefined) {
    throw new Error('Missing PortfolioSettingsContext.Provider further up in the tree. Did you forget to add it?');
  }
  return context;
}

/** Best check to determine if the Portfolio Mamangement System layout is active */
export function useIsPMSActive() {
  const { enablePMS } = usePortfolioSettings();
  const { enablePortfolioManagementSystem } = useFeatureFlag();
  return enablePMS && enablePortfolioManagementSystem;
}
