import type { GridOptions } from 'ag-grid-community';
import { cloneDeep, get, keys, mapValues, noop } from 'lodash';
import { useCallback, useMemo, useRef, useState, type ReactNode } from 'react';
import { map, pipe } from 'rxjs';
import { useUserContext } from '../../contexts';
import { useConstant, useEffectOnce, usePortal, useWSFilterPipe } from '../../hooks';
import { useGlobalToasts } from '../../providers';
import { DELETE, EMPTY_ARRAY, EMPTY_OBJECT, PATCH, POST, request } from '../../utils';
import {
  baseTreeGroupColumnDef,
  BLOTTER_TABLE_FILTERS_CONTAINER_ID,
  BlotterDensity,
  BlotterTable,
  BlotterTableFilters,
  filterExistsAndExcludes,
  useAccordionFilterBuilder,
  useGenericFilter,
  useRestBlotterTable,
  type Column,
  type CompositePipeFunction,
} from '../BlotterTable';
import { Button, ButtonVariants } from '../Button';
import { Box, HStack } from '../Core';
import { useDrawer } from '../Drawer';
import type { InputsAndDropdownsDrawerOption } from '../Drawer/InputsAndDropdownsDrawer';
import type { FilterableProperty } from '../Filters';
import { FormControlSizes } from '../Form';
import { IconName } from '../Icons';
import { NotificationVariants } from '../Notification';
import { Panel, PanelActions, PanelContent, PanelHeader } from '../Panel';
import { Text } from '../Text';
import { EntityAdminDrawer } from './EntityAdminDrawer';
import { ENTITY_INTERNAL_ROW_ID, EntityPageClass, type EntityPageModel } from './types';
import {
  applyInheritanceCellStyle,
  getAddChildEntityColumn,
  getDeleteColumn,
  getEditColumn,
  getEntitiesByParentIDMap,
  getModeColumn,
  type HierarchicalColumnProps,
} from './utils';

export interface EntityAdminPageProps<T> {
  /** The title of the page. */
  title?: string;

  /** The subtitle of the page. */
  subtitle?: string;

  /** The path for the GET API endpoint. */
  path: string;

  /** Function to determine the POST path based on the entity. */
  getPostPath?: (entity: T) => string;

  /** Function to determine the PATCH or DELETE path based on the entity. */
  getPatchDeletePath?: (entity: T) => string;

  /** The name of the entity. */
  entityName?: string;

  /** The request API endpoint override. */
  apiEndpointOverride?: string;

  /** The columns to display in the table. If blank, all columns will be generated and displayed. */
  columns?: Column<unknown, T>[];

  /** The field to use in the API request. */
  entityIDField: keyof T;

  /** The field to use as the Child ID for Tree Data blotters. */
  childIDField?: keyof T;

  /** The panel actions to display alongside the New Entity button. */
  panelActions?: ReactNode[];

  /** The drawer options to display. */
  drawerOptions?: InputsAndDropdownsDrawerOption<T>[];

  /** Function to get the drawer options based on the entity. */
  getDrawerOptions?: (
    entity: T | undefined,
    addingChildEntity?: boolean
  ) => InputsAndDropdownsDrawerOption<T>[] | undefined;

  /** Callback function when columns are ready. */
  onColumnsReady?: (columns: Column<T>[]) => void;

  /** The density of the table. */
  density?: BlotterDensity;

  /** The filter options to display in the table. */
  filterableProperties?: FilterableProperty[];

  /** Whether to allow mode switching. */
  allowModeSwitch?: boolean;

  /** Whether to allow adding new entities. */
  allowAddEntity?: boolean;

  /** Whether to allow editing existing entities. */
  allowEditEntity?: boolean;

  /** Whether to allow deleting entities. */
  allowDeleteEntity?: boolean;

  /** The keys to search for in the table. */
  entitySearchKeys?: (keyof T)[];

  /** Function to resolve the entity name for the drawer. */
  resolveEntityEditDrawerTitle?: (entity: T) => T[keyof T];

  /** Function to filter the list of entities. */
  filterFunc?: (entity: T) => boolean;

  /** The group column definition. */
  groupColumnDef?: GridOptions['autoGroupColumnDef'];

  /** The hierarchical properties, of tree data structures */
  addChildEntityButtonProps?: HierarchicalColumnProps<T>['buttonProps'];
}

export const EntityAdminPage = <T extends EntityPageModel>({
  apiEndpointOverride,
  entityIDField,
  childIDField,
  path: getPath,
  getPostPath = (entity: T) => `${getPath}/${get(entity, entityIDField)}`,
  getPatchDeletePath = (entity: T) => `${getPostPath(entity)}/${get(entity, entityIDField)}`,
  drawerOptions: _drawerOptions,
  getDrawerOptions = () => _drawerOptions,
  entityName = 'Entity',
  title = `${entityName} Page Title`,
  subtitle = `${entityName} Page Subtitle`,
  columns: _columns = undefined,
  panelActions = EMPTY_ARRAY,
  onColumnsReady = noop,
  density = BlotterDensity.Comfortable,
  entitySearchKeys = EMPTY_ARRAY,
  filterableProperties = EMPTY_ARRAY,
  allowModeSwitch = false,
  allowAddEntity = false,
  allowEditEntity = false,
  allowDeleteEntity = false,
  resolveEntityEditDrawerTitle = (entity: T) => get(entity, entityIDField),
  filterFunc: userFilterFunc = () => true,
  groupColumnDef: userTreeGroupColumnDef = EMPTY_OBJECT,
  addChildEntityButtonProps,
}: EntityAdminPageProps<T>) => {
  const { orgApiEndpoint } = useUserContext();
  const apiEndpoint = apiEndpointOverride ?? orgApiEndpoint;
  const columns = useMemo(() => {
    if (childIDField != null) {
      // If the childIDField is provided, we treat the data as hierarchical.
      return _columns?.map(applyInheritanceCellStyle);
    }
    return _columns;
  }, [_columns, childIDField]);

  const [selectedEntity, setSelectedEntity] = useState<T | undefined>();
  const [addingChildEntity, setAddingChildEntity] = useState<boolean>(false);
  const blotterTableApiRef = useRef<{ refresh?: (force?: boolean) => void }>({});
  const [quickFilterText, setQuickFilterText] = useState<string>('');
  const { add: addToast } = useGlobalToasts();
  const entityDrawer = useDrawer({
    position: 'relative',
    width: 450,
    placement: 'right',
    closeOnEscape: true,
  });

  const onDoubleClickRow = useCallback(
    (entity: EntityPageClass<T>) => {
      if (allowEditEntity) {
        setAddingChildEntity(false);
        setSelectedEntity(cloneDeep(entity.data));
        entityDrawer.open();
      }
    },
    [allowEditEntity, entityDrawer]
  );

  // AgGrid wants this return type to be a string[] but it's actually stronger typed as T[keyof T][]
  const getDataPath = useCallback(
    (data: T): string[] => {
      // If the childIDField is provided, we treat the data as hierarchical.
      if (childIDField != null && get(data, childIDField)) {
        return [get(data, entityIDField), get(data, childIDField)] satisfies T[keyof T][] as string[];
      }
      return [get(data, entityIDField)] satisfies T[keyof T][] as string[];
    },
    [childIDField, entityIDField]
  );

  const getUniqueKey = useCallback((entity: T) => getDataPath(entity).join('-'), [getDataPath]);

  const filterResults = useGenericFilter(filterableProperties);
  const filterBuilderAccordion = useAccordionFilterBuilder({
    accordionProps: { initialOpen: keys(filterResults.filter).length > 0 },
    filterBuilderProps: filterResults.filterBuilderProps,
  });

  const filterFunc = useCallback(
    (entity: T) => {
      let filteredOut = false;
      filterableProperties.forEach(property => {
        if (property.field == null) {
          throw new Error('Field is required for all filterable properties of EntityAdminPage');
        }
        if (filterExistsAndExcludes(filterResults.filter, property.key, entity, property.field!)) {
          filteredOut = true;
        }
      });
      return !filteredOut;
    },
    [filterResults.filter, filterableProperties]
  );

  const filterPipe = useWSFilterPipe<T>({ getUniqueKey, filterFunc });
  const parentMapRef = useRef<Map<keyof T, T> | undefined>();
  const blotterTablePipe: CompositePipeFunction<EntityPageClass<T>, T> = useConstant(
    pipe(
      filterPipe,
      map(json => {
        parentMapRef.current = getEntitiesByParentIDMap(json.data, entityIDField, childIDField);
        return json;
      }),
      map(json => ({
        ...json,
        data: json.data
          .filter(item => userFilterFunc(item))
          .map(row => new EntityPageClass<T>(cloneDeep(row), entityIDField, childIDField, parentMapRef.current)),
      }))
    )
  );

  const getPathWithApiEndpoint = useCallback((path: string) => `${apiEndpoint}${path}`, [apiEndpoint]);

  const postEntity = useCallback(
    (entity: T) => request(POST, getPathWithApiEndpoint(getPostPath(entity)), entity),
    [getPathWithApiEndpoint, getPostPath]
  );
  const patchEntity = useCallback(
    (entity: T) => request(PATCH, getPathWithApiEndpoint(getPatchDeletePath(entity)), entity),
    [getPatchDeletePath, getPathWithApiEndpoint]
  );
  const deleteEntity = useCallback(
    (entity: T) => request(DELETE, getPathWithApiEndpoint(getPatchDeletePath(entity))),
    [getPatchDeletePath, getPathWithApiEndpoint]
  );

  const handleOnCreateNewEntity = useCallback(
    (newEntity: T) => {
      postEntity(newEntity)
        .then(() => {
          entityDrawer.close();
          blotterTableApiRef.current.refresh?.();
          addToast({
            text: `${entityName} entity created successfully.`,
            variant: NotificationVariants.Positive,
          });
        })
        .catch(error => {
          addToast({
            text: error.toString() ?? `Failed to create ${entityName} entity.`,
            variant: NotificationVariants.Negative,
          });
        });
    },
    [entityDrawer, addToast, entityName, postEntity, blotterTableApiRef]
  );

  const handleOnUpdateEntity = useCallback(
    (updatedEntity: T) => {
      // Convert empty strings to null
      const entityForPatch: T = mapValues(cloneDeep(updatedEntity), value => (value === '' ? null : value)) as T;

      patchEntity(entityForPatch)
        .then(res => {
          entityDrawer.close();
          blotterTableApiRef.current.refresh?.();
          addToast({
            text: `${entityName} entity saved successfully.`,
            variant: NotificationVariants.Positive,
          });
        })
        .catch(error => {
          addToast({
            text: error.toString() ?? `Failed to save ${entityName} entity.`,
            variant: NotificationVariants.Negative,
          });
        });
    },
    [patchEntity, entityDrawer, addToast, entityName]
  );

  const handleOnSaveEntity = useCallback(
    (entity: T) => {
      if (selectedEntity == null || addingChildEntity) {
        handleOnCreateNewEntity(entity);
      } else {
        handleOnUpdateEntity(entity);
      }
    },
    [selectedEntity, addingChildEntity, handleOnCreateNewEntity, handleOnUpdateEntity]
  );

  const handleOnDeleteEntity = useCallback(
    (selectedEntity: T) => {
      deleteEntity(selectedEntity)
        .then(() => {
          entityDrawer.close();
          blotterTableApiRef.current.refresh?.(true);
          addToast({
            text: 'Entity deleted successfully.',
            variant: NotificationVariants.Positive,
          });
        })
        .catch(error => {
          addToast({
            text: error.toString() ?? 'Failed to delete entity',
            variant: NotificationVariants.Negative,
          });
        });
    },
    [deleteEntity, entityDrawer, addToast]
  );

  const startingColumns: Column<T>[] | undefined = useMemo(() => {
    const cols: Column<T>[] = [];

    if (allowModeSwitch) {
      cols.push(getModeColumn<T>({ handleOnClick: handleOnUpdateEntity }));
    }

    return cols;
  }, [allowModeSwitch, handleOnUpdateEntity]);

  const openEntityDrawer: HierarchicalColumnProps<T>['openEntityDrawer'] = useConstant(
    (setupEntity: T | undefined, addingChildEntity: boolean) => {
      setAddingChildEntity(addingChildEntity);
      setSelectedEntity(setupEntity);
      entityDrawer.open();
    }
  );

  const endingColumns: Column<T>[] | undefined = useMemo(() => {
    const cols: Column<T>[] = [];

    if (allowAddEntity && addChildEntityButtonProps != null && childIDField != null) {
      cols.push(
        getAddChildEntityColumn<T>({
          openEntityDrawer,
          buttonProps: addChildEntityButtonProps,
          entityIDField,
          childIDField,
        })
      );
    }

    if (allowEditEntity) {
      cols.push(
        getEditColumn<T>({
          handleOnClick: (entity: T) => {
            openEntityDrawer(entity, false);
          },
        })
      );
    }

    if (allowDeleteEntity) {
      cols.push(
        getDeleteColumn<T>({
          handleOnClick: (entity: T) => {
            if (window.confirm('Are you sure you want to delete this entity?')) {
              handleOnDeleteEntity(entity);
            }
          },
        })
      );
    }

    return cols;
  }, [
    allowAddEntity,
    addChildEntityButtonProps,
    childIDField,
    allowEditEntity,
    allowDeleteEntity,
    openEntityDrawer,
    entityIDField,
    handleOnDeleteEntity,
  ]);

  const treeDataProps: GridOptions<T> | undefined = useMemo(() => {
    if (childIDField != null) {
      // If the childIDField is provided, we treat the data as hierarchical.
      return {
        autoGroupColumnDef: { ...baseTreeGroupColumnDef, ...userTreeGroupColumnDef },
        treeData: true,
        getDataPath,
      };
    }
    return undefined;
  }, [childIDField, getDataPath, userTreeGroupColumnDef]);

  const blotterTable = useRestBlotterTable<EntityPageClass<T>>({
    request: { path: getPath },
    startingColumns,
    columns,
    endingColumns,
    rowID: ENTITY_INTERNAL_ROW_ID,
    density,
    // @ts-expect-error - The response is T but we turn it into EntityPageClass<T>
    pipe: blotterTablePipe,
    quickSearchParams: {
      entitySearchKeys: entitySearchKeys as (keyof EntityPageClass<T>)[],
      filterText: quickFilterText,
    },
    onColumnsReady,
    onDoubleClickRow,
    ...treeDataProps,
  });

  useEffectOnce(() => {
    blotterTableApiRef.current.refresh = blotterTable.refresh;
  });

  const detailsDrawerKey = useMemo(() => {
    if (selectedEntity == null) {
      return 'new-entity';
    }
    return getUniqueKey(selectedEntity);
  }, [getUniqueKey, selectedEntity]);

  const { setPortalRef: filtersContainerRef } = usePortal(BLOTTER_TABLE_FILTERS_CONTAINER_ID);

  const titleForDrawer = useMemo(() => {
    if (addingChildEntity && selectedEntity != null) {
      const childEntityName = addChildEntityButtonProps?.text;
      return `New ${childEntityName}`;
    }
    if (selectedEntity != null) {
      return `Modify ${resolveEntityEditDrawerTitle(selectedEntity)}`;
    }
    return `New ${entityName}`;
  }, [addChildEntityButtonProps?.text, addingChildEntity, entityName, resolveEntityEditDrawerTitle, selectedEntity]);

  const drawerOptions = getDrawerOptions(selectedEntity, addingChildEntity);

  return (
    <HStack h="100%" w="100%" gap="spacingTiny" overflow="hidden">
      <Panel>
        <PanelHeader alignItems="center">
          <Box>
            {title && <h2 data-testid="entity-admin-page-title">{title}</h2>}
            {subtitle && (
              <Box mt="spacingDefault">
                <Text whiteSpace="break-spaces" data-testid="entity-admin-page-subtitle">
                  {subtitle}
                </Text>
              </Box>
            )}
          </Box>
          <PanelActions>
            <Box ref={filtersContainerRef} />
            {panelActions.length > 0 && (
              <HStack data-testid="entity-admin-page-panel-actions" gap="spacingDefault">
                {panelActions.map((inputComponent, index) => (
                  <Box key={index}>{inputComponent}</Box>
                ))}
              </HStack>
            )}
            {allowAddEntity && (
              <Button
                startIcon={IconName.Plus}
                onClick={() => openEntityDrawer(undefined, false)}
                variant={ButtonVariants.Positive}
                data-testid="entity-admin-page-new-entity-button"
                size={FormControlSizes.Small}
              >
                New {entityName}
              </Button>
            )}
          </PanelActions>
        </PanelHeader>
        <PanelContent>
          <BlotterTableFilters {...filterBuilderAccordion} onQuickFilterTextChanged={setQuickFilterText} />
          <BlotterTable {...blotterTable} />
        </PanelContent>
      </Panel>
      <EntityAdminDrawer<T>
        key={detailsDrawerKey}
        drawerOptions={drawerOptions}
        addingChildEntity={addingChildEntity}
        selectedEntity={selectedEntity}
        handleOnSaveEntity={handleOnSaveEntity}
        handleOnDeleteEntity={handleOnDeleteEntity}
        allowAddEntity={allowAddEntity}
        allowEditEntity={allowEditEntity}
        allowDeleteEntity={allowDeleteEntity}
        entityDrawer={entityDrawer}
        title={titleForDrawer}
      />
    </HStack>
  );
};
