import type { POSITION_SUB_ACCOUNT } from '@talos/kyoko';
import {
  RISK_SUB_ACCOUNT,
  useObservableValue,
  useSubscription,
  type MinimalSubscriptionResponse,
  type WebsocketRequest,
} from '@talos/kyoko';
import { useDisplaySettings } from 'providers/DisplaySettingsProvider';
import { usePortfolioSettings } from 'providers/PortfolioSettingProvider';
import { createContext, useContext, useEffect, useMemo, useState, type PropsWithChildren } from 'react';
import { map } from 'rxjs';
import { POSITIONS_BLOTTER_CONVERSION_TOLERANCE } from '../components/tokens';
import { usePortfolioViewStateSelector } from '../stateManagement/portfolioViewLayoutSlice.hooks';
import type { PortfolioRiskGridData } from '../types/PortfolioRiskGridData';
import { useRequestStateTagging } from '../useRequestStateTagging';
import type { FromRiskObsValue, ToObsValue } from './PortfolioManagementProvider.types';
import { useGetSubAccountNamesForRequest } from './useGetSubAccountNamesForRequest';
import { useSubAccountRiskBatchPipe } from './useSubAccountRiskBatchPipe';

export function getNewTag(prefix: string) {
  return `${prefix}::${Date.now()}`;
}

export interface PortfolioManagementProviderRequest extends WebsocketRequest {
  name: typeof POSITION_SUB_ACCOUNT;
  ShowZeroBalances: boolean;
  EquivalentCurrency: string;
}

export interface PortfolioManagementContextProps {
  batchedRiskData: MinimalSubscriptionResponse<PortfolioRiskGridData> | undefined;
  isDataLoading: boolean;
}

const PortfolioManagementContext = createContext<PortfolioManagementContextProps | undefined>(undefined);

export const PortfolioManagementProvider = ({ children }: PropsWithChildren) => {
  const { treatStablecoinsAsCash } = usePortfolioSettings();
  const { selectedPortfolioId, showZeroBalances, showRollupHierarchy, includeCash } = usePortfolioViewStateSelector();
  const [batchedRiskData, setLatestBatchUpdate] = useState<ToObsValue>();
  const [isDataLoading, setIsDataLoading] = useState(true);
  const { homeCurrency } = useDisplaySettings();
  const getSubAccountForRequest = useGetSubAccountNamesForRequest();

  const { request, tagMapRef } = useRequestStateTagging({
    request: {
      name: RISK_SUB_ACCOUNT,
      ShowZeroBalances: showZeroBalances,
      EquivalentCurrency: homeCurrency,
      Tolerance: POSITIONS_BLOTTER_CONVERSION_TOLERANCE,
      SubAccounts: getSubAccountForRequest(selectedPortfolioId ?? -1),
    },
    extraState: {
      showRollupHierarchy,
      selectedPortfolioId,
      includeCash,
      treatStablecoinsAsCash,
    },
    getNewTag: () => getNewTag('PortfolioManagementProvider'),
  });
  const { data: subscription } = useSubscription<FromRiskObsValue>(selectedPortfolioId != null ? request : null);

  const batchedUpdateResultObs = useSubAccountRiskBatchPipe(subscription, tagMapRef);

  const batchObsUpdate = useObservableValue<ToObsValue>(() => {
    return batchedUpdateResultObs.pipe(
      map(response => {
        setIsDataLoading(false);
        return response;
      })
    );
  }, [batchedUpdateResultObs]);

  useEffect(() => {
    setLatestBatchUpdate(batchObsUpdate);
  }, [batchObsUpdate]);

  const contextValue = useMemo(() => {
    return {
      batchedRiskData,
      isDataLoading,
    };
  }, [isDataLoading, batchedRiskData]);

  return <PortfolioManagementContext.Provider value={contextValue}>{children}</PortfolioManagementContext.Provider>;
};

export const usePortfolioManagementProvider = () => {
  const context = useContext(PortfolioManagementContext);
  if (context === undefined) {
    throw new Error('Missing PortfolioManagementContext.Provider further up in the tree. Did you forget to add it?');
  }
  return context;
};
