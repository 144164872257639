import {
  BlotterTable,
  BlotterTableFilters,
  Button,
  ButtonVariants,
  FormControlSizes,
  IconName,
  TRANSFER,
  filterByCellValueMenuItem,
  removeEmptyFilters,
  useAccordionFilterBuilder,
  useDateRangeFilter,
  useGetDefaultContextMenuItems,
  useJsonModal,
  useMixpanel,
  usePersistedBlotterTable,
  useWsBlotterTable,
  type BlotterTableFilter,
  type Column,
  type FilterClause,
  type FilterableProperty,
  type Transfer,
} from '@talos/kyoko';
import type { GetContextMenuItemsParams, MenuItemDef } from 'ag-grid-community';
import { isEqual } from 'lodash';
import { useCallback, useEffect } from 'react';
import type { AdditionalTransferColumns } from './useTransfersColumns';
import { useTransfersFilter, type TransfersBlotterFilter } from './useTransfersFilter';

const ENTITY_SEARCH_KEYS: (keyof Transfer)[] = [
  'Market',
  'Description',
  'ReferenceData',
  'TransferID',
  'TxHashes',
  'OwnerAccountID',
  'FromAddress',
  'ToAddress',
];

const OTHER_SEARCH_KEYS: AdditionalTransferColumns[] = ['transferDescription'];

export interface RecentTransfersParams {
  blotterID: string;
  tabLabel?: string;
  defaultColumns: Column[];
  defaultFilter: TransfersBlotterFilter;
  initialIsOpen?: boolean;

  showCloneTab?: boolean;
  /** filter and columns are current state to be cloned to new tab */
  onCloneTab: (filter: BlotterTableFilter, columns: Column[]) => void;
  getContextMenuItems?: (params: GetContextMenuItemsParams) => MenuItemDef[];
}

export function RecentTransfers({
  blotterID,
  tabLabel,
  defaultColumns,
  defaultFilter,
  initialIsOpen,
  showCloneTab,
  onCloneTab,
  getContextMenuItems: getParentContextMenuItems,
}: RecentTransfersParams) {
  const mixpanel = useMixpanel();

  const getAppendedContextMenuItems = useGetDefaultContextMenuItems();

  const persistedBlotterTable = usePersistedBlotterTable<Transfer>(blotterID, {
    columns: defaultColumns,
    filter: defaultFilter,
    sort: '-SubmitTime',
  });

  const handleDisplayedColumnsChanged = useCallback(
    columns => {
      persistedBlotterTable.onColumnsChanged(columns);
    },
    [persistedBlotterTable]
  );

  const filterResults = useTransfersFilter({
    persistedBlotterTable,
  });

  const { clientSideFilter, changeFilter, filterableProperties, initialFilterClauses } = filterResults;
  const handleFilterClausesChanged = useCallback(
    (filterClausesByPropertyKey: Map<string, FilterClause>, propertiesByKey: Map<string, FilterableProperty>) => {
      changeFilter(curr => {
        const newFilter = removeEmptyFilters<TransfersBlotterFilter>({
          ...curr,
          ...(Object.fromEntries(
            [...propertiesByKey.keys()].map(key => [key, filterClausesByPropertyKey.get(key)?.selections])
          ) as unknown as TransfersBlotterFilter),
        });
        if (isEqual(curr, newFilter)) {
          return curr;
        }
        return newFilter;
      });
    },
    [changeFilter]
  );

  const dateRangeFilter = useDateRangeFilter(filterResults.filter, changeFilter);

  const { handleClickJson, jsonModal } = useJsonModal();

  const filterBuilderAccordion = useAccordionFilterBuilder({
    accordionProps: { initialOpen: initialIsOpen },
    filterBuilderProps: {
      initialFilterClauses,
      properties: filterableProperties,
      onFilterClausesChanged: handleFilterClausesChanged,
    },
  });

  const getContextMenuItems = useCallback(
    (params: GetContextMenuItemsParams) => [
      ...filterByCellValueMenuItem({
        params,
        openClause: filterBuilderAccordion.openClause,
        filterableProperties,
        colIDToFilterBuilderKey,
        mixpanel,
      }),
      'separator',
      {
        action: () => handleClickJson(params.node?.data),
        name: 'Show JSON',
        icon: `<i class="ag-icon ${IconName.Braces}"/>`,
      },
      'separator',
      ...(getParentContextMenuItems?.(params) || []),
      ...getAppendedContextMenuItems(params),
    ],
    [
      filterBuilderAccordion.openClause,
      filterableProperties,
      getAppendedContextMenuItems,
      getParentContextMenuItems,
      mixpanel,
      handleClickJson,
    ]
  );

  const blotterTable = useWsBlotterTable({
    initialRequest: {
      name: TRANSFER,
      tag: 'TransfersBlotter',
    },
    initialFilter: onlyServerFilterKeys(persistedBlotterTable.initialFilter),
    initialSort: persistedBlotterTable.initialSort,
    columns: persistedBlotterTable.columns,
    rowID: 'rowID' satisfies keyof Transfer,
    clientLocalFilter: clientSideFilter,
    rowSelection: 'multiple',
    onSortChanged: persistedBlotterTable.onSortChanged,
    onColumnsChanged: handleDisplayedColumnsChanged,
    handleClickJson,
    getContextMenuItems,
    quickSearchParams: {
      entitySearchKeys: ENTITY_SEARCH_KEYS,
      otherSearchKeys: OTHER_SEARCH_KEYS,
    },
  });

  /**
   * When the configured filter changes, tell WSBlotterTable about
   * the server keys of the filter but not the locally evaluated keys as they might break the backend.
   */
  useEffect(() => {
    if (filterResults.filter) {
      const serverFilter = onlyServerFilterKeys(filterResults.filter);
      if (!isEqual(blotterTable.filter, serverFilter)) {
        blotterTable.onFilterChanged(serverFilter);
      }
    }
  }, [blotterTable, filterResults.filter]);

  const handleCloneTab = useCallback(() => {
    onCloneTab?.(filterResults.filter, blotterTable.getColumns());
  }, [blotterTable, filterResults.filter, onCloneTab]);

  return (
    <>
      <BlotterTableFilters
        {...filterBuilderAccordion}
        {...dateRangeFilter}
        {...blotterTable.blotterTableFiltersProps}
        prefix={
          showCloneTab && (
            <Button
              startIcon={IconName.Duplicate}
              variant={ButtonVariants.Default}
              size={FormControlSizes.Small}
              onClick={handleCloneTab}
            >
              Clone Tab
            </Button>
          )
        }
      />
      <BlotterTable {...blotterTable} />
      {jsonModal}
    </>
  );
}

function onlyServerFilterKeys(filter: TransfersBlotterFilter | undefined) {
  if (!filter) {
    return filter;
  }

  return {
    StartDate: filter.StartDate,
    EndDate: filter.EndDate,
  } satisfies TransfersBlotterFilter;
}

function colIDToFilterBuilderKey(id: string): keyof TransfersBlotterFilter | undefined {
  switch (id as keyof Transfer) {
    case 'Market':
      return 'Provider';
    case 'Currency':
      return 'Currency';
    case 'Status':
      return 'Status';
    case 'OwnerAccountID':
      return 'Account';
    case 'User':
      return 'Submitter';
    default:
      return undefined;
  }
}
