import type { WritableDraft } from 'immer/dist/types/types-external';

import {
  AllocationValueTypeEnum,
  FieldValidationLevel,
  SettleValueTypeEnum,
  allowContracts,
  getMinIncrementForContract,
  isFuture,
  isOption,
  isPerpetualSwap,
  isSecurityQuantoFuture,
  toBigWithDefault,
  validatePrecision,
  type FieldValidationRule,
  type NumericField,
} from '@talos/kyoko';
import Big from 'big.js';
import type { RFQFormState, RFQState } from './types';

export const allocationTotalValidation: FieldValidationRule<NumericField, WritableDraft<RFQFormState>> = (
  field,
  context
) => {
  const valueType = context?.allocationValueTypeField.value;
  const allocations = context?.allocations.map(pair => pair.valueField.bigValue) || [];
  const quantity = new Big(context?.quantityField.value || 0);

  const sum = allocations.reduce((acc: Big, value: Big | undefined) => {
    if (value) {
      return acc.add(value);
    }
    return acc;
  }, new Big(0));

  if (!field.hasValue || field.value === '0') {
    return {
      message: `${field.name} cannot be 0`,
      level: FieldValidationLevel.Error,
    };
  }

  if (valueType === AllocationValueTypeEnum.Percentage) {
    if (sum.gt(1)) {
      return {
        message: 'Total cannot exceed 100%',
        level: FieldValidationLevel.Error,
      };
    }
    if (sum.lt(1)) {
      return {
        message: 'Total must add up to 100%',
        level: FieldValidationLevel.Error,
      };
    }
  } else {
    if (sum.gt(quantity)) {
      return {
        message: 'Total cannot exceed Quantity',
        level: FieldValidationLevel.Error,
      };
    }
    if (sum.lt(quantity)) {
      return {
        message: 'Total must add up to Quantity',
        level: FieldValidationLevel.Error,
      };
    }
  }

  return null;
};

// TODO: rewrite
// The below validation is a like for like copy of the one in old order form (useOrderValidation line 135 onwards)
// We should really rewrite it so it's more readable as in the current shape it's really hard to explain what's happening
export const quantityValidation: FieldValidationRule<NumericField, WritableDraft<RFQState>> = (field, context) => {
  if (!context) {
    return null;
  }

  const { form, referenceData } = context;

  const security = form?.symbolField.value;
  if (!security) {
    return null;
  }

  const allowQtyInContracts = allowContracts(security);
  const quantity = new Big(field.value || 0);
  const orderCurrency = form.rfqCurrencyField.value || '';

  const { MinimumSize, MinSizeIncrement, QuoteCurrency } = security;
  const orderCurrencyInfo = referenceData.currencies.currenciesBySymbol.get(orderCurrency);
  const { MinIncrement } = orderCurrencyInfo || {};

  // https://talostrading.atlassian.net/browse/UI-4006
  if (isPerpetualSwap(security) || isFuture(security) || isSecurityQuantoFuture(security)) {
    const { PositionCurrency, SettleValueType, BaseCurrency, QuoteCurrency, NotionalMultiplier } = security;

    const isRegularType = SettleValueType === SettleValueTypeEnum.Regular;
    const isInvertedType = SettleValueType === SettleValueTypeEnum.Inverted;
    const orderCcyInBase = orderCurrency === BaseCurrency;
    const orderCcyInQuote = orderCurrency === QuoteCurrency;
    const orderCcyInContracts = orderCurrency === '';

    if (PositionCurrency) {
      if ((isRegularType && orderCcyInBase) || (isInvertedType && orderCcyInQuote)) {
        if (quantity.lt(MinimumSize)) {
          return {
            message: `Min quantity is ${MinimumSize}`,
            level: FieldValidationLevel.Error,
          };
        }
      }
    } else {
      if (orderCcyInContracts) {
        if (quantity.lt(MinimumSize)) {
          return {
            message: `Min contract is ${MinimumSize}`,
            level: FieldValidationLevel.Error,
          };
        }
      }
      if ((isRegularType && orderCcyInBase) || (isInvertedType && orderCcyInQuote)) {
        const minSize = toBigWithDefault(MinimumSize, 0).mul(NotionalMultiplier);
        if (quantity.lt(minSize)) {
          return {
            message: `Min quantity is ${minSize}`,
            level: FieldValidationLevel.Error,
          };
        }
      }
    }
  }

  let minSize = MinimumSize;
  let minIncr = MinSizeIncrement;
  if (!isOption(security) && orderCurrency === QuoteCurrency && MinIncrement) {
    minSize = MinIncrement;
    minIncr = MinIncrement;
  }

  if (allowQtyInContracts && orderCurrency) {
    const minContractIncrement = getMinIncrementForContract(security, orderCurrency);
    if (minContractIncrement) {
      if (quantity.lt(minContractIncrement)) {
        return {
          message: `Min quantity is ${minContractIncrement}`,
          level: FieldValidationLevel.Error,
        };
      }

      if (!validatePrecision(minContractIncrement, field.value)) {
        return {
          message: `Quantity must be a multiple of ${minContractIncrement}`,
          level: FieldValidationLevel.Error,
        };
      }
    } else {
      minSize = orderCurrencyInfo?.MinIncrement ?? '0.01';
      minIncr = orderCurrencyInfo?.MinIncrement ?? '0.01';

      if (quantity.lt(minSize)) {
        return {
          message: `Min quantity is ${minSize}`,
          level: FieldValidationLevel.Error,
        };
      }

      if (!validatePrecision(minIncr, field.value)) {
        return {
          message: `Min increment is ${minIncr}`,
          level: FieldValidationLevel.Error,
        };
      }
    }
  } else {
    if (quantity.lt(minSize)) {
      return {
        message: `Min quantity is ${minSize}`,
        level: FieldValidationLevel.Error,
      };
    }

    if (!validatePrecision(minIncr, field.value)) {
      return {
        message: `Min increment is ${minIncr}`,
        level: FieldValidationLevel.Error,
      };
    }
  }

  return null;
};
