import {
  type Currency,
  FieldValidationLevel,
  type FieldValidationRule,
  type NumericField,
  type SelectorField,
  toBig,
  toBigWithDefault,
  validatePrecision,
} from '@talos/kyoko';
import type { WritableDraft } from 'immer/dist/internal';
import type { PositionAutoHedgingDrawerState } from './types';

export function performValidation(state: WritableDraft<PositionAutoHedgingDrawerState>) {
  state.form.LongPositionLimit = state.form.LongPositionLimit.validate(
    [correctIncrementValidation, longPositionLimitNotEqualToShortPositionLimitValidation],
    state
  );
  state.form.TargetPosition = state.form.TargetPosition.validate(
    [correctIncrementValidation, targetPositionValidation],
    state
  );
  state.form.ShortPositionLimit = state.form.ShortPositionLimit.validate(
    [correctIncrementValidation, longPositionLimitNotEqualToShortPositionLimitValidation],
    state
  );
  state.form.Asset = state.form.Asset.validate([validatePossibleTargetAssets], state);

  state.form.TargetAsset = state.form.TargetAsset.validate([validatePossibleHedingPairs], state);
}

/**
 *  Validates that:
 *  short < limit < long
 *  is true
 */
const targetPositionValidation: FieldValidationRule<NumericField, WritableDraft<PositionAutoHedgingDrawerState>> = (
  field,
  context
) => {
  if (!context || (field.isRequired && !field.value)) {
    return null;
  }

  const {
    form: { LongPositionLimit, ShortPositionLimit },
  } = context;
  if (!LongPositionLimit.value || !ShortPositionLimit.value || !field.value) {
    return null;
  }

  const shortPositionLimit = toBig(ShortPositionLimit.value)?.mul(-1);
  const limitValue = toBig(field.value);
  const longPositionLimit = toBig(LongPositionLimit.value);

  if (!shortPositionLimit || !limitValue || !longPositionLimit) {
    return null;
  }

  // basically: short < limit < long
  if (!(shortPositionLimit.lt(limitValue) && limitValue.lt(longPositionLimit))) {
    return {
      message: `Target Position must be between Short and Long Position Limit`,
      level: FieldValidationLevel.Error,
    };
  }

  return null;
};

/**
 * Validates "longPositionLimit != shortPositionLimit"
 */
const longPositionLimitNotEqualToShortPositionLimitValidation: FieldValidationRule<
  NumericField,
  WritableDraft<PositionAutoHedgingDrawerState>
> = (field, context) => {
  if (!context || (field.isRequired && !field.value)) {
    return null;
  }

  const {
    form: { ShortPositionLimit, LongPositionLimit },
  } = context;
  if (!ShortPositionLimit.value || !LongPositionLimit.value) {
    return null;
  }
  // do this conversion here, so really it only errors if the values are both zero.
  const shortPositionLimit = toBigWithDefault(ShortPositionLimit.value, 0).mul(-1);
  const longPositionLimit = toBigWithDefault(LongPositionLimit.value, 0);

  if (shortPositionLimit.eq(longPositionLimit)) {
    return {
      message: `Long and Short Position Limit cannot be the same`,
      level: FieldValidationLevel.Error,
    };
  }

  return null;
};

/**
 * Validates the increment for the field.
 */
const correctIncrementValidation: FieldValidationRule<NumericField, WritableDraft<PositionAutoHedgingDrawerState>> = (
  field,
  context
) => {
  if (!context || (field.isRequired && !field.value)) {
    return null;
  }
  const { form } = context;
  const currency = form?.TargetAsset.value;
  if (!currency) {
    return null;
  }
  const limitValue = toBigWithDefault(field.value, 0);
  const { MinIncrement } = currency;

  if (!validatePrecision(MinIncrement, limitValue)) {
    return {
      message: `Min increment is ${MinIncrement}`,
      level: FieldValidationLevel.Error,
    };
  }

  return null;
};

const validatePossibleTargetAssets: FieldValidationRule<
  SelectorField<Currency>,
  WritableDraft<PositionAutoHedgingDrawerState>
> = (field, context) => {
  if (!context || (field.isRequired && !field.value)) {
    return null;
  }

  // if the asset is selected, but there are no target assets available, then we should error
  const { Asset, TargetAsset } = context.form;
  if (Asset.value && TargetAsset.availableItems.length === 0) {
    return {
      message: `No symbol pairs available for ${Asset.value.Symbol}`,
      level: FieldValidationLevel.Error,
    };
  }
  return null;
};

/**
 * Ensure that there exists a valid pair that we can hedge the position out of
 */
const validatePossibleHedingPairs: FieldValidationRule<
  SelectorField<Currency>,
  WritableDraft<PositionAutoHedgingDrawerState>
> = (field, context) => {
  if (!context || !field.value) {
    return null;
  }

  const { Asset, TargetAsset } = context.form;
  const { positionHedgerHomeCurrency, spotTradingPairsByBaseCurrency, spotTradingPairsByQuoteCurrency } =
    context.referenceData;

  const targetAsset = TargetAsset.value?.Symbol;
  const asset = Asset.value?.Symbol;

  if (asset === targetAsset && asset === positionHedgerHomeCurrency) {
    return {
      message: `Cannot hedge the position with the same asset`,
      level: FieldValidationLevel.Error,
    };
  }

  // deduce if there is a possible pair to hedge the position
  if (asset && targetAsset) {
    // get all possible pairs that we can trade the asset against, since this is "BaseCurrency -> symbol" map,
    // we extract the QuoteCurrency, basically looking up all the permutations of `asset-quote`
    const possibleQuoteCurrencies =
      (spotTradingPairsByBaseCurrency.get(asset) || [])
        .concat(spotTradingPairsByBaseCurrency.get(targetAsset) || [])
        .map(pair => pair.QuoteCurrency) || [];

    // get all possible pairs that we can trade the asset against, since this is "QuoteCurrency -> symbol" map,
    // we extract the BaseCurrency, basically looking up all the permutations of `base-asset`
    const possibleBaseCurrencies =
      (spotTradingPairsByQuoteCurrency.get(asset) || [])
        .concat(spotTradingPairsByQuoteCurrency.get(targetAsset) || [])
        .map(pair => pair.BaseCurrency) || [];
    const possiblePairsToAsset = new Set([...possibleQuoteCurrencies, ...possibleBaseCurrencies]);
    // There is no way for us to trade out of the position
    if (!possiblePairsToAsset.has(positionHedgerHomeCurrency)) {
      const assetText = asset === targetAsset ? `${asset} is not` : `${asset} nor ${targetAsset} is`;

      return {
        message: `Unable to hedge this position. ${assetText} traded against ${positionHedgerHomeCurrency}`,
        level: FieldValidationLevel.Warning,
      };
    }
  }

  return null;
};
