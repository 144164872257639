import {
  Box,
  InlineFormattedNumber,
  JITTooltip,
  LeftMiddleRightFlexbox,
  Meter,
  Table,
  Tbody,
  Td,
  Th,
  toBigWithDefault,
  Tr,
  useEquityMarginRatioLimitContext,
  VStack,
} from '@talos/kyoko';
import type { Big } from 'big.js';
import { useTheme } from 'styled-components';
import { MIN_EQUITY_MARGIN_RATIO_INCREMENT } from '../../Settings/TradingControls/types';

interface EquityMarginRatioProps {
  marketAccount: string;
  equity: string;
  maintenanceMargin: string;
}

export function EquityMarginRatio({ marketAccount, equity, maintenanceMargin }: EquityMarginRatioProps) {
  const theme = useTheme();
  const { globalEquityMarginRatioLimit, equityMarginRatioLimitByMarketAccount } = useEquityMarginRatioLimitContext();
  const ratio = equityMarginRatioLimitByMarketAccount.get(marketAccount)?.Ratio ?? globalEquityMarginRatioLimit?.Ratio;

  if (!equity || !maintenanceMargin || !ratio) {
    return null;
  }
  const equityBig = toBigWithDefault(equity, 0);
  const maintenanceMarginBig = toBigWithDefault(maintenanceMargin, 0);
  const ratioBig = toBigWithDefault(ratio, 0);
  if ((equityBig.eq(0) && maintenanceMarginBig.eq(0)) || ratioBig.eq(0)) {
    return null;
  }

  let emrBig: Big | undefined;
  if (maintenanceMarginBig.eq(0)) {
    emrBig = equityBig;
  } else if (equityBig.eq(0)) {
    emrBig = undefined;
  } else {
    emrBig = equityBig.div(maintenanceMarginBig);
  }

  let value;
  if (equityBig.eq(0) || maintenanceMarginBig.eq(0)) {
    value = 1; // UI-5054
  } else {
    value = !emrBig || emrBig.eq(0) ? 0 : 1 - ratioBig.div(emrBig).toNumber();
  }

  const colors = maintenanceMarginBig.eq(0)
    ? { bar: theme.colors.blue.lighten, name: 'blue' } // UI-5054
    : equityBig.eq(0)
    ? {
        bar: theme.colors.red.lighten,
        name: 'red',
      }
    : value >= 0.25 // UI-4993
    ? { bar: theme.colors.green.lighten, name: 'green' }
    : value < 0.25 && value > 0.1
    ? { bar: theme.colors.yellow.lighten, name: 'yellow' }
    : { bar: theme.colors.red.lighten, name: 'red' };

  return (
    <Box w="100%">
      <JITTooltip
        tooltip={
          <EquityMarginRatioTooltip
            emr={emrBig}
            limit={ratioBig}
            equity={equityBig}
            maintenanceMargin={maintenanceMarginBig}
          />
        }
        targetStyle={{ width: '100%', height: '100%' }}
        contentMaxWidth="none"
      >
        <VStack
          w="100%"
          h="100%"
          py="spacingSmall"
          fontSize="fontSizeTiny"
          alignItems="flex-end"
          color="colorTextDefault"
          justifyContent="space-between"
          data-color={colors.name}
        >
          <Meter value={Math.min(1, value)} showInitialAnimation={false} color={colors.bar} showLabel={false} />
          <LeftMiddleRightFlexbox
            left={<span>Limit: {ratioBig.toFixed()}</span>}
            right={
              <span>
                Ratio: <InlineFormattedNumber number={emrBig} increment="0.01" />
              </span>
            }
          />
        </VStack>
      </JITTooltip>
    </Box>
  );
}

function EquityMarginRatioTooltip({
  emr,
  limit,
  equity,
  maintenanceMargin,
}: {
  emr: Big | undefined;
  limit: Big;
  equity: Big;
  maintenanceMargin: Big;
}) {
  return (
    <Table w="100%">
      <Tbody>
        <Tr>
          <Th>Equity</Th>
          <Td align="right">
            <InlineFormattedNumber number={equity} />
          </Td>
        </Tr>
        <Tr>
          <Th>Maintenance Margin</Th>
          <Td align="right">
            <InlineFormattedNumber number={maintenanceMargin} />
          </Td>
        </Tr>
        <Tr>
          <Th>Equity Margin Ratio</Th>
          <Td align="right">
            {emr ? <InlineFormattedNumber number={emr} increment={MIN_EQUITY_MARGIN_RATIO_INCREMENT} round /> : 'ERR'}
          </Td>
        </Tr>
        <Tr>
          <Th>Equity Margin Ratio Limit</Th>
          <Td align="right">
            <InlineFormattedNumber number={limit} />
          </Td>
        </Tr>
      </Tbody>
    </Table>
  );
}
