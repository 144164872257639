import {
  Button,
  ButtonVariants,
  Dialog,
  Drawer,
  DrawerContent,
  DrawerFooter,
  Flex,
  FormControlSizes,
  IconButton,
  IconName,
  IndicatorBadge,
  IndicatorDotVariants,
  LoaderTalos,
  Tab,
  TabList,
  TabPanels,
  TabSize,
  Tabs,
  Text,
  VStack,
  useDisclosure,
  useDynamicCallback,
  useTabs,
} from '@talos/kyoko';

import { useState } from 'react';
import styled from 'styled-components';
import { CustomerApiKeysFormTable } from './CustomerApiKeys/CustomerApiKeysFormTable';
import { CustomerUserDetails } from './CustomerUserDetails';
import { CustomerUserPermissions } from './CustomerUserPermissions';
import type { Warning } from './CustomerUserPermissions/types';
import { useCustomerUserDetailsDrawer, type NewUserDrawerProps } from './useCustomerUserDetailsDrawer';

// this is designed to be visually consistent with Settings/Users/EditUserDrawer, but for customer users
// todo - there is an opportunity to refactor out a shared component used by both
export function CustomerUserDetailsDrawer(props: NewUserDrawerProps) {
  const warningDialog = useDisclosure();
  const [warning, setWarning] = useState<Warning | null>(null);

  const onClose = useDynamicCallback(() => {
    setWarning(null);
  });

  const onConfirm = useDynamicCallback(() => {
    warning?.onOk?.();
    onClose();
  });

  const onWarning = useDynamicCallback((warning: Warning) => {
    setWarning(warning);
    warningDialog.open();
  });

  const { customerUser, handleClose, handleSave, tabDefs, userSettingsRef, isSaveDisabled, isLoading } =
    useCustomerUserDetailsDrawer({ ...props, onWarning });

  const tabs = useTabs({
    initialItems: tabDefs,
  });

  return (
    <Drawer {...props}>
      {isLoading ? (
        <LoaderTalos />
      ) : (
        <>
          <UserDrawerHeader data-testid="header">
            <Flex gap="spacingSmall" alignItems="center">
              <Text color="colorTextImportant">{customerUser?.DisplayName ?? 'New User'}</Text>
              {customerUser?.Roles.map(role => (
                <IndicatorBadge key={role.RoleID} children={role.DisplayName} />
              ))}
            </Flex>
            <IconButton size={FormControlSizes.Small} icon={IconName.Close} onClick={handleClose} />
          </UserDrawerHeader>

          <DrawerContent rows="1fr">
            <Dialog
              {...warningDialog}
              usePortal={false}
              onConfirm={onConfirm}
              onCancel={onClose}
              confirmLabel="Ok"
              cancelLabel="Cancel"
              showCancel={!!warning?.onOk}
              width={200}
            >
              <VStack gap="spacingMedium">
                <div>{warning?.message}</div>
              </VStack>
            </Dialog>
            <Tabs {...tabs} style={{ overflow: 'hidden' }} size={TabSize.Large}>
              <TabList isBordered flex="0 0 auto">
                {tabDefs.map((tab, index) => (
                  <Tab key={index} {...tab} showDot={tab.isDirty} dotVariant={IndicatorDotVariants.Primary} />
                ))}
              </TabList>
              <TabPanels h="100%" w="100%" overflow="auto">
                {tabDefs.map(tab => {
                  switch (tab.label) {
                    case 'General':
                      return <CustomerUserDetails key={tab.label} ref={userSettingsRef} />;
                    case 'Account Restrictions':
                      return <CustomerUserPermissions key={tab.label} onWarning={onWarning} />;
                    case 'API Keys':
                      return customerUser && <CustomerApiKeysFormTable key={customerUser.CustomerUserID} />;
                    default:
                      return null;
                  }
                })}
              </TabPanels>
            </Tabs>
          </DrawerContent>
          <DrawerFooter>
            <Button onClick={handleClose}>Close</Button>
            <Button onClick={handleSave} disabled={isSaveDisabled} variant={ButtonVariants.Primary}>
              Save
            </Button>
          </DrawerFooter>
        </>
      )}
    </Drawer>
  );
}

const UserDrawerHeader = styled(Flex)`
  justify-content: space-between;
  padding: ${({ theme }) => theme.spacingDefault}px ${({ theme }) => theme.spacingMedium}px;
  min-height: 3.5rem;
  border-bottom: solid 1px ${({ theme }) => theme.colors.gray['010']};
  align-items: center;
  gap: ${({ theme }) => theme.spacingSmall}px;
`;
