import { ACTION, IconName, isSidebarSubTitle, type SidebarSubNavItem, type SidebarSubTitle } from '@talos/kyoko';
import { useFeatureFlag, useRoleAuth } from 'hooks';
import { useMemo } from 'react';
import { CustomerAddresses } from './CustomerAddresses';
import { CustomerAggregations } from './CustomerAggregations';
import { CustomerBalances } from './CustomerBalances';
import { CustomerConfigurations } from './CustomerConfigurations';
import { CustomerCreditUtilization } from './CustomerCredit';
import { CustomerExecutionRules } from './CustomerExecutionRules';
import { CustomerFIXConnections } from './CustomerFIXConnections';
import { CustomerMarketAccounts } from './CustomerMarketAccounts';
import { CustomerPricing } from './CustomerPricing';
import { CustomerPricingTiers } from './CustomerPricingTiers';
import { CustomerSymbolGroups } from './CustomerSymbolGroups';
import { CustomerTradingLimits } from './CustomerTradingLimits';
import { CustomerTransactions } from './CustomerTransactions';
import { CustomerUsers } from './CustomerUsers';
import { Monitoring } from './Monitoring';
import { SecurityMaster } from './SecurityMaster';

const TITLES = {
  Setup: 'SETUP',
  BuyingPower: 'BUYING POWER',
  Admin: 'ADMIN',
};

export const DEALER_SUB_NAV_ITEMS: (SidebarSubNavItem | SidebarSubTitle)[] = [
  {
    label: 'Monitoring',
    path: '/dealer/monitoring',
    component: <Monitoring />,
    icon: IconName.DeviceDesktop,
    hasSubNavItems: true,
  },
  {
    label: 'Configurations',
    path: '/dealer/configurations',
    component: <CustomerConfigurations />,
    icon: IconName.Cog,
    requiredPermission: ACTION.VIEW_CUSTOMER_CONFIGURATIONS,
  },
  {
    label: 'Pricing Tiers',
    path: '/dealer/tiers',
    component: <CustomerPricingTiers />,
    icon: IconName.CubeTransparent,
    requiredPermission: ACTION.VIEW_CUSTOMER_PRICING_TIERS,
  },
  {
    label: 'Symbol Groups',
    path: '/dealer/symbol-groups',
    component: <CustomerSymbolGroups />,
    icon: IconName.Cube,
    requiredPermission: ACTION.VIEW_CUSTOMER_SYMBOL_GROUPS,
  },
  {
    label: TITLES.Setup,
    type: 'title',
  },
  {
    label: 'Security Master',
    path: '/dealer/secmaster',
    component: <SecurityMaster />,
    icon: IconName.Key,
  },
  {
    label: 'Customers and Pricing',
    path: '/dealer/pricing',
    component: <CustomerPricing />,
    icon: IconName.CurrencyDollar,
  },
  {
    label: 'Aggregations',
    path: '/dealer/aggregations',
    component: <CustomerAggregations />,
    icon: IconName.CubeTransparent,
  },
  {
    label: 'Execution Rules',
    path: '/dealer/execution-rules',
    component: <CustomerExecutionRules />,
    icon: IconName.LightningBolt,
  },
  {
    label: TITLES.BuyingPower,
    type: 'title',
  },
  {
    label: 'Balances',
    path: '/dealer/balances',
    component: <CustomerBalances />,
    icon: IconName.Scale,
  },
  {
    label: 'Transfers',
    path: '/dealer/transfers',
    component: <CustomerTransactions />,
    icon: IconName.ArrowLeftRight,
  },
  {
    label: 'Credit',
    path: '/dealer/credit',
    component: <CustomerCreditUtilization />,
    icon: IconName.Counterparty,
  },
  {
    label: 'Addresses',
    path: '/dealer/addresses',
    component: <CustomerAddresses />,
    icon: IconName.Link,
  },
  {
    label: TITLES.Admin,
    type: 'title',
  },
  {
    label: 'Users',
    path: '/dealer/users',
    component: <CustomerUsers />,
    icon: IconName.Users,
  },
  {
    label: 'Trading Limits',
    path: '/dealer/customer-limits',
    component: <CustomerTradingLimits />,
    icon: IconName.Preferences,
  },
  {
    label: 'Customer Accounts',
    path: '/dealer/customer-accounts',
    component: <CustomerMarketAccounts />,
    icon: IconName.UserCircle,
  },
  {
    label: 'FIX Connections',
    path: '/dealer/fix-connections',
    component: <CustomerFIXConnections />,
    icon: IconName.LightningBolt,
  },
];

// Show only Monitoring and Setup by default
// Buying Power and Admin under feature flag viewDealerAdminAndBuyingPowerMenuItems
export const getDealerSubNavItems = (
  viewDealerAdminAndBuyingPowerMenuItems: boolean,
  showCustomerExecutionRules: boolean,
  showCustomerFIXConnections: boolean,
  showCustomerTiering: boolean
) => {
  let effectiveItems = [...DEALER_SUB_NAV_ITEMS];

  if (!showCustomerExecutionRules) {
    // Find and remove "Execution Rules"
    effectiveItems = effectiveItems.filter(item => item.label !== 'Execution Rules');
  }

  if (!showCustomerFIXConnections) {
    // Find and remove "FIX Connections"
    effectiveItems = effectiveItems.filter(item => item.label !== 'FIX Connections');
  }

  if (!showCustomerTiering) {
    // Find and remove "Configurations", "Pricing Tiers", and "Symbol Groups"
    effectiveItems = effectiveItems.filter(item => item.label !== 'Configurations');
    effectiveItems = effectiveItems.filter(item => item.label !== 'Pricing Tiers');
    effectiveItems = effectiveItems.filter(item => item.label !== 'Symbol Groups');
  }

  if (viewDealerAdminAndBuyingPowerMenuItems) {
    // Return everything
    return effectiveItems;
  }

  // Return only Monitoring and Setup
  const buyingPowerIndex = effectiveItems.findIndex(
    item => isSidebarSubTitle(item) && item.label === TITLES.BuyingPower
  );
  return effectiveItems.slice(0, buyingPowerIndex);
};

export const useDealerSubNavItems = () => {
  const { isAuthorized } = useRoleAuth();
  const { viewDealerAdminAndBuyingPowerMenuItems, showCustomerExecutionRulesBlotter, showCustomerTiering } =
    useFeatureFlag();

  return useMemo(
    () =>
      getDealerSubNavItems(
        viewDealerAdminAndBuyingPowerMenuItems,
        showCustomerExecutionRulesBlotter,
        isAuthorized(ACTION.VIEW_FIX_CONNECTIONS),
        showCustomerTiering
      ),
    [viewDealerAdminAndBuyingPowerMenuItems, showCustomerExecutionRulesBlotter, isAuthorized, showCustomerTiering]
  );
};
