import {
  ACTION,
  Flex,
  IconName,
  MixpanelEvent,
  MixpanelEventSource,
  MixpanelSourceProvider,
  isSidebarSubTitle,
  type SidebarSubNavItem,
  type SidebarSubTitle,
} from '@talos/kyoko';
import { ErrorBoundary } from 'components/ErrorBoundary';
import { NoMatchPage } from 'containers/NoMatchPage';
import { useIsPMSActive } from 'providers/PortfolioSettingProvider';
import { Route, Switch } from 'react-router-dom';
import { useFeatureFlag } from '../../hooks/useFeatureFlag';
import { Addresses } from './Addresses';
import { DealerSettlement } from './DealerSettlement';
import { Performance } from './Performance';
import { getPortfolioLayoutOptions } from './PortfolioManagement/layoutConfiguration/getPortfolioLayoutOptions';
import { PortfolioDashboard } from './PortfolioManagement/PortfolioDashboard';
import { DerivedPositionsFormStateProvider } from './providers/DerivedPositionsFormStateProvider';
import { PositionsFormProvider } from './providers/PositionsFormProvider';
import { Settlement } from './Settlement';
import { TreasuryManagement } from './TreasuryManagement';

const PM_SUB_NAV_ITEMS: (SidebarSubNavItem | SidebarSubTitle)[] = getPortfolioLayoutOptions().reduce((result, next) => {
  result.push({
    label: next.label,
    type: 'title',
    path: `/portfolio${next.route}`,
  });
  if (next.tabs) {
    next.tabs.forEach(tab => {
      result.push({
        label: tab.label,
        path: `/portfolio${next.route}${tab.route}`,
        menuIndent: true,
      });
    });
  }
  return result;
}, [] as (SidebarSubNavItem | SidebarSubTitle)[]);

const PORTFOLIO_ORIG_SUB_NAV_ITEMS: (SidebarSubNavItem | SidebarSubTitle)[] = [
  {
    label: 'Treasury Management',
    path: '/portfolio/treasury-management',
    component: (
      <MixpanelSourceProvider value={MixpanelEventSource.TreasuryManagement}>
        <TreasuryManagement />
      </MixpanelSourceProvider>
    ),
    icon: IconName.ChartDonut,
    mixpanelEvent: MixpanelEvent.NavigateToTreasuryManagement,
  },
  {
    label: 'Performance',
    path: '/portfolio/performance',
    component: (
      <MixpanelSourceProvider value={MixpanelEventSource.PortfolioPerformance}>
        <Performance />
      </MixpanelSourceProvider>
    ),
    icon: IconName.ChartLine,
    mixpanelEvent: MixpanelEvent.NavigateToPerformance,
  },
  {
    label: 'Credit & Settlement',
    path: '/portfolio/credit-settlement',
    component: (
      <MixpanelSourceProvider value={MixpanelEventSource.PortfolioCreditAndSettlement}>
        <DealerSettlement />
      </MixpanelSourceProvider>
    ),
    icon: IconName.CurrencyDollar,
    mixpanelEvent: MixpanelEvent.NavigateToCreditAndSettlement,
  },
  {
    label: 'Addresses',
    path: '/portfolio/addresses',
    component: (
      <MixpanelSourceProvider value={MixpanelEventSource.PortfolioAddresses}>
        <Addresses />
      </MixpanelSourceProvider>
    ),
    icon: IconName.Cog,
    mixpanelEvent: MixpanelEvent.NavigateToPortfolioAddresses,
    requiredPermission: ACTION.EDIT_ADDRESSES, // Either of [VIEW_DEALER, VIEW_PORTFOLIO] is required.
  },
  {
    label: 'Customer Settlement',
    path: '/portfolio/settlement',
    component: (
      <MixpanelSourceProvider value={MixpanelEventSource.PortfolioSettlement}>
        <Settlement />
      </MixpanelSourceProvider>
    ),
    icon: IconName.ArrowLeftRight,
    mixpanelEvent: MixpanelEvent.NavigateToSettlement,
  },
];

export const usePortfolioSubNavItems: () => (SidebarSubNavItem | SidebarSubTitle)[] = () => {
  const { enablePortfolioSettlement } = useFeatureFlag();
  const enablePMS = useIsPMSActive();
  if (enablePMS) {
    return PM_SUB_NAV_ITEMS;
  } else {
    return enablePortfolioSettlement
      ? PORTFOLIO_ORIG_SUB_NAV_ITEMS
      : PORTFOLIO_ORIG_SUB_NAV_ITEMS.filter(item => !('path' in item && item.path === '/portfolio/settlement'));
  }
};

export const PortfolioRouter = () => {
  const items = usePortfolioSubNavItems();
  const enablePMS = useIsPMSActive();

  return (
    <PositionsFormProvider>
      <DerivedPositionsFormStateProvider>
        <ErrorBoundary>
          <Flex gap="spacingTiny" flex="1" overflow="hidden" h="100%">
            <Switch>
              {items.map(
                tab =>
                  !isSidebarSubTitle(tab) &&
                  (enablePMS ? (
                    <Route path="/portfolio" key={tab.path}>
                      {/* All the tabs in the Portfolio Management System are rendered under /portfolio. */}
                      <MixpanelSourceProvider value={MixpanelEventSource.PortfolioDashboard}>
                        <PortfolioDashboard />
                      </MixpanelSourceProvider>
                    </Route>
                  ) : (
                    <Route path={tab.path} key={tab.path}>
                      {tab.component}
                    </Route>
                  ))
              )}
              <Route component={NoMatchPage} />
            </Switch>
          </Flex>
        </ErrorBoundary>
      </DerivedPositionsFormStateProvider>
    </PositionsFormProvider>
  );
};
