import Big from 'big.js';
import { memo, useCallback, useEffect, useMemo, useState } from 'react';

import {
  AllocationValueTypeEnum,
  Button,
  ButtonGroup,
  ButtonVariants,
  FormControlSizes,
  FormMessage,
  Grid,
  HStack,
  IconButton,
  IconName,
  Label,
  MixpanelEvent,
  NumberInput,
  SearchSelect,
  ToggleButton,
  Tooltip,
  VStack,
  getAllChildrenOfSubAccount,
  getDisplayNameOrNameLabel,
  useDynamicCallback,
  useMixpanel,
  type Allocation,
  type ISubaccount,
  type SearchSelectProps,
  type SubAccount,
} from '@talos/kyoko';

import { FormGroup } from '@talos/kyoko';
import { useFeatureFlag } from 'hooks';
import { cloneDeep, sortBy } from 'lodash';
import { useSubAccountRollupMemberships, useSubAccounts } from 'providers';
import { useTradingSettings } from 'providers/AppConfigProvider';
import { MODIFY_ALLOCATIONS_ROW, TRADE_ALLOCATIONS_OMS, TRADE_ALLOCATIONS_OMS_SETTINGS } from 'tokens/tooltips';
import { useSubAccountCreation } from './useSubAccountCreation';

export interface OMSSubAccountsProps {
  subAccountAllocations: Allocation[];
  disabled?: boolean;
  onUpdate: (
    changes: { allocationValueType: AllocationValueTypeEnum } | { subAccountAllocations: Allocation[] }
  ) => void;
  touched: { [key: string]: boolean | string };
  errors: { [key: string]: string };
  allocationValueType?: AllocationValueTypeEnum;
  quantityCurrency?: string;
  showTooltip?: boolean;
  useTradeAllocations: boolean;
  placeholder?: string;
  titlePrefix?: string;
  hideAddAllocation?: boolean;
  /**
   * If set, will not show the toggles for switching between Percentage and Quantity AllocationValueTypes.
   * Does not modify or gate the model / values at all, so that responsibility is still on the implementer.
   *
   * Defaults to false.
   */
  hideTypeToggleButtons?: boolean;
  /**
   * Whether or not to show the Rollup selector. Will only actually show if this is set to true and
   * there are any rollups in the system
   */
  showRollupSelector?: boolean;
  /**
   * If set to true, this component will update the defaultSubAccountId property for you on every relevant change.
   * If set to false, this component will not do this.
   *
   * This should be set to true if in the value logic into this component you are using Trading Settings -> defaultSubAccountId in some way.
   *
   * Made a required property just to avoid mistakes in the case that you forget that this property exists.
   */
  updateDefaultSubAccountOnChange: boolean;
  /**
   * If true, will show a create sub account button which the user can use to create a sub account on the spot. Defaults to false.
   */
  showCreateNewSubAccount?: boolean;
}

const BUTTON_GROUP_WIDTH = '5rem';

const SEARCH_SELECT_STYLE: React.CSSProperties = { flex: 1 };

const EMPTY_SUB_ACCOUNT_SELECTION = '';

export const OMSSubAccounts = memo(function OMSSubAccounts({
  subAccountAllocations,
  disabled = false,
  onUpdate,
  touched = {},
  errors = {},
  allocationValueType,
  quantityCurrency = 'Qty',
  showTooltip = true,
  useTradeAllocations,
  placeholder = '',
  titlePrefix = '',
  hideAddAllocation = false,
  hideTypeToggleButtons = false,
  showRollupSelector: showRollupSelectorProp = false,
  updateDefaultSubAccountOnChange,
  showCreateNewSubAccount = false,
}: OMSSubAccountsProps) {
  useEffect(() => {
    // Safeguard against poorly formatted subAccountAllocations
    if (subAccountAllocations == null || subAccountAllocations.length === 0) {
      onUpdate({
        subAccountAllocations: [{ subAccount: EMPTY_SUB_ACCOUNT_SELECTION, value: '100' }],
        allocationValueType: AllocationValueTypeEnum.Percentage,
      });
    }
  }, [subAccountAllocations, onUpdate]);

  const mixpanel = useMixpanel();
  const { setDefaultSubAccountId } = useTradingSettings();
  const { subAccountsByName, tradableSubAccounts, allSubAccountRollups } = useSubAccounts();
  const { rollupMembershipsByParentChild } = useSubAccountRollupMemberships();
  const { enableAccountSegregation } = useFeatureFlag();
  const showRollupSelector = showRollupSelectorProp && allSubAccountRollups.length > 0;

  const [rollup, setRollup] = useState<ISubaccount | undefined>(undefined);

  const usingSubAccounts = (tradableSubAccounts?.length ?? 0) > 0 || enableAccountSegregation;
  const addAllocationVisible =
    !hideAddAllocation && useTradeAllocations && subAccountAllocations?.length < (tradableSubAccounts?.length ?? 0);

  const subAccountOptions: (SubAccount | Pick<SubAccount, 'Name'>)[] = useMemo(() => {
    if (!tradableSubAccounts || tradableSubAccounts.length === 0) {
      return [];
    }

    return sortBy(tradableSubAccounts, item => item.Name);
  }, [tradableSubAccounts]);

  const handleUpdateTradeAllocations = useCallback(
    <K extends keyof Allocation>(index: number, key: K, value: Allocation[K]) => {
      const newAllocations = cloneDeep(subAccountAllocations);
      newAllocations[index][key] = value;

      // There are different types of updates that can happen. You can change either the sub account itself or the value (numeric) of an allocation.
      if (key === 'subAccount') {
        const id = tradableSubAccounts?.find(sa => sa.Name === value)?.SubaccountID;
        if (updateDefaultSubAccountOnChange) {
          setDefaultSubAccountId(id);
        }

        // These lines handle the case where a sub account is selected programmatically which is not a child of the selected rollup. This is a valid case.
        // When this occurrs, we clear the rollup selection before proceeding to update the sub account selection.
        const childIdsOfSelectedRollup = rollup
          ? getAllChildrenOfSubAccount(rollup.SubaccountID, rollupMembershipsByParentChild)
          : undefined;
        if (childIdsOfSelectedRollup != null && id != null && !childIdsOfSelectedRollup.has(id)) {
          setRollup(undefined);
        }
      }
      mixpanel.track(useTradeAllocations ? MixpanelEvent.ChangeAllocations : MixpanelEvent.ChangeSubAccount);
      onUpdate({ subAccountAllocations: newAllocations });
    },
    [
      onUpdate,
      setDefaultSubAccountId,
      tradableSubAccounts,
      subAccountAllocations,
      useTradeAllocations,
      mixpanel,
      rollup,
      rollupMembershipsByParentChild,
      updateDefaultSubAccountOnChange,
    ]
  );

  const handleDeleteTradeAllocations = useCallback(
    index => {
      const newAllocations = cloneDeep(subAccountAllocations);
      newAllocations.splice(index, 1);
      mixpanel.track(useTradeAllocations ? MixpanelEvent.ChangeAllocations : MixpanelEvent.ChangeSubAccount);
      if (newAllocations.length === 1) {
        newAllocations[0].value = '100';
        onUpdate({ subAccountAllocations: newAllocations, allocationValueType: AllocationValueTypeEnum.Percentage });
      } else {
        onUpdate({ subAccountAllocations: newAllocations });
      }
    },
    [onUpdate, subAccountAllocations, useTradeAllocations, mixpanel]
  );

  const allChildrenOfSelectedRollup = useMemo(() => {
    if (!rollup) {
      return undefined;
    }

    return getAllChildrenOfSubAccount(rollup.SubaccountID, rollupMembershipsByParentChild);
  }, [rollup, rollupMembershipsByParentChild]);

  const availableSubAccountOptions: string[] = useMemo(() => {
    const usedOptions = subAccountAllocations?.map(item => item.subAccount);

    const performRollupNarrowing = allChildrenOfSelectedRollup != null;
    const narrowedSubAccountOptions = performRollupNarrowing
      ? subAccountOptions.filter(option =>
          'SubaccountID' in option ? allChildrenOfSelectedRollup?.has(option.SubaccountID) : true
        )
      : subAccountOptions;

    return narrowedSubAccountOptions
      .filter(item => {
        const usedName = usedOptions.find(i => i === item.Name);
        return usedName == null || !usedOptions?.includes(usedName);
      })
      .map(account => account.Name);
  }, [subAccountOptions, subAccountAllocations, allChildrenOfSelectedRollup]);

  const getSubAccountLabel = useDynamicCallback((subAccountName?: string) => {
    if (!subAccountName) {
      return '';
    }

    return subAccountsByName?.get(subAccountName)?.DisplayName ?? subAccountName;
  });

  const subAccountCreation = useSubAccountCreation({
    subAccountAllocations,
    handleUpdateTradeAllocations,
  });

  if (!usingSubAccounts) {
    return null;
  }

  return (
    <>
      <VStack alignItems="initial">
        {showRollupSelector && (
          <FormGroup
            label="Rollup"
            tooltip="Selecting a Rollup filters the selectable items in the Sub Account selector below to only show Sub Accounts which are assigned to it, either directly or indirectly."
          >
            <SearchSelect
              disabled={disabled}
              data-testid="rollup-select"
              selection={rollup}
              options={allSubAccountRollups}
              getLabel={getDisplayNameOrNameLabel}
              onChange={setRollup}
              showClear
              w="100%"
            />
          </FormGroup>
        )}
        <VStack alignItems="initial" gap="spacingSmall" mb="spacingMedium">
          <HStack justifyContent="space-between">
            <Label tooltip={showTooltip}>
              <Tooltip
                tooltip={
                  showTooltip &&
                  (disabled
                    ? MODIFY_ALLOCATIONS_ROW
                    : useTradeAllocations
                    ? TRADE_ALLOCATIONS_OMS
                    : TRADE_ALLOCATIONS_OMS_SETTINGS)
                }
              >
                <div>
                  {titlePrefix}
                  {useTradeAllocations ? 'Sub Account(s)' : 'Sub Account'}{' '}
                </div>
              </Tooltip>
            </Label>
            <HStack gap="spacingSmall">
              {useTradeAllocations && subAccountAllocations?.length !== 1 && !disabled && !hideTypeToggleButtons && (
                <ButtonGroup style={{ width: BUTTON_GROUP_WIDTH }} size={FormControlSizes.Tiny}>
                  <ToggleButton
                    onClick={() => onUpdate({ allocationValueType: AllocationValueTypeEnum.Percentage })}
                    selected={allocationValueType === AllocationValueTypeEnum.Percentage}
                    variant={ButtonVariants.Default}
                    selectedVariant={ButtonVariants.Priority}
                    size={FormControlSizes.Tiny}
                    data-testid="oms-subaccounts-percentage-button"
                  >
                    %
                  </ToggleButton>
                  <ToggleButton
                    onClick={() => onUpdate({ allocationValueType: AllocationValueTypeEnum.Quantity })}
                    selected={allocationValueType === AllocationValueTypeEnum.Quantity}
                    variant={ButtonVariants.Default}
                    selectedVariant={ButtonVariants.Priority}
                    size={FormControlSizes.Tiny}
                    data-testid="oms-subaccounts-quantity-button"
                  >
                    {quantityCurrency}
                  </ToggleButton>
                </ButtonGroup>
              )}
              {addAllocationVisible && (
                <Button
                  endIcon={IconName.Plus}
                  size={FormControlSizes.Tiny}
                  onClick={() =>
                    onUpdate({
                      subAccountAllocations: [
                        ...subAccountAllocations,
                        { subAccount: EMPTY_SUB_ACCOUNT_SELECTION, value: '' },
                      ],
                    })
                  }
                  data-testid="add-allocation-button"
                  disabled={disabled}
                >
                  Allocate
                </Button>
              )}
            </HStack>
          </HStack>
          {subAccountAllocations?.map((allocation, index) => (
            <Grid key={index} gap="spacingTiny" columns={subAccountAllocations?.length > 1 ? '1fr 1fr auto' : '1fr'}>
              {/* When adding new props to this component, if they would cause the search select to rerender each oms render, please memoize them in the wrapper. See jsdoc on the wrapper. */}
              <MemoizedAllocationsSearchSelect
                selectorIndex={index}
                data-testid={`sub-account-select-${index}`}
                placeholder={placeholder}
                options={availableSubAccountOptions}
                selection={allocation.subAccount}
                showClear={allocation.subAccount !== EMPTY_SUB_ACCOUNT_SELECTION}
                disabled={disabled}
                onChange={subAccount =>
                  handleUpdateTradeAllocations(index, 'subAccount', subAccount || EMPTY_SUB_ACCOUNT_SELECTION)
                }
                onClearClick={() => handleUpdateTradeAllocations(index, 'subAccount', EMPTY_SUB_ACCOUNT_SELECTION)}
                initialSortByLabel
                getLabel={getSubAccountLabel}
                style={SEARCH_SELECT_STYLE}
                touched={!!touched.subAccountAllocations}
                size={subAccountAllocations?.length === 1 ? FormControlSizes.Default : FormControlSizes.Small}
                invalid={
                  (allocation.subAccount == null || allocation.subAccount === EMPTY_SUB_ACCOUNT_SELECTION) &&
                  !!errors.subAccountAllocations
                }
                onIsOpenChange={() => subAccountCreation?.setLastTouchedSelector(index)}
                // Only conditionally render the add account button in the dropdown here based on the prop
                dropdownSuffix={
                  showCreateNewSubAccount ? subAccountCreation?.searchSelectProps.dropdownSuffix : undefined
                }
              />
              {subAccountAllocations?.length > 1 && (
                <>
                  <NumberInput
                    invalid={
                      (allocation.value == null || allocation.value === '' || Big(allocation.value || 0).lte(0)) &&
                      !!errors.subAccountAllocations
                    }
                    data-testid={`allocation-value-input-${index}`}
                    disabled={disabled}
                    touched={!!touched.subAccountAllocations}
                    autoComplete="off"
                    onChange={value => handleUpdateTradeAllocations(index, 'value', value)}
                    value={allocation.value?.toString() || ''}
                    size={FormControlSizes.Small}
                    suffix={
                      allocationValueType === AllocationValueTypeEnum.Percentage
                        ? '%'
                        : allocationValueType === AllocationValueTypeEnum.Quantity
                        ? quantityCurrency
                        : ''
                    }
                  />
                  <IconButton
                    disabled={disabled}
                    size={FormControlSizes.Small}
                    icon={IconName.Trash}
                    onClick={() => handleDeleteTradeAllocations(index)}
                  />
                </>
              )}
            </Grid>
          ))}
          {touched.subAccountAllocations && errors.subAccountAllocations && (
            <FormMessage data-testid="oms-subaccounts-error" style={{ justifyContent: 'flex-end' }}>
              {errors.subAccountAllocations}
            </FormMessage>
          )}
        </VStack>
      </VStack>
      {subAccountCreation?.modal}
    </>
  );
});

type MemoizedAllocationsSearchSelectProps = SearchSelectProps<string> & {
  selectorIndex: number;
};

/**
 * Our SearchSelect component doesn't play nice with unstable props. It'll keep re-rendering and making the items
 * in the dropdown result list unresponsive. To get around this, we make specific props here stable.
 */
const MemoizedAllocationsSearchSelect = ({
  selectorIndex,
  onChange,
  onClearClick,
  onIsOpenChange,
  ...props
}: MemoizedAllocationsSearchSelectProps) => {
  const stableOnChange = useDynamicCallback(onChange);
  const stableOnClearClick = useDynamicCallback(onClearClick);
  const stableOnIsOpenChange = useDynamicCallback(onIsOpenChange);

  return (
    <SearchSelect
      data-testid={`sub-account-select-${selectorIndex}`}
      onChange={stableOnChange}
      onClearClick={stableOnClearClick}
      onIsOpenChange={stableOnIsOpenChange}
      {...props}
    />
  );
};
