import { Box, Flex, Menu, Portal, VStack } from '@talos/kyoko';
import DockViewProvider, { type DockViewProviderType } from 'components/FlexibleLayout/DockViewProvider';

import { DOCK_VIEW_PORTAL_ID } from 'components/FlexibleLayout/constants';
import { useRollupTreeList } from 'hooks';
import { useAppStateDispatch } from 'providers/AppStateProvider';
import { useCallback, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { DockViewWrapper } from './components/DockViewWrapper';
import { MenuDockView } from './components/HeaderMenu/MenuDockView';
import { MenuSeparator } from './components/HeaderMenu/MenuSeparator';
import { CommonHeaderMenuItems } from './components/HeaderMenu/MenuShowZeroBalances';
import { getPortfolioLayoutOptions } from './layoutConfiguration/getPortfolioLayoutOptions';
import { LAYOUT_OPTIONS_FIXED_COMPONENT_FACTORY } from './layoutConfiguration/layoutOptionsComponentFactory';
import { PortfolioDashboardHeader } from './PortfolioDashboardHeader';
import type { PortfolioViewType } from './portfolioDashboardLayouts';
import { PortfolioManagementProvider } from './providers/PortfolioManagementProvider';
import {
  getDefaultLayoutForViewTab,
  getKeyForUserLayout,
  getLayoutForViewTab,
} from './stateManagement/portfolioViewLayoutSlice';
import {
  getPortfolioViewActions,
  usePortfolioViewStateSelector,
} from './stateManagement/portfolioViewLayoutSlice.hooks';
import { usePortfolioRouting } from './usePortfolioRouting';

const useValidatePMSRoute = (): boolean => {
  const location = useLocation();
  const tabRoute = location.pathname.split('/').pop();
  const viewRoute = location.pathname.split('/')[2];
  const selectedView = getPortfolioLayoutOptions().find(option => option.route === `/${viewRoute}`);
  const isRouteValid = Boolean(selectedView?.tabs.find(tab => tab.route === `/${tabRoute}`));
  return isRouteValid;
};

export const PortfolioDashboard = () => {
  const isValidRoute = useValidatePMSRoute();
  return !isValidRoute ? <div data-testid="portfolio-dashboard-invalid-route" /> : <PortfolioDashboardInner />;
};

const { changeSelectedPortfolioId, updateLayout, changeView } = getPortfolioViewActions();
const PortfolioDashboardInner = () => {
  const { userLayoutMapForViewTab } = usePortfolioViewStateSelector();
  const { selectedView, selectedTab } = usePortfolioRouting();
  const rollupTreeList = useRollupTreeList();
  const dispatch = useAppStateDispatch();
  useEffect(() => {
    if (selectedView) {
      dispatch(changeView(selectedView.value));
    }
  }, [dispatch, selectedView]);

  const isInited = useRef(false);
  useEffect(() => {
    if (!isInited.current && rollupTreeList.length > 0) {
      dispatch(
        changeSelectedPortfolioId({
          selectedPortfolioId: rollupTreeList[0].subAccountId,
          onlyIfNotSet: true,
        })
      );
    }
  }, [dispatch, rollupTreeList]);

  const updateViewAndLayout = useCallback(
    ({ userLayout }) => {
      dispatch(
        updateLayout({
          view: selectedView.value,
          tabLabel: selectedTab.label,
          userLayout,
        })
      );
    },
    [dispatch, selectedTab.label, selectedView.value]
  );

  const getLayoutForViewTabCallback: DockViewProviderType<PortfolioViewType>['getLayoutForView'] = useCallback(
    (view, tabLabel) => getLayoutForViewTab(userLayoutMapForViewTab, view, tabLabel),
    [userLayoutMapForViewTab]
  );

  const getDefaultLayoutForViewTabCallback: DockViewProviderType<PortfolioViewType>['getDefaultLayoutForView'] =
    useCallback((view, tabLabel) => getDefaultLayoutForViewTab(view, tabLabel), []);

  return (
    <Box w="100%" h="100%" display="flex" flexDirection="column" data-testid="portfolio-dashboard">
      <VStack
        display="flex"
        flexDirection="column"
        gap="spacingTiny"
        h="100%"
        w="100%"
        mt="spacingLayout"
        flex="auto"
        overflow="hidden"
      >
        <Box flex="none" w="100%">
          <PortfolioDashboardHeader />
        </Box>
        <Flex flex="auto" flexDirection="column" display="flex" w="100%">
          <PortfolioManagementProvider>
            {selectedTab.layoutType === 'fixed' ? (
              <>
                <Portal portalId={DOCK_VIEW_PORTAL_ID}>
                  <Menu>
                    <CommonHeaderMenuItems />
                  </Menu>
                </Portal>
                <Box position="relative" w="100%" h="100%">
                  <Box position="absolute" top="0" left="0" right="0" bottom="0">
                    {LAYOUT_OPTIONS_FIXED_COMPONENT_FACTORY[selectedTab.content]}
                  </Box>
                </Box>
              </>
            ) : (
              <DockViewProvider<PortfolioViewType>
                view={selectedView.value}
                tabLabel={selectedTab.label}
                getLayoutForView={getLayoutForViewTabCallback}
                getDefaultLayoutForView={getDefaultLayoutForViewTabCallback}
                updateLayout={updateViewAndLayout}
              >
                <Portal portalId={DOCK_VIEW_PORTAL_ID}>
                  <Menu>
                    <CommonHeaderMenuItems />
                    <MenuSeparator />
                    <MenuDockView
                      getLayoutForCurrentView={() => {
                        const viewTabKey = getKeyForUserLayout(selectedView.value, selectedTab.label);
                        return userLayoutMapForViewTab[viewTabKey];
                      }}
                    />
                  </Menu>
                </Portal>
                <DockViewWrapper />
              </DockViewProvider>
            )}
          </PortfolioManagementProvider>
        </Flex>
      </VStack>
    </Box>
  );
};
