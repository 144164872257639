import { InlineFormattedNumber, Tbody, Td, Th, Thead, Tr, UnifiedLiquidityEnum, type Order } from '@talos/kyoko';
import { memo, useMemo } from 'react';
import { EmptyRowsDisplay } from '../EmptyRowsDisplay';
import { DistributionTable, DistributionTableRow, DistributionWrapper, LegendIndicator } from '../styles';
import { REMAINDER } from '../types';

import Big from 'big.js';
import { useMarketDistribution } from '../useMarketDistribution';

export interface OpenMarketDistributionChartProps {
  legIndex?: 0 | 1;
  order: Order;
}

export const OpenMarketDistributionChart = memo(function OpenMarketDistributionChart({
  legIndex,
  order,
}: OpenMarketDistributionChartProps) {
  const isUnifiedLiquidity = order.unifiedLiquidity === UnifiedLiquidityEnum.Enabled;
  const { distribution, priceCurrency, priceIncrement, sizeCurrency, sizeIncrement } = useMarketDistribution({
    order,
    legIndex,
    includeZeroCumQty: true,
    variant: null,
  });

  const remainderFreeDistribution = useMemo(() => distribution.filter(d => d.id !== REMAINDER), [distribution]);
  return (
    <DistributionWrapper>
      <DistributionTable w="100%">
        <Thead>
          <Tr>
            <Th>Market</Th>
            <Th align="left" />
            {isUnifiedLiquidity && <Th align="right">Symbol</Th>}
            <Th align="right">Price</Th>
            <Th align="right">Quantity</Th>
          </Tr>
        </Thead>
        {remainderFreeDistribution.length !== 0 && (
          <Tbody>
            {remainderFreeDistribution.map(data => {
              const isLeavesQtyZero = Big(data.leavesQty || 0).eq(0);

              return (
                <DistributionTableRow faded={isLeavesQtyZero} key={data.id}>
                  <Td minimize>{data.market}</Td>
                  <Td minimize align="left">
                    <LegendIndicator background={data.color} />
                  </Td>
                  {isUnifiedLiquidity && <Td minimize>{data.symbol}</Td>}
                  <Td>
                    {isLeavesQtyZero || Big(data.price || 0).eq(0) ? (
                      '-'
                    ) : (
                      <InlineFormattedNumber
                        pretty
                        number={data.price}
                        currency={data.priceCurrency || priceCurrency}
                        increment={priceIncrement}
                      />
                    )}
                  </Td>
                  <Td>
                    {isLeavesQtyZero ? (
                      '-'
                    ) : (
                      <InlineFormattedNumber
                        pretty
                        number={data.leavesQty}
                        currency={data.sizeCurrency || sizeCurrency}
                        increment={sizeIncrement}
                      />
                    )}
                  </Td>
                </DistributionTableRow>
              );
            })}
          </Tbody>
        )}
      </DistributionTable>
      {remainderFreeDistribution.length === 0 && <EmptyRowsDisplay />}
    </DistributionWrapper>
  );
});
