import { Dialog, Divider, HStack, ICON_SIZES, Icon, IconName, Text, VStack, type DialogProps } from '@talos/kyoko';
import { useTheme } from 'styled-components';
import { CancelDialogWrapper } from '../styles';
import type { ActionType, EnableDisableDialogType, MarketSecurityStatusLocal } from '../types';
import { SelectedSecurityMasterList } from './SelectedSecurityMasterList';

export const EnableDisableSelectedDialog = <T extends MarketSecurityStatusLocal>({
  selectedItems,
  enableDisableDialog,
  onConfirm,
  actionType,
  type,
}: {
  selectedItems: T[];
  enableDisableDialog: DialogProps;
  onConfirm: (orders: T[]) => void;
  actionType: ActionType;
  type: EnableDisableDialogType;
}) => {
  const theme = useTheme();
  if (selectedItems.length === 0 || selectedItems.length === 1) {
    return null;
  }
  const titleText = `${actionType} ` + selectedItems.length + ` ${type === 'Symbol' ? 'Symbols' : 'Market Data'}`;
  const description = `Are you sure you want to ${actionType.toLowerCase()} ${
    type === 'Symbol'
      ? selectedItems.length + ' symbols?'
      : 'market data on these ' + selectedItems.length + ' symbols?'
  }`;
  return (
    <Dialog
      {...enableDisableDialog}
      onConfirm={() => {
        onConfirm(selectedItems);
      }}
      confirmLabel="Confirm"
      cancelLabel="Return"
      title={
        <HStack gap="spacingComfortable">
          <Icon
            size={ICON_SIZES.MEDIUM}
            icon={actionType === 'Disable' ? IconName.ExclamationSolid : IconName.CheckCircleSolid}
            color={actionType === 'Disable' ? theme.colorTextWarning : theme.colorTextPositive}
          />
          {titleText}
        </HStack>
      }
    >
      <CancelDialogWrapper>
        <VStack gap="spacingDefault" alignItems="stretch">
          <Text textAlign="left" color="colorTextAttention">
            {description}
          </Text>
          <Divider />
          <SelectedSecurityMasterList type={type} selectedSecurities={selectedItems} />
        </VStack>
      </CancelDialogWrapper>
    </Dialog>
  );
};
