import {
  ACTION,
  BLOTTER_TABLE_FILTERS_CONTAINER_ID,
  Box,
  Button,
  ButtonVariants,
  Dd,
  Divider,
  Dl,
  Dt,
  Flex,
  HStack,
  Icon,
  IconButton,
  IconName,
  IndicatorBadge,
  Menu,
  MenuItem,
  MixpanelEvent,
  MixpanelEventProperty,
  MixpanelEventSource,
  MixpanelSourceContext,
  OrderStatus,
  SmartSummary,
  Tab,
  TabList,
  TabSize,
  Tabs,
  Text,
  Tooltip,
  UnifiedLiquidityEnum,
  abbreviateId,
  formattedDate,
  isCalendarSpread,
  isEntityRFQ,
  isMultileg,
  isOrder,
  isTalosUser,
  useDisclosure,
  useDynamicCallback,
  useElementSizeOfRef,
  useMixpanel,
  usePortal,
  useSecurity,
  type BoxProps,
  type CustomerOrder,
  type Order,
  type Security,
  type UseElementSizeParams,
} from '@talos/kyoko';
import { Loader, LoaderWrapper } from 'components/Loader';
import { CancelSelectedDialog, ForceCancelSelectedDialog } from 'containers/Blotters/CancelSelectedDialog';
import { ExecutionReportsBlotterTable } from 'containers/Blotters/ExecutionReports';
import { MarketExecutionReportsBlotterTable } from 'containers/Blotters/MarketExecutionReports';
import { MarketOrdersBlotterTable } from 'containers/Blotters/MarketOrders';
import { TradeAllocationsBlotterTable } from 'containers/Blotters/TradeAllocations';
import { FilteredTrades } from 'containers/Blotters/Trades/FilteredTrades';
import {
  isCurrentRouteAnalyticsOrderDetails,
  isCurrentRouteCustomerOrderDetails,
  isCurrentRouteDealerMonitoringOrderDetails,
  isCurrentRouteMonitoringOrderDetails,
} from 'containers/Routes/routes';

import { OMSView } from 'components/OMS/OMSView';
import { useAdminUtils } from 'hooks/useAdminUtils';
import { useOrderAccess } from 'hooks/useOrderAccess';
import { useOrderAndCustomerOrder } from 'hooks/useOrderAndCustomerOrder';
import { useRoleAuth } from 'hooks/useRoleAuth';
import { compact } from 'lodash';
import { useAppStateDispatch } from 'providers/AppStateProvider';
import { useOrders } from 'providers/OrdersProvider';
import { prettifySubAccount, useSubAccounts } from 'providers/SubAccountsContext';
import { useTradingSettings } from 'providers/TradingSettingsContext';
import { useAnalyticsTabs } from 'providers/useAnalyticsTabs';
import { useMonitoringTabs } from 'providers/useMonitoringTabs';
import { memo, useCallback, useMemo, useRef, type ReactNode, type RefObject } from 'react';
import { Link, Redirect, Route, useHistory, useLocation, useParams, useRouteMatch } from 'react-router-dom';
import { modifyOrder } from '../../../../components/OMS/NewOrder/OrderSlice';
import { openView } from '../../../../components/OMS/OMSSlice';
import { useUser } from '../../../../hooks';
import { useMarketTabs } from '../../../../providers/MarketTabsProvider';
import {
  BLOTTER_ID_ALLOCATIONS_ORDER_DETAILS,
  BLOTTER_ID_CUSTOMER_TRADES_ORDER_DETAILS,
  BLOTTER_ID_EXEC_REPORTS_ORDER_DETAILS,
  BLOTTER_ID_MARKET_EXEC_REPORTS_ORDER_DETAILS,
  BLOTTER_ID_MARKET_ORDERS_ORDER_DETAILS,
  BLOTTER_ID_TRADES_ORDER_DETAILS,
} from '../../../../tokens/blotters';
import { prettifyAllocations } from '../../../../utils/allocation';
import { prepareAllocationOrderForUI } from '../../../../utils/order';
import { CustomerTradesBlotterTable } from '../../../Blotters/CustomerTrades';
import { AnalyticsTab } from './AnalyticsTab';
import { DetailsTab } from './DetailsTab';
import { Warnings } from './Warnings';
import { ORDER_DETAILS_DEFAULT_TRADE_COLUMNS } from './defaults';
import { OrderInfo, TopWrapper, Wrapper } from './styles';
import type { OrderDetailsRoute } from './types';
import { useTradeAllocationsColumns } from './useTradeAllocationsColumns';

const thresholds: UseElementSizeParams['thresholds'] = [['offsetWidth', [1000, 1200, 1400, 1600]]];

export function OrderDetails({ widthRef }: { widthRef: RefObject<HTMLDivElement> }) {
  const params = useParams<{ orderID: string }>();
  const location = useLocation();
  const isOrderIDCustomerOrder = isCurrentRouteCustomerOrderDetails(location.pathname);
  const customerOrderId = isOrderIDCustomerOrder ? params.orderID : undefined;
  const orderId = isOrderIDCustomerOrder ? undefined : params.orderID;
  return (
    <OrderDetailsInner key={params.orderID} widthRef={widthRef} orderID={orderId} customerOrderID={customerOrderId} />
  );
}

interface OrderDetailsInnerProps {
  widthRef: RefObject<HTMLDivElement>;
  orderID?: string;
  customerOrderID?: string;
}

// Wrapping here is done for cypress component testing
export function OrderDetailsInner({ widthRef, orderID, customerOrderID }: OrderDetailsInnerProps) {
  const match = useRouteMatch();
  const {
    order,
    customerOrder,
    isLoading: isLoadingOrder,
  } = useOrderAndCustomerOrder({
    orderID: orderID,
    customerOrderID: customerOrderID,
    tag: 'OrderDetails',
  });
  const security = useSecurity(order?.Symbol);

  const {
    size: { offsetWidth: width },
  } = useElementSizeOfRef<HTMLDivElement>({ ref: widthRef, thresholds });

  if (match == null || width === undefined) {
    return null;
  }

  if (isLoadingOrder || order == null) {
    return (
      <LoaderWrapper>
        <Loader />
      </LoaderWrapper>
    );
  }

  return (
    <OrderDetailsInnerContainer
      match={match}
      order={order}
      customerOrder={customerOrder}
      security={security}
      width={width}
    />
  );
}

function getBlotterID(prefix: string, order: { OrderID: string }) {
  return `${prefix}/${order.OrderID}`;
}

export function OrderDetailsInnerContainer({
  match,
  order,
  customerOrder,
  security,
  width,
}: {
  match: any;
  order: Order;
  customerOrder?: CustomerOrder;
  security?: Security;
  width: number;
}) {
  const mixpanel = useMixpanel();
  const location = useLocation();
  const history = useHistory();
  const filtersContainerRef = useRef<HTMLDivElement>(null);
  const { pause, resume, cancel } = useOrders();
  const { forceCancelOrder } = useAdminUtils();
  const dispatch = useAppStateDispatch();
  const user = useUser();

  const onModifyOrder = useDynamicCallback((order: Order) => {
    mixpanel.track(MixpanelEvent.ModifyOrder);
    dispatch(modifyOrder(order));
    dispatch(openView(OMSView.NewOrderForm));
  });

  const onPauseOrder: typeof pause = useDynamicCallback(orderID => {
    mixpanel.track(MixpanelEvent.PauseOrder, { [MixpanelEventProperty.ID]: orderID });
    pause(orderID);
  });
  const onResumeOrder: typeof resume = useDynamicCallback(orderID => {
    mixpanel.track(MixpanelEvent.ResumeOrder, { [MixpanelEventProperty.ID]: orderID });
    resume(orderID);
  });
  const onCancelOrder: typeof cancel = useDynamicCallback(orderID => {
    mixpanel.track(MixpanelEvent.CancelOrder, { [MixpanelEventProperty.ID]: orderID });
    cancel(orderID);
  });

  const { isAuthorized } = useRoleAuth();
  const marketTabs = useMarketTabs();
  const monitoringTabs = useMonitoringTabs();

  const analyticsTabs = useAnalyticsTabs();
  const { useTradeAllocations, enableAdvancedOrderDetails, confirmOrderCancels } = useTradingSettings();
  const { subAccountsByName } = useSubAccounts();

  const handleClose = useDynamicCallback(() => {
    if (isCurrentRouteDealerMonitoringOrderDetails(location.pathname)) {
      // TODO add proper close support in dealer monitoring after mixed tabs is implemented
      history.push('/dealer/monitoring');
    } else if (isCurrentRouteMonitoringOrderDetails(location.pathname)) {
      monitoringTabs.removeActiveTab();
    } else if (isCurrentRouteAnalyticsOrderDetails(location.pathname)) {
      analyticsTabs.removeActiveTab();
    } else {
      marketTabs.removeActiveTab();
    }
  });

  const tradeAllocationsColumns = useTradeAllocationsColumns(order?.unifiedLiquidity === UnifiedLiquidityEnum.Enabled);
  const defaultFilter = useMemo(() => (order.SubmitTime ? { StartDate: order.SubmitTime } : {}), [order.SubmitTime]);

  const { subAccountAllocations, allocationValueType } = useMemo(
    () => prepareAllocationOrderForUI({ Allocation: order.Allocation }),
    [order.Allocation]
  );
  const isRFQ = isEntityRFQ(order);
  const isCustomerOrder = customerOrder != null;
  const orderDetailsRoutes: OrderDetailsRoute[] = useMemo(() => {
    const routes: OrderDetailsRoute[] = [
      {
        path: 'details',
        label: 'Details',
        render: props => <DetailsTab {...props} width={width} />,
      },
    ];

    if (!isRFQ) {
      routes.push({
        path: 'analytics',
        label: 'Analytics',
        render: props => <AnalyticsTab {...props} width={width} isOrderMultileg={isMultileg(security)} />,
      });
    }

    const createOrderIdTag = (id: string) => {
      return `Order-${id.slice(0, 4)}`;
    };

    return compact([
      ...routes,
      enableAdvancedOrderDetails
        ? ({
            path: 'market-orders' as const,
            label: 'Market Orders',
            render: props => (
              <MixpanelSourceContext.Provider value={MixpanelEventSource.OrderDetailsMarketOrdersTab}>
                <MarketOrdersBlotterTable
                  blotterID={getBlotterID(BLOTTER_ID_MARKET_ORDERS_ORDER_DETAILS, props.order)}
                  blotterPersistID={BLOTTER_ID_MARKET_ORDERS_ORDER_DETAILS}
                  tabLabel={createOrderIdTag(props.order.OrderID)}
                  parentOrderID={props.order.OrderID}
                  defaultFilter={defaultFilter}
                  source="trading"
                  {...props}
                />
              </MixpanelSourceContext.Provider>
            ),
          } satisfies OrderDetailsRoute)
        : null,
      {
        path: 'trades' as const,
        label: isCustomerOrder ? 'Hedge Trades' : 'Trades',
        render: props => (
          <MixpanelSourceContext.Provider value={MixpanelEventSource.OrderDetailsTradesTab}>
            <FilteredTrades
              orderID={props.order.OrderID}
              blotterPersistID={BLOTTER_ID_TRADES_ORDER_DETAILS}
              defaultFilter={defaultFilter}
              tabLabel={createOrderIdTag(props.order.OrderID)}
              showOpenOrderDetails={false}
              defaultColumns={ORDER_DETAILS_DEFAULT_TRADE_COLUMNS}
              {...props}
            />
          </MixpanelSourceContext.Provider>
        ),
      },
      isCustomerOrder
        ? {
            path: 'customer-trades' as const,
            label: 'Customer Trades',
            render: props => (
              <CustomerTradesBlotterTable
                orderID={props.customerOrder!.OrderID}
                blotterID={getBlotterID(BLOTTER_ID_CUSTOMER_TRADES_ORDER_DETAILS, props.customerOrder)}
                blotterPersistID={BLOTTER_ID_CUSTOMER_TRADES_ORDER_DETAILS}
                defaultFilter={defaultFilter}
                tabLabel={createOrderIdTag(props.order.OrderID)}
                {...props}
              />
            ),
          }
        : null,
      enableAdvancedOrderDetails
        ? {
            path: 'execution-reports' as const,
            label: 'Execution Reports',
            render: props => (
              <MixpanelSourceContext.Provider value={MixpanelEventSource.OrderDetailsExecutionReportsTab}>
                <ExecutionReportsBlotterTable
                  blotterID={getBlotterID(BLOTTER_ID_EXEC_REPORTS_ORDER_DETAILS, props.order)}
                  blotterPersistID={BLOTTER_ID_EXEC_REPORTS_ORDER_DETAILS}
                  defaultFilter={defaultFilter}
                  tabLabel={createOrderIdTag(props.order.OrderID)}
                  orderID={props.order.OrderID}
                  {...props}
                />
              </MixpanelSourceContext.Provider>
            ),
          }
        : null,
      enableAdvancedOrderDetails
        ? {
            path: 'market-execution-reports' as const,
            label: 'Market Execution Reports',
            render: props => (
              <MixpanelSourceContext.Provider value={MixpanelEventSource.OrderDetailsMarketExecutionReportsTab}>
                <MarketExecutionReportsBlotterTable
                  blotterID={getBlotterID(BLOTTER_ID_MARKET_EXEC_REPORTS_ORDER_DETAILS, props.order)}
                  blotterPersistID={BLOTTER_ID_MARKET_EXEC_REPORTS_ORDER_DETAILS}
                  defaultFilter={defaultFilter}
                  tabLabel={createOrderIdTag(props.order.OrderID)}
                  parentOrderID={props.order.OrderID}
                  {...props}
                />
              </MixpanelSourceContext.Provider>
            ),
          }
        : null,
      useTradeAllocations && !!subAccountAllocations?.length && subAccountAllocations?.length > 1
        ? {
            path: 'allocations' as const,
            label: 'Allocations',
            render: props => (
              <MixpanelSourceContext.Provider value={MixpanelEventSource.OrderDetailsAllocationsTab}>
                <TradeAllocationsBlotterTable
                  blotterID={getBlotterID(BLOTTER_ID_ALLOCATIONS_ORDER_DETAILS, props.order)}
                  blotterPersistID={BLOTTER_ID_ALLOCATIONS_ORDER_DETAILS}
                  defaultFilter={defaultFilter}
                  tabLabel={createOrderIdTag(props.order.OrderID)}
                  defaultColumns={tradeAllocationsColumns}
                  orderID={props.order.OrderID}
                  {...props}
                />
              </MixpanelSourceContext.Provider>
            ),
          }
        : null,
    ]);
  }, [
    isRFQ,
    security,
    tradeAllocationsColumns,
    width,
    subAccountAllocations,
    useTradeAllocations,
    isCustomerOrder,
    defaultFilter,
    enableAdvancedOrderDetails,
  ]);

  const selectedTabIndex = orderDetailsRoutes.findIndex(route => location.pathname === `${match.url}/${route.path}`);

  const subAccountString = subAccountAllocations?.length
    ? prettifyAllocations(subAccountAllocations, allocationValueType, order.Currency, subAccountsByName)
    : prettifySubAccount(order.SubAccount, subAccountsByName) || '-';

  const checkOrderAccess = useOrderAccess();
  const canModifyOrder = checkOrderAccess(order);
  const cancelDialog = useDisclosure({ onOpen: () => mixpanel.track(MixpanelEvent.CancelAdmin) });
  const forceCancelDialog = useDisclosure({ onOpen: () => mixpanel.track(MixpanelEvent.CancelForce) });

  const orderTypeLabel = useMemo(() => {
    if (isMultileg(security)) {
      return 'Multi-Leg Order';
    } else if (isCalendarSpread(security)) {
      return 'Calendar Spread';
    } else {
      return 'Order';
    }
  }, [security]);

  return (
    <Wrapper w="100%">
      <TopWrapper>
        <OrderInfo>
          <HStack gap="spacingDefault" justifyContent="flex-start">
            <Icon icon={IconName.Hashtag} size="fontSizeLarge" />
            <Flex flexDirection="column" alignItems="flex-start" gap="spacingSmall">
              <IndicatorBadge children="ID" />
              <Text color="colorTextImportant" size="fontSizeLarge">
                {abbreviateId(order.OrderID)}
              </Text>
            </Flex>
          </HStack>

          <Divider orientation="vertical" my="spacingSmall" />

          <OrderSummaryItem
            title={
              <Flex gap="spacingSmall" alignItems="center">
                <IndicatorBadge children={orderTypeLabel} />
                {formattedDate(order.SubmitTime)}
              </Flex>
            }
          >
            <SmartSummary
              entity={order}
              type={isOrder(order) ? 'order' : 'quote'}
              showSymbol={true}
              showStrategy={true}
              showPriceReference
            />
          </OrderSummaryItem>

          <Divider orientation="vertical" my="spacingSmall" />

          <OrderSummaryItem
            title={
              <Flex gap="spacingSmall" alignItems="center">
                <IndicatorBadge children="Filled" />
                {formattedDate(order.Timestamp)}
              </Flex>
            }
          >
            <SmartSummary
              entity={order}
              type={isOrder(order) ? 'order' : 'quote'}
              showSide={false}
              qtyField="CumQty"
              priceField="AvgPx"
            />
          </OrderSummaryItem>

          {width > 1000 && (
            <>
              <Divider orientation="vertical" my="spacingSmall" />
              <HStack>
                <OrderSummaryItem title={<IndicatorBadge children="User" />}>{order.User}</OrderSummaryItem>
              </HStack>

              {customerOrder != null && (
                <OrderSummaryItem title={<IndicatorBadge children="Customer" />}>
                  {customerOrder.Counterparty ?? '-'}
                </OrderSummaryItem>
              )}

              {order.Group && (
                <OrderSummaryItem title={<IndicatorBadge children="Group" />}>{order.Group ?? '-'}</OrderSummaryItem>
              )}

              <HStack>
                {(subAccountAllocations || order.SubAccount) && (
                  <Tooltip tooltip={subAccountString} placement="bottom">
                    <OrderSummaryItem title={<IndicatorBadge children="Sub Account(s)" />} maxWidth="100px">
                      {subAccountString}
                    </OrderSummaryItem>
                  </Tooltip>
                )}
              </HStack>
            </>
          )}

          <Divider orientation="vertical" my="spacingSmall" />

          <OrderSummaryItem title={<IndicatorBadge children="Status" />}>
            <OrderStatus
              ordStatus={order.OrdStatus}
              decisionStatus={order.DecisionStatus}
              cumQty={order.CumQty}
              orderQty={order.OrderQty}
              pricingMode={order.PricingMode}
            />
          </OrderSummaryItem>
        </OrderInfo>
        <HStack gap="spacingLarge" p="spacingComfortable" justifyContent="flex-end">
          <Warnings order={order} />
          <Divider orientation="vertical" my="spacingSmall" />
          {canModifyOrder ? (
            <>
              <HStack gap="spacingDefault" justifyContent="flex-end">
                {width > 1400 && (
                  <HStack gap="spacingDefault" justifyContent="flex-end">
                    <Button
                      disabled={!order.isCancelable || !isAuthorized(ACTION.CANCEL_ORDER)}
                      onClick={() => {
                        if (confirmOrderCancels) {
                          cancelDialog.open();
                          return;
                        } else {
                          onCancelOrder(order?.OrderID);
                        }
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      disabled={!order.isModifiable || !isAuthorized(ACTION.SUBMIT_ORDER)}
                      onClick={() => onModifyOrder(order)}
                    >
                      Modify
                    </Button>
                    {order.isPausable && (
                      <IconButton
                        variant={ButtonVariants.Default}
                        icon={IconName.Pause}
                        disabled={!isAuthorized(ACTION.PAUSE_ORDER)}
                        onClick={() => onPauseOrder(order.OrderID)}
                        data-testid="ord-details-pause"
                      />
                    )}
                    {order.isResumable && (
                      <IconButton
                        variant={ButtonVariants.Primary}
                        icon={IconName.Play}
                        disabled={!isAuthorized(ACTION.RESUME_ORDER)}
                        onClick={() => onResumeOrder(order.OrderID)}
                        data-testid="ord-details-resume"
                      />
                    )}
                  </HStack>
                )}
                <Menu triggerRound={false}>
                  {isTalosUser(user) && (
                    <>
                      <MenuItem
                        variant={ButtonVariants.Negative}
                        disabled={order.isComplete || !isAuthorized(ACTION.CANCEL_ORDER)}
                        onClick={() => cancelDialog.open()}
                      >
                        Cancel (admin)
                      </MenuItem>
                      <MenuItem
                        variant={ButtonVariants.Negative}
                        disabled={!isAuthorized(ACTION.FORCE_CANCEL_ORDER)}
                        onClick={() => forceCancelDialog.open()}
                      >
                        Force Cancel (admin)
                      </MenuItem>
                    </>
                  )}
                  {width < 1400 && (
                    <>
                      <MenuItem
                        variant={ButtonVariants.Negative}
                        disabled={!order.isCancelable || !isAuthorized(ACTION.CANCEL_ORDER)}
                        onClick={() => {
                          if (confirmOrderCancels) {
                            cancelDialog.open();
                            return;
                          } else {
                            onCancelOrder(order?.OrderID);
                          }
                        }}
                      >
                        Cancel
                      </MenuItem>
                      <MenuItem
                        variant={ButtonVariants.Negative}
                        disabled={!order.isModifiable || !isAuthorized(ACTION.SUBMIT_ORDER)}
                        onClick={() => onModifyOrder(order)}
                      >
                        Modify
                      </MenuItem>
                      {order.isPausable && (
                        <MenuItem
                          onClick={() => onPauseOrder(order.OrderID)}
                          disabled={!isAuthorized(ACTION.SUBMIT_ORDER)}
                          data-testid="ord-details-pause"
                        >
                          <IconButton variant={ButtonVariants.Default} icon={IconName.Pause} />
                        </MenuItem>
                      )}
                      {order.isResumable && (
                        <MenuItem
                          onClick={() => onResumeOrder(order.OrderID)}
                          disabled={!isAuthorized(ACTION.SUBMIT_ORDER)}
                          data-testid="ord-details-resume"
                        >
                          <IconButton variant={ButtonVariants.Primary} icon={IconName.Play} />
                        </MenuItem>
                      )}
                    </>
                  )}
                </Menu>
                <Divider orientation="vertical" my="spacingSmall" />
                <IconButton icon={IconName.Close} ghost={true} onClick={handleClose} />
              </HStack>
            </>
          ) : (
            <IconButton icon={IconName.Close} ghost={true} onClick={handleClose} />
          )}
        </HStack>
      </TopWrapper>
      <MemoizedTabs
        location={location}
        monitoringTabs={monitoringTabs}
        marketTabs={marketTabs}
        selectedTabIndex={selectedTabIndex}
        matchUrl={match.url}
        routes={orderDetailsRoutes}
      />
      <Flex flex="1" flexDirection="column" overflow="hidden" mt="spacingTiny">
        {orderDetailsRoutes.map(route => (
          <Route
            key={route.path}
            exact
            path={`${match.path}/${route.path}`}
            render={props => route.render({ ...props, filtersContainerRef, order, customerOrder })}
          />
        ))}
      </Flex>
      {selectedTabIndex === -1 && <Redirect exact={true} to={`${match.url}/details`} />}
      <CancelSelectedDialog
        key="cancel"
        selectedItems={order}
        cancelSelectedDialog={cancelDialog}
        onConfirm={orders => orders.forEach(order => onCancelOrder(order.OrderID))}
      />
      <ForceCancelSelectedDialog
        key="force-cancel"
        selectedItems={order}
        forceCancelSelectedDialog={forceCancelDialog}
        onConfirm={orders => orders.forEach(order => forceCancelOrder(order.OrderID))}
      />
    </Wrapper>
  );
}

const MemoizedTabs = memo(function MemoizedTabs({
  selectedTabIndex,
  matchUrl,
  routes,
  marketTabs,
  location,
  monitoringTabs,
}: {
  selectedTabIndex: number;
  matchUrl: string;
  routes: OrderDetailsRoute[];
  marketTabs: ReturnType<typeof useMarketTabs>;
  location: ReturnType<typeof useLocation>;
  monitoringTabs: ReturnType<typeof useMonitoringTabs>;
}) {
  const mixpanel = useMixpanel();
  const onSelectTab = useCallback(
    (index: number) => {
      const route = routes.at(index);
      if (route) {
        if (isCurrentRouteMonitoringOrderDetails(location.pathname)) {
          monitoringTabs.updateOrderDetailsMonitoringTab(monitoringTabs.selectedIndex, route.path);
        } else {
          marketTabs.updateOrderDetailsMarketTab(marketTabs.selectedIndex, route.path);
        }
        mixpanel.track(MixpanelEvent.ChangeOrderDetailsTab, { [MixpanelEventProperty.TabLabel]: route.label });
      }
    },
    [mixpanel, routes, marketTabs, monitoringTabs, location]
  );

  const { setPortalRef: filtersContainerRef } = usePortal(BLOTTER_TABLE_FILTERS_CONTAINER_ID);

  return (
    <Tabs
      selectedIndex={selectedTabIndex}
      onSelect={onSelectTab}
      mb="-1px"
      w="100%"
      flex="0 0 auto"
      size={TabSize.Large}
      background="gray.020"
    >
      <TabList rightItems={<Box ref={filtersContainerRef} />}>
        {routes.map(route => {
          return (
            <Tab
              id={route.path}
              key={route.path}
              label={route.label}
              forwardedAs={Link}
              to={`${matchUrl}/${route.path}`}
            />
          );
        })}
      </TabList>
    </Tabs>
  );
});

function OrderSummaryItem({ title, children, ...props }: { title: ReactNode } & Omit<BoxProps, 'title'>) {
  return (
    <Dl alignItems="stretch" {...props}>
      <Dt fontSize="fontSizeTiny">{title}</Dt>
      <Dd mt="spacingSmall" textOverflow="ellipsis" overflow="hidden" whiteSpace="nowrap">
        {children}
      </Dd>
    </Dl>
  );
}
