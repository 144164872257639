import {
  AgGridMeter,
  DecisionStatusEnum,
  EMPTY_ARRAY,
  getAgGridColId,
  getOrderStatusText,
  getStatusColor,
  Meter,
  OrdStatusEnum,
  QuoteStatusEnum,
  toBigWithDefault,
  useDefaultColumns,
  type CareOrder,
  type Column,
  type ColumnDef,
} from '@talos/kyoko';
import type { ICellRendererParams, RowNode, ValueFormatterParams, ValueGetterParams } from 'ag-grid-community';
import Big from 'big.js';
import { compact } from 'lodash';
import { useMemo } from 'react';
import { CareOrderRow, OrderRow, QuoteRow, type CareOrderBlotterEntity } from './types';

export interface UseCareOrderColumns {
  defaultColumns?: (keyof CareOrder | Partial<Column>)[];
}

export const useCareOrderColumns = ({ defaultColumns = EMPTY_ARRAY }: UseCareOrderColumns) => {
  const columnDefinitions = useMemo(() => {
    return new Map<string, Column>(
      compact([
        {
          type: 'security',
          field: 'data.Symbol',
          title: 'Symbol',
          width: 250,
          pinned: 'left',
        },
        {
          type: 'custom',
          id: 'progress',
          params: {
            valueGetter: (
              params: ValueGetterParams<CareOrderBlotterEntity>
            ): number | { value: number; color?: string } => {
              if (params.node == null) {
                throw new Error('missing node in valueGetter');
              }
              if (params.data instanceof CareOrderRow) {
                const careOrder = params.data.data;
                // Note that this value is only used for the label in the meter
                // as the cell renderer itself figures out how to render the bars
                return toBigWithDefault(careOrder.CumQty, 0)
                  .div(params.data.data.OrderQty ?? 1)
                  .toNumber();
              }

              if (params.data instanceof QuoteRow) {
                const quote = params.data.data;
                // ...however, here we return an object with a color to be used in the meter bar
                return {
                  value: [QuoteStatusEnum.Filled, QuoteStatusEnum.PendingFix].includes(quote.QuoteStatus) ? 1 : 0,
                  color:
                    quote.QuoteStatus === QuoteStatusEnum.Filled
                      ? 'var(--colors-green-lighten)'
                      : 'var(--colors-blue-lighten)',
                };
              }

              if (params.data instanceof OrderRow) {
                const order = params.data.data;
                const status = getOrderStatusText({
                  ordStatus: order.OrdStatus ?? OrdStatusEnum.PendingNew,
                  orderQty: params.data.data.OrderQty ?? '',
                  cumQty: params.data.data.CumQty ?? '',
                  decisionStatus: params.data.data.DecisionStatus ?? DecisionStatusEnum.Active,
                });
                const value = toBigWithDefault(params.data.data.OrderQty, 0).gt(0)
                  ? Big(params.data.data.CumQty ?? '0').div(params.data.data.OrderQty)
                  : Big(0);
                // ...and similarly here we return an object with a color to be used in the meter bar
                return {
                  value: value.toNumber(),
                  color: getStatusColor(status, params.context.current.theme),
                };
              }

              throw new Error(`unexpected row type ${params.data?.constructor.name}`);
            },
            valueFormatter: (params: ValueFormatterParams<CareOrderBlotterEntity>) => {
              if (params.data instanceof CareOrderRow) {
                return `${Big(params.value).times(100).toFixed(2)}%`;
              }
              return `${Big(params.value.value).times(100).toFixed(2)}%`;
            },
            cellRendererSelector: params => {
              if (params.data instanceof CareOrderRow) {
                return {
                  component: AgCareOrderProgress,
                };
              }
              return {
                component: AgGridMeter,
                params: {
                  roundLeftEdge: true,
                  roundRightEdge: true,
                  showInitialAnimation: false,
                },
              };
            },
          },
        },
      ] satisfies ColumnDef<CareOrderBlotterEntity>[]).map(c => [getAgGridColId(c), c])
    );
  }, []);

  return useDefaultColumns(defaultColumns, columnDefinitions);
};

const pendingFixingQuoteStatuses = [QuoteStatusEnum.PendingFix];
const fixingAppliedQuoteStatuses = [QuoteStatusEnum.Filled];
const workingQuoteStatuses = [QuoteStatusEnum.Open, QuoteStatusEnum.PendingFill, QuoteStatusEnum.PendingNew];

const pendingFixingOrdStatuses = [OrdStatusEnum.Filled];
const fixingAppliedOrdStatuses = [OrdStatusEnum.DoneForDay];
const workingOrdStatuses = [
  OrdStatusEnum.New,
  OrdStatusEnum.PartiallyFilled,
  OrdStatusEnum.PendingNew,
  OrdStatusEnum.PendingCancel,
  OrdStatusEnum.PendingReplace,
  OrdStatusEnum.Replaced,
  OrdStatusEnum.Replaced,
];

function childRowsToMeterBars(childRows: RowNode[] | null | undefined, totalQty: string) {
  if (childRows == null) {
    throw new Error('missing child rows');
  }
  const fixingApplied = {
    id: 'fixingApplied',
    value: Big(0),
    color: 'var(--colors-green-lighten)',
    appearance: 'filled',
  } as const;
  const pendingFixing = {
    id: 'pendingFixing',
    value: Big(0),
    color: 'var(--colors-blue-lighten)',
    appearance: 'filled',
  } as const;
  const working = {
    id: 'working',
    value: Big(0),
    color: 'var(--colors-blue-lighten)',
    appearance: 'dashed',
  } as const;

  for (const row of childRows) {
    let value: string | undefined;
    // Used as a mutable reference
    let target: { value: Big } | undefined;

    if (row.data instanceof QuoteRow) {
      const quote = row.data.data;
      value = quote.OrderQty;
      if (pendingFixingQuoteStatuses.includes(quote.QuoteStatus)) {
        target = pendingFixing;
      } else if (fixingAppliedQuoteStatuses.includes(quote.QuoteStatus)) {
        target = fixingApplied;
      } else if (workingQuoteStatuses.includes(quote.QuoteStatus)) {
        target = working;
      }
    } else if (row.data instanceof OrderRow) {
      const order = row.data.data;
      value = order.OrderQty;
      if (pendingFixingOrdStatuses.includes(order.OrdStatus)) {
        target = pendingFixing;
      } else if (fixingAppliedOrdStatuses.includes(order.OrdStatus)) {
        target = fixingApplied;
      } else if (workingOrdStatuses.includes(order.OrdStatus)) {
        target = working;
        continue;
      }
    }

    if (value == null || target == null) {
      continue;
    }

    target.value = target.value.plus(Big(value).div(totalQty));
  }

  return [fixingApplied, pendingFixing, working];
}

function AgCareOrderProgress(params: ICellRendererParams<CareOrderBlotterEntity>) {
  if (!(params.data instanceof CareOrderRow)) {
    throw new Error('expected CareOrderRow');
  }
  if (params.node?.id == null) {
    throw new Error('missing node id');
  }
  // Specifically use `childrenAfterGroup` here (instead of `childrenAfterFilter`)
  // as we don't want to exclude any rows that are hidden due to filtering
  const childRows = params.api.getRowNode(params.node.id)?.childrenAfterGroup;
  const bars = childRowsToMeterBars(childRows, params.data.data.OrderQty);
  return (
    <Meter label={params.valueFormatted ?? params.value}>
      {bars.map(bar =>
        bar.value.eq(0) ? null : (
          <Meter.Bar key={bar.id} width={bar.value.toNumber() * 100} color={bar.color} appearance={bar.appearance} />
        )
      )}
    </Meter>
  );
}
