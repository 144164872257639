import {
  Dialog,
  Divider,
  Text,
  VStack,
  isOrder,
  isOrderComplete,
  useUserContext,
  type DialogProps,
  type ExecutionReport,
  type MarketOrder,
  type Order,
} from '@talos/kyoko';
import { compact, isArray } from 'lodash';
import { SelectedOrdersList } from './Orders/Dialogs/SelectedOrdersList';
import { SelectedOrdersUsers } from './Orders/Dialogs/SelectedOrdersUsers';
import { CancelDialogWrapper } from './Orders/Dialogs/styles';

export const CancelSelectedDialog = <T extends Order | ExecutionReport | MarketOrder>({
  selectedItems,
  cancelSelectedDialog,
  onConfirm,
}: {
  selectedItems?: T[] | T;
  cancelSelectedDialog: DialogProps;
  onConfirm: (orders: T[]) => void;
}) => {
  const { user } = useUserContext();

  const selectedItemsArray = isArray(selectedItems) ? selectedItems : compact([selectedItems]);
  if (selectedItemsArray.length === 0) {
    return null;
  }

  return (
    <Dialog
      {...cancelSelectedDialog}
      onConfirm={() => {
        onConfirm(selectedItemsArray);
      }}
      confirmLabel={`Cancel Order${selectedItemsArray.length > 1 ? 's' : ''}`}
      cancelLabel="Return"
      confirmDisabled={selectedItemsArray.every(selectedOrder => isOrderComplete(selectedOrder.OrdStatus))}
    >
      <CancelDialogWrapper>
        <VStack gap="spacingDefault" alignItems="stretch">
          {selectedItemsArray.every(selectedOrder => !isOrder(selectedOrder) || selectedOrder.User === user?.Name) ? (
            <Text textAlign="left" color="colorTextAttention">
              {selectedItemsArray.length === 1
                ? `Are you sure you want to cancel this order?`
                : `Are you sure you want to cancel these ${selectedItemsArray.length} orders?`}
            </Text>
          ) : (
            <Text textAlign="left" color="colorTextAttention">
              You are about to cancel {selectedItemsArray.length} order(s) belonging to{' '}
              <SelectedOrdersUsers selectedOrders={selectedItemsArray} />
              .<br />
              Are you sure you want to proceed?
            </Text>
          )}
          <Divider />
          <SelectedOrdersList selectedOrders={selectedItemsArray} />
        </VStack>
      </CancelDialogWrapper>
    </Dialog>
  );
};
export const ForceCancelSelectedDialog = <T extends Order | ExecutionReport | MarketOrder>({
  selectedItems,
  forceCancelSelectedDialog,
  onConfirm,
}: {
  selectedItems?: T[] | T;
  forceCancelSelectedDialog: DialogProps;
  onConfirm: (orders: T[]) => void;
}) => {
  const { user } = useUserContext();

  const selectedItemsArray = isArray(selectedItems) ? selectedItems : compact([selectedItems]);
  if (selectedItemsArray.length === 0) {
    return null;
  }

  return (
    <Dialog
      {...forceCancelSelectedDialog}
      onConfirm={() => {
        onConfirm(selectedItemsArray);
      }}
      confirmLabel={`Force Cancel Order${selectedItemsArray.length > 1 ? 's' : ''}`}
      cancelLabel="Return"
    >
      <CancelDialogWrapper>
        <VStack gap="spacingDefault" alignItems="stretch">
          {selectedItemsArray.every(selectedOrder => !isOrder(selectedOrder) || selectedOrder.User === user?.Name) ? (
            <Text textAlign="left" color="colorTextAttention">
              {selectedItemsArray.length === 1
                ? `Are you sure you want to force cancel this order?`
                : `Are you sure you want to force cancel these ${selectedItemsArray.length} orders?`}
            </Text>
          ) : (
            <Text textAlign="left" color="colorTextAttention">
              You are about to force cancel {selectedItemsArray.length} order(s) belonging to{' '}
              <SelectedOrdersUsers selectedOrders={selectedItemsArray} />
              .<br />
              Are you sure you want to proceed?
            </Text>
          )}
          <Divider />
          <SelectedOrdersList selectedOrders={selectedItemsArray} />
        </VStack>
      </CancelDialogWrapper>
    </Dialog>
  );
};
