import { HStack } from '@talos/kyoko';
import styled from 'styled-components';

export const MultilegComboDetailsWrapper = styled(HStack)``;
MultilegComboDetailsWrapper.defaultProps = {
  gap: 'spacingComfortable',
  justifyContent: 'space-between',
  py: 'spacingComfortable',
  px: 'spacingLarge',
  mx: '-spacingLarge',
  my: 'spacingMedium',
  borderTop: 'solid 1px',
  borderBottom: 'solid 1px',
  borderColor: 'colors.gray.010',
};
