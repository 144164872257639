import { useCallback } from 'react';
import { useTheme } from 'styled-components';

import {
  Box,
  Button,
  Checkbox,
  Dialog,
  FormControlSizes,
  HelpIcon,
  IconButton,
  MixpanelEvent,
  MixpanelEventProperty,
  NotificationVariants,
  Panel,
  PanelContent,
  PanelHeader,
  ToggleHorizontal,
  useDisclosure,
  useGlobalToasts,
  useMixpanel,
} from '@talos/kyoko';

import { useBlotterSettings } from '@talos/kyoko/src/providers/BlotterSettingContext';
import { FormHeader, FormRow } from 'components/Form';
import { useFeatureFlag } from 'hooks';
import { useSoundSettings, useSounds } from 'providers';
import { useBlotterState } from 'providers/AppConfigProvider';
import { usePortfolioSettings } from 'providers/PortfolioSettingProvider';
import { SOUND_OPTIONS } from 'providers/SoundContext';
import { SoundOptions } from '../styles';

const BLOTTER_SHOW_DIVIDERS_OPTIONS = [
  { displayName: 'On Hover', value: 'onHover' },
  { displayName: 'Always', value: 'always' },
];
export const General = function General() {
  const theme = useTheme();
  const mixpanel = useMixpanel();
  const { clearBlotterState } = useBlotterState();
  const { add } = useGlobalToasts();
  const { playSound } = useSounds();
  const { enableSoundEffects, setEnableSoundEffects, enabledSounds, setEnabledSounds } = useSoundSettings();
  const { enablePortfolioManagementSystem } = useFeatureFlag();

  const handleConfirm = useCallback(() => {
    clearBlotterState();
    mixpanel.track(MixpanelEvent.ResetBlotterColumns);
    add({
      text: 'Blotter column settings cleared.',
      variant: NotificationVariants.Positive,
    });
  }, [clearBlotterState, add, mixpanel]);

  const blotterResetWarnDialog = useDisclosure();
  const { enablePMS, setEnablePMS, treatStablecoinsAsCash, setTreatStablecoinsAsCash } = usePortfolioSettings();
  const { alwaysShowColumnDividers, setAlwaysShowColumnDividers } = useBlotterSettings();
  return (
    <Panel data-testid="settings-general">
      <PanelHeader>
        <h2>General</h2>
      </PanelHeader>
      <PanelContent>
        <FormHeader>Blotters</FormHeader>
        <FormRow>
          <Button
            onClick={() => {
              blotterResetWarnDialog.open();
            }}
          >
            Reset Blotter Columns
          </Button>
          <div style={{ marginLeft: theme.spacingLarge }}>This will reset all columns to their default values.</div>
        </FormRow>
        <FormRow key="formRow-blotterShowDividers">
          <Box pr="spacingSmall">Show Blotter Column Dividers: </Box>
          <ToggleHorizontal
            data-testid="toggle-blotter-show-dividers"
            options={BLOTTER_SHOW_DIVIDERS_OPTIONS}
            value={alwaysShowColumnDividers ? 'always' : 'onHover'}
            onChange={e => {
              mixpanel.track(MixpanelEvent.BlotterShowDividersAlways, {
                [MixpanelEventProperty.Enabled]: e === 'always',
              });
              add({
                text: `Show Blotter Column Dividers: ${
                  BLOTTER_SHOW_DIVIDERS_OPTIONS.find(item => item.value)?.displayName
                }`,
                variant: NotificationVariants.Positive,
              });
              setAlwaysShowColumnDividers(e === 'always');
            }}
          />{' '}
          <HelpIcon
            tooltip="For the column-resizing dividers on blotters, whether to highlight them on column hover
              or always show them."
          />
        </FormRow>

        <FormHeader>Portfolio</FormHeader>
        <FormRow key="formRow-treatStableCoinsAsCash">
          <Checkbox
            checked={treatStablecoinsAsCash}
            onChange={e => {
              mixpanel.track(MixpanelEvent.PMSTreatStablecoinsAsCash, {
                [MixpanelEventProperty.Enabled]: e.target.checked,
              });
              add({
                text: `Portfolio Management System: Treat Stablecoins as Cash ${
                  e.target.checked ? 'enabled' : 'disabled'
                }`,
                variant: NotificationVariants.Positive,
              });
              setTreatStablecoinsAsCash(e.target.checked);
            }}
          >
            Treat Stablecoins as Cash{' '}
            <HelpIcon
              tooltip={
                <>
                  When <strong>&apos;Include Cash&apos;</strong> is <strong>disabledß</strong> (in the Sub Accounts
                  blotter or the Portfolio Management System views):
                  <p />
                  If checked: Stablecoins will be treated as cash and will not show in the blotter
                  <p />
                  If unchecked: Stablecoins will be treated as regular assets and will always show
                </>
              }
            />
          </Checkbox>
        </FormRow>
        {enablePortfolioManagementSystem && (
          <FormRow key="formRow-showPMS">
            <Checkbox
              checked={enablePMS}
              onChange={e => {
                mixpanel.track(MixpanelEvent.ShowPortfolioManagementSystem, {
                  [MixpanelEventProperty.Enabled]: e.target.checked,
                });
                add({
                  text: `Portfolio Management System features ${e.target.checked ? 'enabled' : 'disabled'}`,
                  variant: NotificationVariants.Positive,
                });
                setEnablePMS(e.target.checked);
              }}
            >
              Show Portfolio Management System features{' '}
              <HelpIcon
                tooltip={
                  <>
                    (Beta) Display the new Portfolio Management System layout when inside the{' '}
                    <strong>&apos;Portfolio&apos;</strong> tab from the main menu
                  </>
                }
              />
            </Checkbox>
          </FormRow>
        )}

        <FormHeader>Sound Effects</FormHeader>
        <FormRow>
          <Checkbox
            checked={enableSoundEffects}
            onChange={e => {
              mixpanel.track(MixpanelEvent.SoundEffects, {
                [MixpanelEventProperty.Enabled]: e.target.checked,
              });
              add({
                text: `Sound Effects ${e.target.checked ? 'enabled' : 'disabled'}.`,
                variant: NotificationVariants.Positive,
              });
              setEnableSoundEffects(e.target.checked);
            }}
          >
            Enable Sound Effects
          </Checkbox>
        </FormRow>
        <SoundOptions>
          {SOUND_OPTIONS.map(({ name, icon, sound, mixpanelEvent }) => (
            <div key={name}>
              <Checkbox
                disabled={enableSoundEffects === false}
                checked={enableSoundEffects && enabledSounds[sound]}
                onChange={e => {
                  mixpanel.track(mixpanelEvent, {
                    [MixpanelEventProperty.Enabled]: e.target.checked,
                  });
                  setEnabledSounds({ ...enabledSounds, [sound]: e.target.checked });
                }}
              >
                {name}
              </Checkbox>
              {icon && (
                <IconButton
                  ghost
                  icon={icon}
                  size={FormControlSizes.Tiny}
                  style={{ marginLeft: theme.spacingSmall, paddingBottom: theme.spacingTiny }}
                  onClick={() => playSound(sound, true)}
                />
              )}
            </div>
          ))}
        </SoundOptions>
      </PanelContent>
      <Dialog
        {...blotterResetWarnDialog}
        usePortal={false}
        cancelLabel="Cancel"
        confirmLabel="Confirm"
        onConfirm={handleConfirm}
      >
        <p>
          Are you sure you want to <b>Reset Columns</b> on all Blotters?
        </p>
      </Dialog>
    </Panel>
  );
};
