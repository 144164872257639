import { useCallback, useMemo } from 'react';
import { Route, useLocation } from 'react-router-dom';

import {
  Breadcrumb,
  BreadcrumbItem,
  ButtonVariants,
  FeeModeEnum,
  FormControlSizes,
  HStack,
  IconName,
  MixpanelEvent,
  MixpanelEventProperty,
  MixpanelEventSource,
  MixpanelSourceContext,
  Toggle,
  ToggleButton,
  useMixpanel,
  useWatchlistSettings,
} from '@talos/kyoko';
import { compact } from 'lodash';
import { useDisplaySettings } from 'providers/AppConfigProvider';
import { FEES_TOGGLE, FIRM_LIQUIDITY_TOGGLE } from 'tokens/tooltips';
import { useTabLabelResolver } from '../../hooks/useTabLabelResolver';
import { mapDisplayPartToPath, mapPathToDisplayParts } from '../Routes/utils';
import { HeaderButtons } from './HeaderButtons';
import { HeaderWrapper } from './styles';

export const Header = () => {
  return (
    <MixpanelSourceContext.Provider value={MixpanelEventSource.Header}>
      <InternalHeader />
    </MixpanelSourceContext.Provider>
  );
};

const InternalHeader = () => {
  const mixpanel = useMixpanel();
  const { showAllInPrices, setShowAllInPrices, showFirmLiquidity, setShowFirmLiquidity } = useDisplaySettings();

  const handleToggleAllInPrices = useCallback(
    showAllInPrices => {
      const fees = showAllInPrices ? FeeModeEnum.Taker : null;
      mixpanel.track(MixpanelEvent.ToggleFees, { [MixpanelEventProperty.Enabled]: fees });
      setShowAllInPrices(fees);
    },
    [mixpanel, setShowAllInPrices]
  );

  const handleToggleFirmLiquidity = useCallback(
    showFirmLiquidity => {
      mixpanel.track(MixpanelEvent.ToggleFirmLiquidity, { [MixpanelEventProperty.Enabled]: showFirmLiquidity });
      setShowFirmLiquidity(showFirmLiquidity);
    },
    [mixpanel, setShowFirmLiquidity]
  );

  const { isVisible, setIsVisible } = useWatchlistSettings();

  const toggleWatchlistVisible = useCallback(() => {
    const nextVisible = !isVisible;
    mixpanel.track(MixpanelEvent.ToggleWatchlist, { [MixpanelEventProperty.Enabled]: nextVisible });
    setIsVisible(nextVisible);
  }, [mixpanel, isVisible, setIsVisible]);

  const location = useLocation();

  const { resolveTabLabel } = useTabLabelResolver();

  const breadcrumbItems = useMemo(() => {
    const parts = compact(mapPathToDisplayParts(resolveTabLabel, location.pathname));

    return parts.map((part, index) => {
      const to = mapDisplayPartToPath(parts.slice(0, index + 1), location.pathname);

      const capitalize = !part.startsWith('#');

      return (
        <BreadcrumbItem
          key={index}
          to={index < parts.length - 1 ? `/${to}` : '#'}
          onClick={e => !to && e.preventDefault()}
          style={{ textTransform: capitalize ? 'capitalize' : 'none' }}
        >
          {part}
        </BreadcrumbItem>
      );
    });
  }, [location.pathname, resolveTabLabel]);

  return (
    <HeaderWrapper>
      <Route path="/trading">
        <ToggleButton
          onClick={toggleWatchlistVisible}
          size={FormControlSizes.Tiny}
          selected={isVisible}
          selectedVariant={ButtonVariants.Primary}
          startIcon={IconName.PresentationChartLine}
        >
          Watchlist
        </ToggleButton>
      </Route>
      <HStack justifyContent="flex-start" flex="1">
        <Route
          path={[
            '/trading',
            '/dealer',
            '/transfers',
            '/portfolio',
            '/portfolio-engineering',
            '/analytics',
            '/monitoring',
            '/settings',
            '/admin',
          ]}
        >
          <Breadcrumb>{breadcrumbItems}</Breadcrumb>
        </Route>
      </HStack>
      <HStack gap="spacingComfortable">
        <Route path="/trading">
          <Toggle
            size={FormControlSizes.Small}
            checked={!!showAllInPrices}
            onChange={handleToggleAllInPrices}
            tooltip={FEES_TOGGLE}
            label="Fees"
          />
          <Toggle
            size={FormControlSizes.Small}
            checked={showFirmLiquidity}
            onChange={handleToggleFirmLiquidity}
            tooltip={FIRM_LIQUIDITY_TOGGLE}
            label="Firm Liquidity"
          />
        </Route>
        <HeaderButtons />
      </HStack>
    </HeaderWrapper>
  );
};
