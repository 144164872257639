import type { CellClassParams, CellStyleFunc, RowNode } from 'ag-grid-community';
import { get } from 'lodash';
import { ModeEnum } from '../../types';
import type { Column } from '../BlotterTable';
import { FormControlSizes } from '../Form';
import { IconName } from '../Icons';
import type { EntityPageClass } from './types';

interface ColumnProps<T> {
  handleOnClick: (entity: T) => void;
}
export const getEditColumn = <T,>({ handleOnClick }: ColumnProps<T>): Column => ({
  id: 'edit',
  type: 'button',
  pinned: 'right',
  width: 70,
  suppressColumnsToolPanel: true,
  params: {
    startIcon: IconName.Pencil,
    onClick: ({ node }: { node: RowNode<EntityPageClass<T>> }) => {
      const entity = node.data;
      entity && handleOnClick(entity.data);
    },
    children: 'Edit',
    size: FormControlSizes.Small,
  },
});
export const getDeleteColumn = <T,>({ handleOnClick }: ColumnProps<T>): Column => ({
  id: 'delete',
  type: 'button',
  pinned: 'right',
  width: 50,
  suppressColumnsToolPanel: true,
  params: {
    startIcon: IconName.Trash,
    onClick: ({ node }: { node: RowNode<EntityPageClass<T>> }) => {
      const entity = node.data;
      entity && handleOnClick(entity.data);
    },
    size: FormControlSizes.Small,
  },
});
export const getModeColumn = <T,>({ handleOnClick }: ColumnProps<T>): Column => ({
  id: 'mode',
  field: 'Mode',
  type: 'modeWithMessage',
  pinned: 'left',
  width: 70,
  params: {
    cellRendererParams: ({ node }: { node: RowNode<EntityPageClass<T> & { Mode: ModeEnum }> }) => {
      return {
        checked: node.data?.Mode === ModeEnum.Enabled,
        onChange: (enabled: boolean) => {
          const entity = node.data;
          if (entity != null) {
            handleOnClick({ ...entity.data, Mode: enabled ? ModeEnum.Enabled : ModeEnum.Disabled });
          }
        },
      };
    },
  },
});

export interface HierarchicalColumnProps<T> {
  openEntityDrawer: (entity: T | undefined, isChild: boolean) => void;
  buttonProps: { text?: string; width?: number };
  entityIDField: keyof T;
  childIDField: keyof T;
}
export const getAddChildEntityColumn = <T,>({
  openEntityDrawer,
  buttonProps,
  entityIDField,
  childIDField,
}: HierarchicalColumnProps<T>): Column => ({
  id: 'add-child',
  type: 'button',
  pinned: 'right',
  width: buttonProps.width ?? 120,
  suppressColumnsToolPanel: true,
  params: {
    startIcon: IconName.Plus,
    onClick: ({ node }: { node: RowNode<EntityPageClass<T>> }) => {
      if (node.data) {
        const newEntity = { [entityIDField]: get(node.data, entityIDField) } as T;
        openEntityDrawer(newEntity, true);
      }
    },
    hide: (data: T) => get(data, childIDField) != null,
    children: buttonProps.text ?? 'Add Child',
    size: FormControlSizes.Small,
  },
});

export const getEntitiesByParentIDMap = <T,>(
  data: T[],
  entityIDField: keyof T,
  childIDField?: keyof T
): Map<keyof T, T> | undefined => {
  if (childIDField != null) {
    return new Map(
      data
        // Only get the rows that are parents
        .filter(row => get(row, childIDField) == null)
        // Map the parentID to the row
        .map(row => [get(row, entityIDField), row])
    );
  }
  return undefined;
};

interface InheritanceStyleProps<T> {
  cellStyle: CellStyleFunc<EntityPageClass<T>>;
}
export const applyInheritanceCellStyle = <T,>(column: Column<any, T>): Column<InheritanceStyleProps<T>, T> => ({
  ...column,
  cellStyle: (params: CellClassParams<EntityPageClass<T>>) => ({
    ...(typeof column.cellStyle === 'function' ? column.cellStyle(params) : column.cellStyle),
    // If this column is inherited, make it slightly transparent. We overwrite any cellStyle opacity that was applied
    opacity: params.data?.isInherited(params.colDef.field as keyof T) ? 0.5 : 1,
  }),
});
