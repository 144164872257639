import {
  ConnectionModeEnum,
  Dialog,
  Divider,
  HStack,
  ICON_SIZES,
  Icon,
  IconName,
  Text,
  VStack,
  type DialogProps,
} from '@talos/kyoko';
import { useTheme } from 'styled-components';
import type { HydratedMarketCredential } from '../types';
import { SelectedCredentialsList } from './SelectedCredentialsList';

export const EnableDisableSelectedDialog = <T extends HydratedMarketCredential>({
  selectedItems,
  enableDisableDialog,
  onConfirm,
  mode,
}: {
  selectedItems: T[];
  enableDisableDialog: DialogProps;
  onConfirm: (credentials: T[]) => void;
  mode: ConnectionModeEnum;
}) => {
  const theme = useTheme();
  if (selectedItems.length === 0 || selectedItems.length === 1) {
    return null;
  }
  const label = mode === ConnectionModeEnum.Up ? 'Enable' : 'Disable';
  const titleText = `${label} ${selectedItems.length} Credentials`;
  const description = `Are you sure you want to ${label.toLowerCase()} ${selectedItems.length} credentials?`;
  return (
    <Dialog
      {...enableDisableDialog}
      onConfirm={() => {
        onConfirm(selectedItems);
      }}
      confirmLabel="Confirm"
      cancelLabel="Return"
      title={
        <HStack gap="spacingComfortable">
          <Icon
            size={ICON_SIZES.MEDIUM}
            icon={mode === ConnectionModeEnum.Down ? IconName.ExclamationSolid : IconName.CheckCircleSolid}
            color={mode === ConnectionModeEnum.Down ? theme.colorTextWarning : theme.colorTextPositive}
          />
          {titleText}
        </HStack>
      }
    >
      <VStack gap="spacingDefault" alignItems="stretch">
        <Text textAlign="left" color="colorTextAttention">
          {description}
        </Text>
        <Divider />
        <SelectedCredentialsList credentials={selectedItems} mode={mode} />
      </VStack>
    </Dialog>
  );
};
