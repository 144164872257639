import { HStack, PRINCIPAL_MAIN_HEADER_HEIGHT } from '@talos/kyoko';
import styled from 'styled-components';

export const HeaderWrapper = styled(HStack)`
  @media print {
    display: none;
  }
`;
HeaderWrapper.defaultProps = {
  h: `${PRINCIPAL_MAIN_HEADER_HEIGHT}px`,
  background: 'colors.gray.main',
  w: '100%',
  flex: `0 0 ${PRINCIPAL_MAIN_HEADER_HEIGHT}px`,
  gap: 'spacingComfortable',
  px: 'spacingComfortable',
  overflow: 'hidden',
};
