import type { useDrawer } from '../Drawer';
import { InputsAndDropdownsDrawer } from '../Drawer/InputsAndDropdownsDrawer';
import { ModifyJsonDrawer } from '../Drawer/ModifyJsonDrawer';
import type { EntityAdminPageProps } from './EntityAdminPage';
import type { EntityPageModel } from './types';

export enum GenericDrawerTypeEnum {
  JSON = 'JSON',
  InputsAndDropdowns = 'InputsAndDropdowns',
}

interface EntityAdminDrawerProps<T>
  extends Omit<EntityAdminPageProps<T>, 'path' | 'apiEndpointOverride' | 'entityIDField'> {
  selectedEntity: T | undefined;
  handleOnSaveEntity: (entity: T) => void;
  handleOnDeleteEntity: (entity: T) => void;
  entityDrawer: ReturnType<typeof useDrawer>;
  addingChildEntity?: boolean;
}

export const EntityAdminDrawer = <T extends EntityPageModel>({
  drawerOptions,
  selectedEntity,
  handleOnSaveEntity,
  handleOnDeleteEntity,
  allowAddEntity,
  allowEditEntity,
  allowDeleteEntity,
  entityDrawer,
  addingChildEntity,
  title,
}: EntityAdminDrawerProps<T>) => {
  if (!allowAddEntity && !allowEditEntity) {
    // No permissions to add or edit entities using the drawer
    return null;
  }

  const drawerType = drawerOptions != null ? GenericDrawerTypeEnum.InputsAndDropdowns : GenericDrawerTypeEnum.JSON;

  return (
    <>
      {drawerType === GenericDrawerTypeEnum.JSON && (
        <ModifyJsonDrawer
          title={title}
          selectedEntity={selectedEntity}
          onSave={handleOnSaveEntity}
          allowDeleteEntity={allowDeleteEntity}
          onDelete={handleOnDeleteEntity}
          // Spread all drawer props
          {...entityDrawer}
        />
      )}
      {drawerType === GenericDrawerTypeEnum.InputsAndDropdowns && drawerOptions != null && (
        <InputsAndDropdownsDrawer
          title={title}
          selectedEntity={selectedEntity}
          drawerOptions={drawerOptions}
          onSave={handleOnSaveEntity}
          allowDeleteEntity={allowDeleteEntity}
          onDelete={handleOnDeleteEntity}
          isEditing={selectedEntity != null && !addingChildEntity}
          // Spread all drawer props
          {...entityDrawer}
        />
      )}
    </>
  );
};
