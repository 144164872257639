import type { CareOrder } from '@talos/kyoko';
import {
  BlotterTable,
  BlotterTableExtrasMenu,
  BlotterTableFilters,
  Button,
  ButtonVariants,
  FormControlSizes,
  IconButton,
  IconName,
  MixpanelEvent,
  abbreviateId,
  baseTreeGroupColumnDef,
  createCSVFileName,
  stringColumnComparatorMultiColumn,
  useBlotterTable,
  useBlotterTableExtrasMenu,
  useDynamicCallback,
  useMixpanel,
  usePersistedBlotterTable,
  usePersistedRowGroupsOpenedState,
  type BlotterTableFilter,
  type Column,
  type RowGroupsOpenedState,
} from '@talos/kyoko';
import type { GridOptions, ValueFormatterParams, ValueGetterParams } from 'ag-grid-community';
import { OMSView } from 'components/OMS/OMSView';
import { useAppStateDispatch } from 'providers/AppStateProvider';
import { useMemo } from 'react';
import { openView } from '../../../components/OMS/OMSSlice';
import type { CareOrderBlotterEntity } from './types';
import { useCareOrderBlotterDataObs } from './useCareOrderBlotterDataObs';
import { useCareOrderColumns } from './useCareOrderColumns';
import { useCareOrderMenu } from './useCareOrderMenu';

const ENTITY_SEARCH_KEYS: (keyof CareOrderBlotterEntity)[] = ['data'];

const careOrderBlotterEntityComparator = stringColumnComparatorMultiColumn<CareOrderBlotterEntity['data']>(['Symbol']);

function getDataPath(row: CareOrderBlotterEntity): string[] {
  return row.dataPath;
}

export interface CareOrderBlotterParams {
  blotterID: string;
  initialIsOpen?: boolean;
  portalId?: string;
  defaultFilter?: BlotterTableFilter;
  defaultColumns?: (keyof CareOrder | Partial<Column>)[];
  defaultRowGroupsOpened?: RowGroupsOpenedState;
}

export function CareOrderBlotter({
  blotterID,
  portalId,
  defaultColumns,
  defaultFilter,
  defaultRowGroupsOpened,
  initialIsOpen,
}: CareOrderBlotterParams) {
  const dispatch = useAppStateDispatch();
  const mixpanel = useMixpanel();

  const defaultCareOrderColumns = useCareOrderColumns({ defaultColumns });

  const dataObservable = useCareOrderBlotterDataObs();

  const persistedBlotterTable = usePersistedBlotterTable<CareOrderBlotterEntity>(blotterID, {
    columns: defaultCareOrderColumns,
    filter: defaultFilter,
    sort: '+ag-Grid-AutoColumn',
  });

  const persistedRowGroupsOpened = usePersistedRowGroupsOpenedState(blotterID, {
    defaultRowGroupsOpened,
  });

  const careOrderMenu = useCareOrderMenu();

  const columnsWithMenu = useMemo(
    () => [...persistedBlotterTable.columns, careOrderMenu.column],
    [careOrderMenu.column, persistedBlotterTable.columns]
  );

  const handleColumnsChanged = useDynamicCallback((columns: Column[]) => {
    persistedBlotterTable.onColumnsChanged(columns);
  });

  const autoGroupColumnDef = useMemo(
    () =>
      ({
        ...baseTreeGroupColumnDef,
        valueFormatter: ({ data, context, node }: ValueFormatterParams<CareOrderBlotterEntity>) => {
          return abbreviateId(data?.rowID) ?? '';
        },
        filterValueGetter: ({ data }: ValueGetterParams<CareOrderBlotterEntity>) => {
          return data?.rowID;
        },
        headerName: 'ID',
        pinned: 'left',
        comparator: (valueA, valueB, nodeA, nodeB) => careOrderBlotterEntityComparator(nodeA.data, nodeB.data),
        width: 100,
      } satisfies GridOptions['autoGroupColumnDef']),
    []
  );

  const blotterTable = useBlotterTable<CareOrderBlotterEntity>({
    dataObservable,
    rowID: 'rowID',
    rowSelection: 'multiple',
    sort: persistedBlotterTable.initialSort,
    ...persistedBlotterTable,
    onColumnsChanged: handleColumnsChanged,
    columns: columnsWithMenu,
    getContextMenuItems: careOrderMenu.getContextMenuItems,
    quickSearchParams: {
      entitySearchKeys: ENTITY_SEARCH_KEYS,
    },
    ...persistedRowGroupsOpened.blotterTableProps,
    ...({
      showOpenedGroup: true,
      autoGroupColumnDef,
      treeData: true,
      getDataPath,
    } satisfies GridOptions),
  });

  const { expandAllGroups, collapseAllGroups } = blotterTable;

  const extrasMenuPopover = useBlotterTableExtrasMenu();

  const handleExport = useDynamicCallback(() => {
    mixpanel.track(MixpanelEvent.ExportRows);
    blotterTable.exportDataAsCSV({
      fileName: createCSVFileName({
        name: 'Care Orders',
        tabLabel: 'Care Orders',
      }),
    });
    extrasMenuPopover.close();
  });

  const handleClickNewCareOrder = useDynamicCallback(() => {
    dispatch(openView(OMSView.CareOrderForm));
  });

  return (
    <>
      <BlotterTableFilters
        portalId={portalId}
        prefix={
          <>
            <Button
              size={FormControlSizes.Small}
              variant={ButtonVariants.Primary}
              startIcon={IconName.Plus}
              onClick={handleClickNewCareOrder}
            >
              New Care Order
            </Button>
            <IconButton
              icon={IconName.ListExpand}
              size={FormControlSizes.Small}
              variant={ButtonVariants.Default}
              onClick={expandAllGroups}
              data-testid="expand-all-button"
            />
            <IconButton
              icon={IconName.ListCollapse}
              size={FormControlSizes.Small}
              variant={ButtonVariants.Default}
              onClick={collapseAllGroups}
            />
          </>
        }
        suffix={
          <BlotterTableExtrasMenu {...extrasMenuPopover}>
            <Button startIcon={IconName.DocumentUpload} size={FormControlSizes.Small} onClick={handleExport}>
              Export
            </Button>
          </BlotterTableExtrasMenu>
        }
      />
      <BlotterTable {...blotterTable} />
      {careOrderMenu.dialogs}
    </>
  );
}
