import { useMarketAccountsContext, type Column, type ColumnDef, type Transfer } from '@talos/kyoko';
import { useMemo } from 'react';

export type AdditionalTransferColumns = 'transferDescription';

export const useTransfersColumns = function useTransfersColumns(): Column[] {
  const { marketAccountsByID } = useMarketAccountsContext();
  return useMemo(
    () =>
      [
        { type: 'date', field: 'SubmitTime', title: 'Submit Time', sortable: true, sort: '-' },
        { type: 'date', field: 'TransactTime', hide: true },
        { type: 'date', field: 'Timestamp', title: 'Updated at', hide: true },
        { type: 'market', field: 'Market', title: 'Provider' },
        {
          type: 'transferDescription',
          id: 'transferDescription' satisfies AdditionalTransferColumns,
          title: 'Description',
        },
        {
          type: 'text',
          id: 'AccountType',
          field: 'OwnerAccountID',
          title: 'Account Type',
          hide: true,
          params: {
            getLabel: (value, params) => {
              return (
                marketAccountsByID?.get(params.data.OwnerAccountID)?.TypeDescription ??
                marketAccountsByID?.get(params.data.OwnerSourceAccountID)?.TypeDescription ??
                value
              );
            },
          },
          width: 150,
        },
        { type: 'account', field: 'OwnerAccountID', width: 175, hide: true },
        { type: 'text', field: 'OwnerSourceAccountID', hide: true, title: 'Account ID', width: 150 },
        {
          type: 'size',
          field: 'directionalAmount',
          title: 'Amount',
          id: 'Amount',
          params: {
            currencyField: 'Currency',
            highlightNegative: true,
          },
        },
        { type: 'text', field: 'Currency', hide: true },
        { type: 'size', field: 'FeeAmount', params: { currencyField: 'FeeCurrency' }, hide: true, title: 'Fee' },
        { type: 'text', field: 'FeeCurrency', hide: true, title: 'Fee Currency' },
        { type: 'text', field: 'MarketTransferID', hide: true },
        { type: 'text', field: 'ReferenceData', title: 'Reference' },
        { type: 'text', field: 'Description', title: 'Note' },
        { type: 'text', field: 'User', title: 'Submitter', width: 100 },
        { type: 'text', field: 'TransferID', hide: true },
        { type: 'transferStatus', field: 'Status', title: 'Status' },
        { type: 'text', field: 'MarketDescription', hide: true },
        { type: 'text', field: 'MarketTransferStatus', width: 250 },
        { id: 'json', type: 'json', hide: true },
        { type: 'textArray', field: 'TxHashes', title: 'TxHash(es)' },
        { type: 'text', field: 'ParentTransactionID', hide: true, title: 'Parent Transaction ID' },
        { type: 'text', field: 'FromAddress', hide: true },
        { type: 'text', field: 'ToAddress', hide: true },
      ] satisfies ColumnDef<Transfer>[] as Column[],
    [marketAccountsByID]
  );
};
