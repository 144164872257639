import { useCallback, useState } from 'react';
import { useGlobalToasts } from '../../providers';
import { Button, ButtonVariants, IconButton } from '../Button';
import { Box, HStack } from '../Core';
import type { EntityPageModel } from '../EntityAdminPage';
import { FormControlSizes, TextArea } from '../Form';
import { IconName } from '../Icons';
import { NotificationVariants } from '../Notification';
import { Text } from '../Text';
import { Drawer, type DrawerProps } from './Drawer';
import { DrawerContent, DrawerFooter } from './styles';

type ModifyJsonDrawerProps<T> = {
  onSave: (modifiedEntity: T) => void;
  onDelete: (selectedEntity: T) => void;
  allowDeleteEntity?: boolean;
  selectedEntity?: T;
  title?: string;
} & DrawerProps;

export function ModifyJsonDrawer<T extends EntityPageModel>({
  selectedEntity,
  allowDeleteEntity,
  onSave,
  onDelete,
  title,
  ...drawerProps
}: ModifyJsonDrawerProps<T>) {
  const { add: addToast } = useGlobalToasts();

  const [modifiedEntityString, setModifiedEntityString] = useState<string>(JSON.stringify(selectedEntity, null, 4));

  const handleDelete = useCallback(() => {
    if (window.confirm('Are you sure you want to delete this entity?')) {
      onDelete(selectedEntity!);
    }
  }, [onDelete, selectedEntity]);

  const handleOnSave = useCallback(() => {
    try {
      const modifiedEntity = JSON.parse(modifiedEntityString) as T;
      onSave(modifiedEntity);
    } catch {
      addToast({
        text: 'Failed to save entity. Please check the JSON format.',
        variant: NotificationVariants.Negative,
      });
    }
  }, [addToast, modifiedEntityString, onSave]);

  const showDeleteButton = allowDeleteEntity && selectedEntity != null;

  return (
    <Drawer {...drawerProps} data-testid="entity-admin-page-json-drawer">
      <HStack justifyContent="space-between" p="spacingMedium">
        <Text data-testid="entity-admin-page-json-drawer-title">{title}</Text>
        <IconButton size={FormControlSizes.Small} icon={IconName.Close} onClick={() => drawerProps.close()} />
      </HStack>
      <DrawerContent overflow="overlay">
        <Box>
          <TextArea value={modifiedEntityString} rows={15} onChange={e => setModifiedEntityString(e.target.value)} />
        </Box>
      </DrawerContent>
      <DrawerFooter>
        {showDeleteButton && (
          <Button
            variant={ButtonVariants.Negative}
            onClick={handleDelete}
            data-testid="modify-json-drawer-delete-button"
          >
            Delete
          </Button>
        )}
        <Button onClick={handleOnSave} variant={ButtonVariants.Primary} data-testid="modify-json-drawer-save-button">
          Save
        </Button>
      </DrawerFooter>
    </Drawer>
  );
}
