import { useRef, useState } from 'react';
import { useMixpanel } from '../../contexts';
import { MixpanelEventProperty, type MixpanelEvent } from '../../tokens';
import { IconButton } from '../Button';
import { FormControlSizes, Input } from '../Form';
import { IconName } from '../Icons';
import { Tooltip } from '../Tooltip';
import { FilterInputWrapper } from './styles';

interface FilterInputProps {
  value: string;
  alwaysExpanded?: boolean;
  onChange: (val: string) => void;
  mixpanelEvent?: MixpanelEvent;
  placeholder?: string;
}

export function FilterInput({
  value,
  alwaysExpanded = false,
  placeholder,
  onChange,
  mixpanelEvent,
  ...props
}: FilterInputProps) {
  const inputRef = useRef<HTMLInputElement>(null);
  const [expanded, setExpanded] = useState(alwaysExpanded);
  const [interacted, setInteracted] = useState(false);
  const [hasFocus, setHasFocus] = useState(false);
  const mixpanel = useMixpanel();

  return (
    <FilterInputWrapper isActive={expanded} {...props}>
      <Input
        placeholder={expanded ? placeholder : ''}
        size={FormControlSizes.Small}
        clearable
        prefix={
          <Tooltip tooltip="Filter cards by symbol" placement="bottom">
            <IconButton
              size={FormControlSizes.Small}
              ghost={true}
              icon={IconName.Search}
              style={{ marginLeft: expanded ? '-0.25rem' : '', marginRight: expanded ? '' : '-0.25rem' }}
              onClick={() => {
                setExpanded(true);
                inputRef.current?.focus();
              }}
            />
          </Tooltip>
        }
        ref={inputRef}
        value={value}
        onFocus={() => setHasFocus(true)}
        onChange={e => {
          setInteracted(true);
          onChange(e.target.value);
          !hasFocus && !alwaysExpanded && !e.target.value && setExpanded(false);
        }}
        onBlur={e => {
          setHasFocus(false);
          interacted &&
            mixpanelEvent &&
            mixpanel.track(mixpanelEvent, { [MixpanelEventProperty.Filter]: e.target.value });
          setInteracted(false);
          !alwaysExpanded && !value && setExpanded(false);
        }}
      />
    </FilterInputWrapper>
  );
}
