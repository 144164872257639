import type { TabComponentSpec } from 'components/FlexibleLayout/types';
import Orders from 'containers/Blotters/Orders';
import { Trades } from 'containers/Blotters/Trades';
import { TransfersBlotter } from 'containers/Blotters/Transfers';
import { PORTFOLIO_DOCKVIEW_PANEL_GROUPS } from '../../../portfolioDashboardLayouts';

export type OperationsComponentType = (typeof operationsComponents)[number]['type'];
export const operationsComponents = [
  {
    group: PORTFOLIO_DOCKVIEW_PANEL_GROUPS['blotters'],
    type: 'ordersBlotter',
    label: 'Orders',
    renderer: () => <Orders />,
  },
  {
    group: PORTFOLIO_DOCKVIEW_PANEL_GROUPS['blotters'],
    type: 'tradesBlotter',
    label: 'Trades',
    renderer: () => <Trades />,
  },
  {
    group: PORTFOLIO_DOCKVIEW_PANEL_GROUPS['blotters'],
    type: 'transfersBlotter',
    label: 'Transfers',
    renderer: () => <TransfersBlotter />,
  },
] as const satisfies TabComponentSpec[];
