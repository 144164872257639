import type { FilterableProperty } from '@talos/kyoko';
import { IconName, prettyName } from '@talos/kyoko';
import { DEALER_FEE_MODE_OPTIONS } from 'containers/Dealer/utils';
import { useMemo } from 'react';

/**
 * This hook creates the filter property definition for MarketFeeMode properties.
 *
 * @param overrides Any overrides to make to the defaults here (e.g. `key`, etc.)
 * @returns Filter Property definition to use for MarketFeeMode filters
 */
export function useMarketFeeModesFilter<TKey extends string = 'FeeModes', P = FilterableProperty<TKey>>(
  overrides?: { [K in keyof P]: K extends keyof FilterableProperty<TKey> ? P[K] : never } & { key?: TKey }
): FilterableProperty<TKey> {
  return useMemo(
    () => ({
      ...({
        key: 'FeeModes' as TKey,
        label: 'Fee Mode',
        field: 'FeeMode',
        icon: IconName.Cash,
        options: DEALER_FEE_MODE_OPTIONS,
        getOptionLabel: prettyName,
      } satisfies FilterableProperty<TKey>),
      ...overrides,
    }),
    [overrides]
  );
}
