// Maybe temporary, could in theory be combined with filtered orders blotter

import {
  Accordion,
  AccordionBody,
  BLOTTER_TABLE_FILTERS_CONTAINER_ID,
  BlotterDensity,
  BlotterTable,
  BlotterTablePauseButton,
  Divider,
  FilterBuilder,
  FilterBuilderClearAllButton,
  FilterBuilderToggleButton,
  FilterInput,
  HStack,
  MixpanelEvent,
  MixpanelEventProperty,
  MixpanelEventSource,
  Portal,
  SummaryLine,
  createCSVFileName,
  filterByColumnMainMenuItems,
  getOrderAmount,
  getOrderQuantity,
  isOrderComplete,
  orderStatusFilterTextToBackendStatus,
  removeEmptyFilters,
  useAccordionFilterBuilder,
  useBlotterTable,
  useMixpanel,
  useObservable,
  usePersistedBlotterTable,
  type BlotterTableFilter,
  type BlotterTableRow,
  type Order,
  type UnifiedLiquidityEnum,
} from '@talos/kyoko';
import type { GetMainMenuItemsParams } from 'ag-grid-enterprise';
import { BLOTTER_ID_OPEN_ORDERS } from 'containers/Blotters/Orders/tokens';
import { getOrderDetailsRoute } from 'containers/Routes/routes';
import { isEqual } from 'lodash';
import { useOrders } from 'providers';
import { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { map, scan, shareReplay } from 'rxjs/operators';
import { useFeatureFlag } from '../../../hooks';
import { useDisplaySettings } from '../../../providers/DisplaySettingsProvider';
import { useOrderColumns } from './Columns';
import { OrderOptions } from './OrderOptions';
import { ORDER_SEARCH_KEYS, colIDToFilterBuilderKey, useOrderFilter } from './useOrderFilter';
import { useOrderMenu } from './useOrderMenu';

interface OpenOrdersParams {
  defaultFilter: BlotterTableFilter;
}

export function OpenOrders({ defaultFilter }: OpenOrdersParams) {
  const mixpanel = useMixpanel();
  const history = useHistory();
  const { recentOrders } = useOrders();
  const liveOrders = useObservable(
    () =>
      recentOrders.pipe(
        scan((activeOrders, orders) => {
          orders.forEach(d => {
            const id = d.OrderID;
            const existingOrder = activeOrders.get(id);
            if (!isOrderComplete(d.OrdStatus)) {
              activeOrders.set(id, d);
            } else if (existingOrder && isOrderComplete(d.OrdStatus)) {
              activeOrders.delete(id);
            }
          });
          return activeOrders;
        }, new Map<string, Order>()),
        map(orders => {
          return {
            data: [...orders.values()],
            initial: true,
            type: 'Order',
          };
        }),
        shareReplay({
          bufferSize: 1,
          refCount: true,
        })
      ),
    [recentOrders]
  );
  const { homeCurrency } = useDisplaySettings();
  const [selectedOrders, setSelectedOrders] = useState<Order[]>([]);

  const columns = useOrderColumns({});

  const persisted = usePersistedBlotterTable<Order>(BLOTTER_ID_OPEN_ORDERS, {
    filter: defaultFilter,
    columns,
    sort: '-SubmitTime',
  });

  const filteredOrders = useOrderFilter({
    initialFilter: persisted.initialFilter,
    saveFilter: persisted.onFilterChanged,
    includeStatusFilter: false,
    includeIDFilter: false,
  });
  const { clientSideFilter, changeFilter, filterableProperties, initialFilterClauses } = filteredOrders;

  const handleRowSelectionChanged = useCallback((selectedRows: BlotterTableRow<Order>[]) => {
    setSelectedOrders(selectedRows.map(row => row.data));
  }, []);

  const { filterBuilder, accordion, openClause } = useAccordionFilterBuilder({
    accordionProps: {},
    filterBuilderProps: {
      initialFilterClauses,
      properties: filterableProperties,
    },
  });

  const { filterClausesByPropertyKey, filterClauses, removeAllFilterClauses } = filterBuilder;

  const getExtraMainMenuItems = useCallback(
    (params: GetMainMenuItemsParams) => {
      return filterByColumnMainMenuItems({
        params,
        colIDToFilterBuilderKey,
        openClause,
        mixpanel,
      });
    },
    [mixpanel, openClause]
  );

  const { getContextMenuItems, dialogComponents } = useOrderMenu({
    openClause,
    filterableProperties,
    generateOrderDetailsRoute: getOrderDetailsRoute,
  });

  const { showBlotterPauseButton } = useFeatureFlag();

  const blotterTable = useBlotterTable({
    dataObservable: liveOrders,
    rowID: 'OrderID',
    rowSelection: 'multiple',
    sort: persisted.initialSort,
    density: BlotterDensity.Compact,
    clientLocalFilter: clientSideFilter,
    getContextMenuItems,
    getExtraMainMenuItems,
    onRowSelectionChanged: handleRowSelectionChanged,
    onDoubleClickRow(data) {
      mixpanel.track(MixpanelEvent.OpenOrderDetails, {
        [MixpanelEventProperty.Source]: MixpanelEventSource.OrdersBlotter,
      });
      history.push(getOrderDetailsRoute({ orderID: data.OrderID, tab: 'details', type: 'principal' }));
    },
    ...persisted,
    quickSearchParams: {
      entitySearchKeys: ORDER_SEARCH_KEYS,
    },
    pauseParams: {
      showPauseButton: showBlotterPauseButton,
    },
  });

  // Forward the parts of the filter being sent to the backend
  useEffect(() => {
    changeFilter(curr => {
      const newFilter = removeEmptyFilters({
        ...curr,
        Markets: filterClausesByPropertyKey.get('Markets')?.selections,
        SelectedMarkets: filterClausesByPropertyKey.get('SelectedMarkets')?.selections,
        TradedMarkets: filterClausesByPropertyKey.get('TradedMarkets')?.selections,
        Sides: filterClausesByPropertyKey.get('Sides')?.selections,
        Symbols: filterClausesByPropertyKey.get('Symbols')?.selections,
        // Save the UI bound statuses in _ key. Save the real OrdStatuses used as Statuses
        _statuses: filterClausesByPropertyKey.get('_statuses')?.selections,
        Statuses: orderStatusFilterTextToBackendStatus(filterClausesByPropertyKey.get('_statuses')?.selections),
        Strategies: filterClausesByPropertyKey.get('Strategies')?.selections,
        SubAccounts: filterClausesByPropertyKey.get('SubAccounts')?.selections,
        Users: filterClausesByPropertyKey.get('Users')?.selections,
        Warnings: filterClausesByPropertyKey.get('Warnings')?.selections,
        ProductTypes: filterClausesByPropertyKey.get('ProductTypes')?.selections,
        UnifiedLiquidity: filterClausesByPropertyKey.get('UnifiedLiquidity')?.selections as
          | UnifiedLiquidityEnum[]
          | undefined,
      });
      if (isEqual(curr, newFilter)) {
        return curr;
      }
      return newFilter;
    });
  }, [filterClausesByPropertyKey, filteredOrders.filter, changeFilter]);

  const handleExport = useCallback(() => {
    mixpanel.track(MixpanelEvent.ExportOpenOrders);
    blotterTable.exportDataAsCSV({
      fileName: createCSVFileName({
        name: 'OpenOrders',
      }),
    });
  }, [blotterTable, mixpanel]);

  return (
    <>
      <Portal portalId={BLOTTER_TABLE_FILTERS_CONTAINER_ID}>
        <HStack justifyContent="space-between" gap="spacingSmall">
          <FilterInput
            alwaysExpanded
            value={blotterTable.blotterTableFiltersProps.quickFilterText}
            onChange={val => blotterTable.blotterTableFiltersProps.onQuickFilterTextChanged(val)}
            mixpanelEvent={MixpanelEvent.FilterQuickSearch}
          />
          <Divider orientation="vertical" m="spacingSmall" />
          <FilterBuilderClearAllButton
            removeAllFilterClauses={removeAllFilterClauses}
            disabled={filterClauses.length === 0}
          />
          <FilterBuilderToggleButton
            filterClauses={filterClauses}
            isOpen={accordion.isOpen}
            onClick={() => accordion.toggle()}
          />
          <Divider orientation="vertical" m="spacingSmall" />
          {blotterTable.pauseProps.showPauseButton && (
            <>
              <BlotterTablePauseButton {...blotterTable.pauseProps} />
              <Divider orientation="vertical" m="spacingSmall" />
            </>
          )}

          <OrderOptions
            changeFilter={changeFilter}
            filter={filteredOrders.filter}
            handleExport={handleExport}
            tabType="OpenOrders"
          />
        </HStack>
      </Portal>
      <Accordion {...accordion}>
        <AccordionBody>
          <FilterBuilder {...filterBuilder} />
        </AccordionBody>
      </Accordion>
      <BlotterTable {...blotterTable} />
      <SummaryLine
        homeCurrency={homeCurrency}
        rows={selectedOrders}
        getQuantity={getOrderQuantity}
        getAmount={getOrderAmount}
      />
      {dialogComponents}
    </>
  );
}
