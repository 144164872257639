import type { CSSProperties } from 'react';
import styled from 'styled-components';

export const Header = styled.div<{ headerOverflow?: CSSProperties['overflow'] }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: ${({ theme }) => theme.spacingMedium}px;
  padding: ${({ theme }) => `${theme.spacingMedium}px ${theme.spacingLarge}px`};
  border-bottom: solid 1px ${({ theme }) => theme.colors.gray['010']};
  position: relative;
  order: 1;
  ${({ headerOverflow }) => headerOverflow && `overflow: ${headerOverflow}`};
`;

export const Title = styled.span`
  letter-spacing: 0.05em;
  color: ${({ theme }) => theme.colorTextAttention};
`;

export const Actions = styled.div`
  display: grid;
  grid-auto-columns: 1fr;
  grid-auto-flow: column;
  width: 100%;
  padding: ${({ theme }) => `${theme.spacingSmall}px ${theme.spacingLarge}px ${theme.spacingLarge}px`};
  gap: ${({ theme }) => theme.spacingMedium}px;
  order: 3;
`;

export const Content = styled.div<{ align?: 'left' | 'right'; overflow: CSSProperties['overflow'] }>`
  max-height: 100%;
  overflow: ${({ overflow }) => overflow};
  padding: ${({ theme }) => `${theme.spacingMedium}px ${theme.spacingLarge}px`};
  ${({ align }) => align == null && 'text-align: center'};
  ${({ align }) => align === 'left' && 'text-align: left'};
  ${({ align }) => align === 'right' && 'text-align: right'};
  order: 2;
`;
