import {
  isCounterCurrency,
  isFuture,
  isInversePerp,
  isLinearPerp,
  isMultileg,
  isOrderComplete,
  isPerpetualSwap,
  isSpot,
  isUnifiedLiquidityOrder,
  toBigWithDefault,
  type Order,
  type Security,
} from '@talos/kyoko';
import Big from 'big.js';
import { useCallback } from 'react';

type OrderQuantityProps = {
  order: Order;
  /**
   * The security of the order, relevant for multileg orders or unified liquidity
   */
  orderSecurity?: Security;
  /**
   * The security of the symbol being traded, for multileg orders this would be the leg
   */
  tradedSecurity?: Security;
  legIndex?: 0 | 1;
};

export const getOrderQuantities = ({ order, legIndex, orderSecurity }: OrderQuantityProps) => {
  let cumQty = toBigWithDefault(order.CumQty, 0);
  // For completed orders, cumQty is used to avoid 99.99% for orders not being able to fill any more.
  let orderQty = !isOrderComplete(order.OrdStatus) ? toBigWithDefault(order.OrderQty, 0) : cumQty;

  // If legIndex == undefined but orderSecurity is multileg, we're looking at the total for the order
  // including any native symbols unified with the parent.
  if (isMultileg(orderSecurity) && order.legSummaryLegs != null && legIndex !== undefined) {
    const leg = order.legSummaryLegs.find(leg => leg.LegIndex === legIndex);
    // Look in legSummaryLegs if multileg to only get
    orderQty = toBigWithDefault(leg?.LeavesQty, 0).plus(leg?.CumQty || 0);
    cumQty = toBigWithDefault(leg?.CumQty, 0);
  }

  return {
    cumQty,
    orderQty,
  };
};

export const useGetMarketProportionGetter: (
  props: OrderQuantityProps
) => (market: Order['Markets'][number]) => Big | undefined = ({ order, orderSecurity, tradedSecurity, legIndex }) => {
  const isCcy = tradedSecurity && !isMultileg(tradedSecurity) && isCounterCurrency(order.Currency, tradedSecurity);
  const isQtySpecifiedInContract = (isPerpetualSwap(tradedSecurity) || isFuture(tradedSecurity)) && !order.Currency;
  const isOrderUnifiedLiquidity = isUnifiedLiquidityOrder(order);
  const { orderQty } = getOrderQuantities({ order, orderSecurity, legIndex });

  return useCallback(
    market => {
      if (tradedSecurity == null || orderQty.eq(0)) {
        return Big(0);
      }

      if (isMultileg(orderSecurity)) {
        if (legIndex === undefined) {
          if (order.legSummaryParent?.find(leg => leg.Symbol === market.Symbol)) {
            return toBigWithDefault(market.CumQty, 0).div(orderQty);
          } else {
            return undefined;
          }
        } else {
          const legSummaryCumQty = toBigWithDefault(
            order.legSummaryLegs?.find(leg => leg.LegIndex === legIndex)?.CumQty,
            0
          );
          if (legSummaryCumQty.eq(0)) {
            // if there is no leg cumqty, we cannot know the proportion.
            return Big(0);
          }
          return toBigWithDefault(market.CumQty, 0).div(legSummaryCumQty);
        }
      }

      if (isSpot(tradedSecurity)) {
        if (isCcy) {
          return toBigWithDefault(market.CumAmt, 0).div(orderQty);
        } else {
          return toBigWithDefault(market.CumQty, 0).div(orderQty);
        }
      }
      if (isPerpetualSwap(tradedSecurity)) {
        if (isLinearPerp(tradedSecurity)) {
          if (isQtySpecifiedInContract) {
            return toBigWithDefault(market.CumQty, 0)
              .div(orderQty)
              .div(isOrderUnifiedLiquidity ? tradedSecurity?.NotionalMultiplier || 1 : 1);
          }
          if (isCcy) {
            return toBigWithDefault(market.CumAmt, 0).div(orderQty);
          }
          return toBigWithDefault(market.CumQty, 0)
            .mul(isOrderUnifiedLiquidity ? 1 : tradedSecurity.NotionalMultiplier || 1)
            .div(orderQty);
        } else if (isInversePerp(tradedSecurity)) {
          if (isQtySpecifiedInContract) {
            return toBigWithDefault(market.CumQty, 0)
              .div(orderQty)
              .div(isOrderUnifiedLiquidity ? tradedSecurity?.NotionalMultiplier || 1 : 1);
          }
          if (isCcy) {
            return toBigWithDefault(market.CumQty, 0)
              .mul(isOrderUnifiedLiquidity ? 1 : tradedSecurity.NotionalMultiplier || 1)
              .div(orderQty);
          }
          if (!market.AvgPx || Big(market.AvgPx ?? 0).eq(0)) {
            return Big(0);
          }

          return toBigWithDefault(market.CumQty, 0)
            .mul(isOrderUnifiedLiquidity ? 1 : tradedSecurity.NotionalMultiplier || 1)
            .div(market.AvgPx)
            .div(orderQty);
        }
      }

      return toBigWithDefault(market.CumQty, 0).div(orderQty);
    },
    [tradedSecurity, orderQty, orderSecurity, legIndex, order, isCcy, isQtySpecifiedInContract, isOrderUnifiedLiquidity]
  );
};
