import {
  FieldValidationLevel,
  MultiSelectorField,
  OptionTypeEnum,
  ProductTypeEnum,
  SelectorField,
  formattedUTCDate,
  toBigWithDefault,
  type Security,
} from '@talos/kyoko';
import { immerable } from 'immer';
import type { OMSReferenceDataState } from '../../types';
import type { StringSelectItem } from './utils';

export const OPTION_SIDES: StringSelectItem[] = [
  { value: OptionTypeEnum.Call, label: 'Call' },
  { value: OptionTypeEnum.Put, label: 'Put' },
];

interface OptionData {
  coinField: SelectorField<CoinSelectItem>;
  exchangeField: SelectorField<StringSelectItem>;
  marketAccountField: MultiSelectorField<StringSelectItem>;
  expiryField: SelectorField<StringSelectItem>;
  strikeField: SelectorField<StringSelectItem>;
  typeField: SelectorField<StringSelectItem>;
}

// https://talostrading.atlassian.net/browse/UI-3065
export interface CoinSelectItem extends StringSelectItem {
  exchange: string;
  // we need to keep track of the actual coin, e.g. 'BTC' since this is the value we build our context lookup maps on
  underlyingCoin: string;
  // metadata used in multileg combo to filter out un-eligible symbols
  baseCurrency: string;
  quoteCurrency: string;
}

const getCoinSelectItem = (security: Security): CoinSelectItem => {
  const exchange = security?.Markets?.[0] || '';
  const displayValue = security?.UnderlyingCode ?? security.BaseCurrency;
  if (!displayValue) {
    throw new Error(`${security.Symbol} is not an Option`);
  }

  return {
    value: displayValue,
    label: displayValue,
    exchange,
    underlyingCoin: security?.UnderlyingCode ?? security.BaseCurrency,
    baseCurrency: security?.BaseCurrency,
    quoteCurrency: security?.QuoteCurrency,
  };
};

export const OPTION_DATE_FORMAT = '{dd}{Mon}{yy}';

// We need proper abstraction & encapsulation here in order to scale to multileg options later on
// We don't want to be dealing with arrays of raw fields directly in the order slice
export class Option {
  public static readonly type = ProductTypeEnum.Option;
  private readonly _data: OptionData;

  private constructor(private _referenceData: OMSReferenceDataState, data: OptionData) {
    this._data = data;
    this._data.typeField = this._data.typeField.validate([
      field => {
        if (!field.isDisabled && !this.security) {
          return {
            message: `Not available`,
            level: FieldValidationLevel.Error,
          };
        }
        return null;
      },
    ]);
  }

  public static createFromBlank(referenceData: OMSReferenceDataState) {
    const availableExchanges = Option.getAvailableExchanges(referenceData);
    const availableCoins = Option.getAvailableCoins(referenceData);

    const data = {
      exchangeField: new SelectorField<StringSelectItem>({
        name: 'Exchange',
        idProperty: 'value',
        availableItems: availableExchanges,
      }),
      marketAccountField: new MultiSelectorField<StringSelectItem>({
        name: 'Market Account',
        idProperty: 'value',
      }),
      coinField: new SelectorField<CoinSelectItem>({
        name: 'Coin',
        idProperty: 'value',
        availableItems: availableCoins,
      }),
      expiryField: new SelectorField<StringSelectItem>({
        name: 'Expiry',
        idProperty: 'value',
        isDisabled: true,
      }),
      strikeField: new SelectorField<StringSelectItem>({
        name: 'Strike',
        idProperty: 'value',
        isDisabled: true,
      }),
      typeField: new SelectorField<StringSelectItem>({
        name: 'Type',
        idProperty: 'value',
        isDisabled: true,
        availableItems: OPTION_SIDES,
        value: OPTION_SIDES[0],
      }),
    };

    return new Option(referenceData, data);
  }

  public static createFromSecurity(referenceData: OMSReferenceDataState, security: Security): Option {
    const coin = getCoinSelectItem(security);
    const market = security?.Markets?.[0];
    const expiration = security.Expiration;
    const strike = security.StrikePrice;

    if (!coin || !market || !expiration || !strike) {
      throw new Error(`${security.Symbol} is not an Option`);
    }

    const availableCoins = Option.getCoinsForExchange(referenceData, market);
    const availableExchanges: StringSelectItem[] = Option.getExchangesForCoin(referenceData, coin);
    const availableMarketAccounts = Option.getMarketAccountsForExchange(referenceData, market);
    const availableExpiries: StringSelectItem[] = Option.getExpiriesForExchange(referenceData, coin, market);
    const availableStrikes: StringSelectItem[] = Option.getStrikesForOption(referenceData, coin, market, expiration);

    const data = {
      exchangeField: new SelectorField({
        name: 'Exchange',
        idProperty: 'value',
        value: availableExchanges.find(ex => ex.value === market),
        availableItems: availableExchanges,
      }),
      marketAccountField: new MultiSelectorField<StringSelectItem>({
        name: 'Market Account',
        idProperty: 'value',
        availableItems: availableMarketAccounts,
      }),
      coinField: new SelectorField({
        name: 'Coin',
        idProperty: 'value',
        value: availableCoins.find(c => c.value === coin.value),
        availableItems: availableCoins,
      }),
      expiryField: new SelectorField({
        name: 'Expiry',
        idProperty: 'value',
        value: availableExpiries.find(expiry => expiry.value === expiration),
        availableItems: availableExpiries,
      }),
      strikeField: new SelectorField({
        name: 'Strike',
        idProperty: 'value',
        value: availableStrikes.find(s => s.value === strike),
        availableItems: availableStrikes,
      }),
      typeField: new SelectorField({
        name: 'Call/Put',
        idProperty: 'value',
        availableItems: OPTION_SIDES,
        value: OPTION_SIDES.find(s => s.value === security.OptionType),
      }),
    };

    return new Option(referenceData, data);
  }

  public get data(): OptionData {
    return this._data;
  }

  public get security(): Security | undefined {
    const { coinField, exchangeField, strikeField, expiryField, typeField } = this.data;
    if (
      !coinField.hasValue ||
      !exchangeField.hasValue ||
      !expiryField.hasValue ||
      !strikeField.hasValue ||
      !typeField.hasValue
    ) {
      return undefined;
    }

    return this._referenceData.options.options.find(option => {
      const coin = option.UnderlyingCode ?? option.BaseCurrency;
      return (
        coin === coinField.value!.value &&
        option.Markets?.includes(exchangeField.value!.value) &&
        option.Expiration === expiryField.value!.value &&
        option.StrikePrice === strikeField.value!.value &&
        option.OptionType === typeField.value!.value
      );
    });
  }

  public updateCoin = (coin: CoinSelectItem | undefined) => {
    const newData = {
      ...this._data,
      coinField: this._data.coinField.updateValue(coin),
      exchangeField: this._data.exchangeField.updateAvailableItems(
        coin ? Option.getExchangesForCoin(this._referenceData, coin) : Option.getAvailableExchanges(this._referenceData)
      ),
    };
    return this.updateData(newData);
  };

  public updateExchange = (exchange: StringSelectItem | undefined) => {
    const newData = {
      ...this._data,
      exchangeField: this._data.exchangeField.updateValue(exchange),
      coinField: this._data.coinField.updateAvailableItems(
        exchange
          ? Option.getCoinsForExchange(this._referenceData, exchange.value)
          : Option.getAvailableCoins(this._referenceData)
      ),
    };
    return this.updateData(newData);
  };

  public updateMarketAccount = (marketAccount: StringSelectItem[] | undefined) => {
    const newData = {
      ...this._data,
      marketAccountField: this._data.marketAccountField.updateValue(marketAccount),
    };
    return this.updateData(newData);
  };

  public updateExpiry = (expiry?: StringSelectItem) => {
    const newData = {
      ...this._data,
      expiryField: this._data.expiryField.updateValue(expiry),
    };
    return this.updateData(newData);
  };

  public updateStrike = (strike?: StringSelectItem) => {
    const newData = {
      ...this._data,
      strikeField: this._data.strikeField.updateValue(strike),
    };
    return this.updateData(newData);
  };

  public updateType = (type?: StringSelectItem) => {
    const newData = {
      ...this._data,
      typeField: this._data.typeField.updateValue(type),
    };
    return this.updateData(newData);
  };

  public getType(): ProductTypeEnum {
    return Option.type;
  }

  private updateData(data: Partial<OptionData>): Option {
    const newData = {
      ...this._data,
      ...data,
    };
    return new Option(this._referenceData, this.invariant(newData));
  }

  private invariant(data: OptionData): OptionData {
    const updated = {
      ...data,
      // start disabling all, enable based on how far validation can go
      expiryField: data.expiryField.setDisabled(true),
      strikeField: data.strikeField.setDisabled(true),
      typeField: data.typeField.setDisabled(true),
    };

    if (updated.exchangeField.hasValue && updated.coinField.hasValue) {
      updated.expiryField = updated.expiryField
        .setDisabled(false)
        .updateAvailableItems(
          Option.getExpiriesForExchange(
            this._referenceData,
            updated.coinField.value!,
            updated.exchangeField.value!.value
          )
        );
      updated.marketAccountField = updated.marketAccountField
        .setDisabled(false)
        .updateAvailableItems(
          Option.getMarketAccountsForExchange(this._referenceData, updated.exchangeField.value!.value)
        );
    }

    if (updated.exchangeField.hasValue && updated.coinField.hasValue && updated.expiryField.hasValue) {
      updated.strikeField = updated.strikeField
        .setDisabled(false)
        .updateAvailableItems(
          Option.getStrikesForOption(
            this._referenceData,
            updated.coinField.value!,
            updated.exchangeField.value!.value,
            updated.expiryField.value!.value
          )
        );
      updated.typeField = updated.typeField.setDisabled(false);
    }
    return updated;
  }

  private static getAvailableExchanges(referenceData: OMSReferenceDataState): StringSelectItem[] {
    const options = referenceData.options.options;
    const markets = options.map(o => o.Markets || []).flat();

    return Array.from(new Set(markets)).map(ex => ({
      label: referenceData.markets.marketsByName.get(ex)?.DisplayName || ex,
      value: ex,
    }));
  }

  private static getAvailableCoins(referenceData: OMSReferenceDataState): CoinSelectItem[] {
    const coins = referenceData.options.options.reduce((acc, option) => {
      const coinSelectItem = getCoinSelectItem(option);
      return acc.set(coinSelectItem.value, coinSelectItem);
    }, new Map());

    return Array.from(coins.values());
  }

  private static getCoinsForExchange(referenceData: OMSReferenceDataState, exchange: string): CoinSelectItem[] {
    if (!exchange) {
      return Option.getAvailableCoins(referenceData);
    }
    const options = referenceData.options.options || [];
    const relevantCoins = options.filter(o => o.Markets?.[0] === exchange);

    const coins = relevantCoins.reduce((acc, option) => {
      const coinSelectItem = getCoinSelectItem(option);
      return acc.set(coinSelectItem.value, coinSelectItem);
    }, new Map());

    return Array.from(coins.values());
  }

  private static getExchangesForCoin(referenceData: OMSReferenceDataState, coin: CoinSelectItem): StringSelectItem[] {
    if (!coin) {
      return Option.getAvailableExchanges(referenceData);
    }

    const expirationByMarket = referenceData.options.expirationByMarketByCurrencyIdentity.get(coin.underlyingCoin)!;
    const allCoins = referenceData.options.options.map(getCoinSelectItem);

    return Array.from(expirationByMarket.keys())
      .filter(exchange => {
        const exchangesSupportingCoin = allCoins.filter(c => c.value === coin.value).map(c => c.exchange);
        return exchangesSupportingCoin.includes(exchange);
      })
      .map(ex => ({
        label: referenceData.markets.marketsByName.get(ex)?.DisplayName || ex,
        value: ex,
      }));
  }

  private static getExpiriesForExchange(
    referenceData: OMSReferenceDataState,
    coin: CoinSelectItem,
    market: string
  ): StringSelectItem[] {
    const expirationByMarket = referenceData.options.expirationByMarketByCurrencyIdentity.get(coin.underlyingCoin)!;
    const expirations = expirationByMarket?.get(market) || new Set();

    return Array.from(expirations.values())
      .sort((a, b) => new Date(a).valueOf() - new Date(b).valueOf())
      .map(ex => ({
        label: formattedUTCDate(new Date(ex), OPTION_DATE_FORMAT),
        value: ex,
      }));
  }

  private static getMarketAccountsForExchange(
    referenceData: OMSReferenceDataState,
    market: string
  ): StringSelectItem[] {
    const marketAccounts = referenceData.marketAccounts.marketAccountsByMarket.get(market) || [];

    return marketAccounts.map(ma => ({
      label: ma.DisplayName,
      value: ma.Name,
    }));
  }

  private static getStrikesForOption(
    referenceData: OMSReferenceDataState,
    coin: CoinSelectItem,
    market: string,
    expiration: string
  ): StringSelectItem[] {
    const strikesByKey = referenceData.options.strikesByOptionKey!;
    const optionKey = `${coin.underlyingCoin}/${market}/${expiration}`;

    const uniqueStrikes = new Set(strikesByKey.get(optionKey));
    return (
      Array.from(uniqueStrikes.values())
        .sort((a, b) => toBigWithDefault(a, 0).cmp(b))
        .map(strike => ({ value: strike, label: toBigWithDefault(strike, 0).toFixed() })) || []
    );
  }
}

Option[immerable] = true;
