import {
  Box,
  ExpandableBottomPanel,
  HStack,
  Tab,
  TabAppearance,
  TabList,
  Tabs,
  TabSize,
  useDynamicCallback,
  useExpandablePanel,
  usePortal,
  useTabs,
  VStack,
} from '@talos/kyoko';
import { useState } from 'react';
import { ErrorBoundary } from '../../../../components/ErrorBoundary';
import { TransfersBlotter } from '../../../Blotters/Transfers';
import { Module } from '../../components/Module';
import { BlotterWrapper, XScrollableContainer } from '../../styles';
import { TreasuryManagementBlotterContainer } from '../Blotter';
import { D3ChartWrapper } from '../Chart/D3ChartWrapper';
import { HistoricalValueChart } from '../HistoricalValueChart';
import { useTreasuryManagementContext } from '../providers/TreasuryManagementStateAndTabsProvider';
import { CHART_HEADER_SUFFIX_PORTAL_ID } from '../types';

const TABS = [
  {
    label: 'Treasury',
    render: () => <TreasuryManagementBlotterContainer />,
  },
  {
    label: 'Transfers',
    render: () => <TransfersBlotter showNewTransfer={false} />,
  },
];

const DEFAULT_BLOTTER_HEIGHT = 450;
const TREASURY_MANAGEMENT_BLOTTER_HEIGHT = 'TreasuryManagementBlotterHeight';

export function BottomTabContent() {
  const [isBlotterMaximized, setIsBlotterMaximized] = useState(false);
  const [isBlotterMinimized, setIsBlotterMinimized] = useState(false);

  const handleMaximizeBlotter = useDynamicCallback((shouldExpand: boolean) => {
    setIsBlotterMinimized(false);
    setIsBlotterMaximized(curr => shouldExpand ?? !curr);
  });

  const handleMinimizeBlotter = useDynamicCallback((shouldMinimize: boolean) => {
    setIsBlotterMaximized(false);
    setIsBlotterMinimized(curr => shouldMinimize ?? !curr);
  });

  const tabs = useTabs({
    initialSelectedIndex: 0,
    initialItems: TABS,
  });

  const { containerRef, ...panelProps } = useExpandablePanel<HTMLDivElement>({
    initialHeight: parseInt(localStorage.getItem(TREASURY_MANAGEMENT_BLOTTER_HEIGHT) ?? '0') || DEFAULT_BLOTTER_HEIGHT,
    isExpanded: isBlotterMaximized,
    isMinimized: isBlotterMinimized,
    onToggleExpanded: handleMaximizeBlotter,
    onToggleMinimize: handleMinimizeBlotter,
    onAdjustedHeight(newHeight) {
      localStorage.setItem(TREASURY_MANAGEMENT_BLOTTER_HEIGHT, newHeight.toString());
    },
  });

  const {
    state: { showBy },
  } = useTreasuryManagementContext();

  const { setPortalRef: chartHeaderSuffixRef } = usePortal(CHART_HEADER_SUFFIX_PORTAL_ID);

  return (
    <VStack w="100%" h="100%" overflow="hidden" position="relative" ref={containerRef}>
      <XScrollableContainer w="100%" flex="1 1 0%">
        <HStack minHeight="400px" w="100%" h="100%" minWidth="1000px" gap="spacingTiny">
          <Module
            flex="1 1 45%"
            h="100%"
            minWidth="450px"
            maxWidth="650px"
            title={`Balances by ${showBy === 'counterparty' ? 'Counterparty' : 'Asset'}`}
          >
            <D3ChartWrapper px="spacingComfortable" />
          </Module>
          <Module flex="1 10 55%" h="100%" title="Historical Balances" suffix={<Box ref={chartHeaderSuffixRef} />}>
            {/* The 50px right padding is here to emulate the non-deterministic left "spacing" of the pie chart*/}
            <HistoricalValueChart maxHeight="600px" py="spacingComfortable" px="spacingLarge" />
          </Module>
        </HStack>
      </XScrollableContainer>
      <BlotterWrapper>
        <ExpandableBottomPanel
          {...panelProps}
          showControls
          header={
            <Tabs {...tabs} appearance={TabAppearance.Filled} size={TabSize.Small}>
              <TabList>
                {TABS.map((tab, i) => (
                  <Tab key={i} {...tab} />
                ))}
              </TabList>
            </Tabs>
          }
        >
          {TABS.map(
            (tab, i) => tabs.selectedIndex === i && <ErrorBoundary key={`component-${i}`}>{tab.render()}</ErrorBoundary>
          )}
        </ExpandableBottomPanel>
      </BlotterWrapper>
    </VStack>
  );
}
