import { useCallback, useState } from 'react';

import {
  Button,
  ButtonVariants,
  CustomerBalanceTransactionStatusEnum,
  Dialog,
  FormGroup,
  HStack,
  Input,
  Portal,
  Text,
  Tooltip,
  VStack,
  useDisclosure,
  type CustomerTransaction,
} from '@talos/kyoko';

import { useCustomersContext } from 'hooks/useCustomer';

import { Divider } from './styles';

interface ManualTransferFormValues {
  txID: string;
}

interface ManualTransferFormProps {
  approveDisabledTooltip?: string;
  entity: CustomerTransaction;
  isApproveDisabled: boolean;
  isDisabled?: boolean;
  onFailedRequest: (e: ErrorEvent, errorDescription: string) => void;
  portalId: string;
}

export function ManualTransferForm({
  approveDisabledTooltip,
  entity,
  isApproveDisabled,
  isDisabled = false,
  onFailedRequest,
  portalId,
}: ManualTransferFormProps) {
  const confirmTransferDialog = useDisclosure();
  const { open: handleConfirmTransfer } = confirmTransferDialog;
  const [touched, setTouched] = useState<Record<string, boolean>>({});
  const [form, setForm] = useState<ManualTransferFormValues>({
    txID: '',
  });
  const { txID } = form;

  const handleFormUpdate = useCallback(
    (update: Partial<ManualTransferFormValues>) => {
      for (const key of Object.keys(update)) {
        setTouched(prev => ({ ...prev, [key]: true }));
      }
      setForm(prev => ({ ...prev, ...update }));
    },
    [setForm]
  );

  const { approvePendingTransferTransaction, rejectPendingTransferTransaction } = useCustomersContext();

  const handleApproveManualTransferTransaction = useCallback(() => {
    if (entity == null) {
      return;
    }
    approvePendingTransferTransaction(entity, txID?.length ? txID : undefined).catch(e =>
      onFailedRequest(e, 'Could not approve pending transfer transaction.')
    );
  }, [approvePendingTransferTransaction, entity, onFailedRequest, txID]);

  const handleApproveTransfer = useCallback(() => {
    if (entity == null) {
      return;
    }
    if (entity.Status === CustomerBalanceTransactionStatusEnum.PendingTransfer) {
      handleApproveManualTransferTransaction();
    }
  }, [entity, handleApproveManualTransferTransaction]);

  const handleRejectTransfer = useCallback(() => {
    if (entity == null) {
      return;
    }
    if (entity.Status === CustomerBalanceTransactionStatusEnum.PendingTransfer) {
      rejectPendingTransferTransaction(entity);
    }
  }, [entity, rejectPendingTransferTransaction]);

  return (
    <VStack gap="spacingMedium" w="100%">
      <Portal portalId={portalId}>
        <>
          <Divider />
          <HStack gap="spacingMedium" p="spacingMedium" w="100%">
            <Button disabled={isDisabled} onClick={handleRejectTransfer} variant={ButtonVariants.Negative} width="100%">
              Reject
            </Button>
            <Tooltip tooltip={approveDisabledTooltip} targetStyle={{ width: '100%' }}>
              <Button
                disabled={isApproveDisabled || isDisabled}
                onClick={handleConfirmTransfer}
                variant={ButtonVariants.Primary}
                width="100%"
              >
                Confirm Transfer
              </Button>
            </Tooltip>
          </HStack>
        </>
      </Portal>
      <FormGroup label="Transaction ID" w="100%">
        <Input
          autoComplete="off"
          disabled={isDisabled}
          touched={!!touched.txID}
          id="txID"
          onChange={e => handleFormUpdate({ txID: e.target.value })}
          value={form.txID}
        />
      </FormGroup>
      <Dialog {...confirmTransferDialog} onConfirm={handleApproveTransfer} title="Confirm Transfer Action">
        {txID ? (
          <Text>You are about to approve this transaction. Are you sure you want to proceed?</Text>
        ) : (
          <Text>Transfer confirmed without specifying transaction hash. Are you sure you want to proceed?</Text>
        )}
      </Dialog>
    </VStack>
  );
}
