import {
  getCurrencyColor,
  useBehaviorSubject,
  useCurrenciesContext,
  useObservableValue,
  useSecuritiesContext,
  wsScanToMap,
  type MinimalSubscriptionResponse,
} from '@talos/kyoko';

import { ChartMessagingProvider } from 'containers/Portfolio/providers/ChartMessagingProvider';
import { useMemo } from 'react';
import { map } from 'rxjs';
import styled from 'styled-components';
import { usePortfolioManagementProvider } from '../../providers/PortfolioManagementProvider';
import { usePortfolioViewStateSelector } from '../../stateManagement/portfolioViewLayoutSlice.hooks';
import type { PortfolioRiskGridData } from '../../types/PortfolioRiskGridData';
import { InnerStackedChart, type StackedChartDataItem } from './InnerStackedChart';

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  gap: ${({ theme }) => theme.spacingDefault}px;
  background: ${({ theme }) => theme.backgroundContent};
`;

export function LongShortStackedChart() {
  const { currenciesBySymbol } = useCurrenciesContext();
  const { securitiesBySymbol } = useSecuritiesContext();
  const { overviewChartDisplayType } = usePortfolioViewStateSelector();
  const { batchedRiskData, isDataLoading } = usePortfolioManagementProvider();
  const { observable: riskSubAccountObs } = useBehaviorSubject(
    () => batchedRiskData ?? ({} as MinimalSubscriptionResponse<PortfolioRiskGridData>),
    [batchedRiskData]
  );

  const positionData = useObservableValue(
    () =>
      riskSubAccountObs.pipe(
        wsScanToMap({
          getUniqueKey: ({ rowID }) => rowID,
          newMapEachUpdate: false,
        }),
        map(m => [...m.values()])
      ),
    [riskSubAccountObs]
  );

  const dataToChart = useMemo(() => {
    const assetsToMap: Array<{ direction: '+' | '-'; asset: string; position: number; colorField: string }> = [];
    const assetsToDisplay = (positionData ?? []).filter(item => !item.skipAggregation);
    if (overviewChartDisplayType === 'byUnderlier') {
      type DirectionalAsset = `${'+' | '-'}${string}`;

      const aggregatedAssets = assetsToDisplay.reduce(
        (result, next) => {
          const groupingAsset = next.ExposureGroupAsset;
          const position = next.Equivalent.Position ? parseFloat(next.Equivalent.Position) : 0;
          const colorField = next.ExposureGroupAsset;

          const direction = position > 0 ? '+' : '-';
          const key: DirectionalAsset = `${direction}${groupingAsset}`;
          const current = result.get(key);
          result.set(key, {
            direction,
            asset: groupingAsset,
            position: current ? current.position + position : position,
            colorField,
          });
          return result;
        },
        new Map<
          DirectionalAsset,
          {
            direction: '+' | '-';
            asset: string;
            position: number;
            colorField: string;
          }
        >()
      );
      assetsToMap.push(...aggregatedAssets.values());
    } else {
      assetsToDisplay.forEach(p => {
        const asset = p.Asset;
        const position = Number(p.Equivalent.Position);
        const colorField = p.ExposureGroupAsset;
        assetsToMap.push({
          direction: position > 0 ? '+' : '-',
          asset,
          position,
          colorField,
        });
      });
    }

    const longShortAssets: StackedChartDataItem[] = assetsToMap.map(p => {
      const currency = currenciesBySymbol.get(p.asset);
      const security = securitiesBySymbol.get(p.asset);
      const assetName = security?.DisplaySymbol ?? currency?.Symbol ?? p.asset;
      const color = getCurrencyColor(p.colorField ?? p.asset);

      return {
        asset: assetName,
        value: p.position,
        color: color,
      };
    });
    return longShortAssets;
  }, [currenciesBySymbol, overviewChartDisplayType, positionData, securitiesBySymbol]);

  return (
    <Wrapper>
      <ChartMessagingProvider>
        <InnerStackedChart isDataLoading={isDataLoading} data={dataToChart} />
      </ChartMessagingProvider>
    </Wrapper>
  );
}
