import {
  Dialog,
  Divider,
  HStack,
  ICON_SIZES,
  Icon,
  IconName,
  MarketAccountStatusEnum,
  Text,
  VStack,
  type DialogProps,
} from '@talos/kyoko';
import { useTheme } from 'styled-components';
import type { TradingControlsBlotterEntity } from '../types';
import { SelectedMarketAccountsList } from './SelectedMarketAccountsList';

export const EnableDisableSelectedDialog = <T extends TradingControlsBlotterEntity>({
  selectedItems,
  enableDisableDialog,
  onConfirm,
  mode,
}: {
  selectedItems: T[];
  enableDisableDialog: DialogProps;
  onConfirm: (marketAccounts: T[]) => void;
  mode: MarketAccountStatusEnum;
}) => {
  const theme = useTheme();
  if (selectedItems.length === 0) {
    return null;
  }
  const label = mode === MarketAccountStatusEnum.Active ? 'Enable' : 'Disable';
  const titleText = `${label} Trading for ${selectedItems.length} Market ${
    selectedItems.length > 1 ? 'Accounts' : 'Account'
  }`;
  const description = `Are you sure you want to ${label.toLowerCase()} Trading for ${selectedItems.length} Market ${
    selectedItems.length > 1 ? 'Accounts' : 'Account'
  }?`;

  return (
    <Dialog
      {...enableDisableDialog}
      onConfirm={() => {
        onConfirm(selectedItems);
      }}
      confirmLabel="Confirm"
      cancelLabel="Return"
      title={
        <VStack alignItems="flex-start" gap="spacingSmall">
          <HStack gap="spacingComfortable">
            <Icon
              size={ICON_SIZES.MEDIUM}
              icon={mode === MarketAccountStatusEnum.Disabled ? IconName.ExclamationSolid : IconName.CheckCircleSolid}
              color={mode === MarketAccountStatusEnum.Disabled ? theme.colorTextWarning : theme.colorTextPositive}
            />
            {titleText}
          </HStack>
        </VStack>
      }
    >
      <VStack gap="spacingDefault" alignItems="stretch">
        <Text textAlign="left" color="colorTextAttention">
          {mode === MarketAccountStatusEnum.Disabled && (
            <span>
              Disabling a market account will not cancel orders using this account.
              <br />
              Open market orders will be cancelled and no new market orders will be sent while disabled.
              <br />
            </span>
          )}
          {description}
        </Text>
        <Divider />
        <SelectedMarketAccountsList marketAccounts={selectedItems} mode={mode} />
      </VStack>
    </Dialog>
  );
};
