import { get, keys, uniq } from 'lodash';
import { useCallback, useState } from 'react';
import { ReplaySubject } from 'rxjs';
import { useEndpointsContext } from '../../contexts';
import { useEffectOnce } from '../../hooks';
import { useConstant } from '../../hooks/useConstant';
import { useObservable } from '../../hooks/useObservable';
import { useGlobalToasts } from '../../providers';
import { EMPTY_ARRAY } from '../../utils';
import { request as sendRequest } from '../../utils/http';
import { NotificationVariants } from '../Notification';
import type { Column } from './columns';
import type { BlotterTableProps, UseBlotterTableProps } from './types';
import { useBlotterTable } from './useBlotterTable';

interface RestBlotterRequest {
  path: string;
  filters?: any;
  sortBy?: string;
  limit?: number;
  next?: string;
}

export type UseRestBlotterTableProps<R> = Pick<
  UseBlotterTableProps<R>,
  'rowHeight' | 'flashRows' | 'renderEmpty' | 'density' | 'pipe' | 'quickSearchParams' | 'onDoubleClickRow'
> & {
  rowID: string;
  request: RestBlotterRequest;
  startingColumns?: Column[]; // Columns to be added at the start of the inferred columns
  columns?: Column[]; // If not provided, columns will be inferred from the response.
  endingColumns?: Column[]; // Columns to be added at the end of the inferred columns.
  onColumnsReady?: (columns: Column[]) => void;
};

const columnKeyToTypeMap: Record<string, Column['type']> = {
  Symbol: 'security',
  Timestamp: 'date',
  Mode: 'mode',
  Counterparty: 'counterparty',
};

const getColumnType = (key: string): Column['type'] => {
  return get(columnKeyToTypeMap, key) ?? 'text';
};

export function useRestBlotterTable<R>({
  request,
  rowID,
  columns,
  startingColumns = EMPTY_ARRAY,
  endingColumns = EMPTY_ARRAY,
  pipe,
  onColumnsReady,
  ...props
}: UseRestBlotterTableProps<R>): BlotterTableProps & {
  // Refreshes the Rest API call. If force is true, it will clear the blotter AND make a new request.
  // We need to clear the blotter when deleting a row, for example.
  refresh: (force?: boolean) => void;
} {
  const { orgApiEndpoint } = useEndpointsContext();
  const { add: addToast, remove: removeToast } = useGlobalToasts();
  interface DataSubject {
    data: R[];
    initial?: boolean;
    type?: string;
  }

  const dataSubject = useConstant(new ReplaySubject<DataSubject>(1));
  const dataObservable = useObservable(() => dataSubject.asObservable(), [dataSubject]);

  const pipedSubscription = useObservable(
    () => dataObservable.pipe(pipe ?? (source => source)),
    [dataObservable, pipe]
  );

  const [activeRequest, setActiveRequest] = useState(request);
  const [activeColumns, setActiveColumns] = useState<Column[]>(
    columns ? [...startingColumns, ...columns, ...endingColumns] : EMPTY_ARRAY
  );

  const setActiveColumnsFromResponseArray = useCallback(
    (data: R[]) => {
      const uniqueFieldColumns: Column[] = [];
      startingColumns.forEach(column => {
        uniqueFieldColumns.push(column);
      });
      uniq(data.flatMap(item => keys(item)))
        .map<Column>(key => ({
          type: getColumnType(key),
          field: key,
          width: 120,
        }))
        .forEach(column => {
          uniqueFieldColumns.push(column);
        });
      endingColumns.forEach(column => {
        uniqueFieldColumns.push(column);
      });
      setActiveColumns(uniqueFieldColumns);
      onColumnsReady?.(uniqueFieldColumns);
    },
    [endingColumns, onColumnsReady, startingColumns]
  );

  const queryApi = useCallback(
    req => {
      const query = {
        ...req.filters,
      };
      if (req.limit) {
        query.limit = req.limit;
      }
      if (req.next) {
        query.after = req.next;
      }
      if (req.sortBy) {
        query.orderBy = req.sortBy;
      }
      const queryString = new URLSearchParams(query).toString();
      const url = queryString ? `${orgApiEndpoint}${req.path}?${queryString}` : `${orgApiEndpoint}${req.path}`;
      return sendRequest<DataSubject>('GET', url, null, { paginateRecords: 10000 })
        .then(response => {
          if (activeColumns === EMPTY_ARRAY) {
            setActiveColumnsFromResponseArray(response.data);
          }
          dataSubject.next(response);
        })
        .catch(err => {
          removeToast('rest-blotter-table-error');
          addToast({
            text: err?.toString() ?? 'Check path: Could not fetch.',
            variant: NotificationVariants.Negative,
            id: 'rest-blotter-table-error',
          });
          dataSubject.next({ data: [], initial: true });
        });
    },
    [orgApiEndpoint, activeColumns, dataSubject, setActiveColumnsFromResponseArray, removeToast, addToast]
  );

  const handleFilterChanged = useCallback(filters => {
    setActiveRequest(prev => ({ ...prev, filters }));
  }, []);

  const handleSortChanged = useCallback(sorting => {
    setActiveRequest(prev => {
      const next = { ...prev };
      if (sorting == null) {
        delete next.sortBy;
      } else {
        next.sortBy = `${sorting.sort === 'desc' ? '-' : '+'}${sorting.field}`;
      }
      return next;
    });
  }, []);

  const refresh = useCallback(
    (force?: boolean) => {
      force && dataSubject.next({ data: [], initial: true });
      queryApi(activeRequest);
    },
    [activeRequest, dataSubject, queryApi]
  );

  // Strict Mode change - this still works as intended.
  useEffectOnce(() => {
    if (activeRequest) {
      refresh();
    }
  });

  return {
    ...useBlotterTable({
      dataObservable: pipedSubscription,
      rowID,
      columns: activeColumns,
      onSortChanged: handleSortChanged,
      onFilterChanged: handleFilterChanged,
      ...props,
    }),
    refresh,
  };
}
