import {
  AGGRID_AUTOCOLUMN_ID,
  BlotterTable,
  BlotterTableFilters,
  ButtonVariants,
  FormControlSizes,
  IconButton,
  IconName,
  PnlLookbackEnum,
  useBehaviorSubject,
  useBlotterTable,
  useObservable,
  usePersistedBlotterTable,
  type BlotterTableFilter,
  type MinimalSubscriptionResponse,
  type UsePersistedBlotterTable,
} from '@talos/kyoko';
import { PerformanceActionType } from 'containers/Portfolio/Performance/PerformanceReducer';
import { usePerformanceContext } from 'containers/Portfolio/Performance/providers/PerformanceStateAndTabsProvider';
import { useEffect } from 'react';
import { combineLatest, map, scan, shareReplay, startWith } from 'rxjs';
import { usePortfolioManagementProvider } from '../../providers/PortfolioManagementProvider';
import type { ContextSelectionBlotterParams } from '../../types/types';
import { useRollupTreeGridBuilders } from '../PortfolioRiskBlotter/useRollupTreeGridBuilders';
import { useGroupedDataExportContext } from '../RiskBlotterExportMenuItems';
import { RiskBlotterSuffixMenu } from '../RiskBlotterSuffixMenu';
import { getPositionMapKey } from '../types';
import { useRollupTreeMenu } from '../useRollupTreeMenu';
import type { PortfolioPerformanceBlotterGridItem, PortfolioPerformancePositionPnl } from './types';
import { usePMSSubAccountPositionsSub } from './usePMSSubAccountPositionsSub';
import { usePortfolioPerformanceColumns } from './usePortfolioPerformanceColumns';

export function PortfolioPerformanceBlotter({
  blotterID,
  wrapperContext,
  subAccountId,
}: ContextSelectionBlotterParams) {
  const { dispatch } = usePerformanceContext();
  useEffect(() => {
    dispatch({
      type: PerformanceActionType.PnlLookbacksChange,
      payload: {
        pnlLookbacks: [PnlLookbackEnum.H24],
      },
    });
  }, [dispatch]);

  const { batchedRiskData } = usePortfolioManagementProvider();
  const { observable: riskSubAccountObs } = useBehaviorSubject(
    () => batchedRiskData ?? { data: [] },
    [batchedRiskData]
  );

  const perfObs = usePMSSubAccountPositionsSub();

  const sharedObs = useObservable(() => riskSubAccountObs.pipe(shareReplay(1)), [riskSubAccountObs]);
  const gridObs = useObservable<MinimalSubscriptionResponse<PortfolioPerformanceBlotterGridItem>>(() => {
    return combineLatest([
      riskSubAccountObs,
      perfObs.pipe(
        startWith({ data: [] }),
        scan((result, next) => {
          next.data.forEach(item => {
            const map: PortfolioPerformancePositionPnl = {
              PnLLookbacks: item.PnLLookbacks,
              UnrealizedPnL: item.UnrealizedPnL,
              RealizedPnL: item.RealizedPnL,
              TotalPnL: item.TotalPnL,
              PnLCurrency: item.PnLCurrency,
              Equivalent: {
                UnrealizedPnL: item.UnrealizedPnL,
                RealizedPnL: item.RealizedPnL,
                TotalPnL: item.TotalPnL,
              },
            };
            result[getPositionMapKey(item)] = map;
          });
          return result;
        }, {} as Record<string, PortfolioPerformancePositionPnl>)
      ),
    ]).pipe(
      map(data => {
        const [risk, posPnlLookbacks] = data;
        // cast in order to add PnlLookbacks
        const output = risk as MinimalSubscriptionResponse<PortfolioPerformanceBlotterGridItem>;
        output.data.forEach(item => {
          const key = getPositionMapKey(item);
          const positionPnl = posPnlLookbacks[key];
          item.PositionPnl = positionPnl;
        });
        return output;
      })
    );
  }, [perfObs, riskSubAccountObs]);

  const defaultFilter: BlotterTableFilter = {};
  const { defaultColumns } = usePortfolioPerformanceColumns();
  const persistedBlotterTable: UsePersistedBlotterTable<PortfolioPerformanceBlotterGridItem> = usePersistedBlotterTable(
    blotterID,
    {
      persistColumns: false,
      columns: defaultColumns,
      filter: defaultFilter,
      sort: `+${AGGRID_AUTOCOLUMN_ID}`,
    }
  );

  const { blotterParams } = useRollupTreeGridBuilders();

  const { getContextMenuItems, dialogs } = useRollupTreeMenu();

  const groupedDataExportContext = useGroupedDataExportContext();
  const blotterTable = useBlotterTable<PortfolioPerformanceBlotterGridItem>({
    dataObservable: gridObs,
    rowID: 'rowID',
    rowSelection: 'single',
    sort: persistedBlotterTable.initialSort,
    onSortChanged: persistedBlotterTable.onSortChanged,
    columns: defaultColumns,
    groupableColumns: defaultColumns,
    context: groupedDataExportContext,
    getContextMenuItems,
    quickSearchParams: {
      entitySearchKeys: ['Asset', 'SubAccount'],
      filterOnAutoGroupLevels: true,
    },
    groupDefaultExpanded: -1,
    supportColumnColDefGroups: true,
    ...blotterParams,
  });

  useEffect(() => {
    if (blotterTable.gridApi) {
      if (subAccountId) {
        blotterTable.gridApi.expandAll();
      } else {
        blotterTable.gridApi.collapseAll();
      }
    }
  }, [subAccountId, blotterTable.gridApi]);

  const { expandAllGroups, collapseAllGroups } = blotterTable;

  return (
    <>
      <BlotterTableFilters
        {...blotterTable.blotterTableFiltersProps}
        showFilterBuilder={false}
        showQuickFilterOnly={false}
        prefix={
          <>
            <IconButton
              icon={IconName.ListExpand}
              size={FormControlSizes.Small}
              variant={ButtonVariants.Default}
              onClick={expandAllGroups}
              data-testid="expand-all-button"
            />
            <IconButton
              icon={IconName.ListCollapse}
              size={FormControlSizes.Small}
              variant={ButtonVariants.Default}
              onClick={collapseAllGroups}
            />
          </>
        }
        suffix={
          <RiskBlotterSuffixMenu
            riskObs={sharedObs}
            exportDataAsCSV={blotterTable.exportDataAsCSV}
            exportDataAsExcel={blotterTable.exportDataAsExcel}
            label={wrapperContext.label}
          />
        }
      />
      <BlotterTable {...blotterTable} />
      {dialogs}
    </>
  );
}
