import {
  ACTION,
  Box,
  Button,
  ButtonVariants,
  FormRowStatus,
  FormTable,
  HStack,
  IconName,
  MarketAccountTypeEnum,
  ModeEnum,
  NotificationVariants,
  Text,
  promisesHaveResolved,
  useDynamicCallback,
  useFormTable,
  useGlobalToasts,
  useMarketAccountsContext,
  type Column,
} from '@talos/kyoko';
import type { MarketAccountColumnParams } from '@talos/kyoko/src/components/BlotterTable/columns/marketAccount';
import { useRoleAuth } from 'hooks';
import { useAggregationMarkets } from 'hooks/useAggregationMarkets';
import { compact } from 'lodash';
import { usePricingRules } from 'providers';
import { useCallback, useMemo, useState } from 'react';
import type { Aggregation, AggregationMarket } from 'types';
import { getAggregationMarketRowID } from './utils';

export function AggregationMarketsTable({
  aggregation,
  aggregationMarkets,
  onDeleteAggregation,
}: {
  aggregation: Aggregation;
  aggregationMarkets: AggregationMarket[];
  onDeleteAggregation: (name: string) => void;
}) {
  const { upsertAggregationMarket } = useAggregationMarkets(aggregation.Name);
  const { marketAccountsByName } = useMarketAccountsContext();
  const { isAuthorized } = useRoleAuth();
  const { pricingRules } = usePricingRules();

  const tradingMarketAccountsByName = useMemo(() => {
    const tradingMarketAccounts = new Map(marketAccountsByName);
    for (const [name, marketAccount] of tradingMarketAccounts) {
      if (marketAccount.Type !== MarketAccountTypeEnum.Trading) {
        tradingMarketAccounts.delete(name);
      }
    }
    return tradingMarketAccounts;
  }, [marketAccountsByName]);

  const usedAggregationsMap: Set<string> = useMemo(() => {
    if (pricingRules == null) {
      return new Set();
    }
    return new Set(compact(pricingRules.map(rule => rule.PricingAggregation)));
  }, [pricingRules]);

  const getRows = useDynamicCallback(() => {
    return formTable.getRows();
  });

  const isSelectableMarket = useCallback(
    (marketAccountName: string, selectedValue: string | undefined) => {
      if (!selectedValue) {
        return false;
      }
      const selectedMarkets = new Set<string>();
      const rows = getRows();
      for (const row of rows) {
        selectedMarkets.add(row?.data?.Market || '');
      }
      const currentMarket = marketAccountsByName.get(marketAccountName)?.Market || '';
      const editingMarket = marketAccountsByName.get(selectedValue)?.Market;
      return selectedMarkets.has(currentMarket) && currentMarket !== editingMarket;
    },
    [marketAccountsByName, getRows]
  );

  const getIsMarketDisabled = useCallback(
    (marketAccountName: string, selectedValue: string | undefined) => {
      return isSelectableMarket(marketAccountName, selectedValue);
    },
    [isSelectableMarket]
  );

  const getMarketDescription = useCallback(
    (marketAccountName: string, selectedValue: string = ''): string => {
      const result = isSelectableMarket(marketAccountName, selectedValue)
        ? `${marketAccountsByName.get(marketAccountName)?.DisplayName} - market already selected`
        : marketAccountsByName.get(marketAccountName)?.DisplayName;
      return result ?? '';
    },
    [isSelectableMarket, marketAccountsByName]
  );

  const aggregationAccordionColumns = useMemo<Column[]>(
    () => [
      { field: 'Mode', type: 'mode', title: 'Enabled', width: 80 },
      {
        field: 'MarketAccount',
        type: 'marketAccount',
        width: 300,
        flex: 1,
        editable: true,
        params: {
          marketAccountsByName: tradingMarketAccountsByName,
          isItemDisabled: getIsMarketDisabled,
          getDescription: getMarketDescription,
        } satisfies MarketAccountColumnParams,
      },
      { id: 'filler', type: 'filler' },
      { id: 'remove', type: 'remove', params: { allowOnlyForAddedRows: true } },
    ],
    [tradingMarketAccountsByName, getIsMarketDisabled, getMarketDescription]
  );

  const formTable = useFormTable<AggregationMarket>({
    data: aggregationMarkets,
    columns: aggregationAccordionColumns,
    rowID: 'RowID',
  });

  const [isSaving, setIsSaving] = useState(false);
  const { add: addToast } = useGlobalToasts();
  const handleAdd = useCallback(() => {
    formTable.addRow({ Mode: ModeEnum.Enabled, MarketAccount: '', Aggregation: aggregation.Name });
  }, [formTable, aggregation.Name]);

  const handleSave = useCallback(() => {
    setIsSaving(true);
    const rows = formTable.getRows();
    const requests: (Promise<unknown> | ReturnType<typeof upsertAggregationMarket>)[] = [];

    const showErrorToast = (e: Error) =>
      addToast({
        text: `Could not save aggregation: ${e.message}`,
        variant: NotificationVariants.Negative,
      });

    for (const row of rows) {
      switch (row.status) {
        case FormRowStatus.Added:
        case FormRowStatus.Updated:
          requests.push(
            upsertAggregationMarket({
              Aggregation: aggregation.Name,
              Market: row.data.MarketAccount,
              Mode: row.data.Mode,
            })
              .then(() => {
                row.setData({ ...row.data, RowID: getAggregationMarketRowID(row.data) });
                return row.data;
              })
              .catch((e: Error) => {
                showErrorToast(e);
              })
          );
          break;
      }
    }

    Promise.allSettled(requests).then(promises => {
      setIsSaving(false);
      if (promisesHaveResolved(promises)) {
        addToast({
          text: `Aggregation "${aggregation.DisplayName}" saved.`,
          variant: NotificationVariants.Positive,
        });
      } else {
        addToast({
          text: 'Aggregation could not be saved.',
          variant: NotificationVariants.Negative,
        });
      }
    });
  }, [formTable, addToast, upsertAggregationMarket, aggregation.Name, aggregation.DisplayName]);

  return (
    <Box>
      <HStack mb="spacingDefault" justifyContent="space-between" gap="spacingSmall">
        <HStack gap="spacingDefault">
          {aggregation.Name !== 'dealer' && (
            <Button
              startIcon={IconName.Trash}
              onClick={() => onDeleteAggregation(aggregation.Name)}
              variant={ButtonVariants.Negative}
              disabled={usedAggregationsMap.has(aggregation.Name) || !isAuthorized(ACTION.DEALER_TRADING)}
            >
              Delete Aggregation
            </Button>
          )}
          {usedAggregationsMap.has(aggregation.Name) && (
            <Text color="colorTextNegative">This aggregation is in use by a Pricing Rule and cannot be deleted.</Text>
          )}
        </HStack>
        <HStack gap="spacingSmall">
          <Button startIcon={IconName.Plus} onClick={handleAdd} disabled={!isAuthorized(ACTION.DEALER_TRADING)}>
            Add Market Account
          </Button>
          <Button
            variant={ButtonVariants.Primary}
            onClick={handleSave}
            disabled={isSaving || !formTable.isDirty || !isAuthorized(ACTION.DEALER_TRADING)}
          >
            Save
          </Button>
        </HStack>
      </HStack>
      {/* [sc-56509] Aggregation Markets FormTable Accordion requires domLayout=autoHeight */}
      <FormTable {...formTable} gridOptions={{ ...formTable.gridOptions, domLayout: 'autoHeight' }} />
    </Box>
  );
}
