import { VStack } from '@talos/kyoko';
import { DealerSettlement } from 'containers/Portfolio/DealerSettlement';
import { Performance } from 'containers/Portfolio/Performance/index';
import { TreasuryManagement } from '../../TreasuryManagement';
import { PortfolioOverview } from '../components/PortfolioOverview';
import { PortfolioPerformanceBlotterWrapper } from '../components/PortfolioPerformanceBlotter/PortfolioPerformanceBlotterWrapper';
import { PortfolioRiskBlotterWrapper } from '../components/PortfolioRiskBlotter/PortfolioRiskBlotterWrapper';
import { EmptyPanel } from './EmptyPanel';

export type LayoutOptionComponentType = keyof typeof LAYOUT_OPTIONS_FIXED_COMPONENT_FACTORY;
/** For each fixed layout dashboard tab, configure the tab to show; the keys used will drive the available settings in the config  */
export const LAYOUT_OPTIONS_FIXED_COMPONENT_FACTORY = {
  'PM-Overview': <PortfolioOverview />,
  'PM-Performance': <PortfolioPerformanceBlotterWrapper />,
  'PM-Reports': <Performance />,
  'Risk-Exposures': <PortfolioRiskBlotterWrapper />,
  'Operations-Overview': <TreasuryManagement />,
  'Operations-Reconciliation': (
    <EmptyPanel>
      <VStack gap="spacingTiny">
        <span>Account Reconciliation</span>
        <span>Coming Soon!</span>
      </VStack>
    </EmptyPanel>
  ),
  'Operations-Settlement': <DealerSettlement />,
  'Operations-Reports': <TreasuryManagement />,
} as const satisfies Record<string, React.ReactNode>;
