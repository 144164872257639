import { getDefaultCustomerMarketAccount, type Customer, type MarketAccount, type SelectorField } from '@talos/kyoko';
import type { WritableDraft } from 'immer/dist/types/types-external';

export const getDefaultMarketAccountForCustomer = (
  customerAccountField: WritableDraft<SelectorField<MarketAccount>>,
  marketAccountList: MarketAccount[],
  customer?: Customer
) => {
  if (!customer) {
    return customerAccountField.updateAvailableItems(marketAccountList);
  }

  const customerMarketAccounts = marketAccountList.filter(ma => ma.Counterparty === customer?.Name);
  const defaultAccount = getDefaultCustomerMarketAccount(customerMarketAccounts);

  return customerAccountField
    .updateAvailableItems(customerMarketAccounts)
    .updateValue(defaultAccount, true)
    .setDisabled(customerMarketAccounts.length === 1);
};
