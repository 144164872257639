import type { RollupTreePositionData } from '../components/PortfolioRiskBlotter/useRollupTreeGridBuilders';
import type { BaseBlotterProps } from '../components/types';
import type { PortfolioRiskGridData } from './PortfolioRiskGridData';

export type PortfolioRiskBlotterGridData = PortfolioRiskGridData & RollupTreePositionData;

/** Group key prefixes for Underlier level data */
export const UNDERLIER_GROUP_PREFIX = 'Underlier::';
export const ASSET_GROUP_PREFIX = 'Asset::';
export const BOOK_GROUP_PREFIX = 'Book::';
export const ROLLUP_GROUP_PREFIX = 'Rollup::';

/** Tenor Map from Server format to Display Format */
export const TENOR_COLUMN_MAP = {
  Outright: 'Outright',
  W1: '1W',
  M1: '1M',
  M3: '3M',
  M6: '6M',
  M9: '9M',
  Y1: '1Y',
} as const;
export type TenorServerTypes = keyof typeof TENOR_COLUMN_MAP;
export const TENOR_DISPLAY_ORDER: TenorServerTypes[] = Object.keys(TENOR_COLUMN_MAP) as TenorServerTypes[];

/** Moneyness Map from Server format to Display Format */
export const MONEYNESS_COLUMN_MAP = {
  Outright: 'Outright',
  D10: '10D',
  D25: '25D',
  D50: '50D',
  D75: '75D',
  D90: '90D',
} as const;
export type MoneynessServerTypes = keyof typeof MONEYNESS_COLUMN_MAP;
export const MONEYNESS_DISPLAY_ORDER: MoneynessServerTypes[] = Object.keys(
  MONEYNESS_COLUMN_MAP
) as MoneynessServerTypes[];

export type Greeks = keyof typeof GreeksDisplayNameMap;

export const GreeksDisplayNameMap = {
  Delta: 'Delta',
  DeltaExposure: 'Delta Exposure',
  Gamma: 'Gamma',
  Vega: 'Vega',
  Vanna: 'Vanna',
  Volga: 'Volga',
  Rho: 'Rho',
  Theta: 'Theta',
} as const;

export const GREEK_ORDER = {
  Delta: {
    tooltipBase: `Value change for a 1% change in the underlying token's price`,
  },
  DeltaExposure: {
    tooltipBase: `Equivalent delta notional in home currency from holding this position -- the economic exposure driving performance`,
  },
  Gamma: {
    tooltipBase: `Rate of Delta change for a 1% change in the underlying token's price`,
  },
  Vega: {
    tooltipBase: `Value change for a 1% change in the implied volatility of the underlying`,
  },
  Vanna: {
    tooltipBase: `Value change for a 1% change in the underlying token's price and a 1% change in the implied volatility of the underlying`,
  },
  Volga: {
    tooltipBase: `Rate of Vega change for a 1% change in the implied volatility of the underlying`,
  },
  Rho: {
    tooltipBase: `Value change for a 1 basis point change in the interest rate`,
  },
  Theta: {
    tooltipBase: `Value change for a 1 day decrease in the time to expiration`,
  },
} as const satisfies { [P in Greeks]: { tooltipBase: string } };
export type PortfolioRiskResultType = 'Asset' | 'AssetMissingMark';

export interface ContextSelectionBlotterParams {
  blotterID: string;
  wrapperContext: BaseBlotterProps;
  subAccountId: number | undefined;
  tag?: string;
}

export type RiskPivotType = 'byTenor' | 'byMoneyness';
export type RiskAggMode = 'Net' | 'Gross';
