import {
  abbreviate,
  BaseChart,
  Box,
  Flex,
  Text,
  ThemeTypes,
  unboundedAbbreviate,
  useDefaultChartOptions,
  useHighchartsRef,
  VStack,
} from '@talos/kyoko';
import { useDisplaySettings } from 'providers/DisplaySettingsProvider';
import { useMemo } from 'react';
import { useTheme } from 'styled-components';

const ExposureDisplay = ({
  show,
  numberColor,
  currencyColor,
  value,
  homeCurrency,
}: {
  show: boolean;
  value: string | undefined;
  homeCurrency: string;
  numberColor: string;
  currencyColor: string;
}) => {
  return !show ? null : (
    <>
      <Text fontWeight={500} color={numberColor}>
        {value ?? ''}
      </Text>{' '}
      <Text color={currencyColor}>{homeCurrency}</Text>
    </>
  );
};

export type StackedChartDataItem = {
  /** Stacked item display name */
  asset: string;
  /** Stacked item value */
  value: number;
  /** Color for the stacked box item */
  color: string | undefined;
};

export function InnerStackedChart({
  data,
  isDataLoading,
}: {
  isDataLoading: boolean;
  data: StackedChartDataItem[] | undefined;
}) {
  const { setChartObject } = useHighchartsRef({});
  const { homeCurrency } = useDisplaySettings();
  const theme = useTheme();
  const shortColor = theme.type === ThemeTypes.dark ? theme.colors.blue.lighten : theme.colors.blue.default;
  const chartSeries = useMemo<{
    chartSeriesData: Highcharts.SeriesBarOptions[];
    topLevelExposures: { long: string; short: string; net: string } | undefined;
  }>(() => {
    if (!data) {
      return { chartSeriesData: [], topLevelExposures: undefined };
    }

    const sortedData = data
      .filter(val => val.value !== 0)
      .sort((a, b) => {
        // these are guaranteed safe casts thanks to the KeysMatching type above
        const aVal = a.value;
        const bVal = b.value;
        if (aVal >= 0 && bVal < 0) {
          return -1;
        }
        if (aVal < 0 && bVal >= 0) {
          return 1;
        }
        // In order to display the chart properly, positive numbers need to sort in descending order
        if (aVal < 0) {
          return aVal - bVal;
        } else {
          return bVal - aVal;
        }
      });

    const chartSeriesData = sortedData.map(item => {
      const value = item.value ?? 0;
      const color = item.color;
      const data = value < 0 ? [0, value] : [value, 0];
      return {
        type: 'bar',

        dataLabels: {
          enabled: true,
          shadow: theme.type === ThemeTypes.dark,
          align: 'center',
          style: {
            textOutline: 'none',
            fontWeight: 'normal',
            fontSize: '0.8em',
            fontFamily: theme.fontFamily,
            color: theme.colorTextImportant,
          },
          formatter: function () {
            const value = this.point.y ?? 0;
            if (value === 0) {
              return '';
            }
            const dataValue = `${this.series.name}<br/>${unboundedAbbreviate(value, {
              precision: 2,
            })}`;

            return dataValue;
          },
          verticalAlign: 'middle',
        },
        data,
        color,
        name: item.asset,
      } satisfies Highcharts.SeriesBarOptions;
    });
    const topLevelExposureData = {
      long: sortedData.filter(item => item.value > 0).reduce((acc, val) => acc + val.value, 0),
      short: sortedData.filter(item => item.value < 0).reduce((acc, val) => acc + val.value, 0),
      net: sortedData.reduce((acc, val) => acc + val.value, 0),
    };
    return {
      chartSeriesData,
      topLevelExposures: {
        long: `${unboundedAbbreviate(topLevelExposureData.long, {
          precision: 2,
        })}`,
        short: `${unboundedAbbreviate(topLevelExposureData.short, {
          precision: 2,
        })}`,
        net: `${unboundedAbbreviate(topLevelExposureData.net, {
          precision: 2,
        })}`,
      },
    };
  }, [data, theme]);

  const options = useMemo(() => {
    return {
      chart: {
        type: 'bar',
      },
      xAxis: {
        visible: false,
        accessibility: {
          description: '',
        },
        maxPadding: 0.1,
        minPadding: 0.1,
      },
      yAxis: {
        reversedStacks: false,

        opposite: false,
        plotLines: [
          {
            value: -0.5,
            color: theme.colors.gray['090'],
            width: 1,
            zIndex: 10,
          },
        ],
        title: {
          text: 'Position',
        },
        maxPadding: 0.05,
        minPadding: 0.05,
        labels: {
          enabled: true,
          align: 'center',
          formatter: function () {
            if (typeof this.value === 'string') {
              return '';
            }
            const style = this.value < 0 ? `color: ${shortColor}` : '';
            return `<span style="${style}">${unboundedAbbreviate(this.value, {
              precision: 2,
            })}</span>`;
          },
        },

        stackLabels: {
          enabled: false,
          style: {
            color: theme.colorTextImportant,
            textOutline: 'none',
            fontSize: '0.8em',
            fontFamily: theme.fontFamily,
          },
          formatter: function () {
            return this.total > 0
              ? `Long: ${abbreviate(this.total, {
                  precision: 2,
                })}`
              : `Short: ${abbreviate(Math.abs(this.total), {
                  precision: 2,
                })}`;
          },
        },
        startOnTick: false,
        endOnTick: false,
      },
      legend: {
        enabled: false,
      },
      plotOptions: {
        bar: {
          stacking: 'normal',
          // width cleanly divisible by 3 to ensure even spacing
          borderWidth: 1.5,
          boostBlending: 'darken',
          borderColor: theme.backgroundContent,
          groupPadding: 0,
          pointPadding: 0.01,
          dataLabels: {
            enabled: true,
          },
          accessibility: {
            exposeAsGroupOnly: true,
          },
        },
      },

      tooltip: {
        formatter: function () {
          const value = this.point.y ?? 0;
          const dataValue = unboundedAbbreviate(value, {
            precision: 2,
          });
          const style = value < 0 ? `color: ${shortColor}` : '';
          return `<strong>${this.series.name}</strong><br/>Value: <span style="${style}">${dataValue}</span>`;
        },
      },

      series: chartSeries.chartSeriesData,
    } satisfies Highcharts.Options;
  }, [chartSeries.chartSeriesData, shortColor, theme]);

  const defaultChartOptions = useDefaultChartOptions();
  const defaultOverridesToUse: Highcharts.Options = {
    ...defaultChartOptions,
    xAxis: {},
  };

  const showTotals = chartSeries.chartSeriesData.length > 0;
  return (
    <VStack w="100%" h="100%" position="relative">
      <VStack flex="none" w="100%" alignItems="flex-start" position="relative" p="spacingDefault">
        <Flex pt="spacingDefault" w="100%" fontSize="16px" justifyContent="space-between">
          <Text>
            Short Position:{' '}
            <ExposureDisplay
              show={showTotals}
              value={chartSeries.topLevelExposures?.short}
              homeCurrency={homeCurrency}
              numberColor={theme.colors.red.lighten}
              currencyColor={theme.colors.red.default}
            />
          </Text>
          <Text>
            Net Position:{' '}
            <ExposureDisplay
              show={showTotals}
              value={chartSeries.topLevelExposures?.net}
              homeCurrency={homeCurrency}
              numberColor={theme.colors.gray['100']}
              currencyColor={theme.colors.gray['090']}
            />
          </Text>
          <Text>
            Long Position:{' '}
            <ExposureDisplay
              show={showTotals}
              value={chartSeries.topLevelExposures?.long}
              homeCurrency={homeCurrency}
              numberColor={theme.colors.green.lighten}
              currencyColor={theme.colors.green.default}
            />
          </Text>
        </Flex>
      </VStack>
      <Box flex="1" w="100%" position="relative">
        <Box position="absolute" top="0" left="0" right="0" bottom="0">
          <BaseChart
            throttleResizing={0}
            onChartCreated={setChartObject}
            showOverlayWhenLoading
            isLoading={isDataLoading}
            options={options}
            defaultOverrides={defaultOverridesToUse}
            customNoDataText="No positions returned for this portfolio"
          />
        </Box>
      </Box>
    </VStack>
  );
}
