import { cloneDeep, entries, get } from 'lodash';
import { useCallback, useMemo, useState } from 'react';
import { bpsToPercent, percentToBps, prettyName } from '../../utils';
import { Button, ButtonVariants, IconButton } from '../Button';
import { Box, HStack } from '../Core';
import { Divider } from '../Divider';
import type { EntityPageModel } from '../EntityAdminPage';
import { FormControlSizes, FormGroup, Input, SearchSelect } from '../Form';
import { IconName } from '../Icons';
import { Text } from '../Text';
import { Drawer, type DrawerProps } from './Drawer';
import { DrawerContent, DrawerFooter } from './styles';

export type InputsAndDropdownsDrawerOption<T> =
  | ({ field: keyof T; title?: string; required?: boolean; disabledWhenEditing?: boolean; placeholder?: string } & (
      | { type: 'input' | 'inputBPS' }
      | { type: 'dropdown'; options: { value: string; label: string; description?: string }[] }
    ))
  | { type: 'divider' };

type InputsAndDropdownsDrawerProps<T> = {
  onSave: (modifiedEntity: T) => void;
  onDelete: (selectedEntity: T) => void;
  allowDeleteEntity?: boolean;
  selectedEntity?: T;
  title?: string;
  drawerOptions: InputsAndDropdownsDrawerOption<T>[];
  isEditing: boolean;
} & DrawerProps;

const isOptionDivider = <T,>(option: InputsAndDropdownsDrawerOption<T>): option is { type: 'divider' } =>
  option.type === 'divider';

const getOptionLabel = <T,>(option: InputsAndDropdownsDrawerOption<T>) => {
  if (isOptionDivider(option)) {
    return null;
  }
  return `${option.title || prettyName(String(option.field))}${option.required ? '*' : ''}`;
};

export function InputsAndDropdownsDrawer<T extends EntityPageModel>({
  selectedEntity,
  onSave,
  allowDeleteEntity,
  onDelete,
  title,
  drawerOptions,
  isEditing,
  ...drawerProps
}: InputsAndDropdownsDrawerProps<T>) {
  const [form, setForm] = useState<T>(
    entries(selectedEntity ?? {}).reduce<T>((acc, [field, value]) => {
      const drawerOption = drawerOptions.find(option => !isOptionDivider(option) && option.field === field);
      if (drawerOption?.type === 'inputBPS') {
        // Convert percent to BPS for form initialization
        acc[field as keyof T] = (value ? percentToBps(value as number) : '') as unknown as T[keyof T];
      } else {
        // Initialize rest of the form with empty strings
        acc[field as keyof T] = (value ?? '') as T[keyof T];
      }
      return acc;
    }, {} as T)
  );

  const handleDelete = useCallback(() => {
    if (window.confirm('Are you sure you want to delete this entity?')) {
      onDelete(selectedEntity!);
    }
  }, [onDelete, selectedEntity]);

  const handleOnSave = useCallback(() => {
    const formWithInputTypeBPSConverted = drawerOptions.reduce((acc, option) => {
      if (option.type === 'inputBPS') {
        // Convert BPS to percent for saving
        acc[option.field] = bpsToPercent(form[option.field] as number) as T[keyof T];
      }
      return acc;
    }, cloneDeep(form));

    // Replace empty strings on the form with null
    const formWithNullForRemoved = entries(formWithInputTypeBPSConverted).reduce<T>((acc, [key, value]) => {
      acc[key as keyof T] = value !== '' ? value : null;
      return acc;
    }, formWithInputTypeBPSConverted);

    onSave(formWithNullForRemoved);
  }, [drawerOptions, form, onSave]);

  const showDeleteButton = allowDeleteEntity && isEditing;

  const someRequiredInputNotPopulated = useMemo(
    () => drawerOptions.some(option => !isOptionDivider(option) && option.required && !form[option.field]),
    [drawerOptions, form]
  );

  const handleOnFormUpdate = useCallback((field: keyof T, value: string) => {
    setForm(prev => ({ ...prev, [field]: value }));
  }, []);

  return (
    <Drawer {...drawerProps} data-testid="entity-admin-page-inputs-and-dropdowns-drawer">
      <HStack justifyContent="space-between" p="spacingMedium">
        <Text>{title}</Text>
        <IconButton size={FormControlSizes.Small} icon={IconName.Close} onClick={() => drawerProps.close()} />
      </HStack>
      <DrawerContent overflow="overlay">
        <Box>
          {drawerOptions.map((drawerOption, index) => (
            <FormGroup key={index} label={getOptionLabel(drawerOption)}>
              {drawerOption.type === 'input' || drawerOption.type === 'inputBPS' ? (
                <Input
                  value={(get(form, drawerOption.field) as string | undefined) ?? ''}
                  onChange={e => handleOnFormUpdate(drawerOption.field, e.target.value)}
                  disabled={drawerOption.disabledWhenEditing && isEditing}
                  placeholder={drawerOption.placeholder}
                  suffix={drawerOption.type === 'inputBPS' ? 'BPS' : undefined}
                  inputType={drawerOption.type === 'inputBPS' ? 'number' : 'text'}
                  data-testid={`inputs-and-dropdowns-drawer-${String(drawerOption.field)}`}
                />
              ) : drawerOption.type === 'dropdown' ? (
                <SearchSelect
                  selection={drawerOption.options.find(option => option.value === form[drawerOption.field])}
                  options={drawerOption.options}
                  getLabel={option => option.label}
                  getDescription={option => option.description ?? ''}
                  onChange={newValue => handleOnFormUpdate(drawerOption.field, newValue?.value ?? '')}
                  showClear={true}
                  disabled={drawerOption.disabledWhenEditing && isEditing}
                  placeholder={drawerOption.placeholder}
                  data-testid={`inputs-and-dropdowns-drawer-${String(drawerOption.field)}`}
                />
              ) : drawerOption.type === 'divider' ? (
                <Divider data-testid="inputs-and-dropdowns-drawer-divider" />
              ) : null}
            </FormGroup>
          ))}
        </Box>
      </DrawerContent>
      <DrawerFooter>
        {showDeleteButton && (
          <Button
            variant={ButtonVariants.Negative}
            onClick={handleDelete}
            data-testid="inputs-and-dropdowns-drawer-delete-button"
          >
            Delete
          </Button>
        )}
        <Button
          onClick={handleOnSave}
          variant={ButtonVariants.Primary}
          data-testid="inputs-and-dropdowns-drawer-save-button"
          disabled={someRequiredInputNotPopulated}
        >
          Save
        </Button>
      </DrawerFooter>
    </Drawer>
  );
}
