import type { ColDef } from 'ag-grid-community';
import { get, isUndefined, omitBy } from 'lodash';
import { getIntlKey } from '../../../contexts';
import { prettyName } from '../../../utils';
import { messages } from './columnHeaderTranslations';
import { getAgGridColId } from './getAgGridColId';
import type { Column } from './types';

/**
 * Create a base ColDef for AgGrid.
 *
 * @param column Column definition
 * @param ignoreDefaultHeaderValue Ignore the default title generated by the headerValueGetter if a translation is missing.
 */
export function baseColumn(column: Column, ignoreDefaultHeaderValue = false): ColDef {
  // Prevent clearing out values from `column` that are undefined
  return omitBy(
    {
      colId: getAgGridColId(column),
      hide: column.hide,
      field: column.field,
      flex: column.flex,
      width: column.width,
      maxWidth: column.maxWidth,
      minWidth: column.minWidth,
      headerValueGetter: ({ context }) => {
        const customIntlKey = column.titleIntlKey;
        const effectiveField = column.field ?? column.id;
        const intlKey = customIntlKey ?? (effectiveField ? getIntlKey(effectiveField) : undefined);
        if (column.title) {
          // Column header overwritten with title prop
          return column.title;
        } else if (intlKey && intlKey in messages) {
          // Column header has translation
          return context.current.intl.formatMessage(messages[intlKey], column.titleIntlKeyValues);
        }
        // Columns without a "field" don't have titles. They are used for buttons, etc.
        // If passed a title, it will be used instead.
        else if (!column.field) {
          return undefined;
        }
        // Useful to ignore the Sentry warning if you are intentionally not providing a title (but you did provide a field)
        else if (ignoreDefaultHeaderValue) {
          return undefined;
        }
        // Default behavior, determined by field. "LastUpdateTime" --> "Last Update Time"
        // We don't consider column.id here because some columns intentionally leave it undefined
        // This way, they don't render any column header name. Example: { id: 'edit-button' }
        const defaultTitle = prettyName(column.field);
        if (import.meta.env.VITE_AVA_ENV === 'local' && import.meta.env.VITE_APP !== 'ava') {
          console.warn(
            `Internationalization Missing: Column Title with intlKey "${intlKey}" will render as "${defaultTitle}"`
          );
        }
        return defaultTitle;
      },
      headerTooltip: column.description,
      headerComponent: column.headerGroupToggle ? 'groupToggleHeader' : undefined,
      headerComponentParams: column.columnsInGroup ? { columnsInGroup: column.columnsInGroup } : undefined,
      sortable: column.sortable ?? true,
      sort: column.sort === '+' ? 'asc' : column.sort === '-' ? 'desc' : undefined,
      editable: column.editable,
      resizable: !column.frozen,
      lockVisible: column.frozen,
      lockPinned: true,
      suppressMovable: column.frozen,
      pinned: column.pinned,
      checkboxSelection: column.checkboxSelection,
      showDisabledCheckboxes: column.showDisabledCheckboxes,
      headerCheckboxSelection: column.headerCheckboxSelection,
      rowGroup: column.rowGroup,
      rowGroupIndex: column.rowGroupIndex,
      enableRowGroup: column.enableRowGroup,
      suppressMenu: column.suppressMenu,
      suppressColumnsToolPanel: column.suppressColumnsToolPanel,
      cellStyle: column.cellStyle,
      headerClass: () => {
        return column.columnGroup ? `ag-custom-column-group ${column.headerClass ?? ''}` : column.headerClass;
      },
      cellClass: column.cellClass,
      comparator: (a, b) => {
        if (typeof a === 'string') {
          return a.localeCompare(b);
        }
        if (typeof b === 'string') {
          return -1 * b.localeCompare(a);
        }
        return a - b;
      },
      [COLDEF_CUSTOM_KEY]: {
        exportable: column.exportable,
      } satisfies ColDefCustomProperties,
    } as ColDef,
    isUndefined
  );
}

const COLDEF_CUSTOM_KEY = '__custom__';

export interface ColDefCustomProperties {
  exportable?: boolean;
}

export function getCustomColDefProperties(colDef: ColDef): ColDefCustomProperties | undefined {
  return get(colDef, COLDEF_CUSTOM_KEY);
}
