import {
  ProductTypeEnum,
  SyntheticProductTypeEnum,
  isOptionStrategy,
  type MultilegModel,
  type Security,
} from '@talos/kyoko';
import {
  CalendarSpread,
  Option,
  VerticalSpread,
  getSyntheticProductType,
  type OptionStrategy,
  type OptionStrategyData,
} from '../NewOrder/models';
import type { OMSReferenceDataState } from '../types';
import { OptionStrategies } from './enums';
import type { LegsContainer } from './models';

const DEFAULT_MAX_MARKET_DATA_DEPTH = '50';

export const mapOptionStrategyToMultilegModel = (
  optionStrategy?: OptionStrategy,
  existingSymbol?: string,
  editableProperties?: { [key: string]: string }
): MultilegModel | undefined => {
  const syntheticProductType = getSyntheticProductType(optionStrategy);

  if (!optionStrategy || !syntheticProductType) {
    return undefined;
  }

  return {
    Symbol: existingSymbol || '',
    Description: editableProperties?.description || '',
    DisplaySymbol: editableProperties?.displaySymbol || '',
    ProductType: ProductTypeEnum.Synthetic,
    SyntheticProductType: syntheticProductType,
    MinSizeIncrement: editableProperties?.minSizeIncrement || '',
    MinPriceIncrement: editableProperties?.minPriceIncrement || '',
    MaxMarketDataDepth: parseInt(editableProperties?.maxMarketDataDepth || DEFAULT_MAX_MARKET_DATA_DEPTH),
    ReqLegs: optionStrategy.legs.map(leg => {
      const markets = !leg.data.marketAccountField.value.length
        ? []
        : leg.data.marketAccountField.value.map(m => m.value);
      return {
        Symbol: leg.security!.Symbol,
        Markets: markets,
      };
    }),
    Parameters: {
      LegParams: optionStrategy!.initiatingLegs.map(i => ({ Initiating: i })),
    },
  };
};

export const mapLegsContainerToMultilegModel = (
  legsContainer?: LegsContainer,
  syntheticProductType?: SyntheticProductTypeEnum,
  existingSymbol?: string,
  editableProperties?: { [key: string]: string }
): MultilegModel | undefined => {
  if (!legsContainer || !syntheticProductType) {
    return undefined;
  }

  return {
    Symbol: existingSymbol || '',
    Description: editableProperties?.description || '',
    DisplaySymbol: editableProperties?.displaySymbol || '',
    ProductType: ProductTypeEnum.Synthetic,
    SyntheticProductType: syntheticProductType,
    MinSizeIncrement: editableProperties?.minSizeIncrement || '',
    MinPriceIncrement: editableProperties?.minPriceIncrement || '',
    MaxMarketDataDepth: parseInt(editableProperties?.maxMarketDataDepth || DEFAULT_MAX_MARKET_DATA_DEPTH),
    ReqLegs: legsContainer.legs.map(leg => {
      const symbol = !leg || !leg.security ? '' : leg.security.Symbol;
      const markets =
        !leg || !leg.data.marketAccountField.value.length ? [] : leg.data.marketAccountField.value.map(m => m.value);
      return {
        Symbol: symbol,
        Markets: markets,
      };
    }),
    Parameters: {
      LegParams: legsContainer!.initiatingLegs.map(i => ({ Initiating: i })),
    },
  };
};

export const mapMultilegSecurityToOptionStrategy = (
  referenceData: OMSReferenceDataState,
  security?: Security
): OptionStrategy | undefined => {
  if (isOptionStrategy(security)) {
    const legSymbols = security!.MultilegDetails?.Legs.map(leg => leg.Symbol) || [];
    const legs = legSymbols.map(symbol => referenceData.securities.securitiesBySymbol.get(symbol)).filter(x => !!x);

    if (legs.length !== legSymbols.length) {
      // ensure every symbol is found
      return undefined;
    }

    const initiating = security?.MultilegDetails?.Parameters.LegParams.map(params => params.Initiating);
    const type = security?.MultilegDetails?.SyntheticProductType;

    const partial: Partial<OptionStrategyData> = {
      initiatingLegs: initiating,
      legs: legs.map(leg => Option.createFromSecurity(referenceData, leg!)),
    };

    switch (type) {
      case SyntheticProductTypeEnum.PutSpread:
      case SyntheticProductTypeEnum.CallSpread:
        return VerticalSpread.createFromBlank(referenceData, partial);
      case SyntheticProductTypeEnum.CallCalendarSpread:
      case SyntheticProductTypeEnum.PutCalendarSpread:
        return CalendarSpread.createFromBlank(referenceData, partial);
      default:
        throw new Error(`Unsupported SyntheticProductTypeEnum: ${type}`);
    }
  }
  return undefined;
};

export const mapSyntheticProductTypeToOptionStrategyEnum = (
  type: SyntheticProductTypeEnum
): OptionStrategies | undefined => {
  switch (type) {
    case SyntheticProductTypeEnum.PutSpread:
    case SyntheticProductTypeEnum.CallSpread:
      return OptionStrategies.VerticalSpread;
    case SyntheticProductTypeEnum.CallCalendarSpread:
    case SyntheticProductTypeEnum.PutCalendarSpread:
      return OptionStrategies.CalendarSpread;
    default:
      throw new Error(`Unsupported SyntheticProductTypeEnum: ${type}`);
  }
};

export const mapSyntheticProductTypeToDisplayLabel = (type?: SyntheticProductTypeEnum): string => {
  switch (type) {
    case SyntheticProductTypeEnum.PutSpread:
      return 'Put Spread';
    case SyntheticProductTypeEnum.CallSpread:
      return 'Call Spread';
    case SyntheticProductTypeEnum.CallCalendarSpread:
    case SyntheticProductTypeEnum.PutCalendarSpread:
      return 'Calendar Spread';
    case SyntheticProductTypeEnum.Delta1Spread:
      return 'Delta 1';
    case SyntheticProductTypeEnum.Cross:
      return 'Synthetic Cross';
    default:
      throw new Error(`Unsupported SyntheticProductTypeEnum: ${type}`);
  }
};
