import { get } from 'lodash';

export const ENTITY_INTERNAL_ROW_ID = 'ENTITY_INTERNAL_ROW_ID';

export type EntityPageModel<T = Record<string, any>> = T;

export class EntityPageClass<T = EntityPageModel> {
  private parentEntity?: T;

  private getField(target: T | undefined, field: keyof T): string | undefined {
    return get(target, field);
  }

  constructor(
    private entity: T,
    private entityIDField: keyof T,
    private childIDField?: keyof T,
    private parentMap?: Map<keyof T, T>
  ) {
    const parentKey = get(entity, entityIDField);
    this.parentEntity = parentKey ? this.parentMap?.get(parentKey) : undefined;

    return new Proxy(this, {
      get: (target, prop: string) => {
        if (prop in target) {
          return target[prop];
        }
        return target.getField(target.entity, prop as keyof T) ?? target.getField(target.parentEntity, prop as keyof T);
      },
    });
  }

  get [ENTITY_INTERNAL_ROW_ID](): string {
    const entityID = `${this.getField(this.entity, this.entityIDField)}`;
    if (this.childIDField != null) {
      return `${entityID}-${this.getField(this.entity, this.childIDField)}`;
    }
    return entityID;
  }

  isInherited(field: keyof T): boolean {
    return this.getField(this.entity, field) == null && this.getField(this.parentEntity, field) != null;
  }

  get data(): T {
    return this.entity;
  }

  toJSON() {
    return this.data;
  }
}
