import { useCallback, useEffect, useMemo, useState, type ChangeEvent } from 'react';
import { v1 as uuid } from 'uuid';

import {
  Box,
  Button,
  ButtonVariants,
  CustomerBalanceTransactionStatusEnum,
  CustomerBalanceTransactionTypeEnum,
  Dialog,
  FormGroup,
  FormMessage,
  HStack,
  Icon,
  IconName,
  InlineFormattedNumber,
  Input,
  MarketAccountOwnershipEnum,
  MixpanelEvent,
  NumberInput,
  Portal,
  SearchSelect,
  Text,
  Tooltip,
  VStack,
  format,
  logger,
  useCurrency,
  useDisclosure,
  useDynamicCallback,
  useMarketAccountsContext,
  useMixpanel,
  useObservableValue,
  type CustomerTransaction,
} from '@talos/kyoko';

import { useTransferFormOptions, useTransferFormValidation } from 'containers/Transfers';

import type { TransferForm } from 'containers/Transfers/types';

import { CustomerWithdrawAddressRow } from './CustomerWithdrawAddressRow';
import {
  AssetsSelector,
  DestinationsSelector,
  ProvidersSelector,
  SourcesSelector,
  type AssetsSelectorProps,
  type DestinationsSelectorProps,
  type ProvidersSelectorProps,
  type SourcesSelectorProps,
} from './selectors';

import { useBalances } from 'hooks/useBalances';
import { useCustomersContext } from 'hooks/useCustomer';
import { compact } from 'lodash';
import type { TreasuryLinkResponse } from 'providers/CustomersContext.types';
import type { BalancesFilter } from '../../../../../types';
import { useTransferListenerForClientID } from './hooks';
import { ConfirmTransferSourceDestination, Destination, Divider, Source } from './styles';

interface InitiateTransferFormProps {
  approveDisabledTooltip?: string;
  entity: CustomerTransaction;
  isApproveDisabled: boolean;
  isDisabled?: boolean;
  onFailedRequest: (e: ErrorEvent, errorDescription: string) => void;
  portalId: string;
}

export function InitiateTransferForm({
  approveDisabledTooltip,
  entity,
  isApproveDisabled,
  isDisabled = false,
  onFailedRequest,
  portalId,
}: InitiateTransferFormProps) {
  const { ClReqID, Currency, Quantity, Status, TransactionType } = entity;
  const mixpanel = useMixpanel();
  const confirmInitiateTransferDialog = useDisclosure();
  const { marketAccountsByID, marketAccountsByName } = useMarketAccountsContext();

  const {
    getEffectiveLinkForCustomerTransaction,
    initiatePendingTransferTransaction,
    rejectPendingTransferTransaction,
  } = useCustomersContext();
  const [touched, setTouched] = useState<Partial<Record<keyof TransferForm, boolean>>>({});
  const [showErrors, setShowErrors] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [form, setForm] = useState<TransferForm>({
    amount: '',
    asset: '',
    chainCurrency: undefined,
    clientID: '',
    description: '',
    destination: undefined,
    provider: '',
    reference: '',
    source: undefined,
  });
  const { amount, asset, clientID, description, destination, provider, reference, source, chainCurrency } = form;
  const { chainCurrencyOptions, sourceOptions } = useTransferFormOptions(form);
  const disableChainSelection = chainCurrencyOptions.required === false || source == null || destination == null;

  const { DefaultIncrement } = useCurrency(asset) ?? {};

  const balancesFilter: BalancesFilter = useMemo(
    () => ({
      MarketAccounts: compact([source, destination].map(option => option?.marketAccount.Name)),
    }),
    [source, destination]
  );

  const { balancesByCurrencyMarketAccountIDObs } = useBalances({
    filter: balancesFilter,
    tag: 'Customer transfer form',
    openStreamWithoutFilter: false,
  });
  const balancesByCurrencyMarketAccountID = useObservableValue(
    () => balancesByCurrencyMarketAccountIDObs,
    [balancesByCurrencyMarketAccountIDObs]
  );

  const handleFormUpdate = useCallback(
    (update: Partial<TransferForm>) => {
      for (const key of Object.keys(update) as Array<keyof TransferForm>) {
        setTouched(prev => ({ ...prev, [key]: true }));
      }
      setForm(prev => ({ ...prev, ...update }));
    },
    [setForm]
  );

  const handleAmountUpdate = useCallback((amount: string) => handleFormUpdate({ amount }), [handleFormUpdate]);

  const handleAssetUpdate: AssetsSelectorProps['onChange'] = useCallback(
    asset =>
      handleFormUpdate({
        asset: asset ?? '',
        chainCurrency: undefined,
      }),
    [handleFormUpdate]
  );

  const handleDestinationUpdate: DestinationsSelectorProps['onChange'] = useCallback(
    destination => handleFormUpdate({ destination }),
    [handleFormUpdate]
  );

  const handleProviderUpdate: ProvidersSelectorProps['onChange'] = useCallback(
    provider =>
      handleFormUpdate({
        provider: provider?.Name ?? '',
        reference: '',
        source: undefined,
        destination: undefined,
        asset: '',
      }),
    [handleFormUpdate]
  );

  const handleSourceUpdate: SourcesSelectorProps['onChange'] = useCallback(
    source => handleFormUpdate({ source }),
    [handleFormUpdate]
  );

  const handleReferenceUpdate = useCallback(
    (e: ChangeEvent<HTMLInputElement>) =>
      handleFormUpdate({
        reference: e.target.value,
      }),
    [handleFormUpdate]
  );

  const handleChainCurrencyUpdate = useCallback(
    (chainCurrency: string | undefined) => handleFormUpdate({ chainCurrency }),
    [handleFormUpdate]
  );

  const handleDescriptionUpdate = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => handleFormUpdate({ description: e.target.value }),
    [handleFormUpdate]
  );

  const handleClear = useCallback(() => {
    handleFormUpdate({
      provider: '',
      asset: '',
      amount: '',
      source: undefined,
      description: '',
      destination: undefined,
      reference: '',
    });
    setShowErrors(false);
  }, [handleFormUpdate]);

  const setFormDefaults = useDynamicCallback(() => {
    if (
      TransactionType === CustomerBalanceTransactionTypeEnum.Withdrawal &&
      Status === CustomerBalanceTransactionStatusEnum.PendingTransfer
    ) {
      const update: Partial<TransferForm> = {};

      update.amount = String(Quantity);
      update.asset = Currency;

      getEffectiveLinkForCustomerTransaction(entity)
        .then(({ data }: TreasuryLinkResponse) => {
          const { SourceMarketAccount } = data[0];

          if (SourceMarketAccount) {
            update.source = sourceOptions?.find(option => option.marketAccount.Name === SourceMarketAccount);
            update.provider = marketAccountsByName.get(SourceMarketAccount)?.Market;
          }
        })
        .catch(error => {
          // Fail silently
        })
        .finally(() => {
          handleFormUpdate(update);
        });
    }
  });

  useTransferListenerForClientID({ clientID, handleClear, setIsLoading, setShowErrors });

  useEffect(() => {
    setFormDefaults();
  }, [ClReqID, setFormDefaults, Status]);

  // If destination changed, see if reference data exists to populate the Reference
  // If there is no reference data, clear out reference and description
  useEffect(() => {
    if (destination?.marketAccount?.ReferenceData && provider !== 'fireblocks') {
      handleFormUpdate({ reference: destination?.marketAccount.ReferenceData, description: '' });
    } else {
      handleFormUpdate({ reference: '', description: '' });
    }
  }, [source, destination, marketAccountsByID, provider, handleFormUpdate]);

  // Available Amounts
  const { availableDestinationAmount, availableSourceAmount } = useMemo(() => {
    const sourceAccountBalance = source
      ? balancesByCurrencyMarketAccountID?.get(asset)?.get(source.marketAccount.MarketAccountID)
      : undefined;
    const destinationAccountBalance = destination
      ? balancesByCurrencyMarketAccountID?.get(asset)?.get(destination.marketAccount?.MarketAccountID)
      : undefined;

    return {
      availableSourceAmount: sourceAccountBalance?.AvailableAmount || (source ? '0' : undefined),
      // [ch24667] Don't show balance for external accounts
      availableDestinationAmount:
        destination?.marketAccount.Ownership === MarketAccountOwnershipEnum.Internal
          ? destinationAccountBalance?.AvailableAmount || (destination ? '0' : undefined)
          : undefined,
    };
  }, [asset, balancesByCurrencyMarketAccountID, destination, source]);

  const handleSubmit = useCallback(() => {
    if (!source?.marketAccount || !destination?.marketAccount) {
      logger.error(new Error(`Unknown Source Account Info or Destination Account Info for ${provider} Transfer`));
      return;
    }
    const newClientID = uuid();
    handleFormUpdate({ clientID: newClientID });
    setIsLoading(true);
    mixpanel.track(MixpanelEvent.InitiateCustomerTransfer);
    initiatePendingTransferTransaction({
      amount,
      clientID: newClientID,
      currency: asset,
      market: provider,
      fromMarketAccountID: source.marketAccount.Name,
      toMarketAccountID: destination.marketAccount.Name,
      description,
      reference,
      transactionID: entity.TransactionID,
      chainCurrency,
    })
      .catch(e => onFailedRequest(e, 'Could not approve pending transfer transaction.'))
      .finally(() => {
        setIsLoading(false);
      });
  }, [
    source,
    destination,
    handleFormUpdate,
    mixpanel,
    initiatePendingTransferTransaction,
    amount,
    asset,
    provider,
    description,
    reference,
    entity.TransactionID,
    onFailedRequest,
    chainCurrency,
  ]);

  const handleRejectTransfer = useCallback(() => {
    if (entity == null) {
      return;
    }
    if (entity.Status === CustomerBalanceTransactionStatusEnum.PendingTransfer) {
      rejectPendingTransferTransaction(entity);
    }
  }, [entity, rejectPendingTransferTransaction]);

  const handleConfirmTransfer = useCallback(() => {
    if (entity == null) {
      return;
    }

    handleSubmit();
  }, [entity, handleSubmit]);

  const allInputsDisabled = isDisabled || isLoading;

  const { errors, disabledInputs } = useTransferFormValidation(
    form,
    allInputsDisabled,
    chainCurrencyOptions,
    availableSourceAmount,
    balancesByCurrencyMarketAccountID
  );

  const handleInitiateTransfer = useCallback(() => {
    if (entity == null) {
      return;
    }
    if (entity.Status === CustomerBalanceTransactionStatusEnum.PendingTransfer) {
      confirmInitiateTransferDialog.open();
    }
  }, [entity, confirmInitiateTransferDialog]);

  return (
    <VStack gap="spacingMedium" w="100%">
      <Portal portalId={portalId}>
        <>
          <Divider />
          <HStack gap="spacingMedium" p="spacingMedium" w="100%">
            <Button
              disabled={allInputsDisabled}
              onClick={handleRejectTransfer}
              variant={ButtonVariants.Negative}
              width="100%"
            >
              Reject
            </Button>
            <Tooltip tooltip={approveDisabledTooltip} targetStyle={{ width: '100%' }}>
              <Box
                onMouseEnter={() => {
                  if (provider && asset) {
                    setShowErrors(true);
                  }
                }}
                w="100%"
              >
                <Button
                  disabled={isApproveDisabled || allInputsDisabled || Object.keys(errors).length > 0}
                  onClick={handleInitiateTransfer}
                  variant={ButtonVariants.Primary}
                  width="100%"
                  data-testid="initiate-transfer-button"
                >
                  Initiate Transfer
                </Button>
              </Box>
            </Tooltip>
          </HStack>
        </>
      </Portal>
      <Box w="100%">
        <FormGroup disabled={allInputsDisabled || disabledInputs.provider} label="Provider">
          <ProvidersSelector
            disabled={allInputsDisabled || disabledInputs.provider}
            value={provider}
            onChange={handleProviderUpdate}
          />
        </FormGroup>
      </Box>
      <HStack w="100%" gap="spacingMedium">
        <Box w="50%">
          <FormGroup disabled={allInputsDisabled || disabledInputs.asset} label="Asset">
            <AssetsSelector
              market={provider}
              disabled={allInputsDisabled || disabledInputs.asset}
              value={asset}
              onChange={handleAssetUpdate}
            />
          </FormGroup>
        </Box>
        <Box w="50%">
          <FormGroup disabled={allInputsDisabled || disabledInputs.amount} label="Amount">
            <NumberInput
              name="amount"
              onChange={handleAmountUpdate}
              autoComplete="off"
              disabled={allInputsDisabled || disabledInputs.amount}
              min="0"
              value={amount}
              suffix={asset}
              data-testid="amount-input"
            />
          </FormGroup>
        </Box>
      </HStack>
      <Box w="100%">
        <FormGroup
          disabled={allInputsDisabled || disabledInputs.source}
          help={
            availableSourceAmount && (
              <>
                Available amount:{' '}
                <Text color="colorTextImportant">
                  {format(availableSourceAmount, {
                    spec: DefaultIncrement,
                  })}{' '}
                  {asset}
                </Text>
              </>
            )
          }
          label="Source"
        >
          <SourcesSelector
            availableAmount={availableSourceAmount}
            currency={asset}
            destination={destination}
            disabled={allInputsDisabled || disabledInputs.source}
            market={provider}
            onChange={handleSourceUpdate}
            source={source}
          />
        </FormGroup>
      </Box>
      <Box w="100%">
        <FormGroup
          disabled={allInputsDisabled || disabledInputs.destination}
          label="Destination"
          help={
            availableDestinationAmount && (
              <>
                Available amount:{' '}
                <Text color="colorTextImportant">
                  {format(availableDestinationAmount, {
                    spec: DefaultIncrement,
                  })}{' '}
                  {asset}
                </Text>
              </>
            )
          }
        >
          <DestinationsSelector
            currency={asset}
            destination={destination}
            disabled={allInputsDisabled || disabledInputs.destination}
            market={provider}
            onChange={handleDestinationUpdate}
            source={source}
          />
        </FormGroup>
      </Box>
      <Divider />
      <HStack w="100%" alignItems="flex-start" gap="spacingComfortable">
        <FormGroup label="Chain" flex="1">
          <SearchSelect
            selection={chainCurrency}
            options={chainCurrencyOptions.chainOptions}
            onChange={handleChainCurrencyUpdate}
            getLabel={chainCurrency => chainCurrency}
            disabled={disableChainSelection}
            touched={showErrors}
            invalid={errors?.chainCurrency != null}
            showClear
            data-testid="chain-select"
          />
        </FormGroup>

        <FormGroup disabled={allInputsDisabled || disabledInputs.reference} label="Reference (Provider)" flex="1">
          <Input
            autoComplete="off"
            disabled={allInputsDisabled || disabledInputs.reference}
            touched={!!touched.reference}
            onChange={handleReferenceUpdate}
            value={reference}
            data-testid="reference-input"
          />
        </FormGroup>
      </HStack>
      <Box w="100%">
        <FormGroup disabled={allInputsDisabled || disabledInputs.description} label="Note (Talos)">
          <Input
            autoComplete="off"
            disabled={allInputsDisabled || disabledInputs.description}
            touched={!!touched.description}
            onChange={handleDescriptionUpdate}
            value={description}
            data-testid="description-input"
          />
        </FormGroup>
      </Box>
      {showErrors && Object.keys(errors).length > 0 && (
        <Box data-testid="error-logs" alignSelf="flex-start">
          {Object.keys(errors).map(error => (
            <FormMessage key={error as string}>{errors[error as string]}</FormMessage>
          ))}
        </Box>
      )}
      <Dialog
        {...confirmInitiateTransferDialog}
        cancelLabel="Cancel"
        confirmLabel="Confirm & Initiate Transfer"
        onConfirm={handleConfirmTransfer}
        title="Confirm Transfer"
      >
        <VStack gap="spacingMedium">
          <VStack gap="spacingDefault" w="100%">
            <HStack justifyContent="space-between" w="100%">
              <Text>Send</Text>
              <InlineFormattedNumber number={amount} currency={asset} align="right" />
            </HStack>
            <HStack justifyContent="space-between" w="100%">
              <Text>Via</Text>
              <Text>{provider}</Text>
            </HStack>
            {entity.CustomerAddressID && (
              <CustomerWithdrawAddressRow color="colorTextDefault" entity={entity} justifyContent="flex-end" w="50%" />
            )}
          </VStack>
          <ConfirmTransferSourceDestination background="backgroundContent" p="spacingHuge" w="100%">
            <VStack gap="spacingComfortable" w="100%">
              <Source p="spacingDefault" w="100%">
                <Text>{source?.label}</Text>
              </Source>
              <Icon icon={IconName.ArrowDown} />
              <Destination p="spacingDefault" w="100%">
                <Text>{destination?.label}</Text>
              </Destination>
            </VStack>
          </ConfirmTransferSourceDestination>
        </VStack>
      </Dialog>
    </VStack>
  );
}
