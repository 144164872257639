import type { IAggFuncParams } from 'ag-grid-community';
import Big from 'big.js';
import type { SizeColumnParams } from '../columns';
import type { SizeValue } from './types';

/** Empty result, don't agg above it */
const EMPTY_NO_AGG_RESULT: SizeValue = { value: '', currency: '', aggregate: false };

/**
 * Aggregates SizeValues originating from the size column
 */
export function buildSizeAggFunc(params?: SizeColumnParams) {
  const { aggregateIf, aggregateEmptyValues } = params ?? {};
  return (aggValue: IAggFuncParams<unknown, SizeValue>): SizeValue => {
    const currencyToSum = new Map<string, SizeValue>();
    const { values } = aggValue;
    const firstCurrency = values.find(item => item.currency)?.currency;
    for (const { value, currency, increment, aggregate } of values) {
      // Return an unaggregateable value immediately once we know that one of our received values is itself signaling that we can't proceed with the aggregation.
      if (aggregate === false) {
        return EMPTY_NO_AGG_RESULT;
      }
      // If we have an aggregateIf function, and it returns false, then we should not aggregate this value.
      if (aggregateIf && !aggregateIf(aggValue)) {
        return EMPTY_NO_AGG_RESULT;
      }

      // return empty result once we hit a bad / unknown currency since we cant know for sure that we're within one single currency anymore.
      // And we only care about it if the value is non-null... otherwise just go ahead and try to perform a best-effort aggregation.
      if ((currency === undefined || currency === '') && !!value) {
        return EMPTY_NO_AGG_RESULT;
      }

      if (value == null || value === '') {
        continue;
      }

      try {
        let bigValue = Big(value);
        // try to get the existing value for this currency and add the incoming value to it
        const maybeExistingBig = currencyToSum.get(currency);
        if (maybeExistingBig?.value) {
          bigValue = bigValue.plus(maybeExistingBig.value ?? '0');
        }

        currencyToSum.set(currency, { value: bigValue, currency, increment });
        if (currencyToSum.size > 1) {
          return EMPTY_NO_AGG_RESULT; // return early immediately once we know that theres more than one currency in this column
        }
      } catch (e) {
        return EMPTY_NO_AGG_RESULT; // fail gracefully and return an empty result
      }
    }

    if (currencyToSum.size === 1) {
      return currencyToSum.values().next().value;
    } else {
      // if aggregateEmptyValues is true and have a known currency, return 0
      if (currencyToSum.size === 0 && aggregateEmptyValues && firstCurrency !== undefined && firstCurrency !== '') {
        return { value: '0', currency: firstCurrency, aggregate: true };
      }
      return EMPTY_NO_AGG_RESULT;
    }
  };
}
