import {
  FilterClauseType,
  IconName,
  TransferStatusEnum,
  cleanupInitialFilterDateRange,
  filterExistsAndExcludes,
  useCurrenciesContext,
  useMarketAccountsContext,
  useMarketsContext,
  type BlotterTableClientLocalFilter,
  type BlotterTableFilter,
  type DateRangeFilter,
  type FilterClause,
  type FilterableProperty,
  type Transfer,
  type UsePersistedBlotterTable,
} from '@talos/kyoko';
import { useUsers } from 'hooks/useUsers';
import { compact, isEqual, keys, uniq } from 'lodash';
import { useCallback, useMemo, useState, type SetStateAction } from 'react';

export interface TransfersBlotterFilter extends DateRangeFilter {
  Provider?: string[];
  Currency?: string[];
  Status?: string[];
  Submitter?: string[];
  Account?: string[];
}

export interface UseTransfersFilterParams<TData extends Transfer> {
  persistedBlotterTable: UsePersistedBlotterTable<TData>;
}
export function useTransfersFilter<TData extends Transfer>({ persistedBlotterTable }: UseTransfersFilterParams<TData>) {
  const { initialFilter, onFilterChanged: saveFilter } = persistedBlotterTable;
  const [filter, setFilter] = useState(() => cleanupInitialFilterDateRange(initialFilter));
  const { marketAccountsByID } = useMarketAccountsContext();

  const changeFilter = useCallback(
    (action: SetStateAction<BlotterTableFilter>) => {
      const priorFilter = filter;
      const newFilter = action instanceof Function ? action(filter) : action;

      if (!isEqual(priorFilter, newFilter)) {
        setFilter(newFilter);
        saveFilter(newFilter);
      }
    },
    [filter, saveFilter]
  );

  const clientSideFilter = useCallback<BlotterTableClientLocalFilter<TData>>(
    row => {
      const data = row.data;

      if (filterExistsAndExcludes(filter, 'Provider', data, 'Market')) {
        return false;
      }
      if (filterExistsAndExcludes(filter, 'Currency', data, 'Currency')) {
        return false;
      }
      if (filterExistsAndExcludes(filter, 'Status', data, 'Status')) {
        return false;
      }
      if (
        filterExistsAndExcludes(
          filter,
          'Account',
          // Resolve the MarketAccountID to a MarketAccountName for filtering
          // We are doing this because the backend is sending MarketAccountID as opposed to Name.
          { Account: data ? marketAccountsByID.get(data?.OwnerAccountID)?.Name : undefined },
          'Account'
        )
      ) {
        return false;
      }
      if (filterExistsAndExcludes(filter, 'Submitter', data, 'User')) {
        return false;
      }
      return true;
    },
    [filter, marketAccountsByID]
  );
  const { marketsByName, marketsList } = useMarketsContext();
  const { currenciesList } = useCurrenciesContext();
  const users = useUsers();
  const { marketAccountsList, marketAccountsByName } = useMarketAccountsContext();

  const filterableProperties = useMemo(
    () =>
      compact<FilterableProperty<keyof TransfersBlotterFilter>>([
        {
          key: 'Provider',
          label: 'Provider',
          control: 'multi-select',
          icon: IconName.PresentationChartLine,
          options: marketsList.map(market => market.Name),
          getOptionLabel: (marketName: string) => marketsByName.get(marketName)?.DisplayName ?? marketName,
        },
        {
          key: 'Currency',
          label: 'Currency',
          icon: IconName.CurrencyDollar,
          options: currenciesList.map(cur => cur.Symbol),
          getOptionLabel: (option: string) => option,
        },
        {
          key: 'Status',
          label: 'Status',
          icon: IconName.CheckCircle,
          options: Object.keys(TransferStatusEnum),
          getOptionLabel: status => status,
        },
        {
          key: 'Submitter',
          label: 'Submitter',
          icon: IconName.User,
          options: uniq(users.map(u => u.Name) || []),
          getOptionLabel: (option: string) => option,
        },
        {
          key: 'Account',
          label: 'Account',
          icon: IconName.Identification,
          options: marketAccountsList.map(marketAccount => marketAccount.Name),
          getOptionLabel: (marketName: string) => marketAccountsByName.get(marketName)?.DisplayName ?? marketName,
        },
      ]),
    [currenciesList, marketAccountsByName, marketAccountsList, marketsByName, marketsList, users]
  );
  const initialFilterClauses = useMemo(() => {
    const clauses: FilterClause[] = [];
    if (filter) {
      (keys(filter) as (keyof TransfersBlotterFilter)[]).forEach((key: keyof TransfersBlotterFilter) => {
        switch (key) {
          case '_start':
          case 'StartDate':
          case 'EndDate':
            return;
          default:
            clauses.push({
              key: key,
              type: FilterClauseType.INCLUSIVE,
              selections: filter[key] as string[],
            });
        }
      });
    }
    return clauses;
  }, [filter]);

  return {
    filter,
    filterableProperties,
    initialFilterClauses,
    changeFilter,
    clientSideFilter,
  };
}
