import { useCallback, useState } from 'react';

import {
  AccordionGroup,
  BLOTTER_TABLE_FILTERS_CONTAINER_ID,
  Box,
  MixpanelEvent,
  Tab,
  TabList,
  TabPanels,
  TabSize,
  Tabs,
  VStack,
  beginningOfDay,
  formattedDateForSubscription,
  tabLabelerEnumerated,
  useConstant,
  useDynamicCallback,
  useMixpanel,
  usePersistedTabs,
  usePortal,
  useTabs,
  type Column,
  type TabProps,
} from '@talos/kyoko';
import { addDays } from 'date-fns';
import { SUB_HEADER_HEIGHT } from 'styles/dimensions';
import { TradeAllocationsBlotterTable } from './TradeAllocationsBlotterTable';
import type { TradeAllocationFilter } from './useTradeAllocationFilter';

export * from './TradeAllocationsBlotterTable';

export interface TradeAllocationsTabProps extends TabProps {
  defaultFilter: TradeAllocationFilter;
  defaultColumns?: Column[];
}

export const PERSISTED_TABS_ID = `TRADE_ALLOCATIONS`;

const PRESET_TABS: TradeAllocationsTabProps[] = [
  {
    label: 'Allocations',
    id: 'recent-allocations',
    closable: false,
    editable: false,
    defaultFilter: {
      StartDate: formattedDateForSubscription(addDays(beginningOfDay(), -2)),
    },
  },
];

const NEW_TAB_DEFAULTS: TradeAllocationsTabProps = {
  defaultFilter: {
    StartDate: formattedDateForSubscription(addDays(beginningOfDay(), -2)),
  },
};

const tabLabeler = tabLabelerEnumerated('Allocations');

export function TradeAllocations() {
  const mixpanel = useMixpanel();
  const [initialFiltersOpen, setInitialFiltersOpen] = useState(false);

  const handleSelect = useDynamicCallback(() => setInitialFiltersOpen(false));
  const handleAdd = useDynamicCallback(() => {
    mixpanel.track(MixpanelEvent.AddTab);
    setInitialFiltersOpen(true);
  });

  const defaultInitialItems = useConstant<TradeAllocationsTabProps[]>(PRESET_TABS);
  const [excludedIDs] = useState(PRESET_TABS.map(tab => tab.id || ''));

  const persistedTabs = usePersistedTabs<TradeAllocationsTabProps>(PERSISTED_TABS_ID, {
    defaultInitialItems,
    defaultInitialSelectedIndex: 0,
    excludedIDs,
    onSelect: handleSelect,
  });

  const tabs = useTabs<TradeAllocationsTabProps>({
    ...persistedTabs,
    showAddTab: true,
    tabLabeler,
    onAdd: handleAdd,
  });

  const handleCloneTab = useCallback(
    (filter: TradeAllocationFilter, columns: Column[]) => {
      mixpanel.track(MixpanelEvent.CloneTab);
      tabs.clone(tabs.selectedIndex, {
        defaultColumns: columns,
        defaultFilter: filter,
      });
    },
    [mixpanel, tabs]
  );

  const { setPortalRef: filtersContainerRef } = usePortal(BLOTTER_TABLE_FILTERS_CONTAINER_ID);

  return (
    <VStack justifyContent="start" alignItems="left" h="100%">
      <Tabs {...tabs} style={{ height: `calc(100% - ${SUB_HEADER_HEIGHT}px)` }} size={TabSize.Large}>
        <TabList isBordered rightItems={<Box ref={filtersContainerRef} />}>
          {tabs.items.map((tab, idx) => (
            <Tab key={idx} {...tab} reorderable={true} />
          ))}
        </TabList>
        <TabPanels style={{ flex: '1', display: 'flex', flexDirection: 'column' }}>
          {tabs.items.map((tab, idx) => (
            <AccordionGroup key={tab.id}>
              <TradeAllocationsBlotterTable
                key={idx}
                blotterID={`${PERSISTED_TABS_ID}/${tab.id}`}
                blotterPersistID={PERSISTED_TABS_ID}
                tabLabel={tab.label}
                defaultColumns={'defaultColumns' in tab ? tab.defaultColumns : NEW_TAB_DEFAULTS.defaultColumns}
                defaultFilter={'defaultFilter' in tab ? tab.defaultFilter : NEW_TAB_DEFAULTS.defaultFilter}
                onCloneTab={handleCloneTab}
                initialIsOpen={initialFiltersOpen}
                showCloneTab={true}
              />
            </AccordionGroup>
          ))}
        </TabPanels>
      </Tabs>
    </VStack>
  );
}
