import type { GetContextMenuItems, GetContextMenuItemsParams, MenuItemDef } from 'ag-grid-enterprise';
import Big from 'big.js';
import { compact, every } from 'lodash';

import {
  ACTION,
  IconName,
  MixpanelEvent,
  abbreviateId,
  filterByCellValueMenuItem,
  getOrdersForModify,
  getRowNodesToOperateOn,
  isOrderComplete,
  useDisclosure,
  useDynamicCallback,
  useGetDefaultContextMenuItems,
  useMixpanel,
  useUserContext,
  type ExecutionReport,
  type FilterableProperty,
  type MixpanelInstance,
  type Order,
  type UseDisclosureReturn,
  type UseFilterBuilderOutput,
} from '@talos/kyoko';
import { OMSView } from 'components/OMS/OMSView';
import {
  modifyOrderRequested as modifySalesOrder,
  resubmitOrderRequested as resubmitSalesOrder,
} from 'components/OMS/SalesOrder/SalesOrderSlice';
import type { GenerateOrderDetailsRoute } from 'containers/Trading/Markets/OrderDetails/types';
import { useAdminUtils, useOrderAccess, useRoleAuth } from 'hooks';
import { useOrders, useSubAccounts } from 'providers';
import { useAppStateDispatch } from 'providers/AppStateProvider';
import { useTradingSettings } from 'providers/TradingSettingsContext';
import { useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { useDetailsDrawer } from '../../../components/DetailsDrawer/useDetailsDrawer';
import { modifyOrder, resubmitOrder } from '../../../components/OMS/NewOrder/OrderSlice';
import { resubmitRFQ } from '../../../components/OMS/NewRFQ/RFQSlice';
import { openView } from '../../../components/OMS/OMSSlice';
import { mapOrderToCustomerOrder } from '../../../components/OMS/utils';
import { CancelSelectedDialog, ForceCancelSelectedDialog } from '../CancelSelectedDialog';
import { useCopyCustomerOrderTextMenuItem } from '../CustomerOrders/useCopyCustomerOrderTextMenuItem';
import {
  BulkModifyDialog,
  BulkModifyGroupDialog,
  BulkModifySubAccountDialog,
  BulkModifyWarningDialog,
  BulkResumeDialog,
} from './Dialogs';
import { colIDToFilterBuilderKey } from './useOrderFilter';

export const useOrderMenu = ({
  openClause,
  filterableProperties,
  generateOrderDetailsRoute,
}: {
  openClause: UseFilterBuilderOutput['addAndOpenClause'];
  filterableProperties: FilterableProperty<string>[];
  generateOrderDetailsRoute: GenerateOrderDetailsRoute;
}): {
  getContextMenuItems: GetContextMenuItems<Order>;
  dialogComponents: JSX.Element[];
} => {
  const selectedOrdersRef = useRef<Order[]>([]);

  const mixpanel = useMixpanel();
  const history = useHistory();
  const { openDetailsDrawer } = useDetailsDrawer();
  const { pause, resume, cancel } = useOrders();
  const { forceCancelOrder } = useAdminUtils();
  const { isAuthorized } = useRoleAuth();
  const { user } = useUserContext();
  const { enableGroups, confirmOrderResume, enableSalesWorkflow, enableCustomerBlotterColumns } = useTradingSettings();
  const { subAccountsEnabled } = useSubAccounts();
  const checkOrderAccess = useOrderAccess();

  const resumeDialog = useDisclosure();
  const cancelDialog = useDisclosure();
  const forceCancelDialog = useDisclosure();
  const modifySubAccountDialog = useDisclosure();
  const modifyGroupDialog = useDisclosure();
  const bulkModifyDialog = useDisclosure();
  const modifyWarningDialog = useDisclosure();
  const dispatch = useAppStateDispatch();

  const onResume = useDynamicCallback((orders: Order[]) => {
    if (confirmOrderResume) {
      resumeDialog.open();
    } else {
      orders.forEach(order => resume(order.OrderID));
    }
  });

  const cancelItem = useDynamicCallback((orders: Order[]) => cancelOrderMenuItem({ orders, cancelDialog, mixpanel }));
  const forceCancelItem = useDynamicCallback((orders: Order[]) =>
    forceCancelOrderMenuItem({ orders, forceCancelDialog, mixpanel })
  );
  const resumeItem = useDynamicCallback((orders: Order[]) => resumeOrderMenuItem({ orders, onResume, mixpanel }));
  const modifyWithNewOrderForm = (order: Order | ExecutionReport) => {
    dispatch(modifyOrder(order));
    dispatch(openView(OMSView.NewOrderForm));
  };

  const modifyItem = useDynamicCallback((orders: Order[]) =>
    modifyOrderMenuItem({
      orders,
      modifyWarningDialog,
      name: user.Name,
      modify: modifyWithNewOrderForm,
      mixpanel,
    })
  );
  const modifyCustomerItem = useDynamicCallback((orders: Order[]) =>
    modifyCustomerOrderMenuItem({
      orders,
      modifyWarningDialog,
      name: user.Name,
      modify: order => {
        const customerOrder = mapOrderToCustomerOrder(order);
        dispatch(modifySalesOrder({ order: customerOrder, principalOrderID: order.OrderID }));
        dispatch(openView(OMSView.SalesOrder));
      },
      mixpanel,
      disabled: !enableSalesWorkflow,
    })
  );
  const modifySubaccountItem = useDynamicCallback((orders: Order[]) =>
    modifySubAccountMenuItem({
      orders,
      modifySubAccountDialog,
      subAccountsEnabled,
      mixpanel,
    })
  );
  const modifyGroupItem = useDynamicCallback((orders: Order[]) =>
    modifyGroupMenuItem({ orders, modifyGroupDialog, enableGroups, mixpanel })
  );
  const openDetailsItem = useDynamicCallback((orders: Order[]) =>
    openDetailsMenuItem({ orders, mixpanel, history, generateOrderDetailsRoute })
  );
  const openAnalyticsItem = useDynamicCallback((orders: Order[]) =>
    openAnalyticsMenuItem({ orders, openDetailsDrawer, mixpanel })
  );
  const pauseMenuItem = useDynamicCallback((orders: Order[]) =>
    pauseOrderMenuItem({ orders, onPause: pause, mixpanel })
  );
  const resubmitMenuItem = useDynamicCallback((orders: Order[]) =>
    resubmitOrderMenuItem({
      orders,
      resubmitOrder: order => {
        if (order.ParentOrderID) {
          const customerOrder = mapOrderToCustomerOrder(order);
          dispatch(resubmitSalesOrder(customerOrder));
          dispatch(openView(OMSView.SalesOrder));
        } else {
          dispatch(resubmitOrder({ order }));
          dispatch(openView(OMSView.NewOrderForm));
        }
      },
      resubmitRFQ: order => {
        dispatch(openView(OMSView.RFQForm));
        dispatch(resubmitRFQ(order));
      },
      mixpanel,
    })
  );
  const resubmitRemainingMenuItem = useDynamicCallback((orders: Order[]) => {
    const resubmitWithNewOrderForm = (order, useRemaining) => {
      dispatch(resubmitOrder({ order, useRemaining }));
      dispatch(openView(OMSView.NewOrderForm));
    };

    return resubmitRemainingOrdersMenuItem({
      orders,
      openResubmitOrder: resubmitWithNewOrderForm,
      mixpanel,
    });
  });

  const modifyItems = useDynamicCallback((orders: Order[]) =>
    modifyOrderMenuItems({ orders, mixpanel, name: user.Name, bulkModifyDialog, modifyWarningDialog })
  );

  const getMenuItems = useDynamicCallback((orders: Order[]) => {
    let menuItems: (MenuItemDef | null)[] = [openAnalyticsItem(orders)];

    // We only show customer order related menu items if all selected orders are customer orders
    const selectionIncludesCustomerOrders = orders.every(o => !!o.ParentOrderID);

    menuItems.push(openDetailsItem(orders));

    // Only add the Customer Order Execution Copy Details menu item if customer columns are enabled
    if (enableCustomerBlotterColumns) {
      menuItems.push(copyCustomerOrderExecutionTextMenuItem(orders.at(0)));
    }

    const allowedToModifyOrders = every(orders, order => checkOrderAccess(order));
    if (allowedToModifyOrders) {
      const modifyOrderActions = compact([
        // The items without a permission check opens a dialog where we check the permission
        isAuthorized(ACTION.PAUSE_ORDER) && pauseMenuItem(orders),
        cancelItem(orders),
        isAuthorized(ACTION.FORCE_CANCEL_ORDER) && forceCancelItem(orders),
        isAuthorized(ACTION.SUBMIT_ORDER) && modifyItem(orders),
        isAuthorized(ACTION.VIEW_SALES_ORDER_FORM) && modifyCustomerItem(orders),
        modifyItems(orders),
        isAuthorized(ACTION.RESUME_ORDER) && resumeItem(orders),
        isAuthorized(ACTION.SUBMIT_ORDER) && modifySubaccountItem(orders),
        isAuthorized(ACTION.SUBMIT_ORDER) && modifyGroupItem(orders),
      ]);
      menuItems.push(...modifyOrderActions);
      if (isAuthorized(ACTION.SUBMIT_ORDER)) {
        menuItems.push(resubmitMenuItem(orders));
        menuItems.push(resubmitRemainingMenuItem(orders));
      }
    }

    if (!selectionIncludesCustomerOrders) {
      menuItems = menuItems.filter(item => item?.name !== 'Modify Customer Order');
    }

    return menuItems;
  });

  const getDefaultContextMenuItems = useGetDefaultContextMenuItems();
  const copyCustomerOrderExecutionTextMenuItem = useCopyCustomerOrderTextMenuItem();

  const getContextMenuItems: GetContextMenuItems<Order> = useDynamicCallback((params: GetContextMenuItemsParams) => {
    selectedOrdersRef.current = getRowNodesToOperateOn(params).map(node => node.data);
    const menuItems = getMenuItems(selectedOrdersRef.current);

    return compact([
      ...filterByCellValueMenuItem({
        params,
        filterableProperties,
        openClause,
        colIDToFilterBuilderKey,
        mixpanel,
      }),
      ...menuItems,
      ...getDefaultContextMenuItems(params),
    ]);
  });

  return {
    getContextMenuItems,
    dialogComponents: [
      <BulkResumeDialog
        key="resume"
        selectedOrders={resumeItem(selectedOrdersRef.current)?.ordersForResume}
        resumeDialog={resumeDialog}
      />,
      <CancelSelectedDialog
        key="cancel"
        selectedItems={cancelItem(selectedOrdersRef.current)?.ordersForCancel}
        cancelSelectedDialog={cancelDialog}
        onConfirm={orders => orders.forEach(order => cancel(order.OrderID))}
      />,
      <ForceCancelSelectedDialog
        key="force-cancel"
        selectedItems={selectedOrdersRef.current}
        forceCancelSelectedDialog={forceCancelDialog}
        onConfirm={orders => orders.forEach(order => forceCancelOrder(order.OrderID))}
      />,
      <BulkModifyWarningDialog
        key="modify-warn"
        {...modifyWarningDialog}
        onConfirm={() => bulkModifyDialog.open()}
        selectedOrders={getOrdersForModify(selectedOrdersRef.current)}
      />,
      <BulkModifySubAccountDialog
        key="modify-subaccount"
        selectedOrders={modifySubaccountItem(selectedOrdersRef.current)?.ordersForSubAccountModify}
        {...modifySubAccountDialog}
      />,
      <BulkModifyGroupDialog
        key="modify-group"
        selectedOrders={modifyGroupItem(selectedOrdersRef.current)?.ordersForGroupModify}
        {...modifyGroupDialog}
      />,
      <BulkModifyDialog
        key="bulk-modify"
        selectedOrders={getOrdersForModify(selectedOrdersRef.current)}
        closeOnClickOutside={false}
        {...bulkModifyDialog}
      />,
    ],
  };
};

interface OrderMenuItemProps {
  orders: Order[];
  mixpanel: MixpanelInstance;
}

const pauseOrderMenuItem = ({ orders, onPause, mixpanel }: OrderMenuItemProps & { onPause: (id: string) => any }) => {
  if (!orders) {
    return null;
  }

  const ordersForPause = orders.filter(order => order.isPausable);
  if (ordersForPause.length === 0) {
    return null;
  }

  if (ordersForPause.length === 1) {
    const order = ordersForPause[0];
    return {
      name: `Pause Order (#<b>${abbreviateId(order.OrderID)}</b>)`,
      action: () => {
        mixpanel.track(MixpanelEvent.PauseOrder);
        onPause(order.OrderID);
      },
      icon: `<i class="ag-icon ${IconName.Pause}"/>`,
    };
  }
  return {
    name: `Pause ${ordersForPause.length} Orders`,
    action: () => {
      mixpanel.track(MixpanelEvent.PauseOrder);
      ordersForPause.forEach(order => onPause(order.OrderID));
    },
    icon: `<i class="ag-icon ${IconName.Pause}"/>`,
  };
};

const cancelOrderMenuItem = ({
  orders,
  cancelDialog,
}: OrderMenuItemProps & {
  cancelDialog: UseDisclosureReturn;
}) => {
  const ordersForCancel = orders.filter(order => !isOrderComplete(order.OrdStatus));
  if (ordersForCancel.length === 0) {
    return null;
  }

  const name = ordersForCancel.length === 1 ? 'Cancel Order' : `Cancel ${ordersForCancel.length} Orders`;
  return {
    name,
    action: () => cancelDialog.open(),
    icon: `<i class="ag-icon ${IconName.Trash}"/>`,
    ordersForCancel,
  };
};
const forceCancelOrderMenuItem = ({
  orders,
  forceCancelDialog,
}: OrderMenuItemProps & {
  forceCancelDialog: UseDisclosureReturn;
}) => {
  if (orders.length === 0) {
    return null;
  }

  const name = orders.length === 1 ? 'Force Cancel Order' : ` Force Cancel ${orders.length} Orders`;
  return {
    name,
    action: () => forceCancelDialog.open(),
    icon: `<i class="ag-icon ${IconName.Trash}"/>`,
  };
};

const modifyOrderMenuItem = ({
  orders,
  modifyWarningDialog,
  name,
  modify,
  mixpanel,
}: OrderMenuItemProps & {
  modifyWarningDialog: UseDisclosureReturn;
  name: string;
  modify: (order: Order) => any;
}) => {
  const ordersForModify = getOrdersForModify(orders);
  if (ordersForModify.length !== 1) {
    return null;
  }

  return {
    name: 'Modify Order',
    action: () => {
      mixpanel.track(MixpanelEvent.ModifyOrder);
      const selectedOrder = ordersForModify[0];
      selectedOrder.User === name ? modify(selectedOrder) : modifyWarningDialog.open();
    },
    icon: `<i class="ag-icon ${IconName.Pencil}"/>`,
    // if parentOrderId is set it means it's a customer order, and we don't support modifying customer hedge orders atm
    disabled: !ordersForModify[0].isModifiable,
  };
};

const modifyCustomerOrderMenuItem = ({
  orders,
  modifyWarningDialog,
  name,
  modify,
  mixpanel,
  disabled,
}: OrderMenuItemProps & {
  modifyWarningDialog: UseDisclosureReturn;
  name: string;
  modify: (order: Order) => any;
  disabled?: boolean;
}) => {
  const ordersForModify = getOrdersForModify(orders);
  if (ordersForModify.length !== 1) {
    return null;
  }

  return {
    name: 'Modify Customer Order',
    action: () => {
      mixpanel.track(MixpanelEvent.ModifySalesOrder);
      const selectedOrder = ordersForModify[0];
      modify(selectedOrder);
    },
    icon: `<i class="ag-icon ${IconName.Pencil}"/>`,
    disabled: !ordersForModify[0].isCustomerOrderModifiable || disabled,
  };
};

const modifyOrderMenuItems = ({
  orders,
  mixpanel,
  name,
  bulkModifyDialog,
  modifyWarningDialog,
}: OrderMenuItemProps & {
  name: string;
  bulkModifyDialog: UseDisclosureReturn;
  modifyWarningDialog: UseDisclosureReturn;
}) => {
  const ordersForModify = getOrdersForModify(orders);
  if (ordersForModify.length < 2) {
    return null;
  }

  return {
    name: `Modify End Time`,
    action: () => {
      mixpanel.track(MixpanelEvent.ModifyOrders);
      ordersForModify.some(order => order.User !== name) ? modifyWarningDialog.open() : bulkModifyDialog.open();
    },
    icon: `<i class="ag-icon ${IconName.Pencil}"/>`,
  };
};

interface OpenOrderMenuItemProps {
  orders: Pick<Order, 'OrderID'>[];
  mixpanel: MixpanelInstance;
  text?: string;
}

export const openDetailsMenuItem = ({
  orders,
  history,
  mixpanel,
  generateOrderDetailsRoute,
  text,
}: OpenOrderMenuItemProps & {
  history: ReturnType<typeof useHistory>;
  generateOrderDetailsRoute: GenerateOrderDetailsRoute;
}) => {
  if (!orders || orders.length > 1) {
    return null;
  }
  const order = orders[0];
  return {
    name: text ?? `Open details`,
    action: () => {
      mixpanel.track(MixpanelEvent.OpenOrderDetails);
      history.push(generateOrderDetailsRoute({ orderID: order.OrderID, tab: 'details', type: 'principal' }));
    },
    icon: `<i class="ag-icon ${IconName.Deepdive}"/>`,
  };
};

const openAnalyticsMenuItem = ({
  orders,
  openDetailsDrawer,
  mixpanel,
}: OrderMenuItemProps & { openDetailsDrawer: (props: { entity: Order }) => void }) => {
  if (!orders || orders.length > 1) {
    return null;
  }
  const order = orders[0];
  return {
    name: `Open analytics`,
    action: () => {
      mixpanel.track(MixpanelEvent.OpenOrderAnalytics);
      openDetailsDrawer({ entity: order });
    },
    icon: `<i class="ag-icon ${IconName.ChartLine}"/>`,
  };
};

const resumeOrderMenuItem = ({
  orders,
  onResume,
  mixpanel,
}: OrderMenuItemProps & { onResume: (orders: Order[]) => void }) => {
  if (!orders) {
    return null;
  }

  const ordersForResume = orders.filter(order => !isOrderComplete(order.OrdStatus) && order.isPaused);
  if (ordersForResume.length === 0) {
    return null;
  }

  if (ordersForResume.length === 1) {
    const order = ordersForResume[0];
    return {
      name: `Resume Order (#<b>${abbreviateId(order.OrderID)}</b>)`,
      action: () => {
        mixpanel.track(MixpanelEvent.ResumeOrder);
        onResume([order]);
      },
      icon: `<i class="ag-icon ${IconName.PlayCircle}"/>`,
      ordersForResume,
    };
  }
  return {
    name: `Resume ${ordersForResume.length} Orders`,
    action: () => {
      mixpanel.track(MixpanelEvent.ResumeOrder);
      onResume(ordersForResume);
    },
    icon: `<i class="ag-icon ${IconName.PlayCircle}"/>`,
    ordersForResume,
  };
};

const modifySubAccountMenuItem = ({
  orders,
  modifySubAccountDialog,
  subAccountsEnabled = false,
  mixpanel,
}: OrderMenuItemProps & {
  modifySubAccountDialog: UseDisclosureReturn;
  subAccountsEnabled: boolean;
}) => {
  const ordersForSubAccountModify = orders.filter(
    order => isOrderComplete(order.OrdStatus) && (order.Allocation != null || subAccountsEnabled)
  );
  if (ordersForSubAccountModify.length === 0) {
    return null;
  }
  if (ordersForSubAccountModify.length === 1) {
    const order = ordersForSubAccountModify[0];
    const name = order.Allocation != null ? 'Modify Sub Account(s)' : 'Modify Sub Account';
    return {
      name,
      action: () => {
        modifySubAccountDialog.open();
        mixpanel.track(MixpanelEvent.ModifySubaccount);
      },
      icon: `<i class="ag-icon ${IconName.Pencil}"/>`,
      ordersForSubAccountModify,
    };
  }

  return {
    name: `Modify Sub Account(s) for ${ordersForSubAccountModify.length} Orders`,
    action: () => {
      modifySubAccountDialog.open();
      mixpanel.track(MixpanelEvent.ModifySubaccount);
    },
    icon: `<i class="ag-icon ${IconName.Pencil}"/>`,
    ordersForSubAccountModify,
  };
};

const modifyGroupMenuItem = ({
  orders,
  modifyGroupDialog,
  enableGroups,
  mixpanel,
}: OrderMenuItemProps & {
  modifyGroupDialog: UseDisclosureReturn;
  enableGroups: boolean;
}) => {
  const ordersForGroupModify = orders.filter(order => isOrderComplete(order.OrdStatus) && enableGroups);
  if (ordersForGroupModify.length === 0) {
    return null;
  }
  if (ordersForGroupModify.length === 1) {
    return {
      name: 'Modify Group',
      action: () => {
        mixpanel.track(MixpanelEvent.ModifyOrderGroup);
        modifyGroupDialog.open();
      },
      icon: `<i class="ag-icon ${IconName.Pencil}"/>`,
      ordersForGroupModify,
    };
  }
  return null; // no bulk order group modification
};

const resubmitOrderMenuItem = ({
  orders,
  resubmitOrder,
  resubmitRFQ,
  mixpanel,
}: OrderMenuItemProps & {
  resubmitOrder: (order: Order, useRemaining: boolean) => any;
  resubmitRFQ: (order: Order) => any;
}) => {
  if (orders.length !== 1) {
    return null;
  }

  // No bulk resubmit
  const order = orders[0];
  if (order.QuoteID) {
    return {
      name: 'Resubmit RFQ',
      action: () => {
        resubmitRFQ(order);
      },
      icon: `<i class="ag-icon ${IconName.Repeat}"/>`,
    };
  }
  return {
    name: 'Resubmit Order',
    action: () => {
      mixpanel.track(MixpanelEvent.OpenResubmitOrder);
      resubmitOrder(order, false);
    },
    icon: `<i class="ag-icon ${IconName.Repeat}"/>`,
  };
};

const resubmitRemainingOrdersMenuItem = ({
  orders,
  openResubmitOrder,
  mixpanel,
}: OrderMenuItemProps & {
  openResubmitOrder: (id: Order, useRemaining: boolean) => any;
}) => {
  if (orders.length !== 1) {
    return null;
  } // Never show when multi selecting
  const ordersForResubmit = orders.filter(order => isOrderComplete(order.OrdStatus));
  if (ordersForResubmit.length !== 1) {
    return null;
  } // No bulk resubmit

  const order = ordersForResubmit[0];
  const { OrderQty, CumQty } = order;
  const remaining = Big(OrderQty).minus(CumQty || '0');
  if (remaining.gt(0)) {
    return {
      name: 'Resubmit Remaining',
      action: () => {
        mixpanel.track(MixpanelEvent.OpenResubmitRemaining);
        openResubmitOrder(order, true);
      },
      icon: `<i class="ag-icon ${IconName.RepeatOnce}"/>`,
    };
  }
  return null;
};
