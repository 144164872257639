import {
  LegDirectionEnum,
  OrderMarketStatusEnum,
  SettleValueTypeEnum,
  getCurrencyColor,
  getMarketColor,
  getVWAPFromMarkets,
  isMultileg,
  isPerpetualSwap,
  isUnifiedLiquidityOrder,
  notEmpty,
  setAlpha,
  useCurrenciesContext,
  useMarketsContext,
  useSecuritiesContext,
  type IOMSExecutionReport4203Markets,
  type Order,
  type RequiredProperties,
} from '@talos/kyoko';
import Big from 'big.js';
import { sortBy } from 'lodash';
import { useMemo } from 'react';
import { useTheme } from 'styled-components';
import { REMAINDER, type MarketDistributionItem } from './types';
import { getOrderQuantities, useGetMarketProportionGetter } from './utils';

type UseMarketDistributionProps = {
  order: Order;
  includeZeroCumQty?: boolean;
  /**
   * GroupBy key, when null is specified no grouping is done.
   * */
  variant: 'Symbol' | 'Market' | null;
  legIndex?: 0 | 1;
};

export function useMarketDistribution({
  order,
  legIndex,
  includeZeroCumQty = false,
  variant,
}: UseMarketDistributionProps) {
  const { marketsByName } = useMarketsContext();
  const { securitiesBySymbol } = useSecuritiesContext();
  const { currenciesBySymbol } = useCurrenciesContext();

  const theme = useTheme();
  const sortByKey = variant || 'Market';

  const orderSecurity = securitiesBySymbol.get(order.Symbol);
  const isMultilegOrder = isMultileg(orderSecurity);
  // If the order is multileg, we'll need to use the underlying security for currency display information.
  const maybeLegSecurity = useMemo(() => {
    if (orderSecurity && isMultilegOrder && legIndex !== undefined) {
      const legSymbol = orderSecurity.MultilegDetails?.Legs[legIndex].Symbol || '';
      return securitiesBySymbol.get(legSymbol);
    }
  }, [legIndex, securitiesBySymbol, orderSecurity, isMultilegOrder]);

  /** Since we're not sure if we're displaying a leg or a regular order, we need to know which security to base
   *  our calculations on. */
  const tradedSecurity = maybeLegSecurity || orderSecurity;
  const getMarketProportion = useGetMarketProportionGetter({ order, orderSecurity, tradedSecurity, legIndex });

  const isOrderUnifiedLiquidity = isUnifiedLiquidityOrder(order);
  const sizeCurrency =
    isOrderUnifiedLiquidity && isPerpetualSwap(tradedSecurity)
      ? tradedSecurity?.SettleValueType === SettleValueTypeEnum.Inverted
        ? tradedSecurity?.QuoteCurrency
        : tradedSecurity?.BaseCurrency
      : tradedSecurity?.PositionCurrency;
  const priceCurrency: string | undefined = tradedSecurity?.QuoteCurrency;
  const sizeIncrement = currenciesBySymbol.get(sizeCurrency ?? '')?.DefaultIncrement;
  const priceIncrement = tradedSecurity?.DefaultPriceIncrement;

  const distribution = useMemo(() => {
    if (!order || !orderSecurity || !tradedSecurity) {
      return [];
    }

    const tradedMarkets = order.Markets.filter(
      (market): market is RequiredProperties<IOMSExecutionReport4203Markets, NonNullable<'Market'>> =>
        // Make sure market is nonNull for secure id
        market.Market != null &&
        // Remove zero cumQty unless specified to include it
        (!(market.CumQty == null || Big(market.CumQty).eq(0)) || includeZeroCumQty) &&
        // Remove disabled market statuses unless traded on
        !(includeZeroCumQty && market.MarketStatus === OrderMarketStatusEnum.Disabled) &&
        // Filter on legIndex if it is defined
        (isMultilegOrder ? market.LegIndex === legIndex : legIndex === undefined)
    );

    const series: MarketDistributionItem[] = sortBy(
      getVWAPFromMarkets(order, securitiesBySymbol, tradedMarkets, variant),
      item => item[sortByKey]
    )
      .map<MarketDistributionItem | undefined>(market => {
        const marketProportion = getMarketProportion(market);

        if (marketProportion == null) {
          return undefined;
        }

        // Due to unified liquidity: each Order.Market can trade on a different symbol than
        // their parent. The Size/Price-Increments are shared, but the Size/Price-Currencies needs to be
        // available on each DistributionSeries
        // market.Symbol should always exist, but for some markets it does not. Then grab the order.Symbol
        const symbolSecurity = securitiesBySymbol.get(market.Symbol ?? order.Symbol);
        const currency = symbolSecurity?.QuoteCurrency;

        const sizeCurrency =
          isOrderUnifiedLiquidity && isPerpetualSwap(symbolSecurity)
            ? symbolSecurity?.SettleValueType === SettleValueTypeEnum.Inverted
              ? symbolSecurity?.QuoteCurrency
              : symbolSecurity?.BaseCurrency
            : symbolSecurity?.PositionCurrency;
        const priceCurrency = symbolSecurity?.QuoteCurrency;

        let color: MarketDistributionItem['color'] = theme.colors.blue.lighten;
        if (variant === 'Symbol' && currency) {
          color = getCurrencyColor(currency) ?? color;
          if (isMultileg(symbolSecurity)) {
            const leg = symbolSecurity.MultilegDetails?.Legs[0];
            if (leg) {
              const legSecurity = leg?.Symbol ? securitiesBySymbol.get(leg.Symbol) : undefined;
              const legCurrency =
                leg.Direction === LegDirectionEnum.Same ? legSecurity?.QuoteCurrency : legSecurity?.BaseCurrency;
              color = (legCurrency && getCurrencyColor(legCurrency)) ?? color;
            } else {
              color = setAlpha(0.75, color);
            }
          }
        } else {
          color = getMarketColor(market.Market, theme) ?? color;
        }

        const marketName = (market.Market && marketsByName.get(market.Market)?.DisplayName) || market.Market;
        const symbol = symbolSecurity?.DisplaySymbol || market.Symbol;

        const name = variant === 'Symbol' ? symbol : marketName;

        return {
          name: name,
          market: marketName,
          y: marketProportion.toNumber(),
          cumQty: market.CumQty,
          avgPxAllIn: market.AvgPxAllIn,
          avgPx: market.AvgPx,
          color: color,
          id: `${market.Market}-${market.Symbol}`,
          cumFee: market.CumFee,
          leavesQty: market.LeavesQty,
          price: market.Price,
          sizeCurrency,
          priceCurrency,
          symbol: symbol,
        };
      })
      .filter(notEmpty);

    const { orderQty, cumQty } = getOrderQuantities({ order, orderSecurity, legIndex });
    // Calculate the remainder by subtracting calculated orderQty from cumqty.
    const remainderY = orderQty.eq(0) ? '0' : orderQty.sub(cumQty).div(orderQty).toFixed();
    const y = Big(remainderY).toNumber();

    series.push({
      id: REMAINDER,
      y: y,
      color: theme.colors.gray['030'],
      symbol: 'N/A',
      market: 'N/A',
    });
    return series;
  }, [
    order,
    orderSecurity,
    tradedSecurity,
    variant,
    sortByKey,
    legIndex,
    theme,
    includeZeroCumQty,
    getMarketProportion,
    securitiesBySymbol,
    marketsByName,
    isOrderUnifiedLiquidity,
    isMultilegOrder,
  ]);

  return { distribution, sizeCurrency, priceCurrency, sizeIncrement, priceIncrement };
}
