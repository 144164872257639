import { useMemo } from 'react';
import { getAgGridColId } from '../components/BlotterTable/columns/getAgGridColId';
import type { Column, ColumnDef } from '../components/BlotterTable/columns/types';
import { useDefaultColumns } from '../components/BlotterTable/useDefaultColumns';
import type { ExpectTrue } from '../tests';
import { EMPTY_ARRAY } from '../utils';
import type { AssetTransactionSettlementStatusEnum, AssetTransactionTypeEnum, IAssetTransaction } from './types';

export class AssetTransaction {
  static readonly rowID = 'TransactionID' satisfies keyof AssetTransaction;
  static readonly StreamName = 'AssetTransaction';
  static readonly BlotterExportName = 'Cash Transactions';

  static readonly defaultColumns = [
    'TransactTime',
    'Type',
    'TID',
    'TransactionID',
    'Market',
    'MarketAccount',
    'PositionAsset',
    'Amount',
    'Asset',
  ] satisfies (keyof AssetTransaction)[];

  Timestamp!: string;
  Type!: AssetTransactionTypeEnum;
  TID!: string;
  Market!: string;
  CredentialID!: number;
  MarketAccount!: string;
  Asset!: string;
  Amount!: string;
  Rate!: string;
  PositionAsset!: string;
  TransactionID!: string;
  TransactTime!: string;
  OrgID!: number;
  SettleStatus!: AssetTransactionSettlementStatusEnum;

  constructor(data: IAssetTransaction) {
    Object.assign(this, data);
  }
}

interface UseAssetTransactionColumns {
  defaultColumns?: (keyof AssetTransaction | Partial<Column>)[];
}

export function useAssetTransactionColumns({ defaultColumns = EMPTY_ARRAY }: UseAssetTransactionColumns): Column[] {
  const columnDefinitions = useMemo(
    () =>
      new Map(
        (
          [
            {
              field: 'TransactTime',
              type: 'date',
              width: 200,
              sortable: true,
            },
            {
              field: 'Type',
              type: 'text',
            },
            {
              field: 'TID',
              type: 'text',
            },
            {
              field: 'TransactionID',
              type: 'text',
            },
            {
              field: 'Market',
              type: 'market',
            },
            {
              field: 'MarketAccount',
              type: 'marketAccount',
            },
            {
              field: 'PositionAsset',
              type: 'asset',
            },
            {
              field: 'Amount',
              type: 'size',
            },
            {
              field: 'Asset',
              type: 'asset',
            },
          ] satisfies ColumnDef<AssetTransaction>[]
        ).map(c => [getAgGridColId(c), c])
      ),
    []
  );

  return useDefaultColumns(defaultColumns, columnDefinitions);
}

// TYPE LEVEL TESTS
type _Expect_AssetTransaction_To_Only_Have_Keys_From_IAssetTransaction = ExpectTrue<
  {
    [K in keyof AssetTransaction & string]: K extends keyof IAssetTransaction ? true : K;
  }[Exclude<keyof AssetTransaction, never>]
>;
type _Expect_All_Keys_In_IAssetTransaction_To_Be_In_AssetTransaction = ExpectTrue<
  {
    [K in keyof IAssetTransaction & string]: K extends keyof AssetTransaction ? true : K;
  }[Exclude<keyof IAssetTransaction, never>]
>;
