import {
  BlotterTable,
  type BlotterTableFilter,
  BlotterTableFilters,
  ButtonVariants,
  FormControlSizes,
  IconButton,
  IconName,
  type MinimalSubscriptionResponse,
  PnlLookbackEnum,
  type Position,
  type UsePersistedBlotterTable,
  useBehaviorSubject,
  useBlotterTable,
  useObservable,
  usePersistedBlotterTable,
} from '@talos/kyoko';
import { asset } from '@talos/kyoko/src/components/BlotterTable/columns/columns';
import { PerformanceActionType } from 'containers/Portfolio/Performance/PerformanceReducer';
import { usePerformanceContext } from 'containers/Portfolio/Performance/providers/PerformanceStateAndTabsProvider';
import { useEffect } from 'react';
import { combineLatest, map, scan, shareReplay, startWith } from 'rxjs';
import { usePortfolioManagementProvider } from '../../providers/PortfolioManagementProvider';
import type { PortfolioRiskGridData } from '../../types/PortfolioRiskGridData';
import type { ContextSelectionBlotterParams } from '../../types/types';
import type { PortfolioBreakdownBlotterGridItem } from '../PortfolioPerformanceBlotter/types';
import { usePMSSubAccountPositionsSub } from '../PortfolioPerformanceBlotter/usePMSSubAccountPositionsSub';
import { useRollupTreeGridBuilders } from '../PortfolioRiskBlotter/useRollupTreeGridBuilders';
import { useGroupedDataExportContext } from '../RiskBlotterExportMenuItems';
import { RiskBlotterSuffixMenu } from '../RiskBlotterSuffixMenu';
import { getPositionMapKey } from '../types';
import { useRollupTreeMenu } from '../useRollupTreeMenu';
import { usePortfolioBreakdownColumns } from './usePortfolioBreakdownColumns';

export const assetComponent = asset({
  id: 'Asset',
  field: 'Asset',
  type: 'asset',
  pinned: 'left',
  sortable: true,
  width: 150,
  title: 'Asset',
  params: {
    assetTypeField: 'AssetType',
    colorful: true,
  },
});

export function PortfolioBreakdownBlotter({ blotterID, wrapperContext, subAccountId }: ContextSelectionBlotterParams) {
  const { dispatch } = usePerformanceContext();
  useEffect(() => {
    dispatch({
      type: PerformanceActionType.PnlLookbacksChange,
      payload: {
        pnlLookbacks: [PnlLookbackEnum.H24],
      },
    });
  }, [dispatch]);

  const { batchedRiskData } = usePortfolioManagementProvider();
  const { observable: riskSubAccountObs } = useBehaviorSubject(
    () => batchedRiskData ?? ({} as MinimalSubscriptionResponse<PortfolioRiskGridData>),
    [batchedRiskData]
  );

  const perfObs = usePMSSubAccountPositionsSub();

  const sharedObs = useObservable(() => riskSubAccountObs.pipe(shareReplay(1)), [riskSubAccountObs]);
  const gridObs = useObservable<MinimalSubscriptionResponse<PortfolioBreakdownBlotterGridItem>>(() => {
    return combineLatest([
      sharedObs,
      perfObs.pipe(
        startWith({ data: [] }),
        scan((result, next) => {
          next.data.forEach(item => {
            result[getPositionMapKey(item)] = { PnLLookbacks: item.PnLLookbacks };
          });
          return result;
        }, {} as Record<string, Pick<Position, 'PnLLookbacks'>>)
      ),
    ]).pipe(
      map(data => {
        const [risk, posPnlLookbacks] = data;
        // cast in order to add PnlLookbacks
        const output = risk as MinimalSubscriptionResponse<PortfolioBreakdownBlotterGridItem>;
        output.data?.forEach(item => {
          const key = getPositionMapKey(item);
          item.PnlLookbacks = posPnlLookbacks[key] ? posPnlLookbacks[key].PnLLookbacks : undefined;
        });
        return output;
      })
    );
  }, [perfObs, sharedObs]);

  const defaultFilter: BlotterTableFilter = {};

  const { defaultColumns } = usePortfolioBreakdownColumns();
  const persistedBlotterTable: UsePersistedBlotterTable<PortfolioRiskGridData> = usePersistedBlotterTable(blotterID, {
    persistColumns: false,
    columns: defaultColumns,
    filter: defaultFilter,
    sort: '+ag-Grid-AutoColumn',
  });

  const { blotterParams } = useRollupTreeGridBuilders();

  const { getContextMenuItems, dialogs } = useRollupTreeMenu();

  const groupedDataExportContext = useGroupedDataExportContext();
  const blotterTable = useBlotterTable<PortfolioRiskGridData>({
    dataObservable: gridObs,
    rowID: 'rowID',
    rowSelection: 'multiple',
    sort: persistedBlotterTable.initialSort,
    onSortChanged: persistedBlotterTable.onSortChanged,
    columns: defaultColumns,
    context: groupedDataExportContext,
    getContextMenuItems,
    quickSearchParams: {
      entitySearchKeys: ['Asset', 'SubAccount'],
      filterOnAutoGroupLevels: true,
    },
    groupDefaultExpanded: -1,
    supportColumnColDefGroups: true,
    ...blotterParams,
  });

  useEffect(() => {
    if (blotterTable.gridApi) {
      if (subAccountId) {
        blotterTable.gridApi.expandAll();
      } else {
        blotterTable.gridApi.collapseAll();
      }
    }
  }, [subAccountId, blotterTable.gridApi]);

  const { expandAllGroups, collapseAllGroups } = blotterTable;

  return (
    <>
      <BlotterTableFilters
        {...blotterTable.blotterTableFiltersProps}
        showFilterBuilder={false}
        showQuickFilterOnly={false}
        prefix={
          <>
            <IconButton
              icon={IconName.ListExpand}
              size={FormControlSizes.Small}
              variant={ButtonVariants.Default}
              onClick={expandAllGroups}
              data-testid="expand-all-button"
            />
            <IconButton
              icon={IconName.ListCollapse}
              size={FormControlSizes.Small}
              variant={ButtonVariants.Default}
              onClick={collapseAllGroups}
            />
          </>
        }
        suffix={
          <RiskBlotterSuffixMenu
            riskObs={sharedObs}
            exportDataAsCSV={blotterTable.exportDataAsCSV}
            exportDataAsExcel={blotterTable.exportDataAsExcel}
            label={wrapperContext.label}
          />
        }
      />
      <BlotterTable {...blotterTable} />
      {dialogs}
    </>
  );
}
