import {
  AccordionGroup,
  Box,
  ExpandableBottomPanel,
  FilterClauseType,
  HStack,
  PanelContent,
  Tab,
  TabAppearance,
  TabList,
  TabSize,
  Tabs,
  VStack,
  useDynamicCallback,
  useExpandablePanel,
  useTabs,
  type FilterClause,
} from '@talos/kyoko';
import { useMemo, useState } from 'react';
import { ErrorBoundary } from '../../../../components/ErrorBoundary';
import { BlotterWrapper, XScrollableContainer } from '../../styles';
import type { SettlementMonitoringTab } from '../tabs';
import { PositionMonitoringBlotterWrapper } from './PositionMonitoringBlotter/PositionMonitoringBlotterWrapper';
import { TradeSettlement } from './TradeSettlementBlotter';
import { ControlPanel } from './components/ControlPanel';
import { DEFAULT_CUSTOMER_SETTLEMENT_MONITORING_STATE } from './types';

const DEFAULT_BLOTTER_HEIGHT = 450;
const BOTTOM_BLOTTER_HEIGHT = 'CustomerSettlementMonitoringBottomBlotterHeight';

const BOTTOM_BLOTTER_TABS = [
  {
    label: 'Trades',
  },
];

interface CustomerSettlementMonitoringProps {
  tab: SettlementMonitoringTab;
}

export const CustomerSettlementMonitoring = ({ tab }: CustomerSettlementMonitoringProps) => {
  const [isBlotterMaximized, setIsBlotterMaximized] = useState(false);
  const [isBlotterMinimized, setIsBlotterMinimized] = useState(false);

  const handleMaximizeBlotter = useDynamicCallback((shouldExpand: boolean) => {
    setIsBlotterMinimized(false);
    setIsBlotterMaximized(shouldExpand);
  });

  const handleMinimizeBlotter = useDynamicCallback((shouldMinimize: boolean) => {
    setIsBlotterMaximized(false);
    setIsBlotterMinimized(shouldMinimize);
  });

  const { containerRef, ...panelProps } = useExpandablePanel<HTMLDivElement>({
    initialHeight: parseInt(localStorage.getItem(BOTTOM_BLOTTER_HEIGHT) ?? '0') || DEFAULT_BLOTTER_HEIGHT,
    isExpanded: isBlotterMaximized,
    isMinimized: isBlotterMinimized,
    onToggleExpanded: handleMaximizeBlotter,
    onToggleMinimize: handleMinimizeBlotter,
    onAdjustedHeight(newHeight) {
      localStorage.setItem(BOTTOM_BLOTTER_HEIGHT, newHeight.toString());
    },
  });

  const bottomBlotterTabs = useTabs({
    initialSelectedIndex: 0,
    initialItems: BOTTOM_BLOTTER_TABS,
  });

  // This memo computes the current initialFilterClauses based on the current tab.
  // When the current tab changes, it will re-compute and re-pass to the child FilterBuilder
  const initialFilterClauses: FilterClause[] = useMemo(() => {
    const filterToUse = tab.filter ?? DEFAULT_CUSTOMER_SETTLEMENT_MONITORING_STATE.filter;
    const filterKeys = Object.keys(filterToUse);
    return filterKeys
      .map(key => {
        const selections = filterToUse[key];

        if (selections == null) {
          // safeguard
          return undefined;
        }

        return {
          key,
          type: FilterClauseType.INCLUSIVE,
          selections,
        };
      })
      .compact();
  }, [tab]);

  return (
    <VStack w="100%" flex="1" position="relative" overflow="hidden">
      <AccordionGroup>
        <ControlPanel initialFilterClauses={initialFilterClauses} />
      </AccordionGroup>
      <Box flex="1" w="100%" overflow="hidden">
        <VStack w="100%" h="100%" flex="1" ref={containerRef}>
          <XScrollableContainer w="100%" flex="1 1 0%">
            <HStack minHeight="400px" w="100%" h="100%" minWidth="1000px" gap="spacingTiny">
              <PanelContent px="spacingDefault" py="0" pb="0" data-testid="position-monitoring-blotter">
                <PositionMonitoringBlotterWrapper tab={tab} />
              </PanelContent>
            </HStack>
          </XScrollableContainer>
          <BlotterWrapper>
            <ExpandableBottomPanel
              {...panelProps}
              showControls
              header={
                <Tabs {...bottomBlotterTabs} appearance={TabAppearance.Filled} size={TabSize.Small}>
                  <TabList isBordered>
                    {BOTTOM_BLOTTER_TABS.map((tab, i) => (
                      <Tab key={i} {...tab} />
                    ))}
                  </TabList>
                </Tabs>
              }
            >
              <ErrorBoundary>
                <TradeSettlement externalFilter={tab.filter} />
              </ErrorBoundary>
            </ExpandableBottomPanel>
          </BlotterWrapper>
        </VStack>
      </Box>
    </VStack>
  );
};
