import {
  HamburgerMenu,
  filterByCellValueMenuItem,
  useDynamicCallback,
  useGetDefaultContextMenuItems,
  useJsonModal,
  useMixpanel,
  type Column,
  type ColumnDef,
  type Exposure,
  type FilterableProperty,
  type Quote,
  type UseFilterBuilderOutput,
} from '@talos/kyoko';
import type { GetContextMenuItemsParams, MenuItemDef } from 'ag-grid-community';
import { OMSView } from 'components/OMS/OMSView';
import { compact, isNil } from 'lodash';
import { useAppStateDispatch } from 'providers/AppStateProvider';
import { useMemo, type ReactNode } from 'react';
import { showRFQ } from '../../../components/OMS/NewRFQ/RFQSlice';
import { openView } from '../../../components/OMS/OMSSlice';
import { colIDToFilterBuilderKey } from './useQuotesFilter';

export function useQuotesMenu({
  openClause,
  filterableProperties,
}: {
  openClause: UseFilterBuilderOutput['addAndOpenClause'];
  filterableProperties: FilterableProperty<string>[];
}): {
  columns: Column[];
  getContextMenuItems: (params: GetContextMenuItemsParams<Quote>) => (MenuItemDef | string)[];
  dialogs: ReactNode;
} {
  const mixpanel = useMixpanel();
  const { handleClickJson, jsonModal } = useJsonModal();
  const dispatch = useAppStateDispatch();
  const handleOpenQuote = useDynamicCallback((data: Quote) => {
    dispatch(openView(OMSView.RFQForm));
    dispatch(showRFQ(data));
  });
  const getDefaultContextMenuItems = useGetDefaultContextMenuItems();
  const getContextMenuItems = useDynamicCallback((params: GetContextMenuItemsParams<Quote>) => {
    const data = params?.node?.data;
    const groupData = params?.node?.groupData;
    if (isNil(data) && isNil(groupData)) {
      return [];
    }

    const items = compact([
      ...filterByCellValueMenuItem({ params, filterableProperties, openClause, colIDToFilterBuilderKey, mixpanel }),
      ...(data
        ? [
            {
              name: `Show JSON`,
              action: () => handleClickJson(data),
            },
            'separator',
            {
              name: 'Open Quote',
              action: () => handleOpenQuote(data),
              disabled: data.isTerminal,
            },
          ]
        : []),
      ...getDefaultContextMenuItems(params),
    ]);
    return items;
  });

  const dialogs = useMemo(() => <>{jsonModal}</>, [jsonModal]);

  const columns = useMemo(
    () =>
      compact([
        {
          type: 'hamburgerMenu',
          id: 'rowMenu',
          params: {
            renderItems: params => <HamburgerMenu {...params} onShowJson={handleClickJson} />,
          },
        },
      ] satisfies ColumnDef<Exposure>[]),
    [handleClickJson]
  );

  return {
    columns,
    getContextMenuItems,
    dialogs,
  };
}
