import {
  getAgGridColId,
  isTalosUser,
  logger,
  useDefaultColumns,
  type Column,
  type ColumnDef,
  type Leaves,
  type NumberColumnParams,
  type Position,
} from '@talos/kyoko';
import { compact } from 'lodash';
import { useMemo } from 'react';
import { useFeatureFlag, useRollupTreeRef, useUser } from '../../../../hooks';
import { useDisplaySettings } from '../../../../providers/DisplaySettingsProvider';
import { SubAccountPositionsBlotterMode, type PositionColumn } from './../types';

const DEFAULT_BLOTTER_MODE = SubAccountPositionsBlotterMode.Spot;

export const usePositionsSubAccountsColumns = (defaultColumns: PositionColumn[]): Column[] => {
  const { homeCurrency } = useDisplaySettings();
  const rollupTreeRef = useRollupTreeRef();
  const user = useUser();

  const { subAccountPositionsBlotterMode, showSubAccountPositionPnLTagColumns } = useFeatureFlag();

  const columnDefinitions = useMemo(() => {
    const allColumnDefs: ColumnDef<Position>[] = compact([
      {
        field: 'SubAccount',
        type: 'subAccountName',
        sortable: true,
        params: {
          rollupTree: rollupTreeRef,
        },
        enableRowGroup: true,
      },
      {
        id: 'Asset',
        field: 'Asset',
        type: 'asset',
        sortable: true,
        title: 'Asset',
        params: {
          assetTypeField: 'AssetType',
          colorful: true,
        },
        enableRowGroup: true,
      },
      {
        field: 'Amount',
        type: 'size',
        title: 'Position',
        sortable: true,
        aggregate: true,
        width: 150,
        params: {
          currencyField: 'Asset',
          highlightNegative: true,
          showCurrencyForNullPosition: false,
        },
      },
      {
        type: 'size',
        field: 'Equivalent.Amount',
        title: `Position (${homeCurrency})`,
        params: { currencyField: 'Equivalent.Currency', highlightNegative: true },
        sortable: true,
        aggregate: true,
        description: 'Position normalized in home currency, by using mark and underlying prices.',
      },
      {
        field: 'AvgCost',
        type: 'price',
        width: 150,
        params: {
          quoteCurrencyField: 'AvgCostCurrency',
        },
        description: 'Average cost for the instrument provided by the venue.',
      },
      {
        field: 'Equivalent.AvgCost',
        title: `Avg Cost (${homeCurrency})`,
        type: 'price',
        width: 150,
        params: {
          quoteCurrencyField: 'Equivalent.Currency',
        },
        hide: true,
        description: `Average cost for the instrument provided by the venue converted to ${homeCurrency}.`,
      },
      {
        field: 'MarkPrice',
        type: 'price',
        width: 110,
        params: {
          quoteCurrencyField: 'MarkPriceCurrency',
        },
        description: 'Talos aggregates prices from all available market sources.',
      },
      {
        field: 'Equivalent.MarkPrice',
        type: 'price',
        title: `Mark Price (${homeCurrency})`,
        width: 130,
        params: {
          quoteCurrencyField: 'Equivalent.Currency',
        },
        hide: true,
        description: 'Talos aggregates prices from all available market sources.',
      },
      {
        type: 'number',
        field: 'Equivalent.QuoteConversionRate',
        title: 'Quote Conversion Rate',
        params: {
          increment: '0.0001',
        } satisfies NumberColumnParams,
        hide: true,
      },
      {
        field: 'MarkPriceCurrency',
        title: 'Quote Ccy',
        type: 'text',
        hide: true,
      },
      {
        field: 'Delta',
        type: 'delta',
        aggregate: true,
        width: 60,
        params: {
          securityField: 'Asset',
        },
        description:
          "Delta measures the rate of change of an instrument's price with respect to changes in its underlying asset's price.",
      },
      {
        field: 'Equivalent.Delta',
        type: 'size',
        title: `Delta (${homeCurrency})`,
        aggregate: true,
        width: 100,
        params: {
          currencyField: 'Equivalent.Currency',
          highlightNegative: true,
        },
        description:
          "Delta measures the rate of change of an instrument's price with respect to changes in its underlying asset's price, in home currency.",
      },
      {
        field: 'Equivalent.CumFees',
        type: 'size',
        title: `Fees (${homeCurrency})`,
        params: {
          currencyField: 'Equivalent.Currency',
        },
        aggregate: true,
        description: 'Cumulative trading fees paid (or rebates received) in home currency.',
      },
      {
        field: 'Equivalent.UnrealizedPnL',
        type: 'size',
        title: `Unrealized PnL (${homeCurrency})`,
        aggregate: true,
        params: {
          currencyField: 'Equivalent.Currency',
          highlightNegative: true,
        },
        description: 'Cumulative unrealized P&L in home currency.',
      },
      {
        field: 'Equivalent.RealizedPnL',
        type: 'size',
        title: `Realized PnL (${homeCurrency})`,
        aggregate: true,
        params: {
          currencyField: 'Equivalent.Currency',
          highlightNegative: true,
        },
        description: 'Cumulative realized P&L in home currency.',
      },
      {
        field: 'TotalPnL',
        type: 'size',
        title: 'Total LTD PnL',
        aggregate: true,
        params: {
          currencyField: 'PnLCurrency',
          highlightNegative: true,
        },
        description: 'Total P&L.',
      },
      {
        field: 'Equivalent.TotalPnL',
        type: 'size',
        title: `Total LTD PnL (${homeCurrency})`,
        aggregate: true,
        params: {
          currencyField: 'Equivalent.Currency',
          highlightNegative: true,
        },
        description: `Total P&L in ${homeCurrency}.`,
      },

      // Hidden by default columns below
      {
        id: 'underlying' satisfies ExtraSubAccountPositionColumnIds,
        type: 'positionUnderlyingAsset',
        title: 'Underlying',
        enableRowGroup: true,
        hide: true,
        description: "Underlying of asset upon which a derivative's price is based.",
      },
      {
        field: 'AssetType',
        title: 'Product Type',
        type: 'productTypeField',
        width: 130,
        enableRowGroup: true,
        hide: true,
      },
      {
        field: 'CumFees',
        type: 'size',
        title: 'Fees',
        params: {
          currencyField: 'PnLCurrency' satisfies keyof Position,
        },
        aggregate: true,
        hide: true,
        description: 'Cumulative trading fees paid (or rebates received) in USD.',
      },
      {
        field: 'UnrealizedPnL',
        type: 'size',
        title: 'Unrealized PnL',
        aggregate: true,
        params: {
          currencyField: 'PnLCurrency',
          highlightNegative: true,
        },
        hide: true,
        description: 'Cumulative unrealized P&L in USD.',
      },
      {
        field: 'RealizedPnL',
        type: 'size',
        title: 'Realized PnL',
        params: {
          currencyField: 'PnLCurrency',
          highlightNegative: true,
        },
        hide: true,
        aggregate: true,
        description: 'Cumulative realized P&L in USD.',
      },
      {
        field: 'Amount',
        id: 'position-qty' satisfies ExtraSubAccountPositionColumnIds,
        type: 'size',
        title: 'Position Qty',
        description: 'Number of derivative contracts or size of spot position.',
        params: {
          showInTermsOfContracts: true,
          highlightNegative: true,
          currencyField: 'Asset',
          trimTrailingZeroes: true,
        },
        hide: true,
      },
      {
        field: 'Gamma',
        type: 'gamma',
        width: 80,
        params: {
          securityField: 'Asset',
        },
        hide: true,
        description:
          "Gamma measures the rate of change of option's delta with respect to changes in the underlying asset's price.",
      },
      {
        field: 'Vega',
        type: 'vega',
        width: 60,
        params: {
          securityField: 'Asset',
        },
        hide: true,
        description:
          "Vega measures the rate of change (in USD) of an option's price in response to a 1% shift in implied volatility of underlying asset.",
      },
      {
        field: 'Theta',
        type: 'theta',
        width: 60,
        params: {
          securityField: 'Asset',
        },
        hide: true,
        description:
          'Theta measures the change (in USD) in the price of an option for a one-day decrease in its time to expiration. ',
      },
      {
        field: 'Rho',
        type: 'rho',
        width: 60,
        params: {
          securityField: 'Asset',
        },
        hide: true,
        description:
          "Rho measures the rate of change (in USD) of an option's price in response to a 1% shift in interest rate.",
      },
      {
        field: 'OutstandingBuy',
        type: 'size',
        params: { currencyField: 'Asset', showCurrencyForNullPosition: false },
        hide: true,
      },
      {
        field: 'OutstandingSell',
        type: 'size',
        params: { currencyField: 'Asset', showCurrencyForNullPosition: false },
        hide: true,
      },
      {
        type: 'size',
        field: 'Equivalent.OutstandingBuy',
        title: `Outstanding Buy (${homeCurrency})`,
        params: { currencyField: 'Equivalent.Currency', highlightNegative: true },
        sortable: true,
        aggregate: true,
        hide: true,
      },
      {
        type: 'size',
        field: 'Equivalent.OutstandingSell',
        title: `Outstanding Sell (${homeCurrency})`,
        params: { currencyField: 'Equivalent.Currency', highlightNegative: true },
        sortable: true,
        aggregate: true,
        hide: true,
      },
      {
        field: 'LastUpdateTime',
        type: 'date',
        hide: true,
      },
      {
        field: 'PnLLookbacks.H24.Equivalent.PnLDelta',
        type: 'size',
        title: '24H PnL',
        aggregate: true,
        params: {
          currencyField: 'Equivalent.Currency',
          highlightNegative: true,
        },
        hide: true,
        description: 'P&L on assets over the 24 hours to the current price mark.',
      },
      {
        field: 'PnLLookbacks.D7.Equivalent.PnLDelta',
        type: 'size',
        title: '7D PnL',
        aggregate: true,
        params: {
          currencyField: 'Equivalent.Currency',
          highlightNegative: true,
        },
        hide: true,
        description: 'P&L on assets over the past 7 days to the current price mark.',
      },
      {
        field: 'PnLLookbacks.D30.Equivalent.PnLDelta',
        type: 'size',
        title: '30D PnL',
        aggregate: true,
        hide: true,
        params: {
          currencyField: 'Equivalent.Currency',
          highlightNegative: true,
        },
        description: 'P&L on asset over the past 30 days to the current price mark.',
      },
      {
        field: 'PnLLookbacks.D365.Equivalent.PnLDelta',
        type: 'size',
        title: '1Y PnL',
        aggregate: true,
        hide: true,
        params: {
          currencyField: 'Equivalent.Currency',
          highlightNegative: true,
        },
        description: 'P&L on asset over the past 1 year (365 days) to the current price mark.',
      },
      {
        field: 'PnLLookbacks.Today.Equivalent.PnLDelta',
        type: 'size',
        title: 'Intraday PnL',
        aggregate: true,
        hide: true,
        params: {
          currencyField: 'Equivalent.Currency',
          highlightNegative: true,
        },
        description: "P&L on asset from start of day to the current price mark. (e.g. today's gain/loss)",
      },
      {
        field: 'PnLLookbacks.WeekToDate.Equivalent.PnLDelta',
        type: 'size',
        title: 'WTD PnL',
        aggregate: true,
        params: {
          currencyField: 'Equivalent.Currency',
          highlightNegative: true,
        },
        hide: true,
        description: 'P&L on asset from start of day on Monday to the current price mark.',
      },
      {
        field: 'PnLLookbacks.MonthToDate.Equivalent.PnLDelta',
        type: 'size',
        title: 'MTD PnL',
        aggregate: true,
        hide: true,
        params: {
          currencyField: 'Equivalent.Currency',
          highlightNegative: true,
        },
        description: 'P&L on asset from start of day on 1st of the month to the current price mark.',
      },
      {
        field: 'PnLLookbacks.YearToDate.Equivalent.PnLDelta',
        type: 'size',
        title: 'YTD PnL',
        aggregate: true,
        hide: true,
        params: {
          currencyField: 'Equivalent.Currency',
          highlightNegative: true,
        },
        description: 'P&L on asset from start of day on 1st of the year to the current price mark.',
      },
      showSubAccountPositionPnLTagColumns && {
        field: 'PnLTags.EndOfDay.OpeningPosition',
        type: 'size',
        title: 'EOD Position',
        aggregate: true,
        hide: true,
        params: {
          currencyField: 'Asset',
          highlightNegative: true,
          showCurrencyForNullPosition: false,
        },
        description: 'Notional position as of EOD timestamp.',
      },
      showSubAccountPositionPnLTagColumns && {
        field: 'PnLTags.EndOfDay.OpeningPriceMark',
        type: 'price',
        title: 'EOD Mark Price',
        hide: true,
        params: {
          quoteCurrencyField: 'MarkPriceCurrency',
        },
        description: 'End of day price mark.',
      },
      showSubAccountPositionPnLTagColumns && {
        field: 'PnLTags.EndOfDay.Equivalent.OpeningPriceMark',
        type: 'price',
        title: `EOD Mark Price (${homeCurrency})`,
        hide: true,
        params: {
          quoteCurrencyField: 'PnLTags.EndOfDay.Equivalent.Currency',
        },
        description: `End of day price mark converted to ${homeCurrency}.`,
      },
      showSubAccountPositionPnLTagColumns && {
        field: 'PnLTags.EndOfDay.OpenTimestamp',
        type: 'date',
        title: 'EOD Mark Time',
        hide: true,
        description: 'Timestamp for the end of day price mark.',
      },
      showSubAccountPositionPnLTagColumns && {
        field: 'PnLTags.EndOfDay.OpeningUnrealizedPnL',
        type: 'size',
        title: 'EOD Position PnL',
        aggregate: true,
        hide: true,
        params: {
          currencyField: 'PnLCurrency',
          highlightNegative: true,
        },
        description:
          'PnL of the EOD notional position. Computed by marking the EOD position to current price and comparing to EOD price mark.',
      },
      showSubAccountPositionPnLTagColumns && {
        field: 'PnLTags.EndOfDay.TradePositionDelta',
        type: 'size',
        title: 'Day Trade Position',
        aggregate: true,
        hide: true,
        params: {
          currencyField: 'Asset',
          highlightNegative: true,
          showCurrencyForNullPosition: false,
        },
        description: 'Current notional position minus EOD notional position.',
      },
      showSubAccountPositionPnLTagColumns && {
        field: 'PnLTags.EndOfDay.TradeUnrealizedPnL',
        type: 'size',
        title: 'Day Trade PnL',
        aggregate: true,
        hide: true,
        params: {
          currencyField: 'PnLCurrency',
          highlightNegative: true,
        },
        description:
          "PnL attributable to current day's trading activity. Computed by summing up the PnL of trades executed today, which is computed by comparing the current price mark against the traded price.",
      },
      showSubAccountPositionPnLTagColumns && {
        field: 'eodTotalPnL',
        type: 'size',
        title: 'Total PnL',
        aggregate: true,
        hide: true,
        params: {
          currencyField: 'PnLCurrency',
          highlightNegative: true,
        },
        description: 'Summation of EOD Position PnL and Day Trade PnL.',
      },
      {
        field: 'FundingPnL',
        type: 'size',
        title: 'Funding PnL',
        aggregate: true,
        params: {
          currencyField: 'FundingPnLCurrency',
          highlightNegative: true,
        },
        hide: true,
        description: 'Cumulative funding payment paid or received in home currency.',
      },
      {
        field: 'Equivalent.FundingPnL',
        type: 'size',
        title: `Funding PnL (${homeCurrency})`,
        params: {
          currencyField: 'Equivalent.Currency',
          highlightNegative: true,
        },
        aggregate: true,
        hide: true,
        description: 'Cumulative funding payment paid or received in USD.',
      },
      {
        field: 'AvgCostCurrency',
        type: 'currency',
        hide: true,
      },
      {
        field: 'PnLCurrency',
        type: 'currency',
        title: 'PnL Currency',
        hide: true,
      },
      isTalosUser(user) && {
        field: 'Status',
        type: 'text',
        hide: true,
      },
    ]);

    const columnDefsFilteredToBlotterMode = filterColumnsByMode(
      subAccountPositionsBlotterMode ?? DEFAULT_BLOTTER_MODE,
      allColumnDefs
    );
    return new Map<string, Column>(columnDefsFilteredToBlotterMode.map(c => [getAgGridColId(c), c]));
  }, [homeCurrency, rollupTreeRef, user, subAccountPositionsBlotterMode, showSubAccountPositionPnLTagColumns]);

  return useDefaultColumns(defaultColumns, columnDefinitions);
};

// Its not much but its honest work
type ExtraSubAccountPositionColumnIds = 'underlying' | 'position-qty';

const spotColumns = new Set<string>([
  'SubAccount',
  'Asset',
  'Amount',
  'Equivalent.Amount',
  'position-qty',
  'AvgCost',
  'Equivalent.AvgCost',
  'MarkPrice',
  'Equivalent.MarkPrice',
  'Equivalent.QuoteConversionRate',
  'MarkPriceCurrency',
  'Delta',
  'Equivalent.Delta',
  'Equivalent.CumFees',
  'Equivalent.UnrealizedPnL',
  'Equivalent.RealizedPnL',
  'TotalPnL',
  'Equivalent.TotalPnL',
  'AssetType',
  'CumFees',
  'UnrealizedPnL',
  'RealizedPnL',
  'OutstandingBuy',
  'OutstandingSell',
  'Equivalent.OutstandingBuy',
  'Equivalent.OutstandingSell',
  'PnLLookbacks.H24.Equivalent.PnLDelta',
  'PnLLookbacks.D7.Equivalent.PnLDelta',
  'PnLLookbacks.D30.Equivalent.PnLDelta',
  'PnLLookbacks.D365.Equivalent.PnLDelta',
  'PnLLookbacks.Today.Equivalent.PnLDelta',
  'PnLLookbacks.WeekToDate.Equivalent.PnLDelta',
  'PnLLookbacks.MonthToDate.Equivalent.PnLDelta',
  'PnLLookbacks.YearToDate.Equivalent.PnLDelta',
  'PnLTags.EndOfDay.OpeningPosition',
  'PnLTags.EndOfDay.OpeningPriceMark',
  'PnLTags.EndOfDay.Equivalent.OpeningPriceMark',
  'PnLTags.EndOfDay.OpenTimestamp',
  'PnLTags.EndOfDay.OpeningUnrealizedPnL',
  'PnLTags.EndOfDay.TradePositionDelta',
  'PnLTags.EndOfDay.TradeUnrealizedPnL',
  'eodTotalPnL',
  'LastUpdateTime',
  'Status',
] satisfies (Leaves<Position> | ExtraSubAccountPositionColumnIds)[]);

const derivsColumns = new Set<string>([
  'SubAccount',
  'Asset',
  'Amount',
  'Equivalent.Amount',
  'position-qty',
  'AvgCost',
  'Equivalent.AvgCost',
  'MarkPrice',
  'Equivalent.MarkPrice',
  'Equivalent.QuoteConversionRate',
  'MarkPriceCurrency',
  'Delta',
  'Equivalent.Delta',
  'underlying',
  'AssetType',
  'OutstandingBuy',
  'OutstandingSell',
  'Equivalent.OutstandingBuy',
  'Equivalent.OutstandingSell',
  'LastUpdateTime',
  'Status',
] satisfies (Leaves<Position> | ExtraSubAccountPositionColumnIds)[]);

const derivsAdvancedColumns = new Set<string>([
  'SubAccount',
  'Asset',
  'Amount',
  'Equivalent.Amount',
  'position-qty',
  'AvgCost',
  'Equivalent.AvgCost',
  'MarkPrice',
  'Equivalent.MarkPrice',
  'Equivalent.QuoteConversionRate',
  'MarkPriceCurrency',
  'Delta',
  'Equivalent.Delta',
  'Equivalent.CumFees',
  'Equivalent.UnrealizedPnL',
  'Equivalent.RealizedPnL',
  'TotalPnL',
  'Equivalent.TotalPnL',
  'underlying',
  'AssetType',
  'CumFees',
  'UnrealizedPnL',
  'RealizedPnL',
  'Gamma',
  'Vega',
  'Theta',
  'Rho',
  'OutstandingBuy',
  'OutstandingSell',
  'Equivalent.OutstandingBuy',
  'Equivalent.OutstandingSell',
  'LastUpdateTime',
  'PnLLookbacks.H24.Equivalent.PnLDelta',
  'PnLLookbacks.D7.Equivalent.PnLDelta',
  'PnLLookbacks.D30.Equivalent.PnLDelta',
  'PnLLookbacks.D365.Equivalent.PnLDelta',
  'PnLLookbacks.Today.Equivalent.PnLDelta',
  'PnLLookbacks.WeekToDate.Equivalent.PnLDelta',
  'PnLLookbacks.MonthToDate.Equivalent.PnLDelta',
  'PnLLookbacks.YearToDate.Equivalent.PnLDelta',
  'PnLTags.EndOfDay.OpeningPosition',
  'PnLTags.EndOfDay.OpeningPriceMark',
  'PnLTags.EndOfDay.Equivalent.OpeningPriceMark',
  'PnLTags.EndOfDay.OpenTimestamp',
  'PnLTags.EndOfDay.OpeningUnrealizedPnL',
  'PnLTags.EndOfDay.TradePositionDelta',
  'PnLTags.EndOfDay.TradeUnrealizedPnL',
  'eodTotalPnL',
  'FundingPnL',
  'Equivalent.FundingPnL',
  'AvgCostCurrency',
  'PnLCurrency',
  'Status',
] satisfies (Leaves<Position> | ExtraSubAccountPositionColumnIds)[]);

function filterColumnsByMode(mode: string, columnDefs: ColumnDef<Position>[]): ColumnDef<Position>[] {
  let allowedColumns =
    mode === SubAccountPositionsBlotterMode.Spot
      ? spotColumns
      : mode === SubAccountPositionsBlotterMode.Derivatives
      ? derivsColumns
      : mode === SubAccountPositionsBlotterMode.DerivativesAdvanced
      ? derivsAdvancedColumns
      : undefined;

  if (!allowedColumns) {
    allowedColumns = spotColumns;
    logger.error(
      new Error(`Misconfigured SubAccountPositionsBlotter mode ${mode}. The client is being defaulted to spot columns.`)
    );
  }

  return columnDefs.filter(def => allowedColumns?.has(getAgGridColId(def)));
}
