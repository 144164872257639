import { createContext, useContext, useMemo } from 'react';
import { useObservableValue, useStaticSubscription } from '../hooks';
import { wsScanToMap } from '../pipes';
import { EQUITY_MARGIN_RATIO_LIMIT } from '../tokens';
import type { ModeEnum } from '../types';

export interface EquityMarginRatioLimitResponse {
  LimitID: string;
  MarketAccount?: string;
  Mode: ModeEnum;
  Ratio: string;
}

export interface EquityMarginRatioLimitContextProps {
  equityMarginRatioLimitByMarketAccount: Map<string, EquityMarginRatioLimitResponse | undefined>;
  globalEquityMarginRatioLimit: EquityMarginRatioLimitResponse | undefined;
}

const GLOBAL_EQUITY_MARGIN_RATIO_LIMIT_KEY = 'GLOBAL_EQUITY_MARGIN_RATIO_LIMIT_KEY';

export const EquityMarginRatioLimitContext = createContext<EquityMarginRatioLimitContextProps | undefined>(undefined);
EquityMarginRatioLimitContext.displayName = 'EquityMarginRatioLimitContext';

export function useEquityMarginRatioLimitContext() {
  const context = useContext(EquityMarginRatioLimitContext);
  if (context === undefined) {
    throw new Error('Missing EquityMarginRatioLimitContext.Provider further up in the tree. Did you forget to add it?');
  }
  return context;
}

export function EquityMarginRatioLimitProvider({ children }) {
  const { data: equityMarginRatioLimitSubscription } = useStaticSubscription<EquityMarginRatioLimitResponse>({
    name: EQUITY_MARGIN_RATIO_LIMIT,
    tag: 'useEquityMarginRatioLimit',
  });

  const equityMarginRatioLimitByMarketAccount = useObservableValue(
    () =>
      equityMarginRatioLimitSubscription.pipe(
        wsScanToMap({
          getUniqueKey: d => d.MarketAccount ?? GLOBAL_EQUITY_MARGIN_RATIO_LIMIT_KEY,
          newMapEachUpdate: true,
        })
      ),
    [equityMarginRatioLimitSubscription],
    new Map<string, EquityMarginRatioLimitResponse>()
  );

  const globalEquityMarginRatioLimit = useMemo(
    () => equityMarginRatioLimitByMarketAccount.get(GLOBAL_EQUITY_MARGIN_RATIO_LIMIT_KEY),
    [equityMarginRatioLimitByMarketAccount]
  );

  return (
    <EquityMarginRatioLimitContext.Provider
      value={{
        equityMarginRatioLimitByMarketAccount,
        globalEquityMarginRatioLimit,
      }}
    >
      {children}
    </EquityMarginRatioLimitContext.Provider>
  );
}
