import {
  MultiSelectorField,
  ProductTypeEnum,
  SelectorField,
  getCurrencyPairFromSecurity,
  type Security,
} from '@talos/kyoko';
import { immerable } from 'immer';
import type { OMSReferenceDataState } from '../../types';
import {
  getExchangeAndTypeFromSecurity,
  getSettlementTypeFromExchangeAndType,
  getShortExchangeName,
  isSecurityExchangeAndTypeMatch,
  type CurrencySelectItem,
  type StringSelectItem,
} from './utils';

interface PerpData {
  exchangeAndTypeField: SelectorField<StringSelectItem>;
  symbolField: SelectorField<CurrencySelectItem>;
  marketAccountField: MultiSelectorField<StringSelectItem>;
}

export class Perp {
  public static readonly type = ProductTypeEnum.PerpetualSwap;
  private readonly _data: PerpData;

  private constructor(private _referenceData: OMSReferenceDataState, data: PerpData) {
    this._data = data;
  }

  public static createFromBlank(referenceData: OMSReferenceDataState) {
    const uniqueCcyPairs = Perp.getAvailableSymbols(referenceData);
    const uniqueExchangeAndTypes = Perp.getAvailableExchanges(referenceData);

    const data = {
      exchangeAndTypeField: new SelectorField<StringSelectItem>({
        name: 'Exchange & Type',
        idProperty: 'value',
        availableItems: uniqueExchangeAndTypes,
      }),
      symbolField: new SelectorField<CurrencySelectItem>({
        name: 'Symbol',
        idProperty: 'value',
        availableItems: uniqueCcyPairs,
      }),
      marketAccountField: new MultiSelectorField<StringSelectItem>({
        name: 'Market Account',
        idProperty: 'value',
      }),
    };

    return new Perp(referenceData, data);
  }

  public static createFromSecurity(referenceData: OMSReferenceDataState, security: Security): Perp {
    const market = security?.Markets?.[0];
    const type = security?.SettleValueType;

    if (!market || !type) {
      throw new Error(`${security.Symbol} is not a Perp`);
    }

    const exchangeAndType = getExchangeAndTypeFromSecurity(security, referenceData.markets.marketsByName);

    const symbol = getCurrencyPairFromSecurity(security);
    const availableSymbols = Perp.getSymbolsForExchange(referenceData, exchangeAndType);
    const availableExchanges: StringSelectItem[] = Perp.getExchangesForSymbol(referenceData, symbol);

    const data = {
      exchangeAndTypeField: new SelectorField({
        name: 'Exchange & Type',
        idProperty: 'value',
        value: availableExchanges.find(ex => ex.value === exchangeAndType),
        availableItems: availableExchanges,
      }),
      symbolField: new SelectorField({
        name: 'Symbol',
        idProperty: 'value',
        value: availableSymbols.find(c => c.value === symbol),
        availableItems: availableSymbols,
      }),
      marketAccountField: new MultiSelectorField<StringSelectItem>({
        name: 'Market Account',
        idProperty: 'value',
      }),
    };

    return new Perp(referenceData, data);
  }

  public get data(): PerpData {
    return this._data;
  }

  public get security(): Security | undefined {
    const { symbolField, exchangeAndTypeField } = this.data;
    if (!symbolField.hasValue || !exchangeAndTypeField.hasValue) {
      return undefined;
    }
    const [BaseCurrency, QuoteCurrency] = symbolField.value!.value.split('-');

    const type = getSettlementTypeFromExchangeAndType(exchangeAndTypeField.value?.value);

    return this._referenceData.securities.perps.find(perp => {
      const marketShortName = getShortExchangeName(perp, this._referenceData.markets.marketsByName);

      return (
        perp.BaseCurrency === BaseCurrency &&
        perp.QuoteCurrency === QuoteCurrency &&
        exchangeAndTypeField.value!.value.includes(marketShortName) &&
        perp.SettleValueType === type
      );
    });
  }

  public updateExchangeAndType = (exchangeAndType: StringSelectItem | undefined) => {
    const newData = {
      ...this._data,
      exchangeAndTypeField: this._data.exchangeAndTypeField.updateValue(exchangeAndType),
      symbolField: this._data.symbolField.updateAvailableItems(
        exchangeAndType
          ? Perp.getSymbolsForExchange(this._referenceData, exchangeAndType.value)
          : Perp.getAvailableSymbols(this._referenceData)
      ),
    };
    return this.updateData(newData);
  };

  public updateSymbol = (symbol: CurrencySelectItem | undefined) => {
    const newData = {
      ...this._data,
      symbolField: this._data.symbolField.updateValue(symbol),
      exchangeAndTypeField: this._data.exchangeAndTypeField.updateAvailableItems(
        symbol
          ? Perp.getExchangesForSymbol(this._referenceData, symbol.value)
          : Perp.getAvailableExchanges(this._referenceData)
      ),
    };
    return this.updateData(newData);
  };

  public updateMarketAccount = (marketAccounts: StringSelectItem[] | undefined) => {
    const newData = {
      ...this._data,
      marketAccountField: this._data.marketAccountField.updateValue(marketAccounts),
    };
    return this.updateData(newData);
  };

  public getType(): ProductTypeEnum {
    return Perp.type;
  }

  private updateData(data: Partial<PerpData>): Perp {
    const newData = {
      ...this._data,
      ...data,
    };
    return new Perp(this._referenceData, newData);
  }

  private static getAvailableSymbols(referenceData: OMSReferenceDataState): CurrencySelectItem[] {
    const ccyPairs = referenceData.securities.perps.map(p => getCurrencyPairFromSecurity(p));
    return Array.from(new Set(ccyPairs)).map(c => ({
      value: c,
      label: c,
      baseCurrency: c?.split('-')[0],
      quoteCurrency: c?.split('-')[1],
    }));
  }

  private static getAvailableExchanges(referenceData: OMSReferenceDataState): StringSelectItem[] {
    const perps = referenceData.securities.perps;
    const markets = perps.map(perp => getExchangeAndTypeFromSecurity(perp, referenceData.markets.marketsByName));

    return Array.from(new Set(markets)).map(ex => ({
      label: ex.split('&')[0],
      value: ex,
    }));
  }

  private static getExchangesForSymbol(referenceData: OMSReferenceDataState, symbol: string): StringSelectItem[] {
    if (!symbol) {
      return Perp.getAvailableExchanges(referenceData);
    }

    const perps = referenceData.securities.perpsByCurrencyPair.get(symbol) || [];
    const markets = perps.map(perp => getExchangeAndTypeFromSecurity(perp, referenceData.markets.marketsByName));

    return Array.from(new Set(markets)).map(ex => ({
      label: ex.split('&')[0],
      value: ex,
    }));
  }

  private static getSymbolsForExchange(
    referenceData: OMSReferenceDataState,
    exchangeAndType: string
  ): CurrencySelectItem[] {
    if (!exchangeAndType) {
      return Perp.getAvailableSymbols(referenceData);
    }
    const perps = referenceData.securities.perps || [];
    const symbols = perps
      .filter(p => isSecurityExchangeAndTypeMatch(p, exchangeAndType, referenceData.markets.marketsByName))
      .map(getCurrencyPairFromSecurity);

    return Array.from(new Set(symbols)).map(ex => ({
      label: ex,
      value: ex,
      baseCurrency: ex?.split('-')[0],
      quoteCurrency: ex?.split('-')[1],
    }));
  }
}

Perp[immerable] = true;
