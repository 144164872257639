import Big from 'big.js';
import { compact, max, uniq } from 'lodash';
import type { ReactNode } from 'react';
import type { Column } from '../components/BlotterTable/columns/types';
import { isOrderComplete } from '../utils/isOrderComplete';
import { isOrderPaused } from '../utils/isOrderPaused';
import { isOrderPending } from '../utils/isOrderPending';
import { percentToBps, toBigWithDefault } from '../utils/number';
import { prettyName } from '../utils/string';
import type { CustomerOrder, PricingParameters as CustomerPricingParameters } from './CustomerOrder';
import type { CustomerDealSummary } from './CustomerOrderSummary';
import { WarningSeverity } from './WarningSeverity';
import {
  DecisionStatusEnum,
  MultilegReportingTypeEnum,
  OrdStatusEnum,
  OrdTypeEnum,
  OrderMarketStatusEnum,
  ReduceFirstEnum,
  ReduceOnlyEnum,
  UnifiedLiquidityEnum,
  type CxlRejReasonEnum,
  type ExecTypeEnum,
  type IAllocation,
  type IOMSExecutionReport4203LegSummary,
  type IOMSExecutionReport4203Markets,
  type IStrategyParameters,
  type OrdRejReasonEnum,
  type OrdRiskStatusEnum,
  type PricingModeEnum,
  type SideEnum,
  type TimeInForceEnum,
} from './types';

export function isOrder(entity: any): entity is Order {
  return entity instanceof Order;
}

export interface OrderWarning {
  severity: WarningSeverity;
  topLevelWarnings: OrderWarningItem[];
  warningItems: OrderMarketWarningItem[];
}

export interface OrderWarningItem {
  severity: WarningSeverity;
  label: string;
  value?: NonNullable<ReactNode>;
}

export interface OrderMarketWarningItem {
  severity: WarningSeverity;
  /** Order.Markets[i].Market */
  market: string;
  /** The .Symbol of the market with the warning. Will only be present if there are multiple symbols on the Order.Markets. */
  symbol: string | undefined;
  value: string;
}

// OMSExecutionReport4203
export class Order {
  static readonly rowID = 'OrderID';
  static readonly defaultColumns: (keyof Order | Column)[] = [
    'SubmitTime',
    'Side',
    'Symbol',
    'OrdStatus',
    { type: 'filledPercent', id: 'filledPercent' },
    'Markets',
    'OrderQty',
    'CumQty',
    'LeavesQty',
    'Price',
    'AvgPx',
    'AvgPxAllIn',
    'CumFee',
    'Strategy',
    'SubAccount',
    'Group',
    'User',
    'OrderID',
    'ClOrdID',
  ];

  AvgPx?: string;

  ClOrdID!: string;

  Comments?: string;

  CumFee?: string;

  CumQty!: string;

  CumAmt?: string;

  DecisionStatus!: DecisionStatusEnum;

  ExecID?: string;

  ExecType?: ExecTypeEnum;

  FeeCurrency?: string;

  LeavesQty?: string;

  OrdStatus!: OrdStatusEnum;

  OrdType!: OrdTypeEnum;

  OrderID!: string;

  OrderQty!: string;

  Price?: string;

  Side!: SideEnum;

  /**
   * The currency of the quantity the order was placed with.
   * Note: a currency can be excluded from an order. For example if the order is placed in number of contracts.
   */
  Currency?: string;

  AmountCurrency?: string;

  Strategy?: string;

  SubmitTime!: string;

  Symbol!: string;

  TimeInForce?: TimeInForceEnum;

  TransactTime?: string;

  Text?: string;

  Parameters?: Partial<IStrategyParameters>;

  MultilegParams?: MultilegParameters;

  Timestamp?: string;

  StartTime?: string;

  EndTime?: string;

  User?: string;

  RequestUser?: string;

  ExpectedFillPrice?: string;

  ExpectedFillQty?: string;

  AvgPxAllIn?: string;

  ParentOrderID?: string;

  CumTalosFee?: string;

  TalosFeeCurrency?: string;

  Revision!: number;

  RiskStatus?: Record<OrdRiskStatusEnum, boolean>;

  Markets!: Partial<IOMSExecutionReport4203Markets>[];

  AllowedSlippage?: string;

  RFQID?: string;

  ParentRFQID?: string;

  QuoteID?: string;

  AggressorSide?: SideEnum;

  OrigClOrdID?: string;

  OrdRejReason?: OrdRejReasonEnum;

  SessionID?: string;

  Allocation?: IAllocation;

  SubAccount?: string;

  Group?: string;

  CxlRejReason?: CxlRejReasonEnum;

  LastTradeTime?: string;

  LastRequestTimestamp?: string;

  LastOrderTime?: string;

  LegSummary?: IOMSExecutionReport4203LegSummary[];
  PricingMode?: PricingModeEnum;
  PricingReference?: string;

  FixingDetails?: {
    Index?: string;
    Fixing?: string;
  };

  /** Only includes LegSummary with MultilegReportingType = Leg */
  get legSummaryLegs(): IOMSExecutionReport4203LegSummary[] | undefined {
    return this.LegSummary?.filter(leg => leg.MultilegReportingType === MultilegReportingTypeEnum.Leg);
  }

  /** Only includes LegSummary with MultilegReportingType = Parent */
  get legSummaryParent(): IOMSExecutionReport4203LegSummary[] | undefined {
    return this.LegSummary?.filter(leg => leg.MultilegReportingType === MultilegReportingTypeEnum.Parent);
  }

  get priceProtection() {
    return this.Parameters?.PriceProtection;
  }

  get reduceOnly() {
    return this.Parameters?.ReduceOnly || ReduceOnlyEnum.Disabled;
  }

  get reduceFirst() {
    return this.Parameters?.ReduceFirst || ReduceFirstEnum.Disabled;
  }

  /**
   * Whether or not there are multiple symbols represented in the Order.Markets array
   * This will be true for the multileg and unified liquidity order cases for example.
   */
  get hasMultiSymbolMarkets(): boolean {
    const symbolsFoundInMarketsArray = uniq(this.Markets.map(m => m.Symbol));
    return symbolsFoundInMarketsArray.length > 1;
  }

  get warning(): OrderWarning | null {
    return getMarketWarnings({ entity: this });
  }

  get unifiedLiquidity(): UnifiedLiquidityEnum {
    return this.Parameters?.UnifiedLiquidity || UnifiedLiquidityEnum.Disabled;
  }

  get warningSeverity(): WarningSeverity {
    return this.warning?.severity || WarningSeverity.NONE;
  }

  get filledPx(): string | undefined {
    if (this.OrdType === OrdTypeEnum.LimitAllIn) {
      return this.AvgPxAllIn;
    }
    return this.AvgPx;
  }

  get selectedMarkets(): string[] {
    return uniq(
      compact(this.Markets.map(market => (market.MarketStatus !== OrderMarketStatusEnum.Disabled ? market.Market : '')))
    );
  }

  get tradedMarkets(): string[] {
    return uniq(compact(this.Markets?.filter(m => toBigWithDefault(m.CumQty, 0).gt(0)).map(m => m.Market)));
  }

  get selectedAndTradedMarkets(): string[] {
    return uniq(this.selectedMarkets.concat(this.tradedMarkets));
  }

  get selectedMarketAccounts(): string[] {
    return uniq(
      compact(
        this.Markets.map(market => (market.MarketStatus !== OrderMarketStatusEnum.Disabled ? market.MarketAccount : ''))
      )
    );
  }

  get tradedMarketAccounts(): string[] {
    return uniq(compact(this.Markets?.filter(m => toBigWithDefault(m.CumQty, 0).gt(0)).map(m => m.MarketAccount)));
  }

  get selectedAndTradedMarketAccounts(): string[] {
    return uniq(this.selectedMarketAccounts.concat(this.tradedMarketAccounts));
  }

  get remainQty(): string {
    return Big(this.OrderQty || 0)
      .minus(this.CumQty || 0)
      .toFixed();
  }

  get startTime(): string {
    return this.Parameters?.StartTime ?? this.StartTime ?? '';
  }

  get endTime(): string {
    return this.Parameters?.EndTime ?? this.EndTime ?? '';
  }

  customerOrderID?: string;

  customerSymbol?: string;

  customerOrder?: string;

  customerAccount?: string;

  customerQuantity?: string;

  customerFilledQuantity?: string;

  customerFilledCumAmt?: string;

  customerAmountCurrency?: string;

  customerPrice?: string;

  customerAvgPx?: string;

  customerStrategy?: string;

  customerPricingParams?: CustomerPricingParameters;

  customerSummary?: CustomerDealSummary;

  get allowedSlippageBPS(): string {
    return percentToBps(this.AllowedSlippage);
  }

  get isCancelable(): boolean {
    return !(this.isPendingOrdStatus || this.isComplete);
  }

  get isModifiable(): boolean {
    return !(this.isPendingOrdStatus || this.isComplete) && !this.ParentOrderID;
  }

  // Note: this class represents a principal order in the blotter, based on the optional customer fields we decide
  // if we allow initializing customer order modification workflow
  get isCustomerOrderModifiable(): boolean {
    const canModify = !(this.isPendingOrdStatus || this.isComplete);
    const customerPropertiesLoaded =
      !!this.ParentOrderID &&
      !!this.customerAccount &&
      !!this.customerQuantity &&
      !!this.customerPrice &&
      !!this.customerPricingParams &&
      !!this.customerStrategy;

    return canModify && customerPropertiesLoaded;
  }

  get isPausable(): boolean {
    // Not calling isPaused here as "SystemPaused" orders are also pausable
    return (
      !(
        this.isPendingDecisionStatus ||
        [DecisionStatusEnum.Paused, DecisionStatusEnum.Staged].includes(this.DecisionStatus)
      ) && !this.isComplete
    );
  }

  get isResumable(): boolean {
    return !this.isPendingDecisionStatus && this.isPaused && !this.isComplete;
  }

  get isComplete(): boolean {
    return [OrdStatusEnum.Canceled, OrdStatusEnum.Filled, OrdStatusEnum.Rejected, OrdStatusEnum.DoneForDay].includes(
      this.OrdStatus
    );
  }

  get isPaused(): boolean {
    return isOrderPaused(this.DecisionStatus);
  }

  // Private for now to encourage using the more abstract getters (isCancelable etc.)
  private get isPendingOrdStatus(): boolean {
    return isOrderPending(this.OrdStatus);
  }

  private get isPendingDecisionStatus(): boolean {
    return [DecisionStatusEnum.PendingPause, DecisionStatusEnum.PendingResume].includes(this.DecisionStatus);
  }

  /**
   * Order blotter displays Order objects originating from ORDERS subscription, however for Customer Orders
   * we would like the rows to contain customer specific properties which are extracted from CUSTOMER_ORDERS subscription
   */
  public enrichOrderWithCustomerOrder(customerOrder?: CustomerOrder): void {
    this.customerOrderID = customerOrder?.OrderID;
    this.customerSymbol = customerOrder?.Symbol;
    this.customerOrder = customerOrder?.Counterparty;
    this.customerAccount = customerOrder?.MarketAccount;
    this.customerQuantity = customerOrder?.OrderQty;
    this.customerFilledQuantity = customerOrder?.CumQty;
    this.customerFilledCumAmt = customerOrder?.CumAmt;
    this.customerAmountCurrency = customerOrder?.AmountCurrency;
    this.customerPrice = customerOrder?.Price;
    this.customerAvgPx = customerOrder?.AvgPx;
    this.customerSummary = customerOrder?.summary;
    this.customerPricingParams = customerOrder?.PricingParameters;
    this.customerStrategy = customerOrder?.Strategy;
  }

  // There might be a better way to do this but at least we should be uniform
  public get hasBeenEnrichedWithCustomerOrder(): boolean {
    return this.customerOrderID !== undefined;
  }

  constructor(data: Order) {
    Object.assign(this, data);
  }
}

interface MultilegParameters {
  LegParams: MultilegParametersLegParams[];
}

/** IMultilegParameters12001LegParams but with boolean instead of BoolEnum to match actual WS message */
interface MultilegParametersLegParams {
  MaxRestingLevels: number;
  CrossingAllowed: boolean;
  Initiating: boolean;
}

export const getMarketWarnings = ({
  entity,

  includeTopLevelText = true,
}: {
  entity: Pick<Order, 'OrdStatus' | 'Markets' | 'DecisionStatus' | 'Text'>;

  includeTopLevelText?: boolean;
}): OrderWarning | null => {
  // Do not show warnings for completed orders
  if (isOrderComplete(entity.OrdStatus)) {
    return null;
  }

  // If there's more than one symbol represented in the Markets array
  const hasMultiSymbolMarkets = uniq(compact(entity.Markets.map(m => m.Symbol))).length > 1;

  // Disabled market means unselected by user, we ignore these
  const selectedMarkets = entity.Markets.filter(m => m.MarketStatus !== OrderMarketStatusEnum.Disabled);

  if (selectedMarkets.length === 0) {
    return null;
  }

  // Out of selected markets, find which that are not tradable. We want to show warnings for these.
  const untradableMarkets = selectedMarkets.filter(market => {
    // Specific case: if the order is paused, and market data is stale, we choose to not surface this warning - its fine
    if (
      entity.DecisionStatus === DecisionStatusEnum.Paused &&
      market.MarketStatus === OrderMarketStatusEnum.MarketDataStale
    ) {
      return false;
    }

    return market.MarketStatus !== OrderMarketStatusEnum.Online;
  });

  const topLevelWarnings: OrderWarningItem[] = [];

  if (includeTopLevelText) {
    // If there is a top level .Text on the order, we'll want to include it in topLevelWarnings.
    if (entity.Text) {
      if (entity.Text.includes('Protection')) {
        topLevelWarnings.push({ label: entity.Text, severity: WarningSeverity.LOW });
      } else {
        topLevelWarnings.push({ label: entity.Text, severity: WarningSeverity.MEDIUM });
      }
    }
  }

  if (untradableMarkets.length === selectedMarkets.length) {
    topLevelWarnings.push({
      severity: WarningSeverity.HIGH,
      label: `No markets are tradable`,
      value: `0 / ${selectedMarkets.length}`,
    });
  } else if (untradableMarkets.length > selectedMarkets.length / 2) {
    topLevelWarnings.push({
      severity: WarningSeverity.MEDIUM,
      label: `Less than 50% of markets are tradable`,
      value: `${untradableMarkets.length} / ${selectedMarkets.length}`,
    });
  } else if (untradableMarkets.length > 0) {
    topLevelWarnings.push({
      severity: WarningSeverity.LOW,
      label: `Not all markets are tradable`,
      value: `${untradableMarkets.length} / ${selectedMarkets.length}`,
    });
  }
  const severity = max(topLevelWarnings.map(w => w.severity)) || WarningSeverity.NONE;

  if (severity === WarningSeverity.NONE) {
    return null;
  }

  // For untradable markets, display the error text.
  const warningItems: OrderMarketWarningItem[] = compact(
    untradableMarkets.map(untradableMarket => {
      if (untradableMarket.Market) {
        return {
          severity: WarningSeverity.LOW,
          market: untradableMarket.Market,
          symbol: hasMultiSymbolMarkets ? untradableMarket.Symbol : undefined,
          value:
            untradableMarket.Text ??
            (untradableMarket.MarketStatus ? prettyName(untradableMarket.MarketStatus) : 'Market is not tradable'),
        };
      }
      return null;
    })
  );

  return { topLevelWarnings, severity, warningItems };
};
