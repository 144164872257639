import { ControlPrefix, FormControlSizes, SearchSelect, useDynamicCallback } from '@talos/kyoko';
import { useMemo } from 'react';
import { useSubAccounts } from '../../../../providers';
import { PerformanceActionType } from '../PerformanceReducer';
import { usePerformanceContext } from '../providers/PerformanceStateAndTabsProvider';
import { ALL_SUB_ACCOUNTS_PLACEHOLDER } from '../tokens';

interface Item {
  value: string | undefined;
  label: string;
  description?: string;
}

function getGroup(item: Item) {
  return item.description;
}

function getDescription(item: Item) {
  return item.description ?? '';
}

function getLabel(item: Item) {
  return item.label;
}

export const SubAccountControl = () => {
  const {
    state: { subAccounts },
    dispatch,
  } = usePerformanceContext();

  const { allSubAccounts } = useSubAccounts();

  const options = useMemo(() => {
    const newOptions = (allSubAccounts ?? []).map<Item>(subAccount => ({
      value: subAccount.Name,
      label: subAccount.DisplayName ?? subAccount.Name,
      description: subAccount.Type,
    }));

    newOptions.unshift({ value: undefined, label: 'All Sub Accounts' });
    return newOptions;
  }, [allSubAccounts]);

  const selection = useMemo(() => {
    const selectionInFilter: string | undefined = subAccounts[0];
    return options.find(option => option.value === selectionInFilter);
  }, [options, subAccounts]);

  const handleChange = useDynamicCallback((newSelection: Item | undefined) => {
    dispatch({
      type: PerformanceActionType.SubAccountsChange,
      payload: {
        subAccounts: newSelection?.value ? [newSelection.value] : [],
      },
    });
  });

  return (
    <SearchSelect
      prefix={<ControlPrefix>Sub Account</ControlPrefix>}
      placeholder={ALL_SUB_ACCOUNTS_PLACEHOLDER}
      selection={selection}
      getLabel={getLabel}
      options={options}
      onChange={handleChange}
      getGroup={getGroup}
      getDescription={getDescription}
      size={FormControlSizes.Small}
      dropdownWidth="300px"
      maxHeight={300}
      dropdownPlacement="bottom-start"
    />
  );
};
