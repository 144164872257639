import { SettleValueTypeEnum, isSecurityQuantoFuture, type Market, type Security, type SelectItem } from '@talos/kyoko';

export const MarketsWithMicroContracts = ['cme'];

// This is an ugly hack with consequences - https://talostrading.atlassian.net/browse/UI-2797
// since BE can't properly give us DisplayName we need to manipulate it ourselves
// However this means that we are not able to reverse lookup on context providers with this new label as "DisplayName" won't match
// Until the BE fixes this, UI needs to keep track of the original market and the new value as shown below
export function getShortExchangeName(security: Security, marketsByName: Map<string, Market>): string {
  const market = security.Markets?.[0] || '';
  const displayName = marketsByName.get(market)?.DisplayName || '';
  return displayName.split('Futures')[0].trim();
}

export function getExchangeAndTypeFromSecurity(security: Security, marketsByName: Map<string, Market>): string {
  const market = security.Markets?.[0];
  const name = getShortExchangeName(security, marketsByName);
  const suffix = getExchangeNameSuffix(security);
  // Consequence of the hack above, keeping the real market around
  return `${name} ${suffix}&${market}`;
}

export function isSecurityExchangeAndTypeMatch(
  security: Security,
  exchangeAndType: string,
  marketsByName: Map<string, Market>
) {
  const securityExchangeAndType = getExchangeAndTypeFromSecurity(security, marketsByName);
  return securityExchangeAndType === exchangeAndType;
}

export function getExchangeNameSuffix(security: Security): string {
  return isSecurityQuantoFuture(security)
    ? 'Quanto'
    : security.SettleValueType === SettleValueTypeEnum.Inverted
    ? 'COIN-M'
    : `${security.SettlementCurrency}-M`;
}

export function getSettlementTypeFromExchangeAndType(exchangeAndType?: string): SettleValueTypeEnum | undefined {
  if (!exchangeAndType) {
    return undefined;
  }
  return exchangeAndType.includes('COIN-M') ? SettleValueTypeEnum.Inverted : SettleValueTypeEnum.Regular;
}

export type StringSelectItem = SelectItem<string>;

export interface CurrencySelectItem extends StringSelectItem {
  // metadata used in multileg combo to filter out un-eligible symbols
  baseCurrency: string;
  quoteCurrency: string;
}
