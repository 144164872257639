import {
  ACTION,
  AccordionGroup,
  BLOTTER_TABLE_FILTERS_CONTAINER_ID,
  Box,
  Button,
  ButtonVariants,
  CustomerLedgerUpdateTypeEnum,
  FormControlSizes,
  HStack,
  IconName,
  LookbackOption,
  MixpanelEvent,
  MixpanelEventProperty,
  MixpanelEventSource,
  ROLE,
  Tab,
  TabList,
  TabPanels,
  TabSize,
  Tabs,
  VStack,
  tabLabelerEnumerated,
  useDisclosure,
  useDynamicCallback,
  useMixpanel,
  usePersistedTabs,
  usePortal,
  useTabs,
  type Column,
  type TabProps,
  type Transfer,
  type UseTabsProps,
} from '@talos/kyoko';
import type { GetContextMenuItemsParams, MenuItemDef } from 'ag-grid-community';
import { compact } from 'lodash';
import { useCallback, useMemo, useState } from 'react';
import { SUB_HEADER_HEIGHT } from 'styles/dimensions';
import { useRoleAuth, useTransfersDrawer } from '../../../hooks';
import { NewBalanceTransactionDialog } from '../../Dealer/CustomerBalances/NewBalanceTransactionDialog';
import { RecentTransfers } from './RecentTransfers';
import { TRANSFERS_BLOTTER_PREFIX } from './tokens';
import { useTransfersColumns } from './useTransfersColumns';
import type { TransfersBlotterFilter } from './useTransfersFilter';

export interface TransfersTabProps extends TabProps {
  defaultFilter: Partial<TransfersBlotterFilter>;
  defaultColumns: Column<any>[];
}
const RECENT_TRANSFERS_TAB_ID = 'recent-transfers';
const TABS_EXCLUDED_IDS = [RECENT_TRANSFERS_TAB_ID];

const tabLabeler = tabLabelerEnumerated('Transfers');

export function TransfersBlotter({ noHeader = false, showNewTransfer = true }) {
  const mixpanel = useMixpanel();
  const [transfer, setTransfer] = useState<Transfer | undefined>();
  const { isAuthorized, isRole } = useRoleAuth();
  const canSubmitTransfer = isAuthorized(ACTION.SUBMIT_TRANSFER);
  const canCreateBalanceTransaction = isRole(ROLE.TALOS_SUPPORT) || isRole(ROLE.DEALER);

  const defaultColumns = useTransfersColumns();

  const [initialFiltersOpen, setInitialFiltersOpen] = useState(false);

  const handleSelect = useDynamicCallback(() => setInitialFiltersOpen(false));
  const handleAdd = useDynamicCallback(() => {
    mixpanel.track(MixpanelEvent.AddTab);
    setInitialFiltersOpen(true);
  });

  const newBalanceTransactionDialog = useDisclosure();

  // reset form state when opening new
  const openNewBalanceTransactionDialog = useCallback(
    (transfer?: Transfer) => {
      setTransfer(transfer);
      newBalanceTransactionDialog.open();
    },
    [newBalanceTransactionDialog]
  );

  const newTabDefaults = useMemo(() => {
    return {
      defaultFilter: {
        _start: LookbackOption.Past30Days,
      },
      defaultColumns,
    } satisfies TransfersTabProps;
  }, [defaultColumns]);

  const recentTransfersTab = useMemo(() => {
    return {
      label: 'Recent',
      id: RECENT_TRANSFERS_TAB_ID,
      closable: false,
      editable: false,
      defaultFilter: {
        _start: LookbackOption.Past30Days,
      },
      defaultColumns,
    } satisfies TransfersTabProps;
  }, [defaultColumns]);

  const defaultTransfersTabs = [recentTransfersTab];

  const persistedTabs = usePersistedTabs<TransfersTabProps>(TRANSFERS_BLOTTER_PREFIX, {
    defaultInitialItems: defaultTransfersTabs,
    defaultInitialSelectedIndex: 0,
    excludedIDs: TABS_EXCLUDED_IDS,
    onSelect: handleSelect,
  });

  const onTabSelected = useCallback(
    (index, tabs) => {
      mixpanel.track(MixpanelEvent.SetTransfersBlotterTab, {
        [MixpanelEventProperty.TabIndex]: index,
        [MixpanelEventProperty.TabLabel]: tabs?.[index]?.label ?? '',
      });
      persistedTabs.onSelect(index);
    },
    [mixpanel, persistedTabs]
  ) satisfies UseTabsProps['onSelect'];

  const { openTransfersDrawer } = useTransfersDrawer();

  const tabs = useTabs<TransfersTabProps>({
    ...persistedTabs,
    initialItems: persistedTabs.initialItems,
    showAddTab: true,
    tabLabeler,
    onAdd: handleAdd,
    onSelect: onTabSelected,
  });
  const handleCloneTab = useCallback(
    (filter: TransfersBlotterFilter, columns: Column[]) => {
      mixpanel.track(MixpanelEvent.CloneTab);
      tabs.clone(tabs.selectedIndex, {
        defaultColumns: columns,
        defaultFilter: filter,
        closable: true,
        reorderable: true,
      });
    },
    [mixpanel, tabs]
  );

  const getContextMenuItems = useDynamicCallback((params: GetContextMenuItemsParams<Transfer>) => {
    const transferMenuItems: MenuItemDef[] = [];
    if (canCreateBalanceTransaction) {
      const transfer = params?.node?.data;
      if (transfer != null && !transfer.ParentTransactionID && !transfer?.isTransferOutgoing) {
        transferMenuItems.push({
          name: 'Create Customer Deposit',
          action: () => {
            openNewBalanceTransactionDialog(transfer);
          },
          icon: `<i class="ag-icon ${IconName.DocumentDownload}"/>`,
        });
      }
    }
    return compact([...transferMenuItems]);
  });

  const { setPortalRef: filtersContainerRef } = usePortal(BLOTTER_TABLE_FILTERS_CONTAINER_ID);

  return (
    <VStack justifyContent="start" alignItems="left" h="100%">
      <Tabs {...tabs} h={`calc(100% - ${SUB_HEADER_HEIGHT}px)`} size={TabSize.Large}>
        <TabList
          isBordered
          rightItems={
            <HStack gap="spacingSmall">
              {canSubmitTransfer && showNewTransfer && (
                <Button
                  startIcon={IconName.Plus}
                  variant={ButtonVariants.Default}
                  size={FormControlSizes.Small}
                  onClick={() => openTransfersDrawer({ source: MixpanelEventSource.TransferBlotter })}
                >
                  New Transfer
                </Button>
              )}
              <Box ref={filtersContainerRef} />
            </HStack>
          }
        >
          {tabs.items.map((tab, idx) => (
            <Tab key={idx} {...tab} reorderable={tab.id !== RECENT_TRANSFERS_TAB_ID} />
          ))}
        </TabList>
        <TabPanels style={{ flex: '1', display: 'flex', flexDirection: 'column' }}>
          {tabs.items.map(tab => (
            <AccordionGroup key={tab.id}>
              <RecentTransfers
                key={tab.id}
                blotterID={`${TRANSFERS_BLOTTER_PREFIX}/${tab.id}`}
                tabLabel={tab.label}
                defaultColumns={tab.defaultColumns ?? newTabDefaults.defaultColumns}
                defaultFilter={tab.defaultFilter ?? newTabDefaults.defaultFilter}
                onCloneTab={handleCloneTab}
                initialIsOpen={initialFiltersOpen}
                getContextMenuItems={getContextMenuItems}
              />
            </AccordionGroup>
          ))}
        </TabPanels>
      </Tabs>
      <NewBalanceTransactionDialog
        {...newBalanceTransactionDialog}
        entity={transfer}
        transactionType={CustomerLedgerUpdateTypeEnum.Deposit}
      />
    </VStack>
  );
}
