import { formattedDateForSubscription, removeEmptyFilters, type DateRangeFilter } from '@talos/kyoko';
import { cloneDeep, isEqual } from 'lodash';
import type { BalancesFilter } from '../../../types';
import type { BalancesBlotterShowBy } from '../../Blotters/BalancesV2/types';
import {
  getDefaultTreasuryManagementState,
  type TreasuryManagementState,
} from './providers/getDefaultTreasuryManagementState';
import type { MergedBalance } from './types';

export const VisualizationVariants = ['Value', 'Equal'] as const;
export type VisualizationVariant = (typeof VisualizationVariants)[number];

export const DEV_NOGROUP_GROUP = '$DEV_NOGROUP_GROUP';
export type MergedBalanceIndexKeys = keyof Pick<MergedBalance, 'currency' | 'group' | 'marketAccountID' | 'market'>;

export const KEYS_BY_COUNTERPARTY: MergedBalanceIndexKeys[] = ['marketAccountID', 'currency'];
export const KEYS_BY_ASSET: MergedBalanceIndexKeys[] = ['currency', 'marketAccountID'];

export function showByToIndexingKeys(showBy: BalancesBlotterShowBy) {
  return showBy === 'counterparty' ? KEYS_BY_COUNTERPARTY : KEYS_BY_ASSET;
}

/**
 *
 * @param drillKeys flattened drill keys, eg ["ETH", "coinbase", 123]
 * @param showBy show by state from the reducer, for example
 * @returns A list of equal length to the passed in drillkeys, with
 */
export function drillKeysToIndexingKeys(
  drillKeys: MergedBalance[MergedBalanceIndexKeys][],
  showBy: BalancesBlotterShowBy
): MergedBalanceIndexKeys[] {
  const indexingKeys = showByToIndexingKeys(showBy);
  return drillKeys.map((_, index) => indexingKeys[index]);
}

/**
 * Using the passed drillKeys and showBy parameters, creates a map going from index keys -> drill keys
 * @param drillKeys flattened drill keys, for example taken from the reducer
 * @param showBy
 * @returns a map from index key -> drill key
 */
export function createIndexingKeyToDrillKeyMap(
  drillKeys: MergedBalance[MergedBalanceIndexKeys][],
  showBy: BalancesBlotterShowBy
) {
  const indexKeys = drillKeysToIndexingKeys(drillKeys, showBy);
  return new Map(indexKeys.map((indexKey, i) => [indexKey, drillKeys[i]]));
}

export enum TreasuryManagementActionType {
  ShowByChange,
  ShowUnsettledChange,
  VisualizationChange,
  Drilldown,
  Drillup,
  FilterChange,
  ShowZeroBalancesChange,
  TabChange,
  ResolutionChange,
  ChartRequestDateRangeChange,
  SnapshotDateChange,
}

interface ShowByChangeAction {
  type: TreasuryManagementActionType.ShowByChange;
  payload: {
    showBy: BalancesBlotterShowBy;
  };
}

interface DrilldownAction {
  type: TreasuryManagementActionType.Drilldown;
  payload: {
    keys: MergedBalance[MergedBalanceIndexKeys][];
  };
}

interface DrillupAction {
  type: TreasuryManagementActionType.Drillup;
}

interface ShowUnsettledChangeAction {
  type: TreasuryManagementActionType.ShowUnsettledChange;
  payload: {
    showUnsettled: boolean;
  };
}

interface VisualizationChangeAction {
  type: TreasuryManagementActionType.VisualizationChange;
  payload: {
    visualization: VisualizationVariant;
  };
}

interface FilterChangeAction {
  type: TreasuryManagementActionType.FilterChange;
  payload: {
    filter: BalancesFilter;
  };
}

interface ShowZeroBalancesChangeAction {
  type: TreasuryManagementActionType.ShowZeroBalancesChange;
  payload: {
    showZeroBalances: boolean;
  };
}

interface TabChangeAction {
  type: TreasuryManagementActionType.TabChange;
  payload: {
    filter: BalancesFilter;
    showBy: BalancesBlotterShowBy;
    showZeroBalances: boolean;
  };
}

interface ChartRequestDateRangeChange {
  type: TreasuryManagementActionType.ChartRequestDateRangeChange;
  payload: {
    chartRequestDateRange: DateRangeFilter;
  };
}

interface SnapshotDateChange {
  type: TreasuryManagementActionType.SnapshotDateChange;
  payload: {
    // Here we accept Date | string | null, which is then converted into string | null for storage in the tab.
    snapshotDate: Date | string | null;
  };
}

export type TreasuryManagementAction =
  | ShowByChangeAction
  | ShowUnsettledChangeAction
  | VisualizationChangeAction
  | DrilldownAction
  | DrillupAction
  | FilterChangeAction
  | ShowZeroBalancesChangeAction
  | TabChangeAction
  | ChartRequestDateRangeChange
  | SnapshotDateChange;

export const treasuryManagementReducer = (state: TreasuryManagementState, action: TreasuryManagementAction) => {
  switch (action.type) {
    case TreasuryManagementActionType.ShowByChange: {
      return {
        ...state,
        drillKeys: [],
        flattenedDrillKeys: [],
        showBy: action.payload.showBy,
      };
    }
    case TreasuryManagementActionType.ShowUnsettledChange: {
      return {
        ...state,
        showUnsettled: action.payload.showUnsettled,
      };
    }
    case TreasuryManagementActionType.VisualizationChange: {
      return {
        ...state,
        visualization: action.payload.visualization,
      };
    }
    case TreasuryManagementActionType.Drilldown: {
      const newDrillKeys = [...state.drillKeys, action.payload.keys];
      return {
        ...state,
        drillKeys: newDrillKeys,
        flattenedDrillKeys: newDrillKeys.flat(),
        highlightedSlice: undefined,
      };
    }
    case TreasuryManagementActionType.Drillup: {
      if (state.drillKeys.length > 0) {
        const newDrillKeys = cloneDeep(state.drillKeys.slice(0, -1));
        return {
          ...state,
          drillKeys: newDrillKeys,
          flattenedDrillKeys: newDrillKeys.flat(),
        };
      } else {
        return state;
      }
    }
    case TreasuryManagementActionType.FilterChange: {
      const currentFilters = removeEmptyFilters(state.filter);
      const nextFilters = removeEmptyFilters(action.payload.filter);
      if (isEqual(currentFilters, nextFilters)) {
        return state;
      }

      return {
        ...state,
        filter: nextFilters,
        // Also reset the request date range here since we have to zoom out
        chartRequestDateRange: getDefaultTreasuryManagementState().chartRequestDateRange,
      };
    }
    case TreasuryManagementActionType.ShowZeroBalancesChange: {
      return {
        ...state,
        showZeroBalances: action.payload.showZeroBalances,
      };
    }
    case TreasuryManagementActionType.TabChange: {
      return {
        ...state,
        ...action.payload,
        // Also reset the request date range here
        chartRequestDateRange: getDefaultTreasuryManagementState().chartRequestDateRange,
      };
    }
    case TreasuryManagementActionType.ChartRequestDateRangeChange: {
      return {
        ...state,
        chartRequestDateRange: action.payload.chartRequestDateRange,
      };
    }
    case TreasuryManagementActionType.SnapshotDateChange: {
      // We accept Date | string | null. If is instance of date, convert to string for storage.
      return {
        ...state,
        snapshotDate:
          action.payload.snapshotDate instanceof Date
            ? formattedDateForSubscription(action.payload.snapshotDate)
            : action.payload.snapshotDate,
      };
    }
    default:
      return state;
  }
};
